import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as thunks from "./thunks";
import { InitialState } from "./types";

const initialState: InitialState = {
  listings: [],
  promotionalDetails: {},
  content: "",
  counts: null,
  slug: "",
  uuid: "",
  isLoading: false,
  isLoadingContent: false,
  meta: {},
};

const slice = createSlice({
  name: "App",
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Promotional lists
    builder.addCase(thunks.getPromotionalListingsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunks.getPromotionalListingsThunk.rejected, (state) => {
      state.isLoading = false;
      console.error("failed to fetched promotionals");
    });
    builder.addCase(
      thunks.getPromotionalListingsThunk.fulfilled,
      (state, { payload }) => {
        state.listings = payload.originalData.data;
        state.meta = payload.originalData.meta;
        state.isLoading = false;
      }
    );

    // Promotional Details
    builder.addCase(thunks.getPromotionalDetailsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunks.getPromotionalDetailsThunk.rejected, (state) => {
      state.isLoading = false;
      console.error("failed to fetched promotionals promotionaldetails");
    });
    builder.addCase(
      thunks.getPromotionalDetailsThunk.fulfilled,
      (state, { payload }) => {
        const { details, ...data } = payload?.originalData?.data || {};
        state.promotionalDetails = data || {};
        state.content = atob(details || ""); // decode base64 to html code
        state.isLoading = false;
      }
    );

    // Promotional Content
    builder.addCase(thunks.getPromotionalContentThunk.pending, (state) => {
      state.isLoadingContent = true;
    });
    builder.addCase(thunks.getPromotionalContentThunk.rejected, (state) => {
      state.isLoadingContent = false;
      console.error("failed to fetched content");
    });
    builder.addCase(
      thunks.getPromotionalContentThunk.fulfilled,
      (state, { payload }) => {
        const { content, slug, id } = payload.originalData.data;
        state.content = atob(content || ""); // decode base64 to html code
        state.slug = slug;
        state.uuid = id;
        state.isLoadingContent = false;
      }
    );

    builder.addCase(
      thunks.getPromotionalCountsThunk.fulfilled,
      (state, { payload }) => {
        state.counts = payload.originalData.published_counts;
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = {
  ...slice.actions,
  ...thunks,
};
