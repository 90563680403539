import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sevenDaysAgo } from "common";
import {
  ProductDetailsType,
  ProductPartDetailsType,
} from "modules/product-details/types";
import { Product } from "modules/products/types";

interface State {
  recentlyViewedItems: Product[];
}

const initialState: State = {
  recentlyViewedItems: [],
};

const setRecentlyViewedItems = (
  state,
  { payload }: PayloadAction<Product[]>
) => {
  state.recentlyViewedItems = payload;
};

const addItemToList = (
  state,
  { payload }: PayloadAction<ProductDetailsType>
) => {
  const maxItems = 10;
  const { retail_price: amount, image_url: img_thumbnail, ...rest } = payload;

  const formattedPayload = {
    amount,
    img_thumbnail,
    lastViewed: Date.now(),
    ...rest,
  };

  let updatedItems = state.recentlyViewedItems
    .filter((i) => i.lastViewed > sevenDaysAgo)
    .filter((i) => i.product_uuid !== payload.product_uuid);

  updatedItems.unshift(formattedPayload);

  if (updatedItems.length > maxItems) {
    updatedItems.pop();
  }

  state.recentlyViewedItems = [...updatedItems];
};

const slice = createSlice({
  name: "RecentlyViewed",
  initialState,
  reducers: { addItemToList, setRecentlyViewedItems },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions };
