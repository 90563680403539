import { Api, ApiResponse } from "common";
import { GetBannersResponse } from "./types"; //import types.ts GetBannersResponse

const getBanner = (): ApiResponse<GetBannersResponse> => {
  return Api.get(`api/ecomm/tarps/get`);
};

export const BannersApi = {
  getBanner,
};
