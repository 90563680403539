import { Api, ApiResponse } from "common";
import {
  PCBundleDetailsResponse,
  PCBundleInitialResponse,
  PCBundleListResponse,
  PcBundleManufacturerResponse,
  PCBundlePayload,
  PCBundleSpecificationsResponse,
  PCBundleVideosResponse,
} from "./types";

const getPCBundleList = (brand?: string): ApiResponse<PCBundleListResponse> => {
  return Api.get(`api/ecomm/pc_bundle/get`, { brand });
};

const getPCBundleInitial = (
  payload: PCBundlePayload
): ApiResponse<PCBundleInitialResponse> => {
  return Api.get(`api/ecomm/pc_bundle/get/${payload?.pc_bundle_id}?init=1`);
};

const getPCBundleDetails = (
  payload: PCBundlePayload
): ApiResponse<PCBundleDetailsResponse> => {
  return Api.get(
    `api/ecomm/pc_bundle/get/${payload?.pc_bundle_id}?init=0&section=details`
  );
};

const getPCBundleVideos = (
  payload: PCBundlePayload
): ApiResponse<PCBundleVideosResponse> => {
  return Api.get(
    `api/ecomm/pc_bundle/get/${payload?.pc_bundle_id}?init=0&section=videos`
  );
};

const getPCBundleSpecifications = (
  payload: PCBundlePayload
): ApiResponse<PCBundleSpecificationsResponse> => {
  return Api.get(
    `api/ecomm/pc_bundle/get/${payload?.pc_bundle_id}?init=0&section=specifications`
  );
};

const getPCBundleManufacturer =
  (): ApiResponse<PcBundleManufacturerResponse> => {
    return Api.get(`api/ecomm/pc_bundle/manufacturers/get`);
  };

export const PcBundleApi = {
  getPCBundleList,
  getPCBundleInitial,
  getPCBundleDetails,
  getPCBundleVideos,
  getPCBundleSpecifications,
  getPCBundleManufacturer,
};
