import modules from "modules";
import { useAppSelector } from "./use-app-selector";
import { useAppDispatch } from "./use-app-dispatch";
import { useSnackBar } from "./use-snackbar";
import { localize } from "common";
import { useCallback } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { debounce } from "@mui/material";
import { AddCartItem, CheckoutCart } from "modules/user-cart/types";
import { ProductDetailsType } from "modules/product-details/types";
import { fbqTrack } from "utils/FacebookMetaPixel";

const { constants: metaPixelConstants } = modules.metaPixel;
const { selectors: PcwUserCartSelectors, actions: PcwUserCartActions } =
  modules.pcwUserCart;
const { selectors: productDetailsSelectors, actions: productDetailsActions } =
  modules.productDetails;

export const usePcwUserCart = () => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackBar();

  //=========Cart selectors===================
  const pcwUserCartItem = useAppSelector(
    PcwUserCartSelectors?.selectUserCartItem
  );

  const pcwUserCartItemWithoutStock = useAppSelector(
    PcwUserCartSelectors?.selectUserCartItemWithoutStock
  );

  const pcwUserCartLength = useAppSelector(
    PcwUserCartSelectors?.selectUserCartLength
  );

  const pcwCartTotalAmount = useAppSelector(
    PcwUserCartSelectors?.selectUserCartTotalAmount
  );

  const pcwCheckOutCart = useAppSelector(
    PcwUserCartSelectors?.selectCheckoutCart
  );

  const userCartLoading = useAppSelector(
    PcwUserCartSelectors?.selectUserCartLoading
  );

  const productDetails: ProductDetailsType = useAppSelector(
    productDetailsSelectors.selectProductDetails
  );

  //========Helpers===========
  const debouncedGetUserCartLength = useCallback(
    debounce(() => {
      dispatch(
        PcwUserCartActions?.getUserCartLength({ count_only: 1, limit: 99 })
      );
    }, 200),
    [dispatch]
  );

  const debouncedGetUserCartItem = useCallback(
    debounce(() => {
      dispatch(
        PcwUserCartActions?.getUserCartItemThunk({
          limit: 99,
          with_stocks: 1,
        })
      );
    }, 200),
    [dispatch]
  );

  const isItemInCartWithStock = (slug: string) => {
    const itemWithStocks = pcwUserCartItem.find(
      (cartItem) => cartItem?.product_slug === slug
    );

    if (!itemWithStocks) {
      return true;
    } else {
      return itemWithStocks && itemWithStocks.quantity < itemWithStocks.stocks;
    }
  };

  //===========Cart Actions =============
  //isMultiple variable is used to check if the user is adding multiple product one at a time
  //checkoutCartItem is used to get the whole object of each product component, added to state
  const addUserCartItem = useCallback(
    async (
      itemSlug?: string,
      quantity?: number,
      checkoutCartItem?: CheckoutCart
    ) => {
      //this variable is used to check the stocks of single item
      const checkItem = isItemInCartWithStock(itemSlug);
      setUserCartLoading(true);
      const response = unwrapResult(
        await dispatch(
          PcwUserCartActions?.addUserCartItemThunk({
            data: [
              {
                product_slug: itemSlug,
                quantity: checkItem ? quantity || 1 : 0,
              },
            ],
          })
        )
      );
      if (response?.success) {
        // EC-437: As of May 29, 2024, We are migrating this tag to checkout page.
        // fbqTrack(metaPixelConstants.addToCart, {
        //   value: checkoutCartItem?.amount,
        //   currency: "PHP",
        //   content_ids: [`${checkoutCartItem?.product_slug}`],
        // });

        debouncedGetUserCartLength();
        debouncedGetUserCartItem();
        //TODO: change this logic
        //This will add the item in our state pcwCheckoutcart
        if (checkoutCartItem && Object.keys(checkoutCartItem).length > 0) {
          dispatch(PcwUserCartActions?.addCheckoutCartItem(checkoutCartItem));
        }
        snackbar.show({
          severity: "info",
          message: localize.ITEMS_ADDED_CART,
        });
      }
      if (!checkItem) {
        setUserCartLoading(false);
        snackbar.show({
          severity: "info",
          message: "You reached the maximum quantity of item(s) on your cart",
        });
      }
      //  Delay to prevent accidental re-enablement while other conditions are still being evaluated.
      setTimeout(() => setUserCartLoading(false), 1000);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, debouncedGetUserCartLength, debouncedGetUserCartItem, snackbar]
  );

  //for add multiple item to cart api
  const addToCartMultiple = useCallback(
    async (cartMultiple: AddCartItem[]) => {
      setUserCartLoading(true);
      const response = unwrapResult(
        await dispatch(
          PcwUserCartActions?.addUserCartItemThunk({
            data: cartMultiple,
          })
        )
      );

      if (response?.success) {
        const sumAmountOfItems =
          cartMultiple?.reduce((a, c) => (c?.amount || 0) + +a, 0) || 0;
        const contentIds = cartMultiple?.map((item) => item?.product_slug);
        // EC-437: As of May 29, 2024, We are migrating this tag to checkout page.
        // fbqTrack(metaPixelConstants.addToCart, {
        //   value: sumAmountOfItems,
        //   currency: "PHP",
        //   content_ids: contentIds,
        // });

        setUserCartLoading(false);
        debouncedGetUserCartLength();
        debouncedGetUserCartItem();

        snackbar.show({
          severity: "info",
          message: localize.ITEMS_ADDED_CART,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedGetUserCartItem, debouncedGetUserCartLength, dispatch, snackbar]
  );

  //use for state
  const userCheckoutCartMultiple = useCallback(
    (checkoutCartItem?: CheckoutCart[]) => {
      dispatch(PcwUserCartActions?.checkoutCartMultiple(checkoutCartItem));
    },
    [dispatch]
  );

  const overrideUserCartViaQuotationId = useCallback(
    async (quotation_id?: string, overrideArg?: boolean) => {
      await dispatch(
        PcwUserCartActions?.overrideUserCartThunk({
          limit: 99,
          quotation_id,
          override: overrideArg ? 1 : 0,
        })
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch]
  );

  const getUserCartItemStock = useCallback(async () => {
    const response = unwrapResult(
      await dispatch(
        PcwUserCartActions?.getUserCartItemThunk({
          limit: 99,
          with_stocks: 1,
        })
      )
    );

    return response;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getUserCartItemWithoutStock = useCallback(async () => {
    const response = unwrapResult(
      await dispatch(
        PcwUserCartActions?.getUserCartItemWithoutStockThunk({
          limit: 99,
          with_stocks: 0,
        })
      )
    );
    return response;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getUserCartLength = useCallback(() => {
    dispatch(
      PcwUserCartActions?.getUserCartLength({ count_only: 1, limit: 99 })
    );
  }, [dispatch]);

  const removeUserCartItem = useCallback(
    async (cart_id: number, product_slug: string) => {
      setUserCartLoading(true);
      //for api
      const cart_id_arr = [];
      cart_id_arr.push(cart_id);
      const response = unwrapResult(
        await dispatch(
          PcwUserCartActions.removeCartItemThunk({ cart_ids: cart_id_arr })
        )
      );

      // // for state
      dispatch(PcwUserCartActions.removeFromCart({ cart_id }));
      if (response?.success) {
        setUserCartLoading(false);
        dispatch(
          PcwUserCartActions?.getUserCartLength({ count_only: 1, limit: 99 })
        );
        dispatch(PcwUserCartActions?.removeCheckoutCartItem({ product_slug }));
        snackbar.show({
          severity: "info",
          message: "Item(s) removed to cart",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, snackbar]
  );

  const clearUserCart = async () => {
    let cart_ids_arr = [];
    pcwUserCartItem?.forEach(async (item) => {
      cart_ids_arr.push(item.cart_id);
    });
    pcwUserCartItemWithoutStock?.forEach(async (item) => {
      cart_ids_arr.push(item.cart_id);
    });
    if (pcwUserCartItem) {
      const response = unwrapResult(
        await dispatch(
          PcwUserCartActions.removeCartItemThunk({ cart_ids: cart_ids_arr })
        )
      );
      if (response?.success) {
        getUserCartItemStock();
        getUserCartItemWithoutStock();
        getUserCartLength();
        dispatch(PcwUserCartActions.clearCheckoutCart());
      }
    }
  };

  const addQuantityCart = useCallback(
    async (cart_item_id: number, quantity: number, product_slug: string) => {
      setUserCartLoading(true);
      const response = unwrapResult(
        await dispatch(
          PcwUserCartActions.updateCartItemQuantityThunk({
            cart_item_id,
            quantity,
          })
        )
      );
      if (response?.success) {
        setUserCartLoading(false);
        getUserCartItemStock();
        getUserCartItemWithoutStock();
        dispatch(
          PcwUserCartActions.addCartItemQuantity({ cart_item_id, product_slug })
        );
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, getUserCartItemStock, getUserCartItemWithoutStock]
  );

  const subtractQuantityCart = useCallback(
    async (cart_item_id: number, quantity: number, product_slug: string) => {
      setUserCartLoading(true);
      const response = unwrapResult(
        await dispatch(
          PcwUserCartActions.updateCartItemQuantityThunk({
            cart_item_id,
            quantity,
          })
        )
      );
      if (response?.success) {
        setUserCartLoading(false);
        getUserCartItemStock();
        getUserCartItemWithoutStock();
        dispatch(
          PcwUserCartActions.subtractCartItemQuantity({
            cart_item_id,
            product_slug,
          })
        );
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, getUserCartItemStock, getUserCartItemWithoutStock]
  );

  const pcwProductQtyInCartViaSlug = (slug?: string) => {
    if (!slug) {
      return 0;
    }
    const filteredCartSlug = pcwUserCartItem.find(
      (item) => item?.product_slug === slug
    );
    return filteredCartSlug?.quantity || 0;
  };

  const setUserCartLoading = (isLoading: boolean) => {
    dispatch(PcwUserCartActions?.setCartLoading({ isLoading }));
  };

  const userCartProductQtyInCartViaSlug = (slug?: string) => {
    if (!slug) {
      return 0;
    }
    const filteredCartSlug = pcwUserCartItem?.find(
      (item) => item?.product_slug === slug
    );
    return filteredCartSlug?.quantity || 0;
  };

  const userCartItemStocks = (slug?: string) => {
    if (!slug) {
      return 0;
    }
    const filteredCartSlug = pcwUserCartItem?.find(
      (item) => item?.product_slug === slug
    );
    return filteredCartSlug?.stocks || 0;
  };

  return {
    pcwUserCartItem,
    pcwUserCartLength,
    pcwCartTotalAmount,
    pcwCheckOutCart,
    userCartLoading,
    pcwUserCartItemWithoutStock,

    overrideUserCartViaQuotationId,
    addUserCartItem,
    addToCartMultiple,
    userCheckoutCartMultiple,
    getUserCartItemStock,
    getUserCartItemWithoutStock,
    getUserCartLength,
    removeUserCartItem,
    clearUserCart,
    addQuantityCart,
    subtractQuantityCart,
    pcwProductQtyInCartViaSlug,
    setUserCartLoading,
    userCartProductQtyInCartViaSlug,
    userCartItemStocks,
  };
};
