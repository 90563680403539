import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import { CartApi } from "./api";
import { GetOrderDetailsResponse } from "./types";

export const getOrderDetails = createAsyncThunk<
  CommonAxiosResponse<GetOrderDetailsResponse>,
  string
>("Cart/getCategoriesThunk", async (data) => {
  const res = await CartApi.getOrderDetails(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
