export const payoutRequests = [
  {
    no: 1,
    date: "20-04-2022",
    status: "Accepted",
    seller: "Ethan Booth",
    totalAmount: 1_200,
    requestAmount: 200,
    shopName: "The Beauty Shop",
  },
  {
    no: 2,
    date: "19-04-2022",
    status: "Rejected",
    totalAmount: 250,
    seller: "Sofia Hall",
    requestAmount: 25,
    shopName: "The Gainner",
  },
  {
    no: 3,
    date: "17-04-2022",
    status: "Processing",
    totalAmount: 1_300,
    requestAmount: 200,
    shopName: "Tech Tube",
    seller: "Dominic Moss",
  },
  {
    no: 4,
    date: "14-04-2022",
    status: "Accepted",
    shopName: "Smart HD",
    totalAmount: 2_200,
    requestAmount: 200,
    seller: "Tilly Thomson",
  },
  {
    no: 5,
    date: "08-04-2022",
    status: "Accepted",
    totalAmount: 700,
    requestAmount: 100,
    shopName: "Dream Boy",
    seller: "Finley Henry",
  },
  {
    no: 6,
    date: "01-04-2022",
    totalAmount: 930,
    seller: "Lola Ryan",
    status: "Processing",
    requestAmount: 130,
    shopName: "Beyond Threads",
  },
  {
    no: 7,
    date: "26-03-2022",
    status: "Rejected",
    totalAmount: 450,
    requestAmount: 50,
    seller: "Gabriel Mckenzie",
    shopName: "Febrick Fashion",
  },
  {
    no: 8,
    date: "16-03-2022",
    status: "Processing",
    totalAmount: 360,
    requestAmount: 60,
    seller: "James Davey",
    shopName: "Icon Boy",
  },
];
