import { RootState, _ } from "common";
import { createSelector } from "reselect";

const selectProductDetailsState = (state: RootState) => state.ProductDetails;

const selectProductDetails = createSelector(
  selectProductDetailsState,
  (state) => state.productDetails
);

const selectProductDetailsArr = createSelector(
  selectProductDetailsState,
  (state) => state.productDetailsArr
);

const selectProductImages = createSelector(
  selectProductDetailsState,
  (state) => state.productImages || []
);

const selectProductImagesStatus = createSelector(
  selectProductDetailsState,
  (state) => state.productImagesStatus
);

export const selectors = {
  selectProductDetailsState,
  selectProductDetails,
  selectProductDetailsArr,
  selectProductImages,
  selectProductImagesStatus,
};
