import modules from "modules";
import { useAppSelector } from "./use-app-selector";
import { useCallback, useMemo, useState } from "react";
import { useAppDispatch } from "./use-app-dispatch";
import { mainCategoriesSlugs } from "common";

const { selectors: categorySelectors, actions: categoryActions } =
  modules.category;

export const useMainCategory = () => {
  const dispatch = useAppDispatch();

  const [laptopLoading, setIsLaptopLoading] = useState<boolean>(false);

  const mainCategoriesData = useAppSelector(
    categorySelectors.selectMainCategories
  );

  const mainCategories = useMemo(() => {
    return mainCategoriesData.filter(
      (item) => item.slug && mainCategoriesSlugs.includes(item.slug)
    );
  }, [mainCategoriesData]);

  const getMainCategories = useCallback(async () => {
    setIsLaptopLoading(true);
    await dispatch(categoryActions.getMainCategoriesThunk({}));
    setIsLaptopLoading(false);
  }, [dispatch]);

  return {
    mainCategories,
    getMainCategories,
    laptopLoading,
  };
};
