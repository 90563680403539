import Mixpanel from "mixpanel-browser";
import { UserLoginData } from "modules/pcw-login/types";

// Initiliaze mix panel
const initialize = () =>
  Mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL, {
    debug: false,
    ignore_dnt: true,
    track_pageview: true,
  });

// Identify mixpanel user
const setIdentity = (userID: string, userData?: UserLoginData) => {
  Mixpanel.identify(userID);
  Mixpanel.people.set({
    $name:
      userData?.full_name || `${userData?.first_name} ${userData?.last_name}`,
    $email: userData.email || "",
    mobile: userData.mobile_no || "",
    uuid: userData.uuid || "",
  });
};

// reset mixpanel user / logged out?
const resetIdentity = () => Mixpanel.reset();

// Define a function for tracking events
export const trackEvent = (
  eventName: string,
  properties: Record<string, any> = {}
) => Mixpanel.track(eventName, properties);

// Define a function for tracking errors
export const trackError = (
  errorName: string,
  properties: Record<string, any> = {}
) => Mixpanel.track(`Error_${errorName}`, properties);

// Define a function for tracking actions
export const trackAction = (
  actionName: string,
  properties: Record<string, any> = {}
) => Mixpanel.track(`Action_${actionName}`, properties);

// Define a function for tracking metrics
export const trackMetric = (
  metricName: string,
  properties: Record<string, any> = {}
) => Mixpanel.track(`Metric_${metricName}`, properties);

// Define a function for tracking API-related events
export const trackApi = (
  apiName: string,
  properties: Record<string, any> = {}
) => Mixpanel.track(`Api_${apiName}`, properties);

export const utils = {
  initialize,
  setIdentity,
  resetIdentity,

  trackEvent,
  trackError,
  trackAction,
  trackMetric,
  trackApi,
};
