import { RootState } from "common";
import { createSelector } from "reselect";

const selectBranchState = (state: RootState) => state.Branch;

const selectBranches = createSelector(
    selectBranchState,
    (state) => state.branches
)

export const selectors = {
    selectBranches
}