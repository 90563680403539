import { LocationTabsType, LocationTabValue } from "../types";
import * as mockData from "./mock-data";
import * as staticLocations from "./static-locations";
export const name = "Location";

const emptyLocationValue: LocationTabValue = {
  region: {},
  province: {},
  city: {},
  barangay: {},
};

const locationTabs: LocationTabsType[] = [
  "Region",
  "Province",
  "City",
  "Barangay",
];

export const constants = {
  name,
  emptyLocationValue,
  locationTabs,
  ...mockData,
  ...staticLocations,
};
