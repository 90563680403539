import { categories } from "common/constants";
import { defaultRowColors } from "common/constants/pricelist";
import { CartItem } from "modules/cart/types";

export const getCategoryIdOnCartItem = (item: CartItem) => {
  if (item?.category_id) {
    return item?.category_id;
  }

  // Some endpoints returns category name only. if category_id is undefined lets use the name
  if (item?.category) {
    const categoryNameUpperCase = item?.category?.toUpperCase();
    const resCategoryId = categories?.find(
      (categ) => categ?.name?.toUpperCase() === categoryNameUpperCase
    )?.id;

    if (resCategoryId) {
      return resCategoryId;
    }
  }

  return undefined;
};

export const getCategoryColor = (categoryId) => {
  switch (categoryId) {
    case 21: // gpu.
      return defaultRowColors.gpu;
    case 16: // cpu.
      return defaultRowColors.cpu;
    case 17: // motherboard.
      return defaultRowColors.motherboard;
    case 13: // RAM.
      return defaultRowColors.ram;
    case 14: // SSD.
      return defaultRowColors.ssd;
    case 15: // HDD.
      return defaultRowColors.hdd;
    case 18: // PSU.
      return defaultRowColors.psu;
    case 20: // casing.
      return defaultRowColors.casing;
    case 25: // monitor.
      return defaultRowColors.monitor;
    case 26:
      return defaultRowColors.cpuCooler;
    case 19:
      return defaultRowColors.fan;
    default:
      return defaultRowColors.accessories;
  }
};

export default getCategoryColor;
