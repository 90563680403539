import { DefaultSeoProps } from "next-seo";

const config: DefaultSeoProps = {
  title: "PC Worth",
  defaultTitle: "PC Worth",
  titleTemplate: "%s | PC Worth",
  description:
    "Experience exceptional customer service and competitive prices at PC Worth, your trusted e-commerce store for computer parts. Explore our wide range of high-quality CPUs, GPUs, and more. Shop now for a seamless shopping experience!",
  canonical: "https://pcworth.com/",
  themeColor: "#0C304A",
  facebook: {
    appId: "100508121746833",
  },
  openGraph: {
    type: "website",
    locale: "en_IE",
    url: "https://pcworth.com/",
    siteName: "PC Worth",
    images: [
      {
        url: "/assets/images/landing/pc_worth_20000_builds_1000_reviews_100_customer_satisfaction.png",
        width: 800,
        height: 600,
        alt: "PC Worth, We make IT worth",
        type: "image/png",
      },
    ],
  },
  additionalMetaTags: [
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1",
    },
    {
      name: "viewport",
      content: "text/html; charset=utf-8",
      httpEquiv: "content-type" as any,
    },
  ],
};

export default config;
