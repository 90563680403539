import { DependencyList, EffectCallback, useCallback, useEffect } from 'react';

export const useDebouncedEffect = (
  effect: () => Promise<void | (() => void)> | EffectCallback | void,
  delay: number = 2500,
  deps: DependencyList
) => {
  const callback = useCallback(effect, [effect]);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay, deps]);
};
