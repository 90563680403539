import React, { createContext, useContext, useMemo, useReducer } from "react";

// =================================================================================
type initialState = {
  isHeaderFixed: boolean;
  cart: CartItem[];
};

export type CartItem = {
  qty: number;
  name: string;
  price: number;
  imgUrl?: string;
  id: string | number;
};

type layoutActionType = { type: "TOGGLE_HEADER"; payload: boolean };
type cartActionType = { type: "CHANGE_CART_AMOUNT"; payload: CartItem };
type ActionType = layoutActionType | cartActionType;

// =================================================================================

const initialState = {
  isHeaderFixed: false,
  cart: [
    {
      price: 42245,
      name: "GEFORCE RTX 3070TI MSI GAMING X TRIO OC 8GB TRIPLE",
      imgUrl: "/assets/images/products/video_card_1.png",
      id: "7222243834583537",
      qty: 1,
    },
    {
      price: 42245,
      name: "GEFORCE RTX 3070TI AORUS OC 8GB TRIPLE FAN LHR",
      imgUrl: "/assets/images/products/video_card_2.png",
      id: "38553442244076086",
      qty: 1,
    },
    {
      price: 42245,
      name: "GEFORCE RTX 3080 Vision OC 10GB LHR Graphic Card ",
      imgUrl:
        "/assets/images/products/video_card_3.png",
      id: "9573201630529315",
      qty: 1,
    },
  ],
};

interface ContextProps {
  state: initialState;
  dispatch: (args: ActionType) => void;
}

const AppContext = createContext<ContextProps>({
  state: initialState,
  dispatch: () => {},
});

const reducer = (state: initialState, action: ActionType) => {
  switch (action.type) {
    case "CHANGE_CART_AMOUNT":
      let cartList = state.cart;
      let cartItem = action.payload;
      let exist = cartList.find((item) => item.id === cartItem.id);

      if (cartItem.qty < 1) {
        const filteredCart = cartList.filter((item) => item.id !== cartItem.id);
        return { ...state, cart: filteredCart };
      }

      if (exist) {
        const newCart = cartList.map((item) =>
          item.id === cartItem.id ? { ...item, qty: cartItem.qty } : item
        );

        return { ...state, cart: newCart };
      }

      return { ...state, cart: [...cartList, cartItem] };

    case "TOGGLE_HEADER":
      return { ...state, isHeaderFixed: action.payload };

    default: {
      return state;
    }
  }
};

export const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export const useAppContext = () => useContext<ContextProps>(AppContext);

export default AppContext;
