import { useCallback, useState } from "react";
import modules from "modules";
import { useAppDispatch } from "./use-app-dispatch";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getPCBundleDetailsThunk,
  getPCBundleInitialThunk,
  getPcBundleListThunk,
  getPCBundleManufacturersThunk,
  getPCBundleSpecificationsThunk,
  getPCBundleVideosThunk,
  PcBundleData,
  PCBundleDetailsData,
  PCBundleInitialData,
  PcBundleManufacturerData,
  PCBundleSpecificationsData,
  PCBundleVideosData,
} from "modules/pc-bundle";

export const usePcBundle = () => {
  const dispatch = useAppDispatch();

  /** ========== SELECTORS / VARIABLES / CONSTANTS ========== */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [bundleList, setBundleList] = useState<PcBundleData[]>([]);
  const [bundleInitial, setBundleInitial] = useState<PCBundleInitialData>();
  const [bundleDetails, setBundleDetails] = useState<PCBundleDetailsData>();
  const [bundleVideos, setBundleVideos] = useState<PCBundleVideosData>();
  const [bundleSpecifications, setBundleSpecifications] =
    useState<PCBundleSpecificationsData>();
  const [poweredBuilds, setPoweredBuilds] =
    useState<PcBundleManufacturerData[]>();

  const [banner, setBanner] = useState<string>("");

  /** ========== FUNCTIONS ========== */
  const getPcBundleList = useCallback(
    async (brand?: string) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(getPcBundleListThunk(brand))
        );

        if (response?.success) {
          setBundleList(response?.originalData?.data);
          setBanner(response?.originalData?.banner_url);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const getPCBundleInitial = useCallback(
    async (pc_bundle_id?: string, brand?: string) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(getPCBundleInitialThunk({ pc_bundle_id }))
        );

        if (response?.success) {
          setBundleInitial(response?.originalData?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const getPcBundleDetails = useCallback(
    async (pc_bundle_id?: string, brand?: string) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(getPCBundleDetailsThunk({ pc_bundle_id }))
        );

        if (response?.success) {
          setBundleDetails(response?.originalData?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const getPcBundleVideos = useCallback(
    async (pc_bundle_id?: string, brand?: string) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(getPCBundleVideosThunk({ pc_bundle_id }))
        );

        if (response?.success) {
          setBundleVideos(response?.originalData?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const getPcBundleSpecifications = useCallback(
    async (pc_bundle_id?: string, brand?: string) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(getPCBundleSpecificationsThunk({ pc_bundle_id }))
        );

        if (response?.success) {
          setBundleSpecifications(response?.originalData?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const getPCBundlePoweredBuilds = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = unwrapResult(
        await dispatch(getPCBundleManufacturersThunk())
      );

      if (response?.success) {
        setIsLoading(false);
        setPoweredBuilds(response?.originalData?.data || []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  return {
    isLoading,
    bundleList,
    bundleInitial,
    bundleDetails,
    bundleVideos,
    bundleSpecifications,
    poweredBuilds,
    banner,
    getPcBundleList,
    getPCBundleInitial,
    getPcBundleDetails,
    getPcBundleVideos,
    getPcBundleSpecifications,
    getPCBundlePoweredBuilds,
  };
};
