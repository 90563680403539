import { createSlice, current } from "@reduxjs/toolkit";
import { Action, ErrorResponse, SliceStatusValue, _ } from "common";
import { Casing, CPU, GPU, Motherboard, RAM } from "./constants";
import { isEmpty } from "lodash";

import {
  PartCompatibleListItem,
  PartListItem,
  PartsCategory,
  PartsCompatiblePayload,
  PartsCompatibleResponse,
  PCBuilderState,
} from "./types";
import { isComponentMultiple } from "./utils";

const setPartsCategory = (
  state: PCBuilderState,
  action: Action<PartsCategory>
) => {
  const partCategory = action.payload;

  const gpuSlug = state.buildParts.find(
    (part) => part?.category_name === GPU
  )?.product_id;

  const motherBoardSlug = state.buildParts.find(
    (part) => part?.category_name === Motherboard
  )?.product_id;

  const casingSlug = state.buildParts.find(
    (part) => part?.category_name === Casing
  )?.product_id;

  const motherboardExists = state.buildParts.some(
    (part) => part?.category_name === Motherboard
  );
  const gpuExists = state.buildParts.some(
    (part) => part?.category_name === GPU
  );

  const casingExist = state.buildParts.some(
    (part) => part?.category_name === Casing
  );

  if (partCategory === Casing) {
    const bothMOBOandGPUExist = motherboardExists && gpuExists;

    if (bothMOBOandGPUExist) {
      state.partsPrimaryCategory = Motherboard;
      state.tertiaryCategory = GPU;
      state.tertiaryProductId = gpuSlug;
      state.partsSecondaryCategory = Casing;
      state.selectedProductId = motherBoardSlug;
      return;
    }

    if (gpuExists) {
      state.partsPrimaryCategory = GPU;
      state.partsSecondaryCategory = Casing;
      state.selectedProductId = gpuSlug;
      state.tertiaryCategory = undefined;
      state.tertiaryProductId = undefined;
      return;
    }

    if (motherBoardSlug) {
      state.partsPrimaryCategory = Motherboard;
      state.partsSecondaryCategory = Casing;
      state.selectedProductId = motherBoardSlug;
      state.tertiaryCategory = undefined;
      state.tertiaryProductId = undefined;
      return;
    }

    //default when casing is  selected
    state.partsPrimaryCategory = Casing;
    state.partsSecondaryCategory = undefined;
    state.selectedProductId = undefined;
    state.tertiaryCategory = undefined;
    state.tertiaryProductId = undefined;
    return;
  }

  if (partCategory === CPU) {
    state.partsPrimaryCategory = CPU;
    state.partsSecondaryCategory = undefined;
    state.selectedProductId = undefined;
    return;
  }

  if (partCategory === GPU) {
    //meaning that there is current set casing before selecting motherboard

    if (casingSlug) {
      state.partsPrimaryCategory = Casing;
      state.partsSecondaryCategory = GPU;
      state.selectedProductId = casingSlug;
      state.tertiaryCategory = undefined;
      state.tertiaryProductId = undefined;
      return;
    } else {
      state.partsPrimaryCategory = GPU;
      state.partsSecondaryCategory = undefined;
      state.selectedProductId = undefined;
      state.tertiaryCategory = undefined;
      state.tertiaryProductId = undefined;
      return;
    }
  }

  if (partCategory === Motherboard) {
    if (casingExist && gpuExists) {
      state.partsPrimaryCategory = CPU;
      state.partsSecondaryCategory = Motherboard;
      state.tertiaryCategory = Casing;
      state.tertiaryProductId = casingSlug;
    } else {
      state.partsPrimaryCategory = CPU;
      state.partsSecondaryCategory = Motherboard;
      state.tertiaryCategory = undefined;
      state.tertiaryProductId = undefined;
    }

    if (state.buildParts) {
      const buildPart = _.find(state.buildParts, ["category_name", CPU]);
      state.selectedProductId = buildPart?.product_id;
    }
    return;
  }

  if (partCategory === RAM) {
    state.partsPrimaryCategory = Motherboard;
    state.partsSecondaryCategory = RAM;

    if (state.buildParts) {
      const buildPart = _.find(state.buildParts, [
        "category_name",
        Motherboard,
      ]);
      state.selectedProductId = buildPart?.product_id;
    }
    return;
  }

  state.selectedProductId = "999";
  state.partsPrimaryCategory = CPU;
  state.partsSecondaryCategory = partCategory;
  state.tertiaryCategory = undefined;
  state.tertiaryProductId = undefined;
};

const partsCompatibleRequest = (
  state: PCBuilderState,
  _action: Action<PartsCompatiblePayload>
) => {
  state.partsCompatibleStatus = SliceStatusValue.loading;
  state.partsCompatible = undefined;
};

const partsCompatibleResolve = (
  state: PCBuilderState,
  action: Action<PartsCompatibleResponse>
) => {
  state.partsCompatibleStatus = SliceStatusValue.resolve;
  const { data } = action.payload;
  state.partsCompatible = data;
};

const partsCompatibleReject = (
  state: PCBuilderState,
  _action: Action<ErrorResponse>
) => {
  state.partsCompatibleStatus = SliceStatusValue.reject;
};

const addComponent = (
  state: PCBuilderState,
  action: Action<PartCompatibleListItem>
) => {
  const partListItem = { ...action.payload, quantity: 1 };
  if (state.buildParts) {
    const hasSameCategory = _.some(state.buildParts, [
      "category_name",
      partListItem?.category_name,
    ]);
    if (!hasSameCategory) {
      state.buildParts = _.concat(state.buildParts, partListItem);
      return;
    }

    const isSamePart = _.some(state.buildParts, [
      "product_id",
      partListItem?.product_id,
    ]);

    if (!isSamePart && partListItem?.category_name === CPU) {
      state.buildParts = _.filter(state.buildParts, (part) => {
        const partCategoryName = part?.category_name;
        return partCategoryName !== Motherboard && partCategoryName !== RAM;
      });
    }

    if (!isSamePart && partListItem?.category_name === Motherboard) {
      state.buildParts = _.filter(state.buildParts, (part) => {
        const partCategoryName = part?.category_name;
        return partCategoryName !== RAM;
      });
    }

    state.buildParts = _.map(state.buildParts, (part) => {
      if (
        (partListItem?.product_id &&
          partListItem?.product_id === part?.product_id) ||
        (partListItem?.category_name &&
          partListItem?.category_name === part?.category_name)
      ) {
        return partListItem;
      }
      return part;
    });
    return;
  }

  state.buildParts = [partListItem];
};

/**
 *
 * Only use this reducer on multiple type component item like
 * SSD and accessories
 * don't add categories here that cannot be multiple.
 */
const addComponentOnMultipleItemType = (
  state: PCBuilderState,
  action: Action<PartCompatibleListItem>
) => {
  const partListItem = { ...action.payload, quantity: 1 };
  let multiplePartListItem: PartListItem = {
    category_id: action.payload?.category_id,
    category_name: action.payload?.category_name,
  };
  // check pag same category
  const hasSameCategoryName = _.some(state.buildParts, [
    "category_name",
    multiplePartListItem?.category_name,
  ]);
  const hasSameCategoryId = _.some(state.buildParts, [
    "category_id",
    multiplePartListItem?.category_id,
  ]);

  if (hasSameCategoryName || hasSameCategoryId) {
    // check if category of selected product already exist.
    let isPartCategoryExistIndex = state.buildParts?.findIndex(
      (bp) =>
        bp?.category_id === multiplePartListItem?.category_id ||
        bp?.category_name === multiplePartListItem?.category_name
    );
    if (isPartCategoryExistIndex > -1) {
      // check if same product is already inside multipleParts
      const productIdExistInMultipleIndex = state.buildParts[
        isPartCategoryExistIndex
      ].multipleParts?.findIndex(
        (mp) => mp?.product_id === partListItem?.product_id
      );
      if (productIdExistInMultipleIndex > -1) {
        const proposedNewQtyOfPartItem =
          state.buildParts[isPartCategoryExistIndex].multipleParts[
            productIdExistInMultipleIndex
          ].quantity + 1;
        const stocksLeftOnProduct =
          state.buildParts[isPartCategoryExistIndex].multipleParts[
            productIdExistInMultipleIndex
          ].stocks_left;

        // Add product quantity if product id already exist
        if (proposedNewQtyOfPartItem <= stocksLeftOnProduct) {
          ++state.buildParts[isPartCategoryExistIndex].multipleParts[
            productIdExistInMultipleIndex
          ].quantity;
        }
      } else {
        state.buildParts[isPartCategoryExistIndex].multipleParts =
          state.buildParts[isPartCategoryExistIndex]?.multipleParts?.concat(
            partListItem
          );
      }
    }
  } else {
    // first time
    multiplePartListItem.multipleParts = [partListItem];
    state.buildParts = state.buildParts?.concat(multiplePartListItem);
  }
};

const removeComponent = (
  state: PCBuilderState,
  action: Action<{ product_id: string; category_name: string }>
) => {
  const { product_id: productId, category_name } = action.payload;
  state.buildParts = _.filter(state.buildParts, (part) => {
    return productId !== part?.product_id;
  });

  if (category_name === CPU) {
    state.buildParts = _.filter(state.buildParts, (part) => {
      const partCategoryName = part?.category_name;
      return (
        partCategoryName !== CPU &&
        partCategoryName !== Motherboard &&
        partCategoryName !== RAM
      );
    });
    return;
  }

  if (category_name === Motherboard) {
    state.buildParts = _.filter(state.buildParts, (part) => {
      const partCategoryName = part?.category_name;
      return (
        partCategoryName === CPU ||
        (partCategoryName !== Motherboard && partCategoryName !== RAM)
      );
    });
  }
};

/**
 *
 * Make sure to only use this on multiple parts components
 * eg HDD, SSD, accessories
 */
const removeComponentOnMultipart = (
  state: PCBuilderState,
  action: Action<{ category_name: string; index: number }>
) => {
  const { category_name, index } = action.payload;
  const selectedPartIndex = state.buildParts?.findIndex(
    (pi) => pi?.category_name === category_name
  );
  if (selectedPartIndex > -1) {
    state.buildParts[selectedPartIndex].multipleParts?.splice(index, 1);
  }
  if (isEmpty(state.buildParts[selectedPartIndex].multipleParts)) {
    state.buildParts?.splice(selectedPartIndex, 1);
  }
};

const addQuantity = (
  state: PCBuilderState,
  action: Action<{ product_id: string }>
) => {
  const buildParts = _.map(state.buildParts, (part) => {
    if (part.product_id === action.payload?.product_id) {
      part.quantity += 1;
    }
    return part;
  });
  state.buildParts = buildParts;
};

const addQuantityMultiple = (
  state: PCBuilderState,
  action: Action<{ part: PartCompatibleListItem; componentIndex: number }>
) => {
  const { part: productList, componentIndex } = action.payload;
  let multiplePartListItem: PartListItem = {
    category_id: productList.category_id,
    category_name: productList.category_name,
  };

  const stateIndex = state.buildParts.findIndex(
    (bp) =>
      bp.category_id === multiplePartListItem.category_id ||
      bp.category_name === multiplePartListItem.category_name
  );
  ++state.buildParts[stateIndex].multipleParts[componentIndex].quantity;
};

const subtractQuantityMultiple = (
  state: PCBuilderState,
  action: Action<{ part: PartCompatibleListItem; componentIndex: number }>
) => {
  const { part: productList, componentIndex } = action.payload;
  let multiplePartListItem: PartListItem = {
    category_id: productList.category_id,
    category_name: productList.category_name,
  };

  const stateIndex = state.buildParts.findIndex(
    (bp) =>
      bp.category_id === multiplePartListItem.category_id ||
      bp.category_name === multiplePartListItem.category_name
  );
  --state.buildParts[stateIndex].multipleParts[componentIndex].quantity;
};

const subtractQuantity = (
  state: PCBuilderState,
  action: Action<{ product_id: string }>
) => {
  const buildParts = _.map(state.buildParts, (part) => {
    if (part.product_id === action.payload?.product_id && part.quantity > 1) {
      part.quantity -= 1;
    }
    return part;
  });
  state.buildParts = buildParts;
};

const clearBuildParts = (state: PCBuilderState) => {
  state.buildParts = [];
};

/**
 *
 * If buildPart has a multiple that has a single value. Meaning, They're old data.
 * Transfer them to multipleParts property
 */
const migrateOldBuildPart = (state: PCBuilderState) => {
  state.buildParts?.forEach((bp, bpIndex) => {
    if (isComponentMultiple(bp?.category_name) && bp?.product_id) {
      state.buildParts[bpIndex] = {
        category_id: bp?.category_id,
        category_name: bp?.category_name,
      };
      state.buildParts[bpIndex].multipleParts = [bp];
    }
  });
};

const slice = createSlice({
  name: "PCBuilder",
  initialState: {
    buildParts: [],
  } as PCBuilderState,
  reducers: {
    setPartsCategory,
    partsCompatibleRequest,
    partsCompatibleResolve,
    partsCompatibleReject,
    addComponent,
    addComponentOnMultipleItemType,
    removeComponent,
    removeComponentOnMultipart,
    addQuantity,
    subtractQuantity,
    clearBuildParts,
    addQuantityMultiple,
    subtractQuantityMultiple,
    migrateOldBuildPart,
  },
});

export const actions = {
  ...slice.actions,
  // ...thunks?
};

export const reducer = slice.reducer;
