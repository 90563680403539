import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PriceListPayloadV2, PriceListState } from "./types";
import * as thunks from "./thunks";
import { initialPayload } from "./constants";

const initialState: PriceListState = {
  EUpriceList: {},
  priceListLoading: false,
  payload: initialPayload,
};

const setPayload = (
  state: PriceListState,
  { payload }: PayloadAction<PriceListPayloadV2>
) => {
  state.payload = payload;
};

const slice = createSlice({
  name: "PriceListEU",
  initialState,
  reducers: { setPayload },
  extraReducers(builder) {
    builder.addCase(thunks.getPriceListThunk.pending, (state, { payload }) => {
      state.priceListLoading = true;
    });
    builder.addCase(thunks.getPriceListThunk.rejected, (state, { payload }) => {
      state.priceListLoading = false;
    });
    builder.addCase(
      thunks.getPriceListThunk.fulfilled,
      (state, { payload }) => {
        state.priceListLoading = false;
        state.EUpriceList = payload?.originalData || {};
      }
    );
    builder.addCase(
      thunks.getEUpriceListV2Thunk.pending,
      (state, { payload }) => {
        state.priceListLoading = true;
      }
    );
    builder.addCase(
      thunks.getEUpriceListV2Thunk.rejected,
      (state, { payload }) => {
        state.priceListLoading = false;
      }
    );
    builder.addCase(
      thunks.getEUpriceListV2Thunk.fulfilled,
      (state, { payload }) => {
        state.priceListLoading = false; //TODO: May add state changes in the future
        state.EUpriceList = payload?.originalData || {};
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
