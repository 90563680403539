import { Api, ApiResponse } from "common";
import {
  BuildDetails,
  CommonBuildListPayload,
  CommonBuildResponse,
  CreateBuildPayload,
  CreateBuildResponse,
  GetBuildDetailsResponse,
  GetBuildListPayload,
  UpdateBuildDetailsPayload,
} from "./types";

const savebuild = (
  payload: CreateBuildPayload
): ApiResponse<CreateBuildResponse> => {
  return Api.post("api/ecomm/user/builds/save", payload);
};

const getBuildList = (
  payload: GetBuildListPayload
): ApiResponse<BuildDetails[]> => {
  return Api.get("api/ecomm/user/builds/get", payload);
};

const getBuildDetails = (
  payload: CommonBuildListPayload
): ApiResponse<GetBuildDetailsResponse> => {
  return Api.get(`api/ecomm/user/builds/get/${payload.keyword}`,);
};

const updateBuildDetails = (
  payload: UpdateBuildDetailsPayload
): ApiResponse<CommonBuildResponse> => {
  return Api.patch(`api/ecomm/user/builds/update/${payload.build_id}`, payload);
};

const deleteBuild = (
  payload: CommonBuildListPayload
): ApiResponse<CommonBuildResponse> => {
  return Api.remove(`api/ecomm/user/builds/delete/${payload.keyword}`);
};

export const MyBuildsAPI = {
  savebuild,
  getBuildList,
  getBuildDetails,
  updateBuildDetails,
  deleteBuild,
};
