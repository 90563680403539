import { Api, ApiResponse } from "../../common";
import {
  CLIENT_ID,
  CLIENT_SECRET,
  GRANT_TYPE_CLIENT,
  SCOPE,
} from "./constants";
import { ClientTokenResponse } from "./types";

const getClientToken = (): ApiResponse<ClientTokenResponse> => {
  const clientBody = {
    grant_type: GRANT_TYPE_CLIENT,
    scope: SCOPE,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };
  return Api.post("oauth/token", clientBody);
};

export const OAuthApi = {
  getClientToken,
};
