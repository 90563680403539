import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import { CompanyInfoApi } from "./api";
import { CompanyInfoResponse } from "./types";

export const getCompanyInfoThunk = createAsyncThunk<
    CommonAxiosResponse<CompanyInfoResponse>
>("CompanyInfo/getCompanyInfo", async () => {
    const response = await CompanyInfoApi.getCompanyInfo();
    const toThunk = thunkResponseHandler(response);
    return toThunk;
});