import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse as AxiosResponse, PROBLEM_CODE } from "apisauce";
import { store } from "app/createStore";

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type SliceStatus = "idle" | "loading" | "resolve" | "reject";

export type Action<P = undefined> = PayloadAction<P>;

export type PersistAction<P = unknown> = {
  key: string | undefined;
  err: null | undefined;
} & PayloadAction<P>;

export type CommonAxiosResponse<T = any> = {
  success: boolean;
  message: string;
  errors?: any;
  originalData: T;
};

export type SuccessResponse<T = any> = {
  success: true | boolean;
  message?: string | null;
  // data?: T;
} | null;

export type FailResponse = {
  success: false;
  message: string | null;
  error?: boolean;
  errors?: Record<string, unknown>;
};

export type UnauthenticatedResponse = {
  message: "unauthenticated";
};

export type ProblemCode = PROBLEM_CODE;

export type ErrorResponse =
  | FailResponse
  | ProblemCode
  | UnauthenticatedResponse
  | null
  | undefined;

export type ApiResponse<T, U = ErrorResponse> = Promise<AxiosResponse<T, U>>;

export type Response<T, U = ErrorResponse> = AxiosResponse<T, U>;

export function isSuccessResponseData<T extends SuccessResponse>(
  data: unknown
): data is T {
  return (data as SuccessResponse)?.success === true;
}

export function isFailResponseData(data: unknown): data is FailResponse {
  const failResponseData = data as FailResponse;
  return (
    failResponseData?.success === false ||
    !!failResponseData?.error ||
    !!failResponseData?.errors
  );
}

export function isUnauthenticatedData(
  data: unknown
): data is UnauthenticatedResponse {
  return (data as UnauthenticatedResponse)?.message === "unauthenticated";
}
