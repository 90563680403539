import { RootState, _ } from "common";
import { createSelector } from "reselect";

const selectPromotional = (state: RootState) => state.Promotional;

const selectListings = createSelector(
  selectPromotional,
  (state) => state.listings
);

const selectLoading = createSelector(
  selectPromotional,
  (state) => state.isLoading
);

const selectContentLoading = createSelector(
  selectPromotional,
  (state) => state.isLoadingContent
);

const selectMeta = createSelector(selectPromotional, (state) => state.meta);

const selectSlug = createSelector(selectPromotional, (state) => state.slug);

const selectUUID = createSelector(selectPromotional, (state) => state.uuid);

const selectCounts = createSelector(selectPromotional, (state) => state.counts);

const selectContent = createSelector(
  selectPromotional,
  (state) => state.content
);

const selectDetails = createSelector(
  selectPromotional,
  (state) => state.promotionalDetails
);

export const selectors = {
  selectListings,
  selectContent,
  selectLoading,
  selectContentLoading,
  selectMeta,
  selectDetails,
  selectSlug,
  selectUUID,
  selectCounts,
};
