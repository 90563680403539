import dayjs from "dayjs";
//===================
//setup some event conditions here
//===================

//set date for event first format: "YYYY-MM-DD"
//empty the eventConfig if no event
const eventConfig = "2023-10-10";
const currentDate = dayjs().format("YYYY-MM-DD");

//set if pcw has event
export const eventFlag: boolean = false;

//if we want to disable or hide a page or feature
export const eventChecker = () => {
  return eventFlag;
};

export const isEventOnGoing = eventChecker();

export const pcwEvent = {
  isEventOnGoing,
};
