export const categoriesShippingFee = [
  {
    id: 27,
    name: "Accessories",
    shipping_fee: 100,
  },
  {
    id: 37,
    name: "AVR",
    shipping_fee: 100,
  },
  {
    id: 23,
    name: "Cable Wire",
    shipping_fee: 100,
  },
  {
    id: 20,
    name: "Casing",
    shipping_fee: 2000,
  },
  {
    id: 16,
    name: "CPU",
    shipping_fee: 100,
  },
  {
    id: 26,
    name: "CPU Cooler",
    shipping_fee: 300,
  },
  {
    id: 47,
    name: "DP CABLE",
    shipping_fee: 100,
  },
  {
    id: 7,
    name: "Enclosure",
    shipping_fee: 100,
  },
  {
    id: 19,
    name: "Fan",
    shipping_fee: 100,
  },
  {
    id: 29,
    name: "Fan Hub",
    shipping_fee: 100,
  },
  {
    id: 41,
    name: "FLASH DRIVE",
    shipping_fee: 100,
  },
  {
    id: 36,
    name: "Gaming Chair",
    shipping_fee: 3000,
  },
  {
    id: 42,
    name: "GLASS PANEL",
    shipping_fee: 250,
  },
  {
    id: 21,
    name: "GPU",
    shipping_fee: 300,
  },
  {
    id: 15,
    name: "HDD",
    shipping_fee: 100,
  },
  {
    id: 12,
    name: "HDMI Cable",
    shipping_fee: 100,
  },
  {
    id: 28,
    name: "Headset",
    shipping_fee: 100,
  },
  {
    id: 3,
    name: "Keyboard",
    shipping_fee: 150,
  },
  {
    id: 4,
    name: "Keyboard and Mouse",
    shipping_fee: 200,
  },
  {
    id: 39,
    name: "Laptop",
    shipping_fee: 250,
  },
  {
    id: 24,
    name: "LED Strips",
    shipping_fee: 100,
  },
  {
    id: 32,
    name: "Marketing Materials",
    shipping_fee: 250,
  },
  {
    id: 40,
    name: "MICROPHONE",
    shipping_fee: 250,
  },
  {
    id: 46,
    name: "MOBILE WIFI",
    shipping_fee: 100,
  },
  {
    id: 25,
    name: "Monitor",
    shipping_fee: 1000,
  },
  {
    id: 17,
    name: "Motherboard",
    shipping_fee: 200,
  },
  {
    id: 2,
    name: "Mouse",
    shipping_fee: 100,
  },
  {
    id: 5,
    name: "Mouse Pad",
    shipping_fee: 100,
  },
  {
    id: 45,
    name: "NETWORK CONNECTOR",
    shipping_fee: 250,
  },
  {
    id: 38,
    name: "OPERATING SYSTEM",
    shipping_fee: 100,
  },
  {
    id: 10,
    name: "Patch Cord / LAN Cable",
    shipping_fee: 100,
  },
  {
    id: 18,
    name: "PSU",
    shipping_fee: 250,
  },
  {
    id: 13,
    name: "RAM",
    shipping_fee: 100,
  },
  {
    id: 44,
    name: "ROUTER",
    shipping_fee: 250,
  },
  {
    id: 8,
    name: "SATA Cable",
    shipping_fee: 100,
  },
  {
    id: 35,
    name: "SERVICE",
    shipping_fee: 250,
  },
  {
    id: 30,
    name: "Splitter",
    shipping_fee: 100,
  },
  {
    id: 14,
    name: "SSD",
    shipping_fee: 100,
  },
  {
    id: 43,
    name: "SWITCH",
    shipping_fee: 250,
  },
  {
    id: 9,
    name: "Thermal Paste",
    shipping_fee: 100,
  },
  {
    id: 11,
    name: "UPS",
    shipping_fee: 400,
  },
  {
    id: 6,
    name: "VGA Cable",
    shipping_fee: 100,
  },
  {
    id: 1,
    name: "WiFi Dongle",
    shipping_fee: 100,
  },
];
