import { useCompanyInfo } from "hooks";
import { useMainCategory } from "hooks/useMainCategory";
import { useEffect } from "react";

export const GlobalAPICalls = () => {
  const { getCompanyInfoRequest } = useCompanyInfo();
  const { getMainCategories } = useMainCategory();

  useEffect(() => {
    getCompanyInfoRequest();
    getMainCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null
};
