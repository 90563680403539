import { createAsyncThunk, original } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import {
  PcwLoginResponse,
  UpdatePasswordPayload,
  UserEmailOrMobilePayload,
} from "./types";
import { PcwUserLoginApi } from "./api";
import modules from "modules";

export const setPcwUserLoginThunk = createAsyncThunk<
  CommonAxiosResponse<PcwLoginResponse>,
  UserEmailOrMobilePayload
>("PcwLogin/setPcwUserLoginThunk", async (data, { dispatch }) => {
  const res = await PcwUserLoginApi.getUserLogin(data);
  const toThunk = await thunkResponseHandler(res);
  const dataToken = {
    token_type: "Bearer",
    expires_in: "604800000 ",
    access_token: toThunk?.originalData?.token,
  };
  const { actions: AccessTokenAction } = modules.oauth;
  if (toThunk?.success) {
    dispatch(AccessTokenAction.clientTokenResolve(dataToken));
  }
  return toThunk;
});

export const setPcwUserResetPasswordThunk = createAsyncThunk<
  CommonAxiosResponse<CommonAxiosResponse>,
  UserEmailOrMobilePayload
>("PcwLogin/setPcwUserResetPasswordThunk", async (data) => {
  const res = await PcwUserLoginApi.resetUserPassword(data);
  const toThunk = await thunkResponseHandler(res);
  return toThunk;
});

export const patchUpdateUserPasswordThunk = createAsyncThunk<
  CommonAxiosResponse<CommonAxiosResponse>,
  UpdatePasswordPayload
>("PcwLogin/patchUpdateUserPasswordThunk", async (data) => {
  const res = await PcwUserLoginApi.updatePassword(data);
  const toThunk = await thunkResponseHandler(res);
  return toThunk;
});

export const userLogoutThunk = createAsyncThunk<
  CommonAxiosResponse<CommonAxiosResponse>
>("PcwLogin/userLogoutThunk", async () => {
  const res = await PcwUserLoginApi.userLogout();
  const toThunk = await thunkResponseHandler(res);

  //get new token after logout success
  if (toThunk?.success) {
    await modules.oauth.services.oAuthGetNewToken();
  }

  return toThunk;
});
