import { store } from "app/createStore";
import axios, { AxiosResponse } from "axios";
import modules from "modules";
import { BASE_URL, DEFAULT_HEADERS } from "../../common";
import {
  CLIENT_ID,
  CLIENT_SECRET,
  GRANT_TYPE_CLIENT,
  SCOPE,
} from "./constants";
import { ClientTokenResponse } from "./types";

const oAuthGetNewToken = async () => {
  const clientBody = {
    grant_type: GRANT_TYPE_CLIENT,
    scope: SCOPE,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  const res: AxiosResponse<ClientTokenResponse> = await axios
    .post(`${BASE_URL}/oauth/token`, clientBody, { headers: DEFAULT_HEADERS })
    .then(function (response: AxiosResponse<ClientTokenResponse>) {
      if (response?.data?.access_token && response?.data?.expires_in) {
        const data = response?.data;
        store.dispatch(modules.oauth.actions.clientTokenResolve(data));
      } else {
        store.dispatch(modules.oauth.actions.clientTokenReject());
      }
      return response;
    })
    .catch(function (error) {
      store.dispatch(modules.oauth.actions.clientTokenReject(error));
      return undefined;
    });

  return res?.data || undefined;
};

export const services = {
  oAuthGetNewToken,
};
