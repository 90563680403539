export const sellers = [
  {
    phone: "+12345678910",
    balance: 10_350.25,
    name: "Ethan Booth",
    shopName: "The Beauty",
    package: "Premium",
    published: true,
    image: "/assets/images/avatars/001-man.svg",
  },
  {
    phone: "+12343458910",
    balance: 12_350.45,
    name: "Sofia Hall",
    shopName: "Mobile Gadgets",
    package: "Gold",
    published: true,
    image: "/assets/images/avatars/002-girl.svg",
  },
  {
    phone: "+12345678910",
    balance: 11_345.25,
    shopName: "Tech Tube",
    package: "Premium",
    published: true,
    name: "Dominic Moss",
    image: "/assets/images/avatars/002-woman.svg",
  },
  {
    phone: "+12345678910",
    balance: 9_540.47,
    package: "Silver",
    published: true,
    shopName: "Beyond Threads",
    name: "Tilly Thomson",
    image: "/assets/images/avatars/003-boy.svg",
  },
  {
    phone: "+12345678910",
    balance: 7_250.36,
    package: "Gold",
    published: true,
    shopName: "Beauty Shop",
    name: "Finley Henry",
    image: "/assets/images/avatars/003-man-1.svg",
  },
  {
    phone: "+12345678910",
    balance: 8_356.34,
    package: "Silver",
    published: true,
    shopName: "Febrick Fashion",
    name: "Lola Ryan",
    image: "/assets/images/avatars/004-bald.svg",
  },
  {
    phone: "+12345678910",
    balance: 4_370.55,
    package: "Gold",
    published: true,
    shopName: "The Fashion",
    name: "Gabriel McKenzie",
    image: "/assets/images/avatars/004-woman.svg",
  },
  {
    phone: "+12345678910",
    balance: 2_458.15,
    package: "Premium",
    published: true,
    shopName: "Icon Boy",
    name: "James Davey",
    image: "/assets/images/avatars/005-man-2.svg",
  },
];
