import { Box, CircularProgress } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import BazarButton from "components/BazarButton";
import { H2, Paragraph, Small } from "components/Typography";
import { FlexBox } from "components/flex-box";
import { useFormik } from "formik";
import { useAppDispatch } from "hooks/use-app-dispatch";
import modules from "modules";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import EyeToggleButton from "./EyeToggleButton";
import BazarTextField from "components/BazarTextField";
import { useAppSelector } from "hooks/use-app-selector";
import { useDialogueShow } from "hooks/useDialogue";
import { validateUpdatePassword } from "utils/pcw-account/login-registration-utils";

type ForgotPasswordProps = {};

const { selectors: PcwLoginSelectors, actions: PcwLoginActions } =
  modules.pcwUserLogin;

const UpdatePasswordForm: React.FC<ForgotPasswordProps> = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const emailOrMobile = useAppSelector(
    PcwLoginSelectors.selectUserMobileOrEmail
  );
  const otp = useAppSelector(PcwLoginSelectors.selectUserOtp);

  const {
    showForgotPasswordClose,
    showUpdatePasswordFormClose,
    showLoginFormClose,
  } = useDialogueShow();

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [formErr, getFormErr] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userEmailMobile, setUserEmailMobile] = useState<any>();
  const [userOtp, setUserOtp] = useState<any>();

  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisibility((visible) => !visible);
  }, []);

  const handleFormSubmit = async (values: any) => {
    setIsLoading(true);
    const updatePayload = validateUpdatePassword({
      emailOrMobile: userEmailMobile,
      otp: userOtp,
      password: values.password,
      password_confirmation: values.password_confirmation,
      is_reset: true,
    });

    const response = unwrapResult(
      await dispatch(
        PcwLoginActions?.patchUpdateUserPasswordThunk(updatePayload)
      )
    );
    if (response?.success) {
      setIsLoading(false);
      showForgotPasswordClose();
      showUpdatePasswordFormClose();
      showLoginFormClose();
      router?.push("/confirm/change-success");
    } else {
      setIsLoading(false);
      getFormErr(response?.errors);
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formSchema,
  });

  useEffect(() => {
    if (emailOrMobile && otp) {
      setUserEmailMobile(emailOrMobile);
      setUserOtp(otp);
    }
  }, [emailOrMobile, otp]);

  return (
    <Box
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      p={4}
      maxWidth={450}
      textAlign={"center"}
    >
      <form onSubmit={handleSubmit}>
        <H2>Update Password</H2>
        <FlexBox
          maxWidth={300}
          minHeight={50}
          margin={"auto"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Paragraph fontSize={"12px"} textAlign={"center"} mb={1}>
            {`Your identity has been verified !Set your new password`}
          </Paragraph>
        </FlexBox>

        <BazarTextField
          mb={1.5}
          fullWidth
          size="small"
          name="password"
          label="Password"
          variant="outlined"
          autoComplete="on"
          placeholder="*********"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password}
          type={passwordVisibility ? "text" : "password"}
          error={formErr?.password || (!!touched.password && !!errors.password)}
          helperText={
            formErr?.password || (touched.password && errors.password)
          }
          InputProps={{
            endAdornment: (
              <EyeToggleButton
                show={passwordVisibility}
                click={togglePasswordVisibility}
              />
            ),
          }}
        />

        <BazarTextField
          fullWidth
          mb={1.5}
          size="small"
          autoComplete="on"
          name="password_confirmation"
          variant="outlined"
          label="Retype Password"
          placeholder="*********"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password_confirmation}
          type={passwordVisibility ? "text" : "password"}
          error={
            !!touched.password_confirmation && !!errors.password_confirmation
          }
          helperText={
            touched.password_confirmation && errors.password_confirmation
          }
          InputProps={{
            endAdornment: (
              <EyeToggleButton
                show={passwordVisibility}
                click={togglePasswordVisibility}
              />
            ),
          }}
        />
        <BazarButton
          fullWidth
          type="submit"
          color="secondary"
          variant="contained"
          sx={{ height: 44 }}
          onClick={() => {}}
        >
          {isLoading ? <CircularProgress /> : "Update Password"}
        </BazarButton>
      </form>
    </Box>
  );
};

const formSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least of 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s:])[\w\S]+$/,
      "The password must contain at least one uppercase letter, one lowercase letter, one symbol, and one number."
    ),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Please re-type password"),
});

const initialValues = {
  password: "",
  password_confirmation: "",
};

export default UpdatePasswordForm;
