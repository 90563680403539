import { createAsyncThunk, original } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import {
  PasswordChangePayload,
  PcwProfilePayload,
  PcwProfileResponse,
  updateProfilePayload,
} from "./types";
import { PcwProfileApi } from "./api";

export const pcwUserProfileThunk = createAsyncThunk<
  CommonAxiosResponse<PcwProfileResponse>,
  PcwProfilePayload
>("PcwProfile/pcwUserProfileThunk", async (data) => {
  const res = await PcwProfileApi.getUserProfile(data);
  const toThunk = await thunkResponseHandler(res);
  return toThunk;
});

export const getProfileKachiPointsThunk = createAsyncThunk<
  CommonAxiosResponse<PcwProfileResponse>,
  PcwProfilePayload
>("PcwProfile/getProfileKachiPointsThunk", async (data) => {
  const res = await PcwProfileApi.getUserProfile(data);
  const toThunk = await thunkResponseHandler(res);
  return toThunk;
});

export const pcwProfileEditThunk = createAsyncThunk<
  CommonAxiosResponse<CommonAxiosResponse>,
  updateProfilePayload
>("PcwProfile/pcwProfileEditThunk", async (data) => {
  const res = await PcwProfileApi.getUserUpdateProfile(data);
  const toThunk = await thunkResponseHandler(res);
  return toThunk;
});

export const pcwProfileChangePasswordThunk = createAsyncThunk<
  CommonAxiosResponse<CommonAxiosResponse>,
  PasswordChangePayload
>("PcwProfile/pcwProfileChangePasswordThunk", async (data) => {
  const res = await PcwProfileApi.userPasswordChange(data);
  const toThunk = await thunkResponseHandler(res);
  return toThunk;
});
