import modules from "modules";
import { useAppSelector } from "./use-app-selector";
import { useCallback } from "react";
import { useAppDispatch } from "./use-app-dispatch";

const { selectors: companyInfoSelectors, actions: companyInfoActions } =
  modules.app;
export const useCompanyInfo = () => {
  const dispatch = useAppDispatch();

  const companyInfoData = useAppSelector(
    companyInfoSelectors.selectCompanyInfoData
  );

  const getCompanyInfoRequest = useCallback(() => {
    dispatch(companyInfoActions.getCompanyInfoThunk());
  }, [dispatch]);

  return {
    companyInfoData,
    getCompanyInfoRequest,
  };
};
