import { Api, ApiResponse } from "common";
import {
  GetPromotionalContentRequest,
  GetPromotionalCountsResponse,
  GetPromotionalDetailsRequest,
  GetPromotionalListingsRequest,
  GetPromotionalListingsResponse,
} from "./types";

const getPromotionalListings = (
  params: GetPromotionalListingsRequest
): ApiResponse<GetPromotionalListingsResponse> => {
  return Api.get("/api/ecomm/promotional/get", params);
};

const getPromotionalCounts = (): ApiResponse<GetPromotionalCountsResponse> => {
  const request = {
    action: "counts",
  };
  return Api.get("/api/ecomm/promotional/get", request);
};

const getPromotionalDetails = (
  params: GetPromotionalDetailsRequest
): ApiResponse<GetPromotionalListingsResponse> => {
  return Api.get(`api/ecomm/promotional/details/get/${params.id}`);
};

const getPromotionalContent = (
  params: GetPromotionalContentRequest
): ApiResponse<GetPromotionalListingsResponse> => {
  return Api.get(`api/ecomm/promotional/content/get/${params.id}`);
};

export const PromotionalApi = {
  getPromotionalListings,
  getPromotionalDetails,
  getPromotionalContent,
  getPromotionalCounts,
};
