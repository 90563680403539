import { Api, ApiResponse, CommonAxiosResponse } from "common";
import {
  PasswordChangePayload,
  PcwProfilePayload,
  PcwProfileResponse,
  updateProfilePayload,
} from "./types";

const getUserProfile = (
  payload: PcwProfilePayload
): ApiResponse<PcwProfileResponse> => {
  return Api.post(`api/ecomm/user/profile/details`, payload);
};

const getUserUpdateProfile = (
  payload: updateProfilePayload
): ApiResponse<CommonAxiosResponse> => {
  return Api.patch(`api/ecomm/user/profile/update`, payload);
};

const userPasswordChange = (
  payload: PasswordChangePayload
): ApiResponse<CommonAxiosResponse> => {
  return Api.patch(`api/ecomm/user/account/password/change`, payload);
};

export const PcwProfileApi = {
  getUserProfile,
  getUserUpdateProfile,
  userPasswordChange,
};
