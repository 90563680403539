import { Api, ApiResponse } from "common";
import {
  BlogDetailsResponse,
  BlogFiltersPayload,
  BlogFiltersResponse,
  BlogsPayload,
  BlogsResponse,
} from "./types";

const getAllBlogs = (params?: BlogsPayload): ApiResponse<BlogsResponse> => {
  return Api.get("api/ecomm/blog/get", params);
};

const getBlogFilters = (
  params?: BlogFiltersPayload
): ApiResponse<BlogFiltersResponse> => {
  return Api.get(`api/ecomm/blog/filters/get/${params.keyword}`);
};
const getBlogDetails = (params?: string): ApiResponse<BlogDetailsResponse> => {
  return Api.get(`api/ecomm/blog/get/${params}`);
};

export const BlogsApi = {
  getAllBlogs,
  getBlogFilters,
  getBlogDetails,
};
