import { CategoryItem, MainCategoryItem } from "modules/category/types";

export const categoryIdToName = (
  categoryId: number,
  categoryItems: (MainCategoryItem & CategoryItem)[]
) => {
  const categoryName = categoryItems.find(
    (categoryID) => categoryID.id === categoryId
  );

  return categoryName?.name;
};

export const categoryNameToId = (
  categoryName: string,
  categoryItems: (MainCategoryItem & CategoryItem)[]
) => {
  const categoryId = categoryItems?.find(
    (categoryItems) => categoryItems.name === categoryName
  );

  return categoryId?.id;
};

export const convertCategFormat = (val: string) => {
  switch (val) {
    case "RAM":
      return "RAM";
    case "FAN":
      return "Fan";
    case "CPU":
      return "CPU";
    case "GPU":
      return "GPU";
    case "PSU":
      return "PSU";
    case "HDD":
      return "HDD";
    case "SDD":
      return "SDD";
    default:
      return val
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
  }
};
