import { createSlice } from "@reduxjs/toolkit";

import * as thunks from "./thunks";
import { Action } from "common";
import { UserSocialState } from "./types";

const initialState: UserSocialState = {
  isLoading: false,
  userProfileState: {},
};

const slice = createSlice({
  name: "pcwUserSocial",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(thunks.pcwUserSocialCallback.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunks.pcwUserSocialCallback.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      thunks.pcwUserSocialCallback.fulfilled,
      (state, { payload }) => {
        state.userProfileState = payload?.originalData?.data || {};
      }
    );

    builder.addCase(thunks.pcwUserSocialLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunks.pcwUserSocialLogin.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      thunks.pcwUserSocialLogin.fulfilled,
      (state, { payload }) => {
        state.userProfileState = payload?.originalData?.data || {};
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
