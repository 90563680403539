import React, { useState } from "react";
import { Box, BoxProps, Grid, TextFieldProps } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { Small } from "./Typography";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export interface BazarTextFieldProps {}
type DatePickerProps = {
  onDateChange?: (date: { month: string; day: string; year: string }) => void;
  setValues?: (x: any) => void;
  dateValue?: string;
};

const BazarDatePicker: React.FC<
  TextFieldProps & BoxProps & DatePickerProps
> = ({
  label,
  InputProps,
  onDateChange,
  setValues,
  dateValue,
  ...props
}: any) => {
  const boxProps: any = {};
  const [value, setValue] = React.useState<Dayjs | null>(
    dayjs(dateValue || "2012-01-01")
  );

  const handleDateChange = (newValue: Dayjs | null) => {
    setValue(newValue);
    const formattedDate = dayjs(newValue).format("YYYY-MM-D");
    setValues((prevValues) => ({
      ...prevValues,
      birthdate: formattedDate,
    }));
  };

  return (
    <Box {...boxProps}>
      {label && (
        <Small
          display="block"
          mb={1}
          textAlign="left"
          fontWeight="600"
          color="grey.700"
        >
          {label}
        </Small>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3}>
          <Grid container spacing={1}>
            <Grid item md={4} xs={4}>
              {" "}
              <DatePicker
                views={["month"]}
                label="month"
                value={value}
                onChange={handleDateChange}
                //   renderInput={(params) => <TextField {...params} helperText={null} />}
              />
            </Grid>
            <Grid item md={4} xs={4}>
              {" "}
              <DatePicker
                views={["day"]}
                label="day"
                value={value}
                onChange={handleDateChange}
                //   renderInput={(params) => <TextField {...params} helperText={null} />}
              />
            </Grid>
            <Grid item md={4} xs={4}>
              {" "}
              <DatePicker
                views={["year"]}
                label="year"
                value={value}
                onChange={handleDateChange}
                minDate={dayjs("1950-03-01")}
                maxDate={dayjs("2012-06-01")}
                //   renderInput={(params) => <TextField {...params} helperText={null} />}
              />
            </Grid>
          </Grid>
        </Stack>
      </LocalizationProvider>
    </Box>
  );
};

const spacePropList = [
  "m",
  "mt",
  "mr",
  "mb",
  "ml",
  "mx",
  "my",
  "p",
  "pt",
  "pr",
  "pb",
  "pl",
  "px",
  "py",
  "margin",
  "marginTop",
  "marginRight",
  "marginBottom",
  "marginLeft",
  "marginX",
  "marginY",
  "padding",
  "paddingTop",
  "paddingRight",
  "paddingBottom",
  "paddingLeft",
  "paddingX",
  "paddingY",
];

export default BazarDatePicker;
