import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import { BuildsGalleryApi } from "./api";
import {
  GetBuildsGalleryPayload,
  GetBuildsGalleryResponse,
  BuildsDetailsResponse,
  BuildsGalleryPagePayload,
  BuildsGalleryPageResponse,
} from "./types";

export const getBuildsGalleryThunk = createAsyncThunk<
  CommonAxiosResponse<GetBuildsGalleryResponse>,
  GetBuildsGalleryPayload
>("Cart/getCategoriesThunk", async (data) => {
  const res = await BuildsGalleryApi.getBuildsGallery(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getBuildsGalleryPageThunk = createAsyncThunk<
  CommonAxiosResponse<BuildsGalleryPageResponse>,
  BuildsGalleryPagePayload
>("Cart/getBuidsGalleryPageThunk", async (data) => {
  const res = await BuildsGalleryApi.getBuildsGalleryPage(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getBuildsDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<BuildsDetailsResponse>,
  BuildsGalleryPagePayload
>("Cart/getBuildsDetailsThunk", async (data) => {
  const res = await BuildsGalleryApi.getBuildsDetails({ uuid: data.uuid });
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getBuildsItemOnProductThunk = createAsyncThunk<
  CommonAxiosResponse<GetBuildsGalleryResponse>,
  string
>("Cart/getBuildsItemOnProductThunk", async (uuid) => {
  const res = await BuildsGalleryApi.getBuildsItemOnProduct(uuid);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
