import { Api, ApiResponse } from "common";
import { KachiPointsPayload, KachiPointsResponse } from "./types";

const getKachiPoints = (
  payload: KachiPointsPayload
): ApiResponse<KachiPointsResponse> => {
  return Api.get(`api/ecomm/user/kachi_points/get`, payload);
};

export const KachiPointsApi = {
  getKachiPoints,
};
