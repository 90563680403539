import { Action, Response } from "common";
import { StrictEffect } from "redux-saga/effects";
import { GetLocationPayload, GetLocationResponse } from "./types";

export function* getRegionsSaga(
  action: Action<GetLocationPayload>
): Generator<StrictEffect, void, Response<GetLocationResponse>> {
  let data = null;
  let problem = null;
  // try {
  //   const response = yield call(
  //     BuildsGalleryApi.getRegions,
  //     action.payload
  //   );
  //   data = response.data;
  //   problem = response.problem;
  // } catch (error) {
  //   yield put(actions.buildsGalleryReject());
  // }

  // if (!data) {
  //   yield put(actions.buildsGalleryReject(problem));
  //   return;
  // }

  // if (isFailResponseData(data)) {
  //   yield put(actions.buildsGalleryReject(data));
  //   return;
  // }

  // if (isSuccessResponseData(data)) {
  //   yield put(actions.buildsGalleryResolve(data));
  // }
}

export const sagas = {
  getRegionsSaga,
};
