import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AddCartItemPayload,
  CartItemLengthResponse,
  CartItemResponse,
  DeleteCartItemPayload,
  GetCartItemPayload,
  UpdateCartPayload,
} from "./types";
import {
  CommonAxiosResponse,
  SuccessResponse,
  thunkResponseHandler,
} from "common";
import { UserCartApi } from "./api";

export const addUserCartItemThunk = createAsyncThunk<
  CommonAxiosResponse<SuccessResponse>,
  AddCartItemPayload
>("UserCart/addUserCartItemThunk", async (data) => {
  const res = await UserCartApi.addCartItem(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const overrideUserCartThunk = createAsyncThunk<
  CommonAxiosResponse<CartItemResponse>,
  GetCartItemPayload
>("UserCart/overrideUserCartThunk", async (data) => {
  const res = await UserCartApi.getCartItem(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getUserCartItemThunk = createAsyncThunk<
  CommonAxiosResponse<CartItemResponse>,
  GetCartItemPayload
>("UserCart/getUserCartItemThunk", async (data) => {
  const res = await UserCartApi.getCartItem(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getUserCartItemWithoutStockThunk = createAsyncThunk<
  CommonAxiosResponse<CartItemResponse>,
  GetCartItemPayload
>("UserCart/getUserCartItemWithoutStockThunk", async (data) => {
  const res = await UserCartApi.getCartItem(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getUserCartLength = createAsyncThunk<
  CommonAxiosResponse<CartItemLengthResponse>,
  GetCartItemPayload
>("UserCart/getUserCartLength", async (data) => {
  const res = await UserCartApi.getCartItem({
    count_only: data.count_only,
    limit: data?.limit,
  });
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const updateCartItemQuantityThunk = createAsyncThunk<
  CommonAxiosResponse<SuccessResponse>,
  UpdateCartPayload
>("UserCart/updateCartItemQuantityThunk", async (data) => {
  const res = await UserCartApi.updateCartItem(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const removeCartItemThunk = createAsyncThunk<
  CommonAxiosResponse<SuccessResponse>,
  DeleteCartItemPayload
>("UserCart/removeCartItemThunk", async (data) => {
  const res = await UserCartApi.deleteCartItem(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
