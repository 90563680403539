import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import modules from "modules";

const { reducer: cartReducer, constants: cartConstants } = modules.cart;
const { reducer: blogReducer, constants: blogConstants } = modules.blogs;
const { reducer: productDetailsReducer } = modules.productDetails;
const { reducer: categoryReducer, constants: categoryConstants } =
  modules.category;
const { reducer: oAuthReducer, constants: oAuthConstants } = modules.oauth;
const { reducer: productsReducer, constants: productsConstants } =
  modules.products;
const { reducer: pcBuilderReducer, constants: pcBuilderConstants } =
  modules.pcBuilder;
const { reducer: buildsGalleryReducer, constants: buildsGalleryConstants } =
  modules.buildsGallery;
const { reducer: locationsReducer } = modules.location;
const { reducer: pcPackageReducer } = modules.pcPackage;
const { reducer: recentlyViewedReducer, constants: recentlyViewedConstants } =
  modules.recentlyViewed;
const { reducer: bannersReducer, constants: bannersConstants } = modules.banner;
const { reducer: buildsReducer, constants: buildsContants } = modules.myBuilds;
const { reducer: flashDealsReducer, constants: flashDealsConstants } =
  modules.flashDeal;
const { reducer: pricelistReducer, constants: pricelistConstants } =
  modules.priceList;
const { reducer: optionsReducer, constants: optionsConstants } =
  modules.options;
const { reducer: appReducer, constants: appConstants } = modules.app;
const { reducer: pcwRegistrationReducer, constants: pcwRegistrationConstants } =
  modules.pcwRegistration;
const { reducer: pcwLoginReducer } = modules.pcwUserLogin;
const { reducer: pcwUserProfileReducer, constants: pcwUserProfileConstants } =
  modules.pcwUserProfile;
const { reducer: PcwUserCartReducer, constants: pcwUserCartConstants } =
  modules.pcwUserCart;
const { reducer: PcwOrderHistoryReducer, constants: pcwOrderHistoryConstants } =
  modules.pcwOrderHistory;
const { reducer: PcwUserAddressReducer, constants: pcwUserAddressConstants } =
  modules.pcwUserAddress;
modules.pcwOrderHistory;
const { reducer: BranchReducer } = modules.branch;
const { reducer: KachiPointsReducer } = modules.kachiPoints;
const { reducer: PromotionalReducer } = modules.promotional;

const blogConfig = {
  key: blogConstants.name,
  storage: storage,
  whitelist: ["blogs"],
};

const recentlyViewedConfig = {
  key: recentlyViewedConstants.name,
  storage: storage,
  whitelist: ["recentlyViewedItems"],
};

const cartConfig = {
  key: cartConstants.name,
  storage: storage,
  whitelist: ["cart", "localOrderHistory", "customerShippingInfo"],
};

const categoriesConfig = {
  key: categoryConstants.name,
  storage: storage,
  whitelist: ["categories"],
};

const oauthConfig = {
  key: oAuthConstants.name,
  storage: storage,
  whitelist: ["access_token", "expires_in"],
};

const productsConfig = {
  key: productsConstants.name,
  storage: storage,
  whitelist: ["productsSearchBarItems", "products", "latestProductsItems"],
};

const buildsGalleryConfig = {
  key: buildsGalleryConstants.name,
  storage: storage,
  whitelist: ["buildsGallery"],
};

const pcbuilderConfig = {
  key: pcBuilderConstants.name,
  storage: storage,
  whitelist: [
    "partsPrimaryCategory",
    "partsSecondaryCategory",
    "selectedProductId",
    "buildParts",
  ],
};

const bannerConfig = {
  key: bannersConstants.name,
  storage: storage,
  whitelist: ["banners"],
};

const buildsConfig = {
  key: buildsContants.name,
  storage: storage,
  whitelist: ["builds"],
};

const flashDealsConfig = {
  key: flashDealsConstants.name,
  storage: storage,
  whitelist: ["flashDeals"],
};

const pricelistConfig = {
  key: pricelistConstants.name,
  storage: storage,
  whitelist: ["pricelist"],
};

const appConfig = {
  key: appConstants.name,
  storage: storage,
  whitelist: ["companyInfoState"],
};

const pcwUserProfileConfig = {
  key: pcwUserProfileConstants.name,
  storage: storage,
  whitelist: ["userProfileData"],
};

const pcwUserCartConfig = {
  key: pcwUserCartConstants.name,
  storage: storage,
  whitelist: ["userCartItem", "cartLength", "checkOutCartState"],
};

const pcwOrderHistoryConfig = {
  key: pcwOrderHistoryConstants.name,
  storage: storage,
  whitelist: ["orderDetailState", "orderListState", "orderTotal"],
};

const pcwRegistrationConfig = {
  key: pcwRegistrationConstants.name,
  storage: storage,
  whitelist: ["userReg"],
};

const pcwUserAddressConfig = {
  key: pcwUserAddressConstants.name,
  storage: storage,
  whitelist: ["userAddressDetailState"],
};

const optionsConfig = {
  key: optionsConstants.name,
  storage: storage,
  whitelist: ["paymongoMerchantInterest"],
};

export const rootReducer = combineReducers({
  Branch: BranchReducer,
  App: persistReducer(appConfig, appReducer),
  Banner: persistReducer(bannerConfig, bannersReducer),
  Builds: persistReducer(buildsConfig, buildsReducer),
  Blogs: persistReducer(blogConfig, blogReducer),
  Cart: persistReducer(cartConfig, cartReducer),
  Category: persistReducer(categoriesConfig, categoryReducer),
  OAuth: persistReducer(oauthConfig, oAuthReducer),
  PCBuilder: persistReducer(pcbuilderConfig, pcBuilderReducer),
  Products: persistReducer(productsConfig, productsReducer),
  BuildsGallery: persistReducer(buildsGalleryConfig, buildsGalleryReducer),
  FlashDeal: persistReducer(flashDealsConfig, flashDealsReducer),
  PriceList: persistReducer(pricelistConfig, pricelistReducer),
  Options: persistReducer(optionsConfig, optionsReducer),
  RecentlyViewedItems: persistReducer(
    recentlyViewedConfig,
    recentlyViewedReducer
  ),
  Location: locationsReducer,
  ProductDetails: productDetailsReducer,
  PcPackage: pcPackageReducer,
  PcwRegistration: persistReducer(
    pcwRegistrationConfig,
    pcwRegistrationReducer
  ),
  PcwUserLogin: pcwLoginReducer,
  PcwUserProfile: persistReducer(pcwUserProfileConfig, pcwUserProfileReducer),
  PcwUserCart: persistReducer(pcwUserCartConfig, PcwUserCartReducer),
  PcwOrderHistory: persistReducer(
    pcwOrderHistoryConfig,
    PcwOrderHistoryReducer
  ),
  PcwUserAddress: persistReducer(pcwUserAddressConfig, PcwUserAddressReducer),
  KachiPoints: KachiPointsReducer,
  Promotional: PromotionalReducer,
});
