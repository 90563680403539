export const orders = [
  {
    qty: 12,
    amount: "$250",
    id: "6ed34Edf65d",
    status: "Delivered",
    purchaseDate: "19 April, 2022",
    billingAddress: "19 Moulton Road, GULBERWICK.",
  },
  {
    qty: 2,
    amount: "$24",
    id: "6ed34Edf65e",
    status: "Pending",
    purchaseDate: "18 April, 2022",
    billingAddress: "19 Moulton Road, GULBERWICK.",
  },
  {
    qty: 3,
    amount: "$240",
    id: "6ed34Edf65f",
    status: "Processing",
    purchaseDate: "17 April, 2022",
    billingAddress: "19 Moulton Road, GULBERWICK.",
  },
  {
    qty: 6,
    amount: "$210",
    id: "6ed34Edf65g",
    status: "Delivered",
    purchaseDate: "16 April, 2022",
    billingAddress: "19 Moulton Road, GULBERWICK.",
  },
  {
    qty: 12,
    amount: "$2000",
    id: "6ed34Edf65h",
    status: "Pending",
    purchaseDate: "15 April, 2022",
    billingAddress: "19 Moulton Road, GULBERWICK.",
  },
  {
    qty: 4,
    amount: "$2500",
    id: "6ed34Edf65i",
    status: "Delivered",
    purchaseDate: "12 April, 2022",
    billingAddress: "19 Moulton Road, GULBERWICK.",
  },
  {
    qty: 1,
    amount: "$100",
    id: "6ed34Edf65j",
    status: "Processing",
    purchaseDate: "16 April, 2022",
    billingAddress: "19 Moulton Road, GULBERWICK.",
  },
  {
    qty: 6,
    amount: "$780",
    id: "6ed34Edf65k",
    status: "Delivered",
    purchaseDate: "1 April, 2022",
    billingAddress: "19 Moulton Road, GULBERWICK.",
  },
];
