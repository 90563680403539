import { Keyboard } from "swiper";
import { PCBuildRow } from "./types";

export const name = "PCBuilder";

/* ui category name */
export const PROCESSOR = "PROCESSOR";
export const MOTHERBOARD = "MOTHERBOARD";
export const MEMORY = "MEMORY";
export const GPU = "GPU";
export const SSD = "SSD";
export const HDD = "HDD";
export const PSU = "PSU";
export const CASING = "CASING";
export const CPU_COOLER = "CPU COOLER";
export const FAN = "FAN";
export const ACCESSORIES = "ACCESSORIES";
export const MONITOR = "MONITOR";
export const KEYBOARD_MOUSE = "KEYBOARD AND MOUSE";
export const KEYBOARD = "KEYBOARD";
export const MOUSE = "MOUSE";

/* payload category name */
export const CPU = "CPU";
export const Motherboard = "Motherboard";
export const RAM = "RAM";
export const Casing = "Casing";
export const CPU_Cooler = "CPU Cooler";
export const Fan = "Fan";
export const Accessories = "Accessories";
export const Monitor = "Monitor";
export const KeyboardAndMouse = "Keyboard and Mouse";
export const KeyboardItem = "Keyboard";
export const Mouse = "Mouse";

export const constants = {
  name,
  PROCESSOR,
  MOTHERBOARD,
  MEMORY,
  GPU,
  SSD,
  HDD,
  PSU,
  CASING,
  CPU_COOLER,
  FAN,
  CPU,
  Motherboard,
  RAM,
  Casing,
  CPU_Cooler,
  Fan,
  ACCESSORIES,
  Accessories,
  MONITOR,
  KeyboardAndMouse,
  KEYBOARD_MOUSE,
  KEYBOARD,
  KeyboardItem,
  MOUSE,
  Mouse,
};

/**
 * Need to also edit mapCategoryNameToComponentName for 'multiple' property to take effect
 */
export const componentRows: PCBuildRow[] = [
  { name: PROCESSOR, bgColor: "#FFECB3", disableQty: true },
  { name: MOTHERBOARD, bgColor: "#62CBFF", disableQty: true },
  { name: MEMORY, bgColor: "#B0BEC5" },
  { name: GPU, bgColor: "lawngreen", disableQty: true },
  { name: SSD, bgColor: "#C5E1A5", multiple: true },
  { name: HDD, bgColor: "#B2EBF2", multiple: true },
  { name: PSU, bgColor: "#81D4FA", disableQty: true },
  { name: CASING, bgColor: "#B39DDB", disableQty: true },
  { name: CPU_COOLER, bgColor: "#BCAAA4" },
  { name: FAN, bgColor: "#B2DFDB", multiple: true },
  { name: ACCESSORIES, bgColor: "#B2DFDB", multiple: true },
  { name: MONITOR, bgColor: "#B2DFDB", multiple: true },
  { name: KEYBOARD_MOUSE, bgColor: "#B2DFDB" },
  { name: KEYBOARD, bgColor: "#B2DFDB" },
  { name: MOUSE, bgColor: "#B2DFDB" },
];
