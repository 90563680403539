import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Theme,
  IconButton,
  Divider,
} from "@mui/material";
import BazarButton from "components/BazarButton";
import BazarTextField from "components/BazarTextField";
import { FlexBox, FlexRowCenter } from "components/flex-box";
import { H3, H6, Small } from "components/Typography";
import { useFormik } from "formik";
import React, { useCallback, useState } from "react";
import * as yup from "yup";
import EyeToggleButton from "./EyeToggleButton";
import CloseIcon from "@mui/icons-material/Close";
import { Wrapper } from "./Login";
import SocialButtons from "./SocialButtons";
import BazarDatePicker from "components/BazarDatePicker";
import modules from "modules";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { unwrapResult } from "@reduxjs/toolkit";
import { useRouter } from "next/router";
import { pcwVerifyRegistrationPayload } from "utils/pcw-account/login-registration-utils";
import Link from "next/link";
import { makeStyles } from "@mui/styles";
import { useDialogueShow } from "hooks/useDialogue";

type SignUpProps = {
  signUpClose?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  hide: {
    display: "none",
  },
}));

const { actions: UserRegistrationActions } = modules.pcwRegistration;

const Signup: React.FC<SignUpProps> = ({ signUpClose }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const router = useRouter();

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [birthDateErr, setBirthDateErr] = useState<any>("");
  const [formErr, getFormErr] = useState<any>({});
  const [regLoading, setRegLoading] = useState<boolean>(false);

  const { showSignUpFormClose } = useDialogueShow();

  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisibility((visible) => !visible);
  }, []);

  const handleFormSubmit = async (values: any, { resetForm }) => {
    const registrationPayload = pcwVerifyRegistrationPayload(values);
    setRegLoading(true);
    const response = unwrapResult(
      await dispatch(
        UserRegistrationActions?.setUserRegistrationThunk(
          registrationPayload?.emailOrPhoneObj
        )
      )
    );
    if (response?.success) {
      getFormErr({});
      setBirthDateErr("");
      resetForm();
      setRegLoading(false);
      signUpClose();

      await dispatch(
        UserRegistrationActions.showConfirmationDialogue({
          showConfirmation: true,
          actionCase: registrationPayload?.validation,
        })
      );

      await dispatch(
        UserRegistrationActions.userSignUpData({
          emailOrPhone: values?.emailOrPhone,
        })
      );
    }
    if (response?.errors) {
      getFormErr(response?.errors);
      setBirthDateErr(response?.errors?.birthdate);
      setRegLoading(false);
    }
  };

  const handleBlur = () => {
    getFormErr({});
  };

  const { values, errors, touched, handleChange, handleSubmit, setValues } =
    useFormik({
      initialValues,
      onSubmit: handleFormSubmit,
      validationSchema: formSchema,
    });

  return (
    <Wrapper
      elevation={3}
      passwordVisibility={passwordVisibility}
      sx={{ width: "100%", padding: 4 }}
    >
      <div
        style={{
          position: "relative",
          float: "right",
          marginTop: "-25px",
          marginRight: "-30px",
        }}
      >
        {" "}
        <IconButton onClick={showSignUpFormClose}>
          <CloseIcon
            sx={{ color: "primary" }}
            classes={{ colorPrimary: "primary" }}
          />
        </IconButton>
      </div>
      <form onSubmit={handleSubmit}>
        <H3 textAlign="center" mb={0.5} mt={3}>
          Create your own Account
        </H3>

        {/* <Small
          mb={3.5}
          fontSize={12}
          display="block"
          fontWeight={600}
          color="grey.800"
          textAlign="center"
        >
          Please fill all fields to continue
        </Small> */}

        <Box mt={3.3} />
        <SocialButtons />
        <Box mt={3.3} />

        <Box mb={2} mt={3.3}>
          <Box width="200px" mx="auto">
            <Divider />
          </Box>
          <FlexBox justifyContent="center" mt={-1.625}>
            <Box color="grey.600" bgcolor="background.paper" px={2}>
              or
            </Box>
          </FlexBox>
        </Box>

        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <BazarTextField
              mb={1}
              fullWidth
              name="first_name"
              size="small"
              label="First Name"
              variant="outlined"
              onBlur={handleBlur}
              value={values.first_name}
              onChange={handleChange}
              placeholder="Juan"
              error={!!touched.first_name && !!errors.first_name}
              helperText={touched.first_name && errors.first_name}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <BazarTextField
              mb={1}
              fullWidth
              name="last_name"
              size="small"
              label="Last Name"
              variant="outlined"
              onBlur={handleBlur}
              value={values.last_name}
              onChange={handleChange}
              placeholder="Dela Cruz"
              error={!!touched.last_name && !!errors.last_name}
              helperText={touched.last_name && errors.last_name}
            />
          </Grid>
          <Grid item md={6}></Grid>
        </Grid>

        <BazarTextField
          mb={1.5}
          fullWidth
          name="emailOrPhone"
          size="small"
          variant="outlined"
          onBlur={handleBlur}
          value={values.emailOrPhone}
          onChange={handleChange}
          label="Email or Phone Number"
          placeholder="example@mail.com/09xxxxxxxxx"
          error={
            !!touched.emailOrPhone &&
            (!!errors.emailOrPhone || formErr?.mobile_no || formErr?.email)
              ? true
              : undefined
          }
          helperText={
            touched.emailOrPhone
              ? errors.emailOrPhone || formErr?.mobile_no || formErr?.email
              : ""
          }
        />

        <BazarTextField
          mb={1.5}
          fullWidth
          size="small"
          name="password"
          label="Password"
          variant="outlined"
          autoComplete="on"
          placeholder="*********"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password}
          type={passwordVisibility ? "text" : "password"}
          error={formErr?.password || (!!touched.password && !!errors.password)}
          helperText={
            touched.password
              ? formErr?.password || (touched.password && errors.password)
              : ""
          }
          InputProps={{
            endAdornment: (
              <EyeToggleButton
                show={passwordVisibility}
                click={togglePasswordVisibility}
              />
            ),
          }}
        />

        <BazarTextField
          fullWidth
          mb={1.5}
          size="small"
          autoComplete="on"
          name="password_confirmation"
          variant="outlined"
          label="Retype Password"
          placeholder="*********"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password_confirmation}
          type={passwordVisibility ? "text" : "password"}
          error={
            !!touched.password_confirmation && !!errors.password_confirmation
          }
          helperText={
            touched.password_confirmation
              ? touched.password_confirmation && errors.password_confirmation
              : ""
          }
          InputProps={{
            endAdornment: (
              <EyeToggleButton
                show={passwordVisibility}
                click={togglePasswordVisibility}
              />
            ),
          }}
        />
        <BazarDatePicker
          mb={1.5}
          fullWidth
          name="birthdate"
          label="Birthdate"
          size="small"
          variant="outlined"
          onBlur={handleBlur}
          value={values.birthdate}
          setValues={setValues}
          onChange={handleChange}
          error={!!touched.birthdate && !!errors.birthdate}
          helperText={touched.birthdate && errors.birthdate}
        />
        <Small
          my={1}
          sx={{ textAlign: "center", display: "block", color: "red" }}
        >
          {birthDateErr ? birthDateErr : null}
        </Small>

        <div className={classes.hide}>
          <FormControlLabel
            name="agreement"
            className="agreement"
            onChange={handleChange}
            control={
              <Checkbox
                size="small"
                color="secondary"
                checked={values.agreement || false}
              />
            }
            label={
              <FlexBox
                flexWrap="wrap"
                alignItems="center"
                justifyContent="flex-start"
              >
                <span>Subscribe to our newsletter</span>
              </FlexBox>
            }
          />
        </div>
        <div>
          <FormControlLabel
            name="agreement"
            className="agreement"
            onChange={handleChange}
            control={
              <Checkbox
                size="small"
                color="secondary"
                checked={values.agreement || false}
              />
            }
            label={
              <FlexBox
                flexWrap="wrap"
                alignItems="center"
                justifyContent="flex-start"
              >
                <div>
                  By clicking &apos;Create account&apos;, I confirm that I am at
                  least eighteen years old, accept our{" "}
                  <Link href={"/privacy-policy "}>
                    <a
                      style={{ color: "#2F80ED" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      privacy policy,
                    </a>
                  </Link>{" "}
                  and certify that all the information provided above is
                  accurate to the best of my knowledge and belief.
                </div>
              </FlexBox>
            }
          />
        </div>
        {!!touched.agreement && !!errors.agreement && !values.agreement ? (
          <Small
            mb={1}
            sx={{ textAlign: "center", display: "block", color: "red" }}
          >
            {"You have to agree with our Terms and Conditions!"}
          </Small>
        ) : (
          ""
        )}

        <BazarButton
          fullWidth
          type="submit"
          color="secondary"
          variant="contained"
          sx={{ height: 44 }}
          onClick={() => {}}
        >
          {regLoading ? <CircularProgress /> : "Create Account"}
        </BazarButton>
      </form>

      <FlexRowCenter my="1.25rem">
        <Box>{"Already Have an Account?"}</Box>
        <H6
          ml={1}
          borderBottom="1px solid"
          borderColor="grey.900"
          onClick={showSignUpFormClose}
          sx={{ cursor: "pointer" }}
        >
          {"Login"}
        </H6>
      </FlexRowCenter>
    </Wrapper>
  );
};

const initialValues = {
  first_name: "",
  last_name: "",
  emailOrPhone: "",
  password: "",
  password_confirmation: "",
  agreement: false,
  birthdate: "2012-01-01",
};

const formSchema = yup.object().shape({
  first_name: yup.string().required("Name is required"),
  last_name: yup.string().required("Lastname is required"),
  emailOrPhone: yup
    .string()
    .required("Field is required")
    .test("emailOrPhoneNumber", "Invalid email or phone number", (value) => {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const phoneRegex = /^\d{11}$/;
      if (!value) {
        return true;
      }
      return emailRegex.test(value) || phoneRegex.test(value);
    }),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least of 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s:])[\w\S]+$/,
      "The password must contain at least one uppercase letter, one lowercase letter, one symbol, and one number."
    ),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Please re-type password"),
  agreement: yup
    .bool()
    .oneOf([true], "You must agree to the terms and conditions")
    .nullable(),
});

export default Signup;
