import { UpdatePasswordPayload } from "modules/pcw-login/types";
import { OtpPurpose } from "utils/constants";

type EmailOrPhoneLogin = {
  emailOrPhone?: string;
  password?: string;
};

type EmailOrPhoneRegistration = {
  birthdate?: string;
  emailOrPhone?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
  password_confirmation?: string;
  middle_name?: string;
};

export const pcwVerifyLoginPayload = (values: EmailOrPhoneLogin) => {
  const regex = /^\d+$/;
  const isMobile = regex.test(values.emailOrPhone);
  let emailOrPhone = {
    ...(isMobile
      ? { mobile_no: values?.emailOrPhone }
      : { email: values.emailOrPhone }),
    password: values?.password,
  };
  let validation = isMobile ? "mobile" : "email";

  return { emailOrPhone, validation };
};

export const pcwVerifyRegistrationPayload = (
  values: EmailOrPhoneRegistration
) => {
  const regex = /^\d+$/;
  const isMobile = regex.test(values.emailOrPhone);
  let emailOrPhoneObj = {
    birthdate: values.birthdate,
    ...(isMobile
      ? { mobile_no: values.emailOrPhone }
      : { email: values.emailOrPhone }),
    first_name: values.first_name,
    last_name: values.last_name,
    password: values.password,
    password_confirmation: values.password_confirmation,
    middle_name: "",
  };
  let validation = isMobile ? "mobile" : "email";
  return {
    emailOrPhoneObj,
    validation,
  };
};

export const validatePayloadOtp = (getOTP: string, userOtpData: any) => {
  const regex = /^\d+$/;
  const isMobile = regex.test(userOtpData);
  const validatePayload = {
    otp: getOTP,
    purpose: OtpPurpose.reset_password,
    ...(isMobile ? { mobile_no: userOtpData } : { email: userOtpData }),
  };
  return validatePayload;
};

export const resendPayloadOtp = (userOtpData: any) => {
  const regex = /^\d+$/;
  const isMobile = regex.test(userOtpData);
  const validatePayload = {
    purpose: OtpPurpose.reset_password,
    ...(isMobile ? { mobile_no: userOtpData } : { email: userOtpData }),
  };
  return validatePayload;
};

export const validateUpdatePassword = (payload: UpdatePasswordPayload) => {
  const regex = /^\d+$/;
  const isMobile = regex.test(payload?.emailOrMobile);
  const validatePayload = {
    otp: payload?.otp,
    password: payload?.password,
    password_confirmation: payload?.password_confirmation,
    is_reset: true,
    ...(isMobile
      ? { mobile_no: payload?.emailOrMobile }
      : { email: payload?.emailOrMobile }),
  };
  return validatePayload;
};
