import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import { FlashDealsApi } from "./api";
import { GetFlashDealsPayload, GetFlashDealsResponse } from "./types";

export const getFlashDealsThunk = createAsyncThunk<
  CommonAxiosResponse<GetFlashDealsResponse>,
  GetFlashDealsPayload
>("Cart/getFlashDealsThunk", async (data) => {
  const res = await FlashDealsApi.getFlashDeals(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
