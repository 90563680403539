import { createSlice } from "@reduxjs/toolkit";
import { Action, ErrorResponse } from "common";
import { SliceStatusValue, _ } from "common";
import {
  LocationState,
  GetLocationPayload,
  GetLocationResponse,
} from "./types";
import * as thunks from "./thunks";

const initialState: LocationState = {
  regions: [],
  provinces: [],
  cities: [],
  barangays: [],

  regionsLoading: false,
  provincesLoading: false,
  citiesLoading: false,
  barangaysLoading: false,

  isRegionsError: false,
  isProvincesError: false,
  isCitiesError: false,
  isBarangaysError: false,
};

// const buildsGalleryRequest = (
//   state: LocationState,
//   _action: Action<GetLocationPayload>
// ) => {
//   state.getBuildsGalleryStatus = SliceStatusValue.loading;
//   state.buildsGalleryLoading = true;
// };

// const buildsGalleryResolve = (
//   state: LocationState,
//   action: Action<GetLocationResponse>
// ) => {
//   state.buildsGalleryLoading = false;
//   state.buildsGallery = action?.payload?.data || [];
//   state.getBuildsGalleryStatus = SliceStatusValue.resolve;
// };

// const buildsGalleryReject = (
//   state: LocationState,
//   _action: Action<ErrorResponse>
// ) => {
//   state.getBuildsGalleryStatus = SliceStatusValue.reject;
// };

const slice = createSlice({
  name: "BuildsGallery",
  initialState,
  reducers: {
    // buildsGalleryRequest,
    // buildsGalleryResolve,
    // buildsGalleryReject,
  },
  extraReducers(builder) {
    // Regions
    builder.addCase(thunks.getLocationRegions.pending, (state, { payload }) => {
      state.regions = [];
      state.regionsLoading = true;
      state.isRegionsError = false;
    });
    builder.addCase(
      thunks.getLocationRegions.rejected,
      (state, { payload }) => {
        state.regions = [];
        state.isRegionsError = true;
        state.regionsLoading = false;
      }
    );
    builder.addCase(
      thunks.getLocationRegions.fulfilled,
      (state, { payload }) => {
        state.regionsLoading = false;
        state.isRegionsError = false;
        state.regions = payload?.originalData?.data?.data || [];
      }
    );

    // Provinces
    builder.addCase(
      thunks.getLocationProvinces.pending,
      (state, { payload }) => {
        state.provinces = [];
        state.provincesLoading = true;
        state.isProvincesError = false;
      }
    );
    builder.addCase(
      thunks.getLocationProvinces.rejected,
      (state, { payload }) => {
        state.provinces = [];
        state.isProvincesError = true;
        state.provincesLoading = false;
      }
    );
    builder.addCase(
      thunks.getLocationProvinces.fulfilled,
      (state, { payload }) => {
        state.provincesLoading = false;
        state.isProvincesError = false;
        state.provinces = payload?.originalData?.data?.data || [];
      }
    );

    // cities
    builder.addCase(thunks.getLocationCities.pending, (state, { payload }) => {
      state.citiesLoading = true;
      state.isCitiesError = false;
      state.cities = [];
    });
    builder.addCase(thunks.getLocationCities.rejected, (state, { payload }) => {
      state.cities = [];
      state.citiesLoading = false;
      state.isCitiesError = true;
    });
    builder.addCase(
      thunks.getLocationCities.fulfilled,
      (state, { payload }) => {
        state.isCitiesError = false;
        state.citiesLoading = false;
        state.cities = payload?.originalData?.data?.data || [];
      }
    );

    // barangays
    builder.addCase(
      thunks.getLocationBarangays.pending,
      (state, { payload }) => {
        state.isBarangaysError = false;
        state.barangaysLoading = true;
        state.barangays = [];
      }
    );
    builder.addCase(
      thunks.getLocationBarangays.rejected,
      (state, { payload }) => {
        state.barangays = [];
        state.isBarangaysError = true;
        state.barangaysLoading = false;
      }
    );
    builder.addCase(
      thunks.getLocationBarangays.fulfilled,
      (state, { payload }) => {
        state.isBarangaysError = false;
        state.barangaysLoading = false;
        state.barangays = payload?.originalData?.data?.data || [];
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
