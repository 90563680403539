import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action } from "common";
import * as thunks from "./thunks";
import { PcwLoginState } from "./types";

const initialState: PcwLoginState = {
  userProfile: {},
  isLoading: false,
  showForgotPassword: false,
  userMobileOrEmail: "",
  userOtp: "",
  showUpdateForm: false,
  showPasswordOtp: false,
  showLoginForm: false,
  showSignUpForm: false,
  showEmailVerify: false,
  showMobileVerify: false,
  confirmationPurpose: "",
  showErrorLoginFacebookMsg: "",
};

const showVerificationDialogue = (
  state: PcwLoginState,
  action: Action<{ showConfirmation: boolean; actionCase: string }>
) => {
  const { showConfirmation, actionCase } = action?.payload;
  switch (actionCase) {
    case "email":
      state.showEmailVerify = showConfirmation;
      break;
    case "mobile":
      state.showMobileVerify = showConfirmation;
      break;
    default:
      return;
  }
};

const showUpdatePasswordForm = (
  state: PcwLoginState,
  action: Action<{ isOpen?: boolean; emailOrMobile?: string; otp?: string }>
) => {
  const { isOpen, emailOrMobile, otp } = action?.payload;
  state.showUpdateForm = isOpen;
  state.userMobileOrEmail = emailOrMobile;
  state.userOtp = otp;
};

const showPasswordOtp = (
  state: PcwLoginState,
  action: Action<{
    isOpen?: boolean;
    emailOrMobile?: string;
    setPurpose?: string;
  }>
) => {
  const { isOpen, emailOrMobile, setPurpose } = action?.payload;
  state.showPasswordOtp = isOpen;
  state.userMobileOrEmail = emailOrMobile;
  state.confirmationPurpose = setPurpose;
};

const showPasswordDialogue = (
  state: PcwLoginState,
  action: Action<boolean>
) => {
  state.showForgotPassword = action?.payload;
};

const showLoginForm = (state: PcwLoginState, action: Action<boolean>) => {
  state.showLoginForm = action?.payload;
};

const showSignUpForm = (state: PcwLoginState, action: Action<boolean>) => {
  state.showSignUpForm = action?.payload;
};

const checkFacebookSuccessLogin = (
  state: PcwLoginState,
  action: Action<{ isSuccess: boolean }>
) => {
  if (action.payload.isSuccess) {
    state.showErrorLoginFacebookMsg = "";
  } else {
    state.showErrorLoginFacebookMsg =
      "There is an error logging in your facebook account, please try other login option";
  }
};

const slice = createSlice({
  name: "PcwLogin",
  initialState,
  reducers: {
    showPasswordDialogue,
    showUpdatePasswordForm,
    showPasswordOtp,
    showLoginForm,
    showSignUpForm,
    showVerificationDialogue,
    checkFacebookSuccessLogin,
  },
  extraReducers(builder) {
    builder.addCase(thunks.setPcwUserLoginThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunks.setPcwUserLoginThunk.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      thunks.setPcwUserLoginThunk.fulfilled,
      (state, { payload }) => {
        state.userProfile = payload?.originalData?.data || {};
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
