import bazarReactDatabase from "data/bazar-react-database";

export const mainCarouselData = [
  {
    title: "50% Off For Your First Shopping",
    photoUrl: "/assets/images/home/carousel_01.png",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convalliss.",
    buttonText: "Shop Now",
    buttonLik: "#",
  },
];

export const flashDealsData = [
  {
    price: '₱ 42,245',
    title: "GEFORCE RTX 3070TI MSI GAMING X TRIO OC 8GB TRIPLE",
    imgUrl: "/assets/images/products/video_card_1.png",
    brandUrl: "/assets/images/products/oem_brand_1.png",
    discount: 20,
    id: 4674163016,
    rating: 4,
  },
  {
    price: '₱ 42,245',
    title: "GEFORCE RTX 3070TI AORUS OC 8GB TRIPLE FAN LHR",
    imgUrl: "/assets/images/products/video_card_2.png",
    brandUrl: "/assets/images/products/oem_brand_2.png",
    discount: 15,
    id: 1786818620,
    rating: 3,
  },
  {
    price: '₱ 42,245',
    title: "GEFORCE RTX 3080 Vision OC 10GB LHR Graphic Card ",
    imgUrl: "/assets/images/products/video_card_3.png",
    brandUrl: "/assets/images/products/oem_brand_3.png",
    discount: 10,
    id: 5287174158,
    rating: 5,
  },
  {
    price: '₱ 42,245',
    title: "GEFORCE RTX 3070TI AORUS OC 8GB TRIPLE FAN LHR",
    imgUrl: "/assets/images/products/video_card_4.png",
    brandUrl: "/assets/images/products/oem_brand_1.png",
    discount: 20,
    id: 6708885936,
    rating: 4,
  },

  {
    price: '₱ 42,245',
    title: "GEFORCE RTX 3070TI MSI GAMING X TRIO OC 8GB TRIPLE",
    imgUrl: "/assets/images/products/video_card_1.png",
    brandUrl: "/assets/images/products/oem_brand_2.png",
    discount: 25,
    id: 4674163013436,
    rating: 4,
  },
  {
    price: '₱ 42,245',
    title: "GEFORCE RTX 3070TI AORUS OC 8GB TRIPLE FAN LHR",
    imgUrl: "/assets/images/products/video_card_2.png",
    brandUrl: "/assets/images/products/oem_brand_3.png",
    discount: 15,
    id: 178681823432620,
    rating: 3,
  },
  {
    price: '₱ 42,245',
    title: "GEFORCE RTX 3080 Vision OC 10GB LHR Graphic Card ",
    imgUrl: "/assets/images/products/video_card_3.png",
    brandUrl: "/assets/images/products/oem_brand_1.png",
    discount: 28,
    id: 528717423432158,
    rating: 5,
  },
  {
    price: '₱ 42,245',
    title: "GEFORCE RTX 3070TI MSI GAMING X TRIO OC 8GB TRIPLE",
    imgUrl: "/assets/images/products/video_card_4.png",
    brandUrl: "/assets/images/products/oem_brand_2.png",
    discount: 21,
    id: 670883242385936,
    rating: 4,
  },
];

export const topCategoryList = [
  {
    title: "Headphone",
    subtitle: "3k orders this week",
    categoryUrl: "/",
    imgUrl: "/assets/images/banners/category-1.png",
  },
  {
    title: "Watch",
    subtitle: "3k orders this week",
    categoryUrl: "/",
    imgUrl: "/assets/images/banners/category-2.png",
  },
  {
    title: "Sunglass",
    subtitle: "3k orders this week",
    categoryUrl: "/",
    imgUrl: "/assets/images/banners/category-3.png",
  },
  {
    title: "Headphone",
    subtitle: "3k orders this week",
    categoryUrl: "/",
    imgUrl: "/assets/images/banners/category-1.png",
  },
  {
    title: "Watch",
    subtitle: "3k orders this week",
    categoryUrl: "/",
    imgUrl: "/assets/images/banners/category-2.png",
  },
];

export const topRatedList = [
  {
    price: 3300,
    reviewCount: 49,
    title: "Camera",
    imgUrl: "/assets/images/products/camera-1.png",
    discount: 8,
    id: 2294256829,
    rating: 4,
  },
  {
    price: 400,
    reviewCount: 20,
    title: "Shoe",
    imgUrl: "/assets/images/products/shoes-2.png",
    discount: 5,
    id: 4295570149,
    rating: 4.75,
  },
  {
    price: 999,
    reviewCount: 65,
    title: "Phone",
    imgUrl: "/assets/images/products/mobile-1.png",
    discount: 5,
    id: 5676950804,
    rating: 5,
  },
  {
    price: 999,
    reviewCount: 75,
    title: "Watch",
    imgUrl: "/assets/images/products/watch-1.png",
    discount: 6,
    id: 1674789677,
    rating: 5,
  },
];

export const topRatedBrandList = [
  {
    imgUrl: "/assets/images/products/london-britches.png",
    title: "London Britches",
    productUrl: "/",
  },
  {
    imgUrl: "/assets/images/products/jim and jiko.png",
    title: "Jim & Jago",
    productUrl: "/",
  },
];

export const newArrivalsList = [
  {
    price: 150,
    title: "Sunglass",
    imgUrl: "/assets/images/products/imagegoggles.png",
    discount: 8,
    id: 1670078035,
    rating: 5,
  },
  {
    price: 250,
    title: "Makeup",
    imgUrl: "/assets/images/products/lipstick (2).png",
    discount: 8,
    id: 3302401429,
    rating: 4,
  },
  {
    price: 350,
    title: "Smart Watch",
    imgUrl: "/assets/images/products/bgwatch.png",
    discount: 10,
    id: 814272833,
    rating: 5,
  },
  {
    price: 15,
    title: "Lipstick",
    imgUrl: "/assets/images/products/lipstick (1).png",
    discount: 5,
    id: 8169421085,
    rating: 4,
  },
  {
    price: 55,
    title: "Green plant",
    imgUrl: "/assets/images/products/lipstick (4).png",
    discount: 7,
    id: 8398582292,
    rating: 2,
  },
  {
    price: 535,
    title: "Bonsai tree",
    imgUrl: "/assets/images/products/lipstick (3).png",
    discount: 6,
    id: 6046531880,
    rating: 5,
  },
];

export const categories = [
  {id: 30, name: "Splitter", created_at: "2021-11-26T05:10:59.000000Z"},
  {id: 29, name: "Fan Hub", created_at: "2021-11-18T03:47:05.000000Z"},
  {id: 28, name: "Headset", created_at: "2021-11-06T18:07:34.000000Z"},
  {id: 27, name: "Accessories", created_at: "2021-11-06T18:04:48.000000Z"},
  {id: 26, name: "CPU Cooler", created_at: "2021-11-06T17:19:51.000000Z"},
  {id: 25, name: "Monitor", created_at: "2021-11-04T05:07:54.000000Z"},
  {id: 24, name: "LED Strips", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 23, name: "Cable Wire", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 21, name: "GPU", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 20, name: "Casing", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 19, name: "Fan", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 18, name: "PSU", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 17, name: "Motherboard", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 16, name: "CPU", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 15, name: "HDD", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 14, name: "SSD", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 13, name: "RAM", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 12, name: "HDMI Cable", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 11, name: "UPS", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 10, name: "Patch Cord / LAN Cable", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 9, name: "Thermal Paste", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 8, name: "SATA Cable", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 7, name: "Enclosure", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 6, name: "VGA Cable", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 5, name: "Mouse Pad", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 4, name: "Keyboard and Mouse", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 3, name: "Keyboard", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 2, name: "Mouse", created_at: null, updated_at: null, is_hidden_pricelist: 0},
  {id: 1, name: "WiFi Dongle", created_at: null, updated_at: null, is_hidden_pricelist: 0},
];

export const mobileBrandList = ["apple", "dell", "xiaomi", "asus", "sony", "acer"];
export const mobileShopList = ["herman miller", "otobi", "hatil", "steelcase"];

export const opticsBrandList = ["ray-ban", "zeiss", "occular", "apple", "titan"];
export const opticsShopList = ["herman miller", "zeiss", "hatil", "steelcase"];

export const bottomCategoryList = [
  {
    name: "Automobile",
    imgUrl: bazarReactDatabase[13 + 100].imgUrl,
  },
  {
    name: "Car",
    imgUrl: bazarReactDatabase[2 * 13 + 100].imgUrl,
  },
  {
    name: "Fashion",
    imgUrl: bazarReactDatabase[3 * 13 + 100].imgUrl,
  },
  {
    name: "Mobile",
    imgUrl: bazarReactDatabase[4 * 13 + 100].imgUrl,
  },
  {
    name: "Laptop",
    imgUrl: bazarReactDatabase[5 * 13 + 100].imgUrl,
  },
  {
    name: "Desktop",
    imgUrl: bazarReactDatabase[6 * 13 + 100].imgUrl,
  },
  {
    name: "Tablet",
    imgUrl: bazarReactDatabase[7 * 13 + 100].imgUrl,
  },
  {
    name: "Fashion",
    imgUrl: bazarReactDatabase[8 * 13 + 100].imgUrl,
  },
  {
    name: "Electronics",
    imgUrl: bazarReactDatabase[9 * 13 + 100].imgUrl,
  },
  {
    name: "Furniture",
    imgUrl: bazarReactDatabase[10 * 13 + 100].imgUrl,
  },
  {
    name: "Camera",
    imgUrl: bazarReactDatabase[11 * 13 + 100].imgUrl,
  },
  {
    name: "Electronics",
    imgUrl: bazarReactDatabase[100].imgUrl,
  },
];

export const serviceList = [
  {
    icon: "Truck",
    title: "Worldwide Delivery",
  },
  {
    icon: "CreditCardVerified",
    title: "Safe Payment",
  },
  {
    icon: "Shield",
    title: "Shop With Confidence",
  },
  {
    icon: "CustomerService",
    title: "24/7 Support",
  },
];
