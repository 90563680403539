import { Api, ApiResponse, CommonAxiosResponse } from "common";
import {
  GetOrderDetailsPayload,
  MyPurchasedResponse,
  OrderDetailResponse,
  OrderListPayload,
  OrderListResponse,
} from "./types";

const getOrderHistoryList = (
  payload: OrderListPayload
): ApiResponse<OrderListResponse> => {
  return Api.get(`api/ecomm/user/order/get`, payload);
};

const getOrderHistoryDetails = (
  payload: GetOrderDetailsPayload
): ApiResponse<OrderDetailResponse> => {
  // Spread to not mutate
  const data = { ...payload };
  delete data.ecommOrderId;
  return Api.get(
    `/api/ecomm/user/order/details/get/${payload.ecommOrderId}`,
    data
  );
};

const getPruchasedProducts = (): ApiResponse<MyPurchasedResponse> => {
  return Api.get(`/api/ecomm/user/order/purchased_products/get`);
};

export const PcwOrderApi = {
  getOrderHistoryList,
  getOrderHistoryDetails,
  getPruchasedProducts,
};
