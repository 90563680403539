import { createSelector } from "reselect";
import { RootState } from "common";

const selectUserRegistration = (state: RootState) => state.PcwRegistration;

const selectShowMobileDialogue = createSelector(
  selectUserRegistration,
  (state) => state.showDialogueMobile
);

const selectShowEmailDialogue = createSelector(
  selectUserRegistration,
  (state) => state.showDialogueEmail
);

const selectUserRegData = createSelector(
  selectUserRegistration,
  (state) => state?.userRegistrationDataState
);

const selectLoginMobileData = createSelector(
  selectUserRegistration,
  (state) => state?.loginMobileNumber
);

const selectUserSignUpData = createSelector(selectUserRegistration, (state) => {
  return state?.userReg;
});

export const selectors = {
  selectShowMobileDialogue,
  selectShowEmailDialogue,
  selectUserRegData,
  selectLoginMobileData,
  selectUserSignUpData,
};
