import { Api, ApiResponse } from "common";
import {
  ProductFiltersPayload,
  BestSellingProductResponse,
  AlternativeProductPayload,
  AlternativeProductResponse,
  ProductsPayload,
  ProductsResponse,
} from "./types";

const getProducts = (
  params: ProductsPayload
): ApiResponse<ProductsResponse> => {
  return Api.get("api/ecomm/products/available/get", params);
};

const getProductsFilter = (
  params: ProductFiltersPayload
): ApiResponse<ProductsResponse> => {
  return Api.get("api/ecomm/products/filters/get", params);
};

/**
 * Get all products with or without stocks
 */
const getAllProducts = (
  params: ProductsPayload
): ApiResponse<ProductsResponse> => {
  return Api.get("api/ecomm/products/all/get", params);
};

const getBestSellingProductsApi = (): // params: ProductsPayload
ApiResponse<BestSellingProductResponse> => {
  return Api.get("api/ecomm/products/best-seller/get");
};

const getAlternativeProducts = (
  params: AlternativeProductPayload
): ApiResponse<AlternativeProductResponse> => {
  return Api.get("api/ecomm/products/alternative/get", params);
};

export const ProductsApi = {
  getProducts,
  getAllProducts,
  getProductsFilter,
  getBestSellingProductsApi,
  getAlternativeProducts,
};
