import { Api, ApiResponse, SuccessResponse } from "common";
import {
  CreateOrderPayload,
  GetCompatibleMotherboardsResponse,
  GetOrderDetailsResponse,
  ProductAvailabilityPayload,
  ProductAvailabilityResponse,
} from "./types";

const createOrder = (
  data: CreateOrderPayload
): ApiResponse<SuccessResponse> => {
  return Api.post("api/ecomm/order/submit", data);
};

const getProductAvailability = (
  params: ProductAvailabilityPayload
): ApiResponse<ProductAvailabilityResponse> => {
  const finalParams = {
    quantity: params.quantity,
  };
  return Api.get(
    `api/ecomm/products/availability/get/${params.product_slug}`,
    finalParams
  );
};

const getCompatibleMotherboards = (
  productSlug?: string
): ApiResponse<GetCompatibleMotherboardsResponse> => {
  return Api.get(
    `api/ecomm/products/compatible/motherboard/get/${productSlug}`
  );
};

const getOrderDetails = (
  orderRefNo: string
): ApiResponse<GetOrderDetailsResponse> => {
  return Api.get(`api/ecomm/order/history/details/get/${orderRefNo}`);
};

export const CartApi = {
  createOrder,
  getOrderDetails,
  getProductAvailability,
  getCompatibleMotherboards,
};
