import {
  Action,
  CategoriesIdsEnum,
  FailResponse,
  getRandomDelay,
  isFailResponseData,
  isSuccessResponseData,
  Response,
  SuccessResponse,
} from "common";
import { call, delay, put, select, StrictEffect } from "redux-saga/effects";
import { getAllCartItemInQtyOne, getCompatibleMoboViaCPU } from "utils/cart";
import { CartApi } from "./api";
import { selectors as cartSelectors } from "./selectors";
import { actions } from "./slice";
import {
  CartItem,
  CompatibleBundleDataCollection,
  CreateOrderPayload,
  GetCompatibleMotherboardsResponse,
  ProductAvailabilityPayload,
  ProductAvailabilityResponse,
  ProductAvailable,
} from "./types";
import { sagas as oAuthSagas } from "../../modules/oauth/sagas";
import {
  cartToSubmitOrderPayload,
  isCompatibleMotherboardResponse,
  isProductAvailabilityResponse,
} from "./util";

export function* createOrderSaga(
  action: Action<CreateOrderPayload>
): Generator<StrictEffect, void, Response<SuccessResponse>> {
  let data = null;
  let problem = null;
  try {
    const randomDelay = getRandomDelay();
    yield delay(randomDelay);
    let finalPayload = action.payload;
    finalPayload = cartToSubmitOrderPayload(finalPayload);
    const response = yield call(CartApi.createOrder, finalPayload);
    data = response.data;
  } catch (error) {
    yield put(actions.createOrderReject());
  }

  if (!data) {
    yield put(actions.createOrderReject(problem));
    return;
  }

  if (isFailResponseData(data)) {
    yield put(actions.createOrderReject(data));
    return;
  }

  if (isSuccessResponseData(data)) {
    yield put(actions.createOrderResolve(data));
  }
}

export function* productsAvailabilitySaga(
  action: Action<CreateOrderPayload>
): Generator<
  StrictEffect,
  void,
  ProductAvailabilityPayload[] & Response<ProductAvailabilityResponse>
> {
  try {
    let data = null;
    let problem = null;
    const randomDelay = getRandomDelay();
    yield delay(randomDelay);

    // This is an edge case but needed.
    // Sometimes amount may change automatically and we need to let our users know it before checkout.
    const orderShippingFee: number = action?.payload?.shipping_fee || 0;
    let cartTotalAmount: any = yield select(
      cartSelectors.selectCartTotalAmount
    );
    if (isNaN(cartTotalAmount)) {
      cartTotalAmount = 0;
    }
    const payloadGrandTotal = cartTotalAmount + orderShippingFee;

    const cartProducts: ProductAvailabilityPayload[] = yield select(
      cartSelectors.selectCartProducts
    );
    const cartProductsLength = cartProducts.length;
    if (cartProductsLength <= 0) {
      yield put(actions.productsAvailabilityResolve());
      return;
    }
    for (let index = 0; index < cartProductsLength; index++) {
      const isLastProduct = index === cartProductsLength - 1;
      const product = cartProducts[index];
      const response: Response<ProductAvailabilityResponse> = yield call(
        CartApi.getProductAvailability,
        product
      );
      data = response.data;
      problem = response.problem;

      if (!data) {
        yield put(actions.productAvailabilityReject(problem));
        break;
      }

      if (isFailResponseData(data)) {
        yield put(actions.productAvailabilityReject(data));
        break;
      }

      if (isProductAvailabilityResponse(data) && !isLastProduct) {
        const productAvailable = {
          ...product,
          with_stock: data?.data?.with_stock,
          message: data?.message,
          stocks_left: data?.data?.stocks,
          retail_price: data?.data?.retail_price,
        };
        yield put(actions.productSingleAvailabilityResolve(productAvailable));
      }

      if (isProductAvailabilityResponse(data) && isLastProduct) {
        const productAvailable = {
          ...product,
          with_stock: data?.data?.with_stock,
          message: data?.message,
          stocks_left: data?.data?.stocks,
          retail_price: data?.data?.retail_price,
        };
        yield put(actions.productSingleAvailabilityResolve(productAvailable));
        yield put(actions.productsAvailabilityResolve());

        const hasProductUnavailable = yield select(
          cartSelectors.selectCartHasProductUnavailable
        );
        if (hasProductUnavailable) {
          yield put(actions.productAvailabilityReject());
          return;
        }

        // This is an edge case but needed.
        // Sometimes amount may change automatically and we need to let our users know it before checkout.
        let newCartTotalAmount: any = yield select(
          cartSelectors.selectCartTotalAmount
        );
        if (isNaN(cartTotalAmount)) {
          cartTotalAmount = 0;
        }
        const newGrandTotal = newCartTotalAmount + orderShippingFee;
        if (payloadGrandTotal !== newGrandTotal) {
          const errRes: FailResponse = {
            success: false,
            message:
              "Some item(s) in your cart got an updated price. Please check your cart",
          };
          yield put(actions.createOrderReject(errRes));
          return;
        }

        const contentIds = action?.payload?.products?.map(
          (item) => item?.product_slug
        );

        // Finally create the order
        yield put(actions.createOrderRequest(action.payload));
      }
    }
  } catch (error) {
    yield put(actions.productAvailabilityReject());
  }
}

export function* cartAvailabilitySaga(
  action: Action<CreateOrderPayload>
): Generator<
  StrictEffect,
  void,
  ProductAvailabilityPayload[] &
    Response<ProductAvailabilityResponse> &
    Response<GetCompatibleMotherboardsResponse>
> {
  try {
    let data = null;
    let problem = null;

    let bundleData = null;
    let bundleProblem = null;
    const initialCartProducts: ProductAvailabilityPayload[] = yield select(
      cartSelectors.selectCartProducts
    );
    const cartProductsLength = initialCartProducts.length;

    if (cartProductsLength <= 0) {
      yield put(actions.productsAvailabilityResolve());
      return;
    }

    for (let index = 0; index < cartProductsLength; index++) {
      const product = initialCartProducts[index];
      const response: Response<ProductAvailabilityResponse> = yield call(
        CartApi.getProductAvailability,
        product
      );
      data = response.data;
      problem = response.problem;

      if (!data) {
        yield put(actions.productAvailabilityReject(problem));
        break;
      }

      if (isFailResponseData(data)) {
        yield put(actions.productAvailabilityReject(data));
        break;
      }

      if (isProductAvailabilityResponse(data)) {
        const productAvailable: ProductAvailable = {
          ...product,
          with_stock: data?.data?.with_stock,
          message: data?.message,
          stocks_left: data?.data?.stocks,
          retail_price: data?.data?.retail_price,
        };
        yield put(actions.productSingleAvailabilityResolve(productAvailable));
      }
    }

    const cartForCpus: ProductAvailabilityPayload[] = yield select(
      cartSelectors.selectCartProductsForCpus
    );
    const cartHasCPUs = cartForCpus
      ?.sort((a, b) => (b?.with_bundle || 0) - (a?.with_bundle || 0))
      ?.filter(
        (item) =>
          item?.category_id === CategoriesIdsEnum.CPU ||
          item?.category?.toUpperCase() === "CPU"
      );

    // needed because sometimes a cpu could have 2 or more quantity.
    const dissectedCPUs = getAllCartItemInQtyOne(cartHasCPUs) || [];

    const cpusInCartLength = dissectedCPUs?.length || 0;
    if (dissectedCPUs && dissectedCPUs?.length > 0) {
      // prepare mobos logic here.
      let motherboardsInCart = cartForCpus?.filter(
        (item) =>
          item?.category_id === CategoriesIdsEnum.Motherboard ||
          item?.category?.toUpperCase() === "Motherboard"
      );
      const dissectedMobos = getAllCartItemInQtyOne(motherboardsInCart) || [];

      // NOTE: USE THIS ONE BECAUSE THIS PULLS OUT BASE ALSO ON QTY.
      // Please also update these after foreach checker
      let mobosForChecker = dissectedMobos;
      let bundleDataCollection: CompatibleBundleDataCollection[] = [];

      for (let index = 0; index < cpusInCartLength; index++) {
        const isLastProduct = index === cpusInCartLength - 1;
        const product = dissectedCPUs[index];
        // let bundleRes: Response<GetCompatibleMotherboardsResponse>;
        let bundleRes: any;
        const currentProductCPUSlug = dissectedCPUs[index].product_slug;
        const itemInBundleResCollection = bundleDataCollection?.find(
          (x) => x?.product_slug === currentProductCPUSlug
        );

        if (itemInBundleResCollection) {
          bundleRes = {
            data: { data: itemInBundleResCollection },
            problem: undefined,
          };
        } else {
          bundleRes = yield call(
            CartApi.getCompatibleMotherboards,
            product?.product_slug
          );
          if (bundleRes?.data) {
            bundleDataCollection?.push({
              product_slug: currentProductCPUSlug || "",
              ...bundleRes?.data?.data,
            });
          }
        }

        bundleData = bundleRes?.data;
        bundleProblem = bundleRes?.problem;

        if (!bundleData) {
          yield put(actions.productAvailabilityReject(bundleProblem));
          break;
        }

        if (isFailResponseData(bundleData)) {
          yield put(actions.productAvailabilityReject(bundleData));
          break;
        }

        if (isCompatibleMotherboardResponse(bundleData)) {
          let productAvailableForCPU: ProductAvailable = {
            ...product,
            with_bundle: bundleData?.data?.with_bundle,
            non_bundle_addtl_amt: bundleData?.data?.non_bundle_addtl_amt,
            compatible_mobos: bundleData?.data?.compatible_mobos,
          };

          // Check if compatible mobo exist in mobos
          const compatibleMoboSlug = getCompatibleMoboViaCPU(
            productAvailableForCPU as CartItem,
            mobosForChecker
          );

          // set totalNonBundleAdditional to zero if undefined or null or zero
          if (!productAvailableForCPU?.totalNonBundleAdditional) {
            productAvailableForCPU.totalNonBundleAdditional = 0;
          }

          // update mobosForChecker. If compatibleMoboName has value. Slice / Remove it there...
          if (compatibleMoboSlug) {
            const indexOfMoboName = mobosForChecker?.findIndex(
              (x) => x?.product_slug === compatibleMoboSlug
            );
            if (indexOfMoboName > -1) {
              mobosForChecker?.splice(indexOfMoboName, 1);
            }
          }

          productAvailableForCPU.cpuHasCompatibleMoboInCart = compatibleMoboSlug
            ? true
            : false;

          yield put(
            actions.productSingleAvailabilityResolve(productAvailableForCPU)
          );
        }

        if (isLastProduct) {
          yield put(actions.productsAvailabilityResolve());
        }
      }
    } else {
      yield put(actions.productsAvailabilityResolve());
    }
  } catch (error) {
    yield put(actions.productAvailabilityReject());
  }
}

export const sagas = {
  createOrderSaga,
  productsAvailabilitySaga,
  cartAvailabilitySaga,
};
