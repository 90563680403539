import { Alert, Snackbar } from "@mui/material";
import { H5 } from "components/Typography";
import { useSnackBar } from "hooks";
import React from "react";

const GlobalSnackBar = () => {
  const { snackBarOptions, hide } = useSnackBar();

  const handleClose = () => {
    hide();
  };

  return (
    <Snackbar
      sx={{ width: "100%" }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={snackBarOptions?.open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        variant="filled"
        sx={{ width: "80%" }}
        onClose={handleClose}
        severity={snackBarOptions?.severity}
      >
        <H5>{snackBarOptions?.message || ""}</H5>
      </Alert>
    </Snackbar>
  );
};

export default GlobalSnackBar;
