import { createSlice } from "@reduxjs/toolkit";
import {
  Action,
  currentTimeMilliseconds,
  ErrorResponse,
  SliceStatusValue,
} from "../../common";
import { ClientTokenResponse, OAuthState } from "./types";

const clientTokenRequest = (state: OAuthState) => {
  state.status = SliceStatusValue.loading;
};

const clientTokenResolve = (
  state: OAuthState,
  action: Action<ClientTokenResponse>
) => {
  state.status = SliceStatusValue.resolve;
  const { access_token, expires_in } = action.payload;
  state.access_token = access_token;
  state.expires_in = currentTimeMilliseconds() + expires_in;
};

const clientTokenReject = (
  state: OAuthState,
  action: Action<ErrorResponse>
) => {
  state.status = SliceStatusValue.reject;
  const error = action?.payload;
  state.errorResponse = error;
};

const clearClientToken = (
  _state: OAuthState,
  _action: Action<ErrorResponse>
) => {
  return {};
};

const slice = createSlice({
  name: "OAuth",
  initialState: {} as OAuthState,
  reducers: {
    clientTokenRequest,
    clientTokenResolve,
    clientTokenReject,
    clearClientToken,
  },
});

export const reducer = slice.reducer;
export const actions = {
  ...slice.actions,
  // ...thunks
};
