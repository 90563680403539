import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, RootState, thunkResponseHandler } from "common";
import { PriceListApi } from "./api";
import {
  PriceListPayloadV2,
  PriceListResponseV2,
  getPriceListResponse,
} from "./types";

// Pwede na ba to delete bos dabs not used anywhere na
export const getPriceListThunk = createAsyncThunk<
  CommonAxiosResponse<getPriceListResponse>
>("EUpricelist/getPriceListThunk", async () => {
  const res = await PriceListApi.getEUpriceList();
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getEUpriceListV2Thunk = createAsyncThunk<
  CommonAxiosResponse<PriceListResponseV2>,
  undefined,
  { state: RootState }
>("EUpricelist/getEUpriceListV2Thunk", async (_, api) => {
  const request = api.getState().PriceList.payload;
  const res = await PriceListApi.getEUpriceListV2(request);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
