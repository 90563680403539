export const loginDialog = {
  actions: {
    onClickLogin: "onClickLogin",
  },
  event: {
    onDialogOpen: "onDialogOpen",
    onSuccessLogin: "onSuccessLogin",
  },
  error: {
    onErrorLogin: "onErrorLogin",
  },
};

export const userProfileScreen = {
  actions: {
    onClickLogout: "onClickLogout",
  },
  event: {
    onSuccessLogout: "onSuccessLogout",
  },
};

export const pageTracker = {
  error: {
    onPageViewError: "onPageViewError",
  },
};

export const searchBar = {
  event: {
    onSearch: "onSearch",
  },
};

export const constants = {
  loginDialog,
  userProfileScreen,
  pageTracker,
  searchBar
};
