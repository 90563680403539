import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CommonAxiosResponse,
  SuccessResponse,
  thunkResponseHandler,
} from "common";
import {
  CreateAddressPayload,
  GetUserAddressDetailResponse,
  GetUserAddressPayload,
  GetUserAddressResponse,
  UpdateAddressPayload,
  UserAddressIdPayload,
} from "./types";
import { PcwUserAddressApi } from "./api";

export const createUserAddressThunk = createAsyncThunk<
  CommonAxiosResponse,
  CreateAddressPayload
>("PcwUserAddress/createUserAddressThunk", async (data) => {
  const res = await PcwUserAddressApi.createUserAddressApi(data);
  const toThunk = await thunkResponseHandler(res);
  return toThunk;
});

export const getUserAddressThunk = createAsyncThunk<
  CommonAxiosResponse<GetUserAddressResponse>,
  GetUserAddressPayload
>("PcwUserAddress/createUserAddressThunk", async (data) => {
  const res = await PcwUserAddressApi.getUserAddressApi(data);
  const toThunk = await thunkResponseHandler(res);
  return toThunk;
});

export const getUserAddressDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<GetUserAddressDetailResponse>,
  UserAddressIdPayload
>("PcwUserAddress/getUserAddressDetailsThunk", async (data) => {
  const res = await PcwUserAddressApi.getUserAddressDetailsApi(data);
  const toThunk = await thunkResponseHandler(res);
  return toThunk;
});

export const updateUserAddressThunk = createAsyncThunk<
  CommonAxiosResponse<SuccessResponse>,
  UpdateAddressPayload
>("PcwUserAddress/updateUserAddressThunk", async (data) => {
  const res = await PcwUserAddressApi.updateUserAddressApi(data);
  const toThunk = await thunkResponseHandler(res);
  return toThunk;
});

export const deleteUserAddressThunk = createAsyncThunk<
  CommonAxiosResponse<SuccessResponse>,
  UserAddressIdPayload
>("PcwUserAddress/updateUserAddressThunk", async (data) => {
  const res = await PcwUserAddressApi.deleteUserAddressApi(data);
  const toThunk = await thunkResponseHandler(res);
  return toThunk;
});
