import { RootState, _ } from "common";
import { createSelector } from "reselect";

const selectCategoryState = (state: RootState) => state.Category;

const selectCategories = createSelector(
  selectCategoryState,
  (state) => state.categories
);

const selectMainCategories = createSelector(
  selectCategoryState,
  (state) => state.mainCategories
);

const selectMainCategoryItem = createSelector(
  selectCategoryState,
  (state) => state.mainCategoryItem
);

const selectIsEmptyCategories = createSelector(selectCategoryState, (state) =>
  _.isEmpty(state.categories)
);

const selectCategoryItem = createSelector(
  selectCategoryState,
  (state) => state.categoryItem
);

export const selectors = {
  selectCategories,
  selectIsEmptyCategories,
  selectCategoryItem,
  selectMainCategories,
  selectMainCategoryItem
};
