export const payouts = [
  {
    no: 1,
    amount: 1_200,
    date: "20-04-2022",
    payment: "Cash",
    sellerInfo: "The Beauty Shop",
  },
  {
    no: 2,
    amount: 250,
    date: "19-04-2022",
    payment: "Visa Cash",
    sellerInfo: "The Gainner",
  },
  {
    no: 3,
    amount: 9_300,
    date: "17-04-2022",
    payment: "Stripe",
    sellerInfo: "Tech Tube",
  },
  {
    no: 4,
    amount: 2_200,
    date: "14-04-2022",
    payment: "Paypal",
    sellerInfo: "Smart HD",
  },
  {
    no: 5,
    amount: 700,
    date: "08-04-2022",
    payment: "Payoneer",
    sellerInfo: "Dream Boy",
  },
  {
    no: 6,
    amount: 930,
    date: "01-04-2022",
    payment: "Cash",
    sellerInfo: "Beyond Threads",
  },
  {
    no: 7,
    amount: 450,
    date: "26-03-2022",
    payment: "Stripe",
    sellerInfo: "Febrick Fashion",
  },
  {
    no: 8,
    amount: 360,
    date: "16-03-2022",
    payment: "PayPal",
    sellerInfo: "Icon Boy",
  },
  {
    no: 9,
    amount: 120,
    date: "12-03-2022",
    payment: "Cash",
    sellerInfo: "Mobile Gadgets",
  },
];
