import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import {
  GetPromotionalContentRequest,
  GetPromotionalContentResponse,
  GetPromotionalCountsResponse,
  GetPromotionalDetailsRequest,
  GetPromotionalDetailsResponse,
  GetPromotionalListingsRequest,
  GetPromotionalListingsResponse,
} from "./types";
import { PromotionalApi } from "./api";

export const getPromotionalListingsThunk = createAsyncThunk<
  CommonAxiosResponse<GetPromotionalListingsResponse>,
  GetPromotionalListingsRequest | undefined
>("Promotional/getPromotionalListingsThunk ", async (data) => {
  const res = await PromotionalApi.getPromotionalListings(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getPromotionalCountsThunk = createAsyncThunk<
  CommonAxiosResponse<GetPromotionalCountsResponse>
>("Promotional/getPromotionalCountsThunk", async () => {
  const res = await PromotionalApi.getPromotionalCounts();
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getPromotionalDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<GetPromotionalDetailsResponse>,
  GetPromotionalDetailsRequest
>("Promotional/getPromotionalDetailsThunk ", async (data) => {
  const res = await PromotionalApi.getPromotionalDetails(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getPromotionalContentThunk = createAsyncThunk<
  CommonAxiosResponse<GetPromotionalContentResponse>,
  GetPromotionalContentRequest
>("Promotional/getPromotionalContentThunk ", async (data) => {
  const res = await PromotionalApi.getPromotionalContent(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
