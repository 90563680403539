import { RootState, _ } from "common";
import { createSelector } from "reselect";

const selectFlashDealsState = (state: RootState) => state.FlashDeal;

//to get our tryStateCountBadge we create a selector 
const selectTryStateBadgeCount = createSelector(selectFlashDealsState, (state) => state.tryStateCountBadge);

const selectFlashDeals = createSelector(
  selectFlashDealsState,
  (state) => state.flashDeals
);

const selectFlashDealsLoading = createSelector(
  selectFlashDealsState,
  (state) => state.flashDealsLoading
);

export const selectors = {
  selectFlashDeals,
  selectFlashDealsLoading,
  selectTryStateBadgeCount
};
