import {
  Action,
  isFailResponseData,
  isSuccessResponseData,
  Response,
} from "common";
import { call, put, StrictEffect } from "redux-saga/effects";
import { ProductDetailsApi } from "./api";
import { actions } from "./slice";
import {
  GetProductDetailsPayload,
  GetProductDetailsResponse,
  GetProductImagesPayload,
  GetProductImagesResponse,
} from "./types";
import { sagas as oAuthSagas } from "../../modules/oauth/sagas";

export function* productDetailsSaga(
  action: Action<GetProductDetailsPayload>
): Generator<StrictEffect, void, Response<GetProductDetailsResponse>> {
  let data = null;
  let problem = null;
  try {
    const payload = {
      ...action.payload,
    };
    const response = yield call(ProductDetailsApi.getProductDetails, payload);
    data = response.data;
    problem = response.problem;
  } catch (error) {
    yield put(actions.productDetailsReject());
  }

  if (!data) {
    yield put(actions.productDetailsReject(problem));
    return;
  }

  if (isFailResponseData(data)) {
    yield put(actions.productDetailsReject(data));
    return;
  }

  if (isSuccessResponseData(data)) {
    yield put(actions.productDetailsResolve(data));
  }
}

export function* productImagesSaga(
  action: Action<GetProductImagesPayload>
): Generator<StrictEffect, void, Response<GetProductImagesResponse>> {
  let data = null;
  let problem = null;
  try {
    const payload = {
      ...action.payload,
    };
    const response = yield call(ProductDetailsApi.getProductImages, payload);
    data = response.data;
    problem = response.problem;
  } catch (error) {
    yield put(actions.productImagesReject());
  }

  if (!data) {
    yield put(actions.productImagesReject(problem));
    return;
  }

  if (isFailResponseData(data)) {
    yield put(actions.productDetailsReject(data));
    return;
  }

  if (isSuccessResponseData(data)) {
    yield put(actions.productImagesResolve(data));
  }
}

export const sagas = {
  productDetailsSaga,
  productImagesSaga
}