import { useCallback, useState } from "react";
import modules from "modules";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useAppSelector } from "hooks/use-app-selector";

// Might be causing cyclic dependencies
// Cannot access '__WEBPACK_DEFAULT_EXPORT__' before initialization
// const {
//   actions: locationActions,
//   utils: locationUtils,
//   constants: locationConst,
//   enums: locationEnums,
//   selectors: locationSelectors,
// } = modules.location;

const useLocationModule = () => {
  const dispatch = useAppDispatch();

  /** ========== SELECTORS / VARIABLES / CONSTANTS ========== */
  const regions = useAppSelector(modules.location.selectors.selectRegions);
  const provinces = useAppSelector(modules.location.selectors.selectProvinces);
  const cities = useAppSelector(modules.location.selectors.selectCities);
  const barangays = useAppSelector(modules.location.selectors.selectBarangays);

  const isRegionsLoading = useAppSelector(
    modules.location.selectors.selectRegionsLoading
  );
  const isProvincesLoading = useAppSelector(
    modules.location.selectors.selectProvincesLoading
  );
  const isCitiesLoading = useAppSelector(
    modules.location.selectors.selectCitiesLoading
  );
  const isBarangaysLoading = useAppSelector(
    modules.location.selectors.selectBarangaysLoading
  );

  const isRegionsError = useAppSelector(
    modules.location.selectors.selectRegionsError
  );
  const isProvincesError = useAppSelector(
    modules.location.selectors.selectProvincesError
  );
  const isCitiesError = useAppSelector(
    modules.location.selectors.selectCitiesError
  );
  const isBarangaysError = useAppSelector(
    modules.location.selectors.selectBarangaysError
  );

  /** ========== FUNCTIONS ========== */
  const getRegions = useCallback(() => {
    dispatch(modules.location.actions.getLocationRegions({}));
  }, [dispatch]);

  const getProvinces = useCallback(
    (regionCode: number) => {
      dispatch(
        modules.location.actions.getLocationProvinces({
          region_code: regionCode,
        })
      );
    },
    [dispatch]
  );

  const getCities = useCallback(
    (provinceCode: number) => {
      dispatch(
        modules.location.actions.getLocationCities({
          province_code: provinceCode,
        })
      );
    },
    [dispatch]
  );

  const getBarangays = useCallback(
    (cityCode: number) => {
      dispatch(
        modules.location.actions.getLocationBarangays({
          city_code: cityCode,
        })
      );
    },
    [dispatch]
  );

  return {
    regions,
    provinces,
    cities,
    barangays,

    isRegionsLoading,
    isProvincesLoading,
    isCitiesLoading,
    isBarangaysLoading,

    getRegions,
    getCities,
    getBarangays,
    getProvinces,

    isRegionsError,
    isProvincesError,
    isCitiesError,
    isBarangaysError,
  };
};

export const hooks = {
  useLocationModule,
};
