import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action } from "common";
import * as thunks from "./thunks";
import { UserRegistrationState } from "./types";

const initialState: UserRegistrationState = {
  userRegistrationDataState: {},
  pcwLoading: false,
  showDialogueEmail: false,
  showDialogueMobile: false,
  loginMobileNumber: "",
  userReg: {},
};

const showConfirmationDialogue = (
  state: UserRegistrationState,
  action: Action<{
    showConfirmation: boolean;
    actionCase: string;
    loginMobile?: string;
    password?: string;
  }>
) => {
  const { showConfirmation, actionCase, loginMobile } = action?.payload;
  switch (actionCase) {
    case "email":
      state.showDialogueEmail = showConfirmation;
      break;
    case "mobile":
      state.showDialogueMobile = showConfirmation;
      break;
    default:
      return;
  }
  state.loginMobileNumber = loginMobile;
};

const userSignUpData = (
  state: UserRegistrationState,
  action: Action<{
    emailOrPhone: string;
  }>
) => {
  state.userReg = action.payload || {};
};

const slice = createSlice({
  name: "PcwRegistration",
  initialState,
  reducers: { showConfirmationDialogue, userSignUpData },
  extraReducers(builder) {
    builder.addCase(
      thunks.setUserRegistrationThunk.pending,
      (state, { payload }) => {
        state.pcwLoading = true;
      }
    );
    builder.addCase(
      thunks.setUserRegistrationThunk.rejected,
      (state, { payload }) => {
        state.pcwLoading = false;
      }
    );
    builder.addCase(
      thunks.setUserRegistrationThunk.fulfilled,
      (state, { payload }) => {
        state.pcwLoading = false;
        state.userRegistrationDataState = payload?.originalData?.data || {};
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
