import { Button, CircularProgress, TextField } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import BazarButton from "components/BazarButton";
import { H3, Paragraph, Small } from "components/Typography";
import { FlexBox } from "components/flex-box";
import { useSnackBar } from "hooks";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useAppSelector } from "hooks/use-app-selector";
import { useDialogueShow } from "hooks/useDialogue";
import modules from "modules";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { OtpPurpose } from "utils/constants";
import { navigationRoutes } from "common/constants/navigation-routes";
import { pcwVerifyLoginPayload } from "utils/pcw-account/login-registration-utils";

type OtpVerificationProps = {};

const { selectors: UserRegDataSelectors, actions: ValidateOtpActions } =
  modules.pcwRegistration;

const { actions: PcwLoginActions } = modules.pcwUserLogin;

const OtpVerification: React.FC<OtpVerificationProps> = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const snackbar = useSnackBar();

  const resendTime = 240;

  const { showLoginFormClose } = useDialogueShow();

  const [userOtpData, getUserData] = useState<any>();
  const [mobile_no, getMobileNo] = useState<any>();
  const [getOTP, setOTP] = useState("");
  const [time, setTime] = useState(resendTime);
  const [isCounting, setIsCounting] = useState(true);
  const [intervalId, setIntervalId] = useState(null);
  const [errMsg, setErrMsg] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loginData = useAppSelector(UserRegDataSelectors.selectUserSignUpData);
  const loginPayload = pcwVerifyLoginPayload(loginData);
  const userData = useAppSelector(UserRegDataSelectors?.selectUserRegData);
  const mobileNumber = useAppSelector(
    UserRegDataSelectors?.selectLoginMobileData
  );

  const ValidateOtp = async () => {
    setIsLoading(true);
    const validatePayload = {
      mobile_no: userOtpData?.mobile_no || mobile_no,
      otp: getOTP,
      purpose: OtpPurpose.confirm_account,
    };
    const response = unwrapResult(
      await dispatch(ValidateOtpActions?.setValidateOtpThunk(validatePayload))
    );
    if (response?.success) {
      setIsLoading(false);
      setErrMsg("");
      showLoginFormClose();
      await dispatch(
        ValidateOtpActions.patchConfirmAccountOtpThunk({ otp: getOTP })
      );

      router.push(navigationRoutes.pcwProfile).then(() => {
        dispatch(
          ValidateOtpActions.showConfirmationDialogue({
            showConfirmation: false,
            actionCase: "mobile",
          })
        );
      });
    }
    if (response?.message && !response?.success) {
      setErrMsg(response?.errors?.otp || response?.message);
      setIsLoading(false);
    }
  };

  const resendOtp = async () => {
    setIsLoading(true);
    setErrMsg("");
    const validatePayload = {
      mobile_no: userOtpData?.mobile_no || mobile_no,
      otp: getOTP,
      purpose: OtpPurpose.confirm_account,
    };
    const response = unwrapResult(
      await dispatch(ValidateOtpActions?.setResendOtpThunk(validatePayload))
    );
    if (response?.success) {
      setIsCounting(true);
      setIsLoading(false);
      snackbar.show({
        severity: "info",
        message: "Successfully resend OTP",
      });
    }
    if (response?.message && !response?.success) {
      setIsLoading(false);
      setErrMsg(response?.message);
    }
  };

  const handleChangeInput = (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue.slice(0, 4);
    inputValue = inputValue.slice(0, 4);
    setOTP(inputValue);
  };

  const resendOtpCountdown = () => {
    setIsCounting(true);
    const timerId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(intervalId);
          setIsCounting(false);
          return prevTime;
        }
      });
    }, 1000);
    setIntervalId(timerId);
  };

  useEffect(() => {
    if (userData) {
      getUserData(userData);
    }
    if (mobileNumber) {
      getMobileNo(mobileNumber);
    }

    if (isCounting) {
      resendOtpCountdown();
    } else {
      clearInterval(intervalId);
      setTime(resendTime);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, isCounting]);

  return (
    <FlexBox
      justifyContent={"center"}
      alignItems={"center"}
      minWidth={250}
      flexDirection={"column"}
      p={5}
      textAlign={"center"}
    >
      <H3>Verify Your Mobile</H3>
      <Paragraph fontSize={"12px"}>
        {`Please Enter The 4 Digit Code Sent to ${
          userOtpData?.mobile_no || mobile_no
        }`}
      </Paragraph>
      <TextField
        fullWidth
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            ValidateOtp();
          }
        }}
        label="Enter OTP"
        sx={{
          margin: 1,
          "& input": {
            textAlign: "center",
            padding: 2,
            fontSize: "16px",
            letterSpacing: 5,
            fontWeight: 900,
          },
        }}
        onChange={(e) => {
          handleChangeInput(e);
        }}
      />
      <Small
        mb={1}
        sx={{ textAlign: "center", display: "block", color: "red" }}
      >
        {errMsg ? errMsg : null}
      </Small>
      <BazarButton
        variant="contained"
        sx={{ mt: 2 }}
        color="secondary"
        fullWidth
        onClick={ValidateOtp}
      >
        {isLoading ? <CircularProgress /> : "Verify"}
      </BazarButton>
      <Small
        sx={{
          textDecoration: "underline",
          color: "#2F80ED",
          cursor: "pointer",
        }}
        mt={1}
      >
        <Button
          color="info"
          variant="outlined"
          onClick={resendOtp}
          sx={{ fontSize: "12px" }}
          disabled={isCounting}
        >
          {isCounting ? `Resend(${time})` : `Resend`}
        </Button>
      </Small>
    </FlexBox>
  );
};

export default OtpVerification;
