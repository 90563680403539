import { RootState } from "common";
import { createSelector } from "reselect";

const selecBuildState = (state: RootState) => state.Builds;

const selectBuildList = createSelector(
  selecBuildState,
  (state) => state.buildList
);

const selectBuildListLinks = createSelector(
  selecBuildState,
  (state) => state.links
);

const selectBuildListMeta = createSelector(
  selecBuildState,
  (state) => state.meta
);

const selectOverwriteBuildParts = createSelector(
  selecBuildState,
  (state) => state.overwriteBuildParts
);

const selectBuildDetails = createSelector(
  selecBuildState,
  (state) => state.buildPageDetails
);

const selectConfigureBuildId = createSelector(
  selecBuildState,
  (state) => state.configureBuildId
);

export const selectors = {
  selectBuildList,
  selectOverwriteBuildParts,
  selectBuildDetails,
  selectConfigureBuildId,
  selectBuildListLinks,
  selectBuildListMeta,
};
