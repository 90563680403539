import { Api, ApiResponse } from "common";
import {
  GetBuildsGalleryPayload,
  GetBuildsGalleryResponse,
  BuildsDetailsResponse,
  BuildsGalleryPagePayload,
  BuildsGalleryPageResponse,
} from "./types";

const getBuildsGallery = (
  payload: GetBuildsGalleryPayload
): ApiResponse<GetBuildsGalleryResponse> => {
  return Api.get(`api/gallery/latest/get`, payload);
};

//for page gallery
const getBuildsGalleryPage = (
  payload: BuildsGalleryPagePayload
): ApiResponse<BuildsGalleryPageResponse> => {
  return Api.post(`api/gallery/get`, payload);
};

// for builds details
const getBuildsDetails = (
  payload: BuildsGalleryPagePayload
): ApiResponse<BuildsDetailsResponse> => {
  return Api.post(`api/gallery/get/${payload?.uuid}`);
};

const getBuildsItemOnProduct = (
  payload: string
): ApiResponse<BuildsDetailsResponse> => {
  return Api.get(`api/ecomm/products/build_galleries/get/${payload}`);
};

export const BuildsGalleryApi = {
  getBuildsGallery,
  getBuildsGalleryPage,
  getBuildsDetails,
  getBuildsItemOnProduct,
};
