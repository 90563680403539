import { createSelector } from "reselect";
import { RootState } from "common";

const selectUserRegistration = (state: RootState) => state.PcwUserProfile;

const selectUserProfileData = createSelector(
  selectUserRegistration,
  (state) => state.userProfileData
);

export const selectors = {
  selectUserProfileData,
};
