export const refundRequest = [
  {
    orderNo: "6ed34Edf65d",
    shopName: "Shopify Store",
    amount: 250,
    status: "Accepted",
    name: "Samsung Galaxy-M1",
    image: "/assets/images/products/samsung.png",
  },
  {
    orderNo: "6ed34Edf65d",
    shopName: "Shopify Store",
    amount: 250,
    status: "Accepted",
    name: "Tomatto",
    image: "/assets/images/products/tomato.png",
  },
  {
    orderNo: "6ed34Edf65d",
    shopName: "Shopify Store",
    amount: 24,
    status: "Pending",
    name: "Boston Round Cream Pack",
    image: "/assets/images/products/beauty-cream.png",
  },
  {
    orderNo: "6ed34Edf65d",
    shopName: "Shopify Store",
    amount: 250,
    status: "Processing",
    name: "Woman Party Dress",
    image: "/assets/images/products/red-dress.png",
  },
  {
    orderNo: "6ed34Edf65d",
    shopName: "Shopify Store",
    amount: 250,
    status: "Accepted",
    name: "White Tops",
    image: "/assets/images/products/white-tops.png",
  },
  {
    orderNo: "6ed34Edf65d",
    shopName: "Shopify Store",
    amount: 450,
    status: "Processing",
    name: "Casual Shirt for Man",
    image: "/assets/images/products/formal-shirt.png",
  },
];
