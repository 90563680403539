import { Location } from "../../types";

export const techOutFreeAllowedRegions: Location[] = [
  {
    id: 13,
    name: "NATIONAL CAPITAL REGION (NCR)",
  },
];

export const techOutExpressAllowedRegionsForCOD: Location[] = [
  {
    id: 13,
    name: "NATIONAL CAPITAL REGION (NCR)",
  },
];

export const techOutExpressAllowedRegions: Location[] = [
  {
    id: 13,
    name: "NATIONAL CAPITAL REGION (NCR)",
  },
  {
    id: 4,
    name: "REGION IV-A (CALABARZON)",
  },
  {
    id: 3,
    name: "REGION III (CENTRAL LUZON)",
  },
];

export const techOutExpressAllowedProvincesRegion4A: Location[] = [
  {
    id: 421,
    name: "CAVITE",
    region_code: 4,
  },
  {
    id: 458,
    name: "RIZAL",
    region_code: 4,
  },
];

export const techOutExpressAllowedProvincesRegion3: Location[] = [
  {
    id: 314,
    name: "BULACAN",
    region_code: 3,
  },
];

export const techOutExpressAllowedCitiesRegion3Bulacan: Location[] = [
  {
    id: 31402,
    name: "BALAGTAS (BIGAA)",
    region_code: 3,
    province_code: 314,
  },
  {
    id: 31404,
    name: "BOCAUE",
    region_code: 3,
    province_code: 314,
  },
  {
    id: 31405,
    name: "BULACAN",
    region_code: 3,
    province_code: 314,
  },
  {
    id: 31407,
    name: "CALUMPIT",
    region_code: 3,
    province_code: 314,
  },
  {
    id: 31410,
    name: "CITY OF MALOLOS (Capital)",
    region_code: 3,
    province_code: 314,
  },
  {
    id: 31412,
    name: "CITY OF MEYCAUAYAN",
    region_code: 3,
    province_code: 314,
  },
  {
    id: 31408,
    name: "GUIGUINTO",
    region_code: 3,
    province_code: 314,
  },
  {
    id: 31409,
    name: "HAGONOY",
    region_code: 3,
    province_code: 314,
  },
  {
    id: 31411,
    name: "MARILAO",
    region_code: 3,
    province_code: 314,
  },
  {
    id: 31414,
    name: "OBANDO",
    region_code: 3,
    province_code: 314,
  },
  {
    id: 31416,
    name: "PAOMBONG",
    region_code: 3,
    province_code: 314,
  },
  {
    id: 31417,
    name: "PLARIDEL",
    region_code: 3,
    province_code: 314,
  },
  {
    id: 31418,
    name: "PULILAN",
    region_code: 3,
    province_code: 314,
  },
];
