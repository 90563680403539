import moment from "moment";

export const currentDateNow = () => new Date();

export const sevenDaysAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;

export const currentDateNowConst = currentDateNow();

export const currentTimeMilliseconds = () => {
  const time = moment().unix();
  return time;
};

export const currentDateTime = (formatting: string = "LLL") => {
  const dateTimeNow = moment().format(formatting);
  return dateTimeNow;
};

export const dateToMs = (dateArg: string | Date) => {
  if (!dateArg) {
    return 0;
  }
  const date = new Date(dateArg);
  const ms = date.getTime();
  return ms;
};

export const getTimeLeftInHours = (dateArg: Date | string): number => {
  if (!dateArg) {
    return 0;
  }
  const targetTime = new Date(dateArg);
  const currentTime: Date = new Date();
  const timeDiff: number = targetTime.getTime() - currentTime.getTime();
  const hoursLeft: number = Math.floor(timeDiff / (1000 * 60 * 60));
  return hoursLeft;
};

export const dateBirthPixel = (str?: string) => {
  return moment(str).format("YYYYMMDD");
};
