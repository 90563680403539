import { RootState, SliceStatusValue, _ } from "common";
import { createSelector } from "reselect";

const selectCartState = (state: RootState) => state.Cart;

const selectCart = createSelector(selectCartState, (state) => state.cart);

const selectCartLength = createSelector(selectCartState, (state) =>
  _.size(state.cart || [])
);

const selectCartTotalAmount = createSelector(selectCartState, (state) =>
  state.cart?.reduce(
    (previousAmount, cartItem) =>
      previousAmount + cartItem?.amount * cartItem?.quantity,
    0
  )
);

const selectCreateOrderLoading = createSelector(
  selectCartState,
  (state) => state.createOrderStatus === SliceStatusValue.loading
);

const selectCreateOrderSuccess = createSelector(
  selectCartState,
  (state) => state.createOrderStatus === SliceStatusValue.resolve
);

const selectCreateOrderResponse = createSelector(
  selectCartState,
  (state) => state.createOrderResponse
);

const selectCreateOrderError = createSelector(
  selectCartState,
  (state) => state.createOrderStatus === SliceStatusValue.reject
);

const selectCreateOrderErrors = createSelector(
  selectCartState,
  (state) => state.createOrderErrors
);

const selectCreateOrderErrorMsg = createSelector(
  selectCartState,
  (state) => state.createOrderErrMsg
);

const selectCartProducts = createSelector(selectCartState, (state) => {
  const cart = state.cart || [];
  const productsAvailable = cart?.map((item) => {
    return {
      product_slug: item.product_slug,
      quantity: item.quantity,
      category_id: item?.category_id,
      with_bundle: item?.with_bundle,
      category: item?.category || "",
    };
  });
  return productsAvailable;
});

const selectCartProductsForCpus = createSelector(selectCartState, (state) => {
  const cart = state.cart || [];
  const productsAvailable = cart?.map((item) => {
    return {
      ...item,
      product_slug: item.product_slug,
      quantity: item.quantity,
      category_id: item?.category_id,
      with_bundle: item?.with_bundle,
      category: item?.category || "",
    };
  });
  return productsAvailable;
});

const selectCartHasProductUnavailable = createSelector(
  selectCartState,
  (state) => _.some(state.cart, ["with_stock", false])
);

const selectCartAvailabilityLoading = createSelector(
  selectCartState,
  (state) => state.cartAvailabilityStatus === SliceStatusValue.loading
);

const selectCartAvailabilitySuccess = createSelector(
  selectCartState,
  (state) => state.cartAvailabilityStatus === SliceStatusValue.resolve
);

const selectProductsAvailabilityLoading = createSelector(
  selectCartState,
  (state) => state.productsAvailabilityStatus === SliceStatusValue.loading
);

const selectProductsAvailabilitySuccess = createSelector(
  selectCartState,
  (state) => state.productsAvailabilityStatus === SliceStatusValue.resolve
);

const selectProductsAvailabilityError = createSelector(
  selectCartState,
  (state) => state.productsAvailabilityStatus === SliceStatusValue.reject
);

const selectOrderHistory = createSelector(
  selectCartState,
  (state) => state.localOrderHistory
);

const selectCustomerShippingInfo = createSelector(
  selectCartState,
  (state) => state.customerShippingInfo || {}
);

export const selectors = {
  selectCart,
  selectCartLength,
  selectCartTotalAmount,
  selectCreateOrderLoading,
  selectCreateOrderSuccess,
  selectCreateOrderResponse,
  selectCreateOrderError,
  selectCreateOrderErrors,
  selectCreateOrderErrorMsg,
  selectCartProducts,
  selectCartProductsForCpus,
  selectCartHasProductUnavailable,
  selectCartAvailabilityLoading,
  selectCartAvailabilitySuccess,
  selectProductsAvailabilityLoading,
  selectProductsAvailabilitySuccess,
  selectProductsAvailabilityError,
  selectOrderHistory,
  selectCustomerShippingInfo,
};
