import { createSelector } from "reselect";
import { RootState } from "common";

const selectUserLogin = (state: RootState) => state.PcwUserLogin;

const selectShowForgotPassword = createSelector(
  selectUserLogin,
  (state) => state.showForgotPassword
);

const selectUserMobileOrEmail = createSelector(
  selectUserLogin,
  (state) => state.userMobileOrEmail
);

const selectUserOtp = createSelector(selectUserLogin, (state) => state.userOtp);

const selectUserPurpose = createSelector(
  selectUserLogin,
  (state) => state.confirmationPurpose
);

const selectShowResetPasswordOtp = createSelector(
  selectUserLogin,
  (state) => state.showPasswordOtp
);
const selectUpdatePasswordForm = createSelector(
  selectUserLogin,
  (state) => state.showUpdateForm
);

const selectShowLoginForm = createSelector(
  selectUserLogin,
  (state) => state.showLoginForm
);

const selectShowSignUpForm = createSelector(
  selectUserLogin,
  (state) => state.showSignUpForm
);

const selectEmailVerify = createSelector(
  selectUserLogin,
  (state) => state.showEmailVerify
);

const selectMobileVerify = createSelector(
  selectUserLogin,
  (state) => state.showMobileVerify
);

const selectFacebookLoginMessage = createSelector(
  selectUserLogin,
  (state) => state.showErrorLoginFacebookMsg
);

export const selectors = {
  selectShowForgotPassword,
  selectUserMobileOrEmail,
  selectShowResetPasswordOtp,
  selectUpdatePasswordForm,
  selectUserOtp,
  selectShowLoginForm,
  selectShowSignUpForm,
  selectMobileVerify,
  selectEmailVerify,
  selectUserPurpose,
  selectFacebookLoginMessage,
};
