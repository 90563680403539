import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DealTimeLeft, FlashDealsState } from "./types";
import * as thunks from "./thunks";

//declare mo yung parang variable na kagaya dun sa State mo sample yung tryStateCountBadge dito sa initial state
const initialState: FlashDealsState = {
  flashDeals: [],
  flashDealsLoading: false,
  tryStateCountBadge: 0,
};

//function that use to set the state number or ung value ng state
const setBadgeCount = (state: FlashDealsState, { payload }: PayloadAction<number>) => {
  state.tryStateCountBadge = payload;
}

const slice = createSlice({
  name: "FlashDeals",
  initialState,
  reducers: {
    //ito yung function na magseset ng data papunta sa variable na dineclare sa initial state
    setBadgeCount
  },
  extraReducers(builder) {
    builder.addCase(
      thunks.getFlashDealsThunk.pending,
      (state, { payload }) => {
        state.flashDealsLoading = true;
      }
    );
    builder.addCase(
      thunks.getFlashDealsThunk.rejected,
      (state, { payload }) => {
        state.flashDealsLoading = false;
      }
    );
    builder.addCase(
      thunks.getFlashDealsThunk.fulfilled,
      (state, { payload }) => {
        state.flashDealsLoading = false;
        state.flashDeals = payload?.originalData?.data || [];
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
