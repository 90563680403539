import { Api, ApiResponse } from "common";
import { AiBuildPayload, AiBuildResponse } from "./types";

const getAiPcBuilds = (payload: AiBuildPayload
): ApiResponse<AiBuildResponse> => {
    return Api.get(`api/ecomm/pc_builder_ai/list/get`, payload);
};

const getAiPcBuildsDetails = (payload: AiBuildPayload
): ApiResponse<AiBuildResponse> => {
    return Api.get(`api/ecomm/pc_builder_ai/details/get/${payload.id}`);
};

export const WorthBuilderApi = {
    getAiPcBuilds,
    getAiPcBuildsDetails
}