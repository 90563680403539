export const metaPixelEarnshaw = "1212485753045487";
export const metaPixelAlabang = "857596619547645";

export const metaPixelQC = "1120034706393675";

export const metaPixelLaptop = "1189271192076352";

export const addToCart = "AddToCart";
export const purchase = "Purchase";
export const viewContent = "ViewContent";
export const initiateCheckout = "InitiateCheckout";

export const addressesThatUseAlabangPixel = [
  "las piñas",
  "parañaque",
  "muntinlupa",
  "cavite",
];

export const addressesThatUseQCPixel = [
  "marikina",
  "quezon city",
  "pasig",
  "taguig",
  "san juan",
  "mandaluyong",
  "caloocan",
  "malabon",
  "valenzuela",
  "rizal",
  "bulacan",
  "pampanga",
];

export const addressesThatUseEarnshawPixel = ["manila", "pasay", "makati"];

export const constants = {
  addToCart,
  purchase,
  viewContent,
  initiateCheckout,
};
