import { useCallback, useMemo } from "react";
import {
  CategoriesIdsEnum,
  formatCurrencySRP,
  localize,
  PaymentOptionsEnum,
  promoPriceToSRP,
} from "common";
import { useSnackBar } from "./use-snackbar";
import { cloneDeep } from "lodash";

import modules from "modules";
import {
  CartItem,
  CompatibleMotherboardsDialogItem,
  CustomerShippingInfo,
} from "modules/cart/types";
import { useAppSelector } from "./use-app-selector";
import { useAppDispatch } from "./use-app-dispatch";
import {
  getAllCartItemInQtyOne,
  getCompatibleMoboViaCPU,
  isCPUAllowedInCart,
} from "utils/cart";
import { usePcwUserCart } from "./use-pcw-cart";
import { useLoginStatus } from "./useLoginStatus";

const {
  selectors: cartSelectors,
  actions: cartActions,
  utils: cartUtils,
} = modules.cart;
const { selectors: pcBuilderSelectors } = modules.pcBuilder;
const { selectors: optionsSelectors } = modules.options;

/** ========== SOME HELPER OF THIS HOOKS ========== **/

const addToCartReachedMaxQty = (cartItem?: CartItem, cart?: CartItem[]) => {
  const clonedCartState = cloneDeep(cart);
  const newItemToBeInsertedInCart = cartItem;
  const indexOfExistingItem = clonedCartState?.findIndex(
    (x) => x.product_slug === newItemToBeInsertedInCart?.product_slug
  );

  if (indexOfExistingItem > -1) {
    // If item already in cart just add the existing quantity
    const toBeInsertedQty = newItemToBeInsertedInCart.quantity || 0;
    const currentQty = clonedCartState[indexOfExistingItem].quantity || 0;
    const theNewQty = currentQty + toBeInsertedQty;

    if (
      theNewQty > (clonedCartState[indexOfExistingItem]?.stocks_left || 999)
    ) {
      return `Maximum stock(s) reached. (${clonedCartState[indexOfExistingItem]?.stocks_left})`;
    } else {
      const sumQty = (currentQty || 0) + (toBeInsertedQty || 0);
      return `${sumQty} ${cartItem?.product_name} now in cart`;
    }
  }
};

export const useCart = () => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackBar();
  const { addToCartMultiple, userCheckoutCartMultiple, clearUserCart } =
    usePcwUserCart();
  const { isUserLogin } = useLoginStatus();
  const isLogin = isUserLogin;

  const buildPartsToCart = useAppSelector(
    pcBuilderSelectors.selectBuildPartsToCart
  );

  /** ========== SELECTORS / VARIABLES / CONSTANTS ========== */
  const cart = useAppSelector(cartSelectors.selectCart);
  const cartLength = useAppSelector(cartSelectors.selectCartLength);
  const cartTotalAmount = useAppSelector(cartSelectors.selectCartTotalAmount);
  const createOrderSuccess = useAppSelector(
    cartSelectors.selectCreateOrderSuccess
  );
  const createOrderResponse = useAppSelector(
    cartSelectors.selectCreateOrderResponse
  );
  const createOrderError = useAppSelector(cartSelectors.selectCreateOrderError);
  const createOrderLoading = useAppSelector(
    cartSelectors.selectCreateOrderLoading
  );
  const productsAvailabilityError = useAppSelector(
    cartSelectors.selectProductsAvailabilityError
  );
  const productsAvailabilityLoading = useAppSelector(
    cartSelectors.selectProductsAvailabilityLoading
  );
  const orderErrors = useAppSelector(cartSelectors.selectCreateOrderErrors);
  const orderErrorMsg = useAppSelector(cartSelectors.selectCreateOrderErrorMsg);
  const orderHistory = useAppSelector(cartSelectors.selectOrderHistory);
  const savedCustomerInfo = useAppSelector(
    cartSelectors.selectCustomerShippingInfo
  );
  const isCheckingAvailability = useAppSelector(
    cartSelectors.selectCartAvailabilityLoading
  );
  const hasUnavailable = useAppSelector(
    cartSelectors.selectCartHasProductUnavailable
  );
  const paymongoInterestFee = useAppSelector(
    optionsSelectors.selectPaymongoMerchantInterest
  );

  const cpusThatNeedsMotherboardAsBundle: CompatibleMotherboardsDialogItem[] =
    useMemo(() => {
      let cartItemsCPUsThatNeedsMobo: CartItem[] = [];
      const cartClone = cloneDeep(cart);
      const cpusWithBundle = cartClone
        ?.filter(
          (item) =>
            (item?.category_id === CategoriesIdsEnum.CPU ||
              item?.category?.toUpperCase() === "CPU") &&
            item?.with_bundle
        )
        ?.sort((a, b) => (b?.with_bundle || 0) - (a?.with_bundle || 0));
      const dissectedCPUs = getAllCartItemInQtyOne(cpusWithBundle);
      const cpusInCartLength = dissectedCPUs?.length || 0;

      // prepare mobos logic here.
      if (dissectedCPUs && cpusInCartLength > 0) {
        let motherboardsInCart = cartClone?.filter(
          (item) =>
            item?.category_id === CategoriesIdsEnum.Motherboard ||
            item?.category?.toUpperCase() === "Motherboard"
        );
        const dissectedMobos = getAllCartItemInQtyOne(motherboardsInCart) || [];
        let mobosForChecker = dissectedMobos;

        for (let index = 0; index < cpusInCartLength; index++) {
          const product = dissectedCPUs[index];

          // Check if compatible mobo exist in mobos
          const compatibleMoboSlug = getCompatibleMoboViaCPU(
            product,
            mobosForChecker
          );

          // update mobosForChecker. If compatibleMoboName has value. Slice / Remove it there...
          if (compatibleMoboSlug) {
            const indexOfMoboName = mobosForChecker?.findIndex(
              (x) => x?.product_slug === compatibleMoboSlug
            );
            if (indexOfMoboName > -1) {
              mobosForChecker?.splice(indexOfMoboName, 1);
            }
          } else {
            cartItemsCPUsThatNeedsMobo.push(product);
          }
        }
      }

      const mappedMobos: CompatibleMotherboardsDialogItem[] =
        cartItemsCPUsThatNeedsMobo?.map((item) => ({
          cpuSlug: item?.product_slug,
          cpuProductName: item?.product_name,
          motherboards: item?.compatible_mobos,
        }));

      return mappedMobos;
    }, [cart]);

  /** ========== FUNCTIONS ========== */
  const addPCPackageToCart = useCallback(
    (cartItems: CartItem[]) => {
      dispatch(cartActions.addPCPackageToCart(cartItems));
    },
    [dispatch]
  );

  const addBuildProductsToCart = useCallback(
    (cartItems: CartItem[]) => {
      dispatch(cartActions.addBuildProductsToCart(cartItems));
    },
    [dispatch]
  );

  const checkOutCartReplace = useCallback(
    (cartItems: CartItem[]) => {
      dispatch(cartActions.checkoutCartReplace(cartItems));
    },
    [dispatch]
  );

  const addToCart = useCallback(
    (cartItem: CartItem) => {
      // Check if CPU is strictly for bundle.
      if (!isCPUAllowedInCart(cartItem, cart)) {
        snackbar.show({
          severity: "error",
          message: `This CPU is strictly for bundle. Please select a compatible motherboard below.`,
        });
        return;
      }

      const itemAddedCopy = addToCartReachedMaxQty(cartItem, cart);
      if (itemAddedCopy) {
        snackbar.show({
          severity: "info",
          message: `${itemAddedCopy}`,
        });
      }

      dispatch(cartActions.addToCart(cartItem));

      snackbar.show({
        severity: "info",
        message: localize.ITEMS_ADDED_CART,
      });
    },
    [cart, dispatch, snackbar]
  );

  const removeFromCart = useCallback(
    (product_slug?: string, index?: number) => {
      if (product_slug) {
        dispatch(cartActions.removeFromCart({ product_slug }));
      } else {
        dispatch(cartActions.removeFromCart({ index }));
      }
    },
    [dispatch]
  );

  const addQuantity = useCallback(
    (product_slug?: string) => {
      if (product_slug) {
        dispatch(cartActions.addQuantity({ product_slug }));
      }
    },
    [dispatch]
  );

  const subtractQuantity = useCallback(
    (product_slug?: string) => {
      if (product_slug) {
        dispatch(cartActions.subtractQuantity({ product_slug }));
      }
    },
    [dispatch]
  );

  const updateCustomerShippingInfo = useCallback(
    (newCustomerShippingInfo: CustomerShippingInfo) => {
      dispatch(cartActions.updateCustomerShippingInfo(newCustomerShippingInfo));
    },
    [dispatch]
  );

  const productsAvailabilityRequest = useCallback(
    (payload: CustomerShippingInfo) => {
      dispatch(cartActions.productsAvailabilityRequest(payload));
    },
    [dispatch]
  );

  const clearCart = useCallback(() => {
    dispatch(cartActions.clearCart());
  }, [dispatch]);

  const clearCreateOrder = useCallback(() => {
    dispatch(cartActions.clearCreateOrder());
  }, [dispatch]);

  const addBuildPartsToCart = useCallback(
    (onSuccess?: () => void) => {
      const cpuWithBundle = buildPartsToCart?.find(
        (x) => x?.category_id === CategoriesIdsEnum.CPU && x?.with_bundle
      );

      if (cpuWithBundle) {
        // NOTE: For now were only checking if Motherboard exist. Since technically if motherboard exist in this feature its compatible..
        const moboExist = buildPartsToCart?.find(
          (x) => x?.category_id === CategoriesIdsEnum.Motherboard
        );
        if (!moboExist) {
          snackbar.show({
            severity: "error",
            message: `${cpuWithBundle?.product_name} is strictly for bundle. Please add a compatible motherboard on your build to proceed`,
          });
          return;
        }
      }
      //if user is login use usercart
      if (isLogin) {
        clearUserCart().then(() => {
          const cleanBuildParts = buildPartsToCart.reduce(
            (accumulator, currVal) => {
              if (currVal.stocks_left !== 0 || currVal.stocks_left > 0) {
                accumulator.push(currVal);
              }
              return accumulator;
            },
            []
          );
          //for api
          addToCartMultiple(cleanBuildParts);
        });
      }

      onSuccess();
    },
    [addToCartMultiple, buildPartsToCart, clearUserCart, isLogin, snackbar]
  );

  const cartAvailabilityRequest = useCallback(() => {
    dispatch(cartActions.cartAvailabilityRequest());
  }, [dispatch]);

  const clearCustomerShippingInfo = useCallback(() => {
    dispatch(cartActions.clearCustomerShippingInfo());
  }, [dispatch]);

  const setCartStateToUniqueSlugs = useCallback(() => {
    dispatch(cartActions.cartAvailabilityRequest());
  }, [dispatch]);

  const productQtyInCartViaSlug = (slug?: string) => {
    if (!slug) {
      return 0;
    }
    const filteredCartSlug = cart?.find((item) => item?.product_slug === slug);
    return filteredCartSlug?.quantity || 0;
  };

  /** Checkout data */

  // NOTE: Properties to check if item should have an added fee
  /** item?.non_bundle_addtl_amt && !item?.with_bundle && item?.cpuHasCompatibleMoboInCart */

  /**
   * Sometimes CPUs have their `non_bundle_addtl_amt`,
   * means amount will be added on total of cart because they are buying a CPU
   * alone without compatible motherboard. Just a business logic.
   */
  const nonBundleFee = useMemo(() => {
    const cartItemsWithNonBundleFee = cart?.filter(
      (item) =>
        (item?.category_id === CategoriesIdsEnum.CPU ||
          item?.category === "CPU") && // should be cpu
        item?.non_bundle_addtl_amt && // should have additional amount
        !item?.with_bundle && // should not be 'strictly for bundle'
        item?.cpuHasCompatibleMoboInCart === false && // should not have a compatible mobo in cart
        item?.totalNonBundleAdditional
    );

    const nonBundleFeeSum =
      cartItemsWithNonBundleFee?.reduce(
        (a, c) => (c?.totalNonBundleAdditional || 0) + +a,
        0
      ) || 0;

    return nonBundleFeeSum || 0;
  }, [cart]);

  /** Checkout functions */

  /**
   *
   * A function to know how much interest percentage does a payment method has.
   * Sometimes it will be based on Options from API
   *
   * @param paymentMode = PaymentOptionsEnum.Paymongo
   * @returns a number
   */
  const interestPercentageBasedOnPaymentOption = useCallback(
    (paymentMode: string) => {
      if (paymentMode === PaymentOptionsEnum.Paymongo && paymongoInterestFee) {
        return paymongoInterestFee || 0;
      }
      return 0;
    },
    [paymongoInterestFee]
  );

  /**
   * checkoutGrandTotal - the sum of shippingFee, cartTotal and nonBundleFee
   * WITH the interest based on payment method
   */
  const checkoutGrandTotal = useCallback(
    (paymentMode: string, shippingFee = 0, pointsDeducted?: number) => {
      const paymentModeInterest =
        interestPercentageBasedOnPaymentOption(paymentMode);

      const shippingFeeInternal = shippingFee || 0;
      const cartTotalInternal = cartTotalAmount || 0;
      const nonBundleFeeInternal = nonBundleFee || 0;
      const kachiPoints = pointsDeducted || 0;

      const grandTotalWithoutPaymentModeInterest =
        cartTotalInternal +
        shippingFeeInternal +
        nonBundleFeeInternal -
        kachiPoints;

      return promoPriceToSRP(
        grandTotalWithoutPaymentModeInterest,
        paymentModeInterest
      );
    },
    [cartTotalAmount, interestPercentageBasedOnPaymentOption, nonBundleFee]
  );

  /**
   * The "Cart Total:" label that would be shown on summary totals.
   * checkoutCartTotalAmount = the true cart total amount from cart reducer with interest of a payment method
   * HOW IS THIS DIFFERENT ON `cartTotalAmount`?....
   * well, `checkoutCartTotalAmount` has interest depending on payment method
   */
  const checkoutCartTotalAmount = useCallback(
    (paymentMode: string) => {
      const paymentModeInterest =
        interestPercentageBasedOnPaymentOption(paymentMode);
      return promoPriceToSRP(cartTotalAmount, paymentModeInterest);
    },
    [cartTotalAmount, interestPercentageBasedOnPaymentOption]
  );

  /**
   * checkoutShippingFeeAmount returns shippingFee amount based on shipping fee and payment method
   */
  const checkoutShippingFeeAmount = useCallback(
    (paymentMode: string, shippingFee: number = 0) => {
      const paymentModeInterest =
        interestPercentageBasedOnPaymentOption(paymentMode);
      return promoPriceToSRP(shippingFee, paymentModeInterest);
    },
    [interestPercentageBasedOnPaymentOption]
  );

  /**
   * checkoutNonBundleFee returns non-bundle-fee amount based on payment method
   */
  const checkoutNonBundleFee = useCallback(
    (paymentMode) => {
      const paymentModeInterest =
        interestPercentageBasedOnPaymentOption(paymentMode);
      return promoPriceToSRP(nonBundleFee, paymentModeInterest);
    },
    [interestPercentageBasedOnPaymentOption, nonBundleFee]
  );

  return {
    cart,
    cartLength,
    cartTotalAmount,
    createOrderSuccess,
    createOrderResponse,
    createOrderError,
    createOrderLoading,
    productsAvailabilityError,
    productsAvailabilityLoading,
    orderErrors,
    orderErrorMsg,
    orderHistory,
    savedCustomerInfo,
    isCheckingAvailability,
    hasUnavailable,
    cpusThatNeedsMotherboardAsBundle,
    paymongoInterestFee,

    addToCart,
    addPCPackageToCart,
    addBuildProductsToCart,
    removeFromCart,
    addQuantity,
    subtractQuantity,
    clearCart,
    clearCreateOrder,
    addBuildPartsToCart,
    cartAvailabilityRequest,
    updateCustomerShippingInfo,
    productsAvailabilityRequest,
    clearCustomerShippingInfo,
    productQtyInCartViaSlug,
    setCartStateToUniqueSlugs,

    // Data and Functions below is mostly gonna be used on checkout functionality
    // since checkout and cart works closely with each other

    // checkout data
    nonBundleFee,

    // checkout functions
    checkOutCartReplace,
    checkoutGrandTotal,
    checkoutNonBundleFee,
    checkoutCartTotalAmount,
    checkoutShippingFeeAmount,
    interestPercentageBasedOnPaymentOption,
  };
};
