import { RootState, _ } from "common";
import { createSelector } from "reselect";

const selectBlogState = (state: RootState) => state.Blogs;

const selectBlogList = createSelector(
  selectBlogState,
  (state) => state.blogs.data
);

const selectFilters = createSelector(selectBlogState, (state) => state.filters);

const selectFound = createSelector(
  selectBlogState,
  (state) => state.blogs.found
);
const selectCategoryFilters = createSelector(
  selectBlogState,
  (state) => state.categoryFilters
);

const selectDateFilters = createSelector(
  selectBlogState,
  (state) => state.dateFilters
);

const selectContentFilters = createSelector(
  selectBlogState,
  (state) => state.contentFilters
);

const selectIsBlogsLoading = createSelector(
  selectBlogState,
  (state) => state.isBlogsLoading
);

const selectBlogDetails = createSelector(
  selectBlogState,
  (state) => state.blogDetails
);

const selectPayload = createSelector(selectBlogState, (state) => state.payload);

export const selectors = {
  selectBlogList,
  selectFilters,
  selectFound,
  selectCategoryFilters,
  selectDateFilters,
  selectContentFilters,
  selectPayload,
  selectIsBlogsLoading,
  selectBlogDetails,
};
