import { colors } from "common";

export const defaultRowColors = {
  gpu: colors.green,
  cpu: colors.amber,
  motherboard: colors.blue,
  ram: colors.blueGrey,
  hdd: colors.cyan,
  ssd: colors.lightGreen,
  psu: colors.lightBlue,
  casing: colors.deepPurple,
  monitor: colors.yellow,
  cpuCooler: colors.brown,
  fan: colors.teal,
  accessories: colors.indigo,
};

export default defaultRowColors;
