import { createSlice } from "@reduxjs/toolkit";
import { OptionsState } from "./types";
import * as thunks from "./thunks";

const initialState: OptionsState = {
  paymongoMerchantInterest: 0,
  paymongoMerchantInterestLoading: false,
  isSocialBtnLogin: 0,
};

const slice = createSlice({
  name: "Options",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      thunks.getPaymongoInterestThunk.pending,
      (state, { payload }) => {
        state.paymongoMerchantInterestLoading = true;
      }
    );
    builder.addCase(
      thunks.getPaymongoInterestThunk.rejected,
      (state, { payload }) => {
        state.paymongoMerchantInterestLoading = false;
      }
    );
    builder.addCase(
      thunks.getPaymongoInterestThunk.fulfilled,
      (state, { payload }) => {
        state.paymongoMerchantInterestLoading = false;
        state.paymongoMerchantInterest = payload?.originalData?.data;
      }
    );
    builder.addCase(
      thunks.getSocialBtnThunk.fulfilled,
      (state, { payload }) => {
        state.isSocialBtnLogin = payload?.originalData?.data;
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
