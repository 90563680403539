import cloneDeep from "lodash/cloneDeep";
import concat from "lodash/concat";
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import find from "lodash/find";
import get from "lodash/get";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import reduce from "lodash/reduce";
import size from "lodash/size";
import slice from "lodash/slice";
import some from "lodash/some";
import union from "lodash/union";

export const _ = {
  cloneDeep,
  concat,
  filter,
  find,
  debounce,
  get,
  isArray,
  isEmpty,
  map,
  reduce,
  size,
  slice,
  some,
  union,
};
