import { createSlice, current } from "@reduxjs/toolkit";
import {
  Action,
  addNonPrimItemInArrayUnique,
  currentDateTime,
  ErrorResponse,
  SliceStatusValue,
  SuccessResponse,
  _,
} from "common";
import {
  CartItem,
  CartState,
  CreateOrderPayload,
  CreateOrderResponse,
  CustomerShippingInfo,
  OrderHistoryItem,
  ProductAvailable,
  ProductSlug,
} from "./types";
import * as thunks from "./thunks";
import { cartItemsToUniqSlugs } from "utils/cart";

// NOTE: DO NOT USE customerShippingInfo as alternative to CreateOrderPayload
// This is only used for good user experience
const updateCustomerShippingInfo = (
  state: CartState,
  action: Action<CustomerShippingInfo>
) => {
  const payload = action?.payload;
  state.customerShippingInfo = payload;
};

const clearCustomerShippingInfo = (state: CartState) => {
  state.customerShippingInfo = undefined;
};

const setCartStateToUniqueSlugs = (state: CartState) => {
  state.cart = cartItemsToUniqSlugs(state.cart);
};

const addToCart = (state: CartState, action: Action<CartItem>) => {
  const clonedCartState = _.cloneDeep(state.cart);
  const newItemToBeInsertedInCart = action.payload;
  const indexOfExistingItem = state.cart?.findIndex(
    (x) => x.product_slug === newItemToBeInsertedInCart?.product_slug
  );

  if (indexOfExistingItem > -1) {
    // If item already in cart just add the existing quantity
    const toBeInsertedQty = newItemToBeInsertedInCart.quantity || 0;
    const currentQty = clonedCartState[indexOfExistingItem].quantity || 0;
    const theNewQty = currentQty + toBeInsertedQty;

    if (
      theNewQty > (clonedCartState[indexOfExistingItem]?.stocks_left || 999)
    ) {
      clonedCartState[indexOfExistingItem].quantity =
        clonedCartState[indexOfExistingItem]?.stocks_left;
    } else {
      clonedCartState[indexOfExistingItem].quantity =
        currentQty + toBeInsertedQty;
    }
    state.cart = clonedCartState;
    return;
  }

  if (state.cart) {
    state.cart = state.cart.concat(newItemToBeInsertedInCart);
    return;
  }
  state.cart = [newItemToBeInsertedInCart];
};

const removeFromCart = (
  state: CartState,
  action: Action<{ product_slug?: string; index?: number }>
) => {
  const { product_slug, index } = action.payload;
  const cart = _.filter(state.cart, (item, checkIndex) => {
    if (product_slug) {
      return item?.product_slug !== action.payload?.product_slug;
    } else {
      return checkIndex !== index;
    }
  });
  state.cart = cart;
};

const addQuantity = (state: CartState, action: Action<ProductSlug>) => {
  const clonedCartState = _.cloneDeep(state.cart);
  const cart = _.map(clonedCartState, (cartItem) => {
    if (cartItem.product_slug === action.payload?.product_slug) {
      cartItem.quantity += 1;
    }
    return cartItem;
  });
  state.cart = cart;
};

const subtractQuantity = (state: CartState, action: Action<ProductSlug>) => {
  const cart = _.map(state.cart, (cartItem) => {
    if (
      cartItem.product_slug === action.payload?.product_slug &&
      cartItem.quantity > 1
    ) {
      cartItem.quantity -= 1;
    }
    return cartItem;
  });
  state.cart = cart;
};

const createOrderRequest = (
  state: CartState,
  _action: Action<CreateOrderPayload>
) => {
  state.createOrderResponse = undefined;
  state.createOrderStatus = SliceStatusValue.loading;
};

const createOrderResolve = (
  state: CartState,
  action: Action<CreateOrderResponse>
) => {
  state.createOrderResponse = action?.payload;
  if (action?.payload?.data?.order_ref_no) {
    const newOrderHistory: OrderHistoryItem = {
      orderRef: action?.payload?.data?.order_ref_no || "",
      date: currentDateTime(),
      user_id: action?.payload?.data?.user_id,
    };

    const newOrderHistoryArr = addNonPrimItemInArrayUnique(
      state.localOrderHistory,
      newOrderHistory,
      "orderRef"
    );
    state.localOrderHistory = newOrderHistoryArr;
    state.cart = [];
    state.createOrderErrMsg = "";
    state.createOrderErrors = {};
  }

  state.createOrderStatus = SliceStatusValue.resolve;
};

const createOrderReject = (state: CartState, action: Action<ErrorResponse>) => {
  const errData: any = action?.payload;
  state.createOrderErrMsg = errData?.message;
  state.createOrderErrors = errData?.errors ?? {};
  state.createOrderStatus = SliceStatusValue.reject;
};

const clearCreateOrder = (state: CartState) => {
  state.createOrderStatus = undefined;
  state.createOrderResponse = undefined;
  state.productsAvailabilityStatus = undefined;
  state.cartAvailabilityStatus = undefined;

  state.createOrderErrMsg = "";
  state.createOrderErrors = {};
};

const clearCart = (state: CartState) => {
  state.cart = undefined;
};

const resetCartAvailabilityStates = (
  state: CartState,
  _action: Action<CreateOrderPayload>
) => {
  state.cartAvailabilityStatus = undefined;
};

const cartAvailabilityRequest = (state: CartState) => {
  const totalNonBundleAdditional = state?.cart
    ?.map((x) => ({
      ...x,
      totalNonBundleAdditional: 0,
    }))
    ?.sort((a, b) => (b?.with_bundle || 0) - (a?.with_bundle || 0));

  state.cart = totalNonBundleAdditional;
  state.cartAvailabilityStatus = SliceStatusValue.loading;
};

const productsAvailabilityRequest = (
  state: CartState,
  _action: Action<CreateOrderPayload>
) => {
  state.productsAvailabilityStatus = SliceStatusValue.loading;
};

const productSingleAvailabilityResolve = (
  state: CartState,
  action: Action<ProductAvailable>
) => {
  const tempCart = state.cart || [];
  const incomingProduct = action.payload;
  const newProperties = {
    message: incomingProduct?.message,
    with_stock: incomingProduct.with_stock,
    with_bundle: incomingProduct?.with_bundle,
    compatible_mobos: incomingProduct?.compatible_mobos,
    non_bundle_addtl_amt: incomingProduct?.non_bundle_addtl_amt,
    cpuHasCompatibleMoboInCart: incomingProduct?.cpuHasCompatibleMoboInCart,
  };
  const existingIndex = state?.cart?.findIndex(
    (x) => x?.product_slug === incomingProduct?.product_slug
  );
  if (existingIndex > -1) {
    //   item?.totalNonBundleAdditional &&
    //   item?.non_bundle_addtl_amt &&
    //   !item?.with_bundle &&
    //   item?.cpuHasCompatibleMoboInCart === false

    const incomingItemHasAddNonBundleFee =
      incomingProduct?.cpuHasCompatibleMoboInCart === false &&
      !incomingProduct?.with_bundle;
    const existingCartItem = tempCart[existingIndex];

    if (incomingItemHasAddNonBundleFee) {
      tempCart[existingIndex].totalNonBundleAdditional =
        (incomingProduct?.non_bundle_addtl_amt || 0) +
        (tempCart[existingIndex].totalNonBundleAdditional || 0);
    }

    tempCart[existingIndex] = {
      ...existingCartItem,
      ...newProperties,
      amount: incomingProduct?.retail_price || existingCartItem?.amount,
      stocks_left:
        incomingProduct?.stocks_left || existingCartItem?.stocks_left || 100,
    };
  }

  // let cart = _.map(state.cart, (item) => {
  //   const product = action.payload;
  //   if (item.product_slug === product.product_slug) {
  //     return {
  //       ...item,
  //       // message: product?.message,
  //       // with_stock: product.with_stock,
  //       // with_bundle: product?.with_bundle,
  //       // non_bundle_addtl_amt: product?.non_bundle_addtl_amt,
  //       // compatible_mobos: product?.compatible_mobos,
  //       // cpuHasCompatibleMoboInCart: product?.cpuHasCompatibleMoboInCart,

  //       amount: product?.retail_price || item?.amount,
  //       stocks_left: product?.stocks_left || item?.stocks_left || 100,
  //       // totalNonBundleAdditional: product?.cpuHasCompatibleMoboInCart
  //       // ? product?.totalNonBundleAdditional
  //       // : product?.totalNonBundleAdditional,
  //     };
  //   }
  //   return item;
  // });

  state.cart = tempCart;
};

const productsAvailabilityResolve = (state: CartState) => {
  state.productsAvailabilityStatus = SliceStatusValue.resolve;
  state.cartAvailabilityStatus = SliceStatusValue.resolve;
};

const productAvailabilityReject = (
  state: CartState,
  _action: Action<ErrorResponse>
) => {
  state.productsAvailabilityStatus = SliceStatusValue.reject;
};

const addBuildPartsToCart = (state: CartState, action: Action<CartItem[]>) => {
  state.cart = action.payload;
};

const addPCPackageToCart = (state: CartState, action: Action<CartItem[]>) => {
  state.cart = action.payload;
};

const addBuildProductsToCart = (
  state: CartState,
  action: Action<CartItem[]>
) => {
  state.cart = action.payload;
};

const checkoutCartReplace = (state: CartState, action: Action<CartItem[]>) => {
  state.cart = action.payload;
};

const slice = createSlice({
  name: "Cart",
  initialState: { OrderHistoryItem: [], cart: [] } as CartState,
  reducers: {
    addToCart,
    clearCart,
    addQuantity,
    removeFromCart,
    subtractQuantity,

    createOrderRequest,
    createOrderResolve,
    createOrderReject,
    clearCreateOrder,

    productsAvailabilityRequest,
    productSingleAvailabilityResolve,
    productsAvailabilityResolve,
    productAvailabilityReject,

    cartAvailabilityRequest,
    resetCartAvailabilityStates,

    addBuildPartsToCart,

    addPCPackageToCart,
    addBuildProductsToCart,
    checkoutCartReplace,

    updateCustomerShippingInfo,
    clearCustomerShippingInfo,
    setCartStateToUniqueSlugs,
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
