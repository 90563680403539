/* eslint-disable import/prefer-default-export */

import { drjInfo } from "common/constants";
import { isIOS, isAndroid } from "react-device-detect";

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const openLinkWithApps = (url, iosUrl, androidUrl) => {
  if (isIOS && iosUrl) {
    window.open(iosUrl, "_top");
  } else if (isAndroid && androidUrl) {
    window.open(androidUrl, "_top");
  } else {
    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  }
};

export const openFacebookPage = () => {
  if (isIOS) {
    window.open(drjInfo.facebookUrlIOS, "_blank");
  } else if (isAndroid) {
    window.open(drjInfo.facebookUrlAndroid, "_blank");
  } else {
    const newWindow = window.open(drjInfo?.facebookFullUrl, "_blank");
    if (newWindow) newWindow.opener = null;
  }
};

export const openFacebookMessenger = () => {
  if (isIOS) {
    window.open(drjInfo.messenger, "_top");
  } else if (isAndroid) {
    window.open(drjInfo.messenger, "_top");
  } else {
    const newWindow = window.open(drjInfo.messenger, "_blank");
    if (newWindow) newWindow.opener = null;
  }
};

// export const openShopeeStore = () => {
//   const newWindow = window.open("https://shp.ee/j9nghgm", "_blank");
//   if (newWindow) newWindow.opener = null;
// };
