import { createSlice } from "@reduxjs/toolkit";
import { AIBuilderState } from "./types";
import * as thunks from "./thunks";

const initialState: AIBuilderState = {
    AIBuilder: {},
    AIBuilderDetails: {}
}

const slice = createSlice({
    name: "AiBulderState",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(
            thunks.getAiBuilderThunk.pending,
            (state, { payload }) => {
            }
        );
        builder.addCase(
            thunks.getAiBuilderThunk.rejected,
            (state, { payload }) => { }
        );
        builder.addCase(
            thunks.getAiBuilderThunk.fulfilled,
            (state, { payload }) => {
                state.AIBuilder = payload?.originalData || {};
            }
        );
        builder.addCase(
            thunks.getAiBuilderDetailsThunk.pending,
            (state, { payload }) => {
            }
        );
        builder.addCase(
            thunks.getAiBuilderDetailsThunk.rejected,
            (state, { payload }) => { }
        );
        builder.addCase(
            thunks.getAiBuilderDetailsThunk.fulfilled,
            (state, { payload }) => {
                state.AIBuilderDetails = payload?.originalData || {};
            }
        );
    },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };