import { useEffect } from "react";
import { useRouter } from "next/router";
import mixpanel from "mixpanel-browser";
import modules from "modules";

const { utils: trackingUtils, constants: trackingConst } = modules.tracking;

const PageTracker = () => {
  const router = useRouter();

  useEffect(() => {
    const completeHandler = () => {
      mixpanel.track_pageview({ page: window.location.href });
    };

    const errorHandler = () => {
      trackingUtils.trackError(
        trackingConst.pageTracker.error.onPageViewError,
        { page: router.asPath }
      );
    };

    router.events.on("routeChangeComplete", completeHandler);
    router.events.on("routeChangeError", completeHandler);

    return () => {
      router.events.off("routeChangeError", errorHandler);
      router.events.off("routeChangeComplete", completeHandler);
    };
  }, [router]);

  return null;
};

export default PageTracker;
