import { isAndroid, isIOS } from "react-device-detect";
import { useCompanyInfo } from "./useCompanyInfo";
import { drjInfo } from "common";

export const useLinkHandler = () => {
  const { companyInfoData } = useCompanyInfo();

  const openFacebookPage = () => {
    if (isIOS) {
      window.open(
        companyInfoData.facebookUrlIOS || drjInfo.facebookUrlIOS,
        "_blank"
      );
    } else if (isAndroid) {
      window.open(
        companyInfoData.facebookUrlAndroid || drjInfo.facebookUrlAndroid,
        "_blank"
      );
    } else {
      const newWindow = window.open(
        companyInfoData?.facebookFullUrl || drjInfo.facebookFullUrl,
        "_blank"
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  const openFacebookMessenger = () => {
    if (isIOS) {
      window.open(companyInfoData.messenger || drjInfo.messenger, "_top");
    } else if (isAndroid) {
      window.open(companyInfoData.messenger || drjInfo.messenger, "_top");
    } else {
      const newWindow = window.open(
        companyInfoData.messenger || drjInfo.messenger,
        "_blank"
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  const openFbMessengerWithMessage = (message?: string) => {
    if (isIOS) {
      window.open(`http://m.me/PC.WORTH?text=${message}`, "_top");
    } else if (isAndroid) {
      window.open(`http://m.me/PC.WORTH?text=${message}`, "_top");
    } else {
      const newWindow = window.open(
        `http://m.me/PC.WORTH?text=${message}`,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  return {
    openFacebookPage,
    openFacebookMessenger,
    openFbMessengerWithMessage,
  };
};
