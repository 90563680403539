import { getRandomInteger } from "./random";

export const sleep = (milliseconds: number): Promise<void> => {
  if (
    !milliseconds ||
    // NOTE: This is necessary to assert that `milliseconds` isn't a string of letters for instance,
    // which ironically returns `true` with `Number.isNan()`
    Number.isNaN(Number.parseInt(String(milliseconds), 10))
  ) {
    throw Error(`${milliseconds} is not a valid number, which sleep() expects`);
  }
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const getRandomDelay = () => {
  const integer = getRandomInteger(1, 4);
  // delay in millisecond
  const delay = integer * 1000;
  return delay;
};
