import { Api } from "common";
import { PartsCompatiblePayload, PartsCompatibleResponse } from "./types";

const getPartsCompatible = (params: PartsCompatiblePayload) => {
  return Api.post<PartsCompatibleResponse>("api/parts/compatible/get", params);
};

export const PCBuilderApi = {
  getPartsCompatible,
};
