import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import {
  CommonBuildListPayload,
  CommonBuildResponse,
  CreateBuildPayload,
  CreateBuildResponse,
  GetBuildDetailsResponse,
  GetBuildListPayload,
  GetBuildListResponse,
  UpdateBuildDetailsPayload,
} from "./types";
import { MyBuildsAPI } from "./api";

export const saveBuildThunk = createAsyncThunk<
  CommonAxiosResponse<CreateBuildResponse>,
  CreateBuildPayload
>("myBuilds/saveBuildThunk", async (data) => {
  const res = await MyBuildsAPI.savebuild(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getBuildListThunk = createAsyncThunk<
  CommonAxiosResponse<GetBuildListResponse>,
  GetBuildListPayload
>("myBuilds/getBuildList", async (data) => {
  const res = await MyBuildsAPI.getBuildList(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getBuildDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<GetBuildDetailsResponse>,
  CommonBuildListPayload
>("myBuilds/getBuildDetails", async (data) => {
  const res = await MyBuildsAPI.getBuildDetails(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const updateBuildDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<CommonBuildResponse>,
  UpdateBuildDetailsPayload
>("myBuilds/updateBuildDetails", async (data) => {
  const res = await MyBuildsAPI.updateBuildDetails(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const deleteBuildThunk = createAsyncThunk<
  CommonAxiosResponse<CommonBuildResponse>,
  CommonBuildListPayload
>("myBuilds/deleteBuild", async (data) => {
  const res = await MyBuildsAPI.deleteBuild(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});


