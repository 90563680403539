import Mock from "./mock";
import "./server/dashboard";
import "./server/fashion-shop/carouselsDB";
import "./server/fashion-shop/sectionDB";
import "./server/furniture-shop/navigationDB";
import "./server/furniture-shop/productsDB";
import "./server/gadget-shop/carouselsDB";
import "./server/gadget-shop/sectionsDB";
import "./server/gift-shop/navigationDB";
import "./server/gift-shop/productsDB";
import "./server/gift-shop/servicesAndCategoriesDB";
import "./server/grocery1-shop/navigationDB";
import "./server/grocery1-shop/productsDB";
import "./server/grocery1-shop/servicesDB";
import "./server/grocery2-shop/carouselsDB";
import "./server/grocery2-shop/navigationDB";
import "./server/grocery2-shop/sectionDB";
import "./server/grocery3-shop/productsDB";
import "./server/healthbeauty-shop/navigationDB";
import "./server/healthbeauty-shop/productsDB";
import "./server/healthbeauty-shop/servicesDB";
import "./server/related-products/productsDB";
import "./server/superstore-shop/carouselsDB";
import "./server/superstore-shop/sectionsDB";
import "./server/home/sectionsDB";
import "./server/home/carouselsDB";

Mock.onAny().passThrough();
