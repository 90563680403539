import { Api, ApiResponse } from "common";
import {
  PriceListPayloadV2,
  PriceListResponseV2,
  getPriceListResponse,
} from "./types";

const getEUpriceListV2 = (
  params?: PriceListPayloadV2
): ApiResponse<PriceListResponseV2> => {
  return Api.get(`api/ecomm/product/pricelist/get`,params);
};

const getEUpriceList = (): ApiResponse<getPriceListResponse> => {
  return Api.get(`api/products/getEUPriceList`);
};

const downloadEUpriceListPDF = (): ApiResponse<Blob> => {
  return Api.get(
    `api/products/price_list/download`,
    {},
    { responseType: "blob" }
  );
};

export const PriceListApi = {
  getEUpriceList,
  downloadEUpriceListPDF,
  getEUpriceListV2,
};
