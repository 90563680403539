import { RootState, _ } from "common";
import { createSelector } from "reselect";

const selectPcPackageState = (state: RootState) => state.PcPackage;

const selectPcPackageList = createSelector(
  selectPcPackageState,
  (state) => state.pcPackageList
);

const selectPcPackageListLoading = createSelector(
  selectPcPackageState,
  (state) => state.pcPackageListLoading
);

const selectPcPackageDetailData = createSelector(
  selectPcPackageState,
  (state) => state.pcPackageDetailState
);

const selectPcPackageDetailState = createSelector(
  selectPcPackageState,
  (state) => state.pcPackageDetailState
);

const selectPcPackageOnProduct = createSelector(
  selectPcPackageState,
  (state) => state.pcPackagesOnProduct
);

export const selectors = {
  selectPcPackageList,
  selectPcPackageListLoading,
  selectPcPackageDetailData,
  selectPcPackageDetailState,
  selectPcPackageOnProduct,
};
