export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
export const ENV_BRANCH = process.env.NEXT_PUBLIC_BRANCH;
export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME;
export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION;
export const GOOGLE_MAPS_API_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ||
  `AIzaSyCK_m4b8aDghtCbLSuxCfguxVKj45HdF3c`;

export const DEFAULT_HEADERS = {
  "app-name": APP_NAME,
  "app-version": APP_VERSION,
};

export * from "./drj-info";
export * from "./categories";
export * from "./colors";
export * from "./old-sites";
export * from "./social-media-links";
export * from "./copies";
export * from "./maps";
export * from "./localize";
export * from "./banners";
