import { Api, ApiResponse } from "common";
import {
  GetPaymongoMerchantInterestResponse,
  GetSocialLoginBtnOptionResponse,
} from "./types";

const getPaymongoInterest =
  (): ApiResponse<GetPaymongoMerchantInterestResponse> => {
    return Api.get(`api/option/get/paymongo-merchant-interest`);
  };

const getSocialLoginOptions =
  (): ApiResponse<GetSocialLoginBtnOptionResponse> => {
    return Api.get(`api/option/get/ecom-disable-social-login`);
  };

export const PriceListApi = {
  getPaymongoInterest,
  getSocialLoginOptions,
};
