import { socialMediaLinks } from "./social-media-links";

export const drjInfo = {
  name: "DRJ TECHNOLOGIES TRADING CORP.",
  subName: "PC WORTH",
  branchName: "Earnshaw Branch",
  copyright: "Copyright 2023. PC Worth Computer Store",
  location: "618 M. Earnshaw St. Sampaloc Manila",
  businessEmail: "inquire@pcworth.com",
  telNo: "(02)8 656 0586",
  cellNo: "(+63) 967-701-8178",
  cellNo2: "(+63) 998 594 3037",
  viber: "viber://chat/?number=%2B639985943037",  
  viberInvite: "https://invite.viber.com/?g2=AQAzEUVlGhoFKVLnmrskg0u7MHMTUxN1VHacSRAcGO%2Bj0DGLba24Ocp4PrLT7TP8",  
  website: "pcworth.com",
  marketingEmail: "marketing@pcworth.com",
  managementEmail: "inquire@pcworth.com",
  salesSupport: "sales@pcworth.com",
  storeOperation: "10am to 7pm",
  customerSupport: "10am to 9pm",
  coverImg: "/assets/images/branches/earnshaw.png",
  gMapsPin: "https://goo.gl/maps/Gixp8649japUZREf8",
  facebookPageId: "100508121746833",
  // Facebook related
  ...socialMediaLinks,
};

export const drjInfoAlabang = {
  name: "DRJ TECHNOLOGIES TRADING CORP.",
  subName: "PC WORTH",
  branchName: "Alabang Branch",
  copyright: "Copyright 2023. PC Worth Computer Store",
  location: "2nd Flr. Mega Accent Bldg. 479 Alabang-Zapote Road, Brgy. Almanza Uno, Las Piñas City",
  businessEmail: "inquire@pcworth.com",
  telNo: "(02)8 292 9044",
  cellNo: "(+63) 931-062-1294",
  cellNo2: "",
  website: "pcworth.com",
  marketingEmail: "marketing@pcworth.com",
  managementEmail: "inquire@pcworth.com",
  salesSupport: "sales@pcworth.com",
  storeOperation: "10am to 7pm",
  customerSupport: "10am to 9pm",
  coverImg: "/assets/images/branches/alabang.png",
  gMapsPin: "https://goo.gl/maps/7MmLKx4gDcHVac4x9",
  // Facebook related
  ...socialMediaLinks,
};