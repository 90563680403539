import Script from "next/script";
import React from "react";

import { isEnvProduction } from "common/constants/feature-toggles";

export const gtmPush = (event: string, data: any = {}) => {
  const gtmDataLayer = window.dataLayer || [];

  if (gtmDataLayer && gtmDataLayer?.push) {
    gtmDataLayer?.push({ event, ecommData: data });
  }
};

const GoogleTags: React.FC = () => {
  if (!isEnvProduction) {
    return null;
  }

  return (
    <div>
      <Script key="google-tags" id="google-tags">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-52V8ZFRL');
        `}
      </Script>
    </div>
  );
};

export default GoogleTags;
