import { RootState, _ } from "common";
import { createSelector } from "reselect";

const selectBannerState = (state: RootState) => state.Banner;

const selectBanners = createSelector(
  selectBannerState,
  (state) => state.banners
);

const selectBannerLoading = createSelector(
  selectBannerState,
  (state) => state.bannersLoading
);

export const selectors = {
  selectBanners,
  selectBannerLoading,
};
