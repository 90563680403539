export const customers = [
  {
    phone: "+12345678910",
    email: "ethan@gmail.com",
    balance: "$10,350.25",
    orders: "07",
    name: "Ethan Booth",
    image: "/assets/images/avatars/001-man.svg",
  },
  {
    phone: "+12343458910",
    email: "sofia@gmail.com",
    balance: "$12,350.45",
    orders: "02",
    name: "Sofia Hall",
    image: "/assets/images/avatars/002-girl.svg",
  },
  {
    phone: "+12345678910",
    email: "dominic@gmail.com",
    balance: "$11,345.25",
    orders: "03",
    name: "Dominic Moss",
    image: "/assets/images/avatars/002-woman.svg",
  },
  {
    phone: "+12345678910",
    email: "tilly@gmail.com",
    balance: "$9,540.47",
    orders: "04",
    name: "Tilly Thomson",
    image: "/assets/images/avatars/003-boy.svg",
  },
  {
    phone: "+12345678910",
    email: "finley@gmail.com",
    balance: "$7,250.36",
    orders: "09",
    name: "Finley Henry",
    image: "/assets/images/avatars/003-man-1.svg",
  },
  {
    phone: "+12345678910",
    email: "lora@gmail.com",
    balance: "$8,356.34",
    orders: "09",
    name: "Lola Ryan",
    image: "/assets/images/avatars/004-bald.svg",
  },
  {
    phone: "+12345678910",
    email: "gabriel@gmail.com",
    balance: "$4,370.55",
    orders: "12",
    name: "Gabriel McKenzie",
    image: "/assets/images/avatars/004-woman.svg",
  },
  {
    phone: "+12345678910",
    email: "james@gmail.com",
    balance: "$2,458.15",
    orders: "11",
    name: "James Davey",
    image: "/assets/images/avatars/005-man-2.svg",
  },
];
