import modules from "modules";
import { AppSnackBarOption } from "modules/app/types";
import { useAppSelector } from "./use-app-selector";
import { useAppDispatch } from "./use-app-dispatch";

const { actions: appActions, selectors: appSelectors } = modules.app;

export const useSnackBar = () => {
  const dispatch = useAppDispatch();
  const snackBarOptions = useAppSelector(appSelectors.selectSnackBarOptions);

  const show = (data: AppSnackBarOption) => {
    dispatch(appActions.showSnackBar(data));
  };

  const hide = () => {
    dispatch(appActions.hideSnackBar());
  };

  return {
    show,
    hide,
    snackBarOptions,
  };
};
