export const earningHistory = [
  {
    no: 1,
    date: "20-04-2022",
    sellerEarning: 200,
    adminCommission: 5.15,
    orderNo: "5256SD6465D32",
    shopName: "The Beauty Shop",
  },
  {
    no: 2,
    date: "19-04-2022",
    shopName: "The Gainner",
    orderNo: "5256SD6465D33",
    adminCommission: 2.5,
    sellerEarning: 250,
  },
  {
    no: 3,
    date: "17-04-2022",
    orderNo: "5256SD6465D34",
    shopName: "The Beauty Shop",
    adminCommission: 5.15,
    sellerEarning: 200,
  },
  {
    no: 4,
    orderNo: "5256SD6465D35",
    date: "14-04-2022",
    shopName: "The Beauty Shop",
    adminCommission: 2.5,
    sellerEarning: 250,
  },
  {
    no: 5,
    orderNo: "5256SD6465D36",
    date: "08-04-2022",
    shopName: "The Beauty Shop",
    adminCommission: 2.5,
    sellerEarning: 250,
  },
  {
    no: 6,
    orderNo: "5256SD6465D37",
    date: "01-04-2022",
    shopName: "Beyond Threads",
    adminCommission: 2.5,
    sellerEarning: 250,
  },
  {
    no: 7,
    orderNo: "5256SD6465D38",
    date: "26-03-2022",
    shopName: "Beyond Threads",
    adminCommission: 2.5,
    sellerEarning: 250,
  },
  {
    no: 8,
    orderNo: "5256SD6465D39",
    date: "16-03-2022",
    shopName: "Beyond Threads",
    adminCommission: 2.5,
    sellerEarning: 250,
  },
  {
    no: 9,
    orderNo: "5256SD6465D40",
    date: "12-03-2022",
    shopName: "Beyond Threads",
    adminCommission: 2.5,
    sellerEarning: 250,
  },
];
