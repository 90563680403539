import { Api, ApiResponse, CommonAxiosResponse } from "common";
import {
  PcwLoginResponse,
  UpdatePasswordPayload,
  UserEmailOrMobilePayload,
} from "./types";

const getUserLogin = (
  payload: UserEmailOrMobilePayload
): ApiResponse<PcwLoginResponse> => {
  return Api.post(`api/ecomm/user/login`, payload);
};

const userLogout = (): ApiResponse<CommonAxiosResponse> => {
  return Api.post(`api/ecomm/user/account/logout`);
};

const resetUserPassword = (
  payload: UserEmailOrMobilePayload
): ApiResponse<CommonAxiosResponse> => {
  return Api.post(`api/ecomm/user/account/password/reset/otp`, payload);
};

const updatePassword = (
  payload: UpdatePasswordPayload
): ApiResponse<CommonAxiosResponse> => {
  return Api.patch(`api/ecomm/user/account/password/change`, payload);
};

export const PcwUserLoginApi = {
  getUserLogin,
  resetUserPassword,
  updatePassword,
  userLogout,
};
