import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import { PcwLoginApi } from "./api";
import {
  CommonResponse,
  ConfirmAccountPayload,
  EmailVerifyPayload,
  OtpPayload,
  ResendOtpPayload,
  UserRegistrationPayload,
  UserRegistrationResponse,
} from "./types";
import modules from "modules";
import { actionCase } from "./constants";

export const setUserRegistrationThunk = createAsyncThunk<
  CommonAxiosResponse<UserRegistrationResponse>,
  UserRegistrationPayload
>("PcwRegistration/setUserRegistrationThunk", async (data, { dispatch }) => {
  const res = await PcwLoginApi.getUserRegistration(data);
  const toThunk = await thunkResponseHandler(res);
  const dataToken = {
    token_type: "Bearer",
    expires_in: "9999999",
    access_token: toThunk?.originalData?.access_token,
  };
  const { actions: AccessTokenACtion } = modules.oauth;
  dispatch(AccessTokenACtion.clientTokenResolve(dataToken));
  return toThunk;
});

export const setValidateOtpThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponse>,
  OtpPayload
>("PcwRegistration/setValidateOtpThunk", async (data) => {
  const res = await PcwLoginApi.getValidateOtp(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const patchConfirmAccountOtpThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponse>,
  ConfirmAccountPayload
>("PcwRegistration/patchConfirmAccountThunk", async (data, { dispatch }) => {
  const res = await PcwLoginApi.patchAccountConfirmOtp(data);
  const toThunk = thunkResponseHandler(res);
  const { actions: ValidateOtpActions } = modules.pcwRegistration;
  dispatch(
    ValidateOtpActions.showConfirmationDialogue({
      showConfirmation: false,
      actionCase: actionCase.mobile,
    })
  );
  return toThunk;
});

export const patchConfirmAccountEmailThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponse>,
  EmailVerifyPayload
>(
  "PcwRegistration/patchConfirmAccountEmailThunk",
  async (data, { dispatch }) => {
    const res = await PcwLoginApi.patchAccountConfirmEmail(data);
    const toThunk = thunkResponseHandler(res);
    const { actions: ValidateOtpActions } = modules.pcwRegistration;
    dispatch(
      ValidateOtpActions.showConfirmationDialogue({
        showConfirmation: false,
        actionCase: actionCase.email,
      })
    );
    return toThunk;
  }
);

export const setResendOtpThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponse>,
  ResendOtpPayload
>("PcwRegistration/setResendOtpThunk", async (data, { dispatch }) => {
  const res = await PcwLoginApi.setResendOtp(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
