import {
  CartItem,
  CreateOrderPayload,
  GetCompatibleMotherboardsResponse,
  ProductAvailabilityResponse,
} from "./types";
import { cloneDeep } from "lodash";
import { CategoriesIdsEnum } from "common";

export function isProductAvailabilityResponse(
  data: unknown
): data is ProductAvailabilityResponse {
  return !!(data as ProductAvailabilityResponse)?.data;
}

export function isCompatibleMotherboardResponse(
  data: unknown
): data is GetCompatibleMotherboardsResponse {
  return !!(data as GetCompatibleMotherboardsResponse)?.data;
}

const cartItemPayloadHasAdditionalPrice = (item: CartItem) =>
  (item?.category?.toUpperCase() === "CPU" ||
    item?.category_id === CategoriesIdsEnum.CPU) &&
  item?.with_bundle === 0 &&
  item?.totalNonBundleAdditional > 0 &&
  item?.non_bundle_addtl_amt > 0;

/**
 *
 * Convert cart to proper payload.
 * For example, those who have with_bundle logic..
 *
 * @param cart
 *
 */
export const cartToSubmitOrderPayload = (payload: CreateOrderPayload) => {
  let clonedPayload = cloneDeep(payload);
  let productsWithAdditional: CartItem[] = [];

  clonedPayload?.products?.forEach((item, index) => {
    if (cartItemPayloadHasAdditionalPrice(item)) {
      const qtyWithAdditional = Math.round(
        (item?.totalNonBundleAdditional || 1) /
          (item?.non_bundle_addtl_amt || 1)
      );
      const qtyWithoutAdditional = (item?.quantity || 1) - qtyWithAdditional;
      const newItemWithAdditional: CartItem = {
        ...item,
        quantity: qtyWithAdditional,
        with_additional_price: 1,
      };

      productsWithAdditional.push(newItemWithAdditional);
      clonedPayload.products[index].quantity = qtyWithoutAdditional;
    }
  });

  // concat items with additional and with none
  clonedPayload.products = clonedPayload.products.concat(
    productsWithAdditional
  );

  // filter out items with 0 quantity
  clonedPayload.products = clonedPayload.products?.filter(
    (x) => x?.quantity > 0
  );

  // sort alphabetically
  clonedPayload.products?.sort((a, b) =>
    a?.product_name?.localeCompare(b?.product_name)
  );

  // Remove useless properties
  clonedPayload.products = clonedPayload.products?.map((x) => ({
    product_slug: x?.product_slug,
    product_name: x?.product_name,
    amount: x?.amount,
    quantity: x?.quantity,
    with_additional_price: x?.with_additional_price ? 1 : 0,
    img_thumbnail: x?.img_thumbnail,
  }));

  clonedPayload.addtl_fee = 0;

  return clonedPayload;
};

export const utils = {
  cartToSubmitOrderPayload,
  isProductAvailabilityResponse,
  isCompatibleMotherboardResponse,
};
