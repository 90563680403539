import { useCallback } from "react";

import modules from "modules";
import { useAppSelector } from "./use-app-selector";
import { useAppDispatch } from "./use-app-dispatch";
import { fbqTrack } from "utils/FacebookMetaPixel";

const { utils: trackingUtils, constants: trackingConst } = modules.tracking;
const { selectors: productsSelectors, actions: productsActions } =
  modules.products;

export const useSearchBar = () => {
  const dispatch = useAppDispatch();

  /** ========== SELECTORS / VARIABLES / CONSTANTS ========== */
  const searchBarItems = useAppSelector(
    productsSelectors.selectProductsSearchBarItems
  );
  const isSearchBarLoading = useAppSelector(
    productsSelectors.selectProductsSearchBarLoading
  );

  /** ========== FUNCTIONS ========== */
  const searchBarRequest = useCallback(
    async (keyword?: string) => {
      if (keyword?.length > 2) {
        // tracking keywords on mixpanel
        trackingUtils.trackAction(trackingConst.searchBar.event.onSearch, {
          keyword,
        });

        fbqTrack("Search", {
          search_string: keyword,
          content_category: "Product Search Bar",
        });

        //the getSearchBarThunk is depracated
        await dispatch(productsActions.getSearchPageProductsThunk({ keyword }));
      }
    },
    [dispatch]
  );

  return {
    isSearchBarLoading,
    searchBarItems,

    searchBarRequest,
  };
};
