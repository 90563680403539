import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import * as thunks from "./thunks";
import { BuildDetailsWithProducts } from "./types";
import { PaginationLinks, PaginationMeta } from "common/types/pagination";

type State = {
  buildList: BuildDetailsWithProducts[];
  buildPageDetails: BuildDetailsWithProducts;
  configureBuildId: string;
  links?: PaginationLinks;
  meta?: PaginationMeta;
  overwriteBuildParts?: boolean;
};

const initialState: State = {
  buildList: [],
  buildPageDetails: {},
  configureBuildId: "",
  overwriteBuildParts: false,
  links: {},
  meta: {},
};

const setOverwriteBuildParts = (
  state: State,
  action: PayloadAction<boolean>
) => ({ ...state, overwriteBuildParts: action.payload });

const setBuildListItemDetails = (
  state: State,
  action: PayloadAction<number>
) => {
  const buildItemIndex = action.payload;

  const updatedBuildList = state.buildList.map((buildItem, index) => {
    if (index === buildItemIndex) {
      buildItem = { build_id: buildItem.build_id, ...state.buildPageDetails };
    }

    return buildItem;
  });

  return { ...state, buildList: updatedBuildList };
};

const setConfigureBuildId = (state: State, action: PayloadAction<string>) => {
  return { ...state, configureBuildId: action?.payload };
};

const clearConfigureBuildId = (state: State) => {
  return { ...state, configureBuildId: "" };
};

const slice = createSlice({
  name: "Builds",
  initialState,
  reducers: {
    setBuildListItemDetails,
    setConfigureBuildId,
    clearConfigureBuildId,
    setOverwriteBuildParts,
  }, // Will add more soon once needed
  extraReducers(builder) {
    builder.addCase(
      thunks.getBuildListThunk.fulfilled,
      (state, { payload }) => {
        state.buildList = payload?.originalData?.data || [];
        state.links = payload?.originalData?.links || {};
        state.meta = payload?.originalData?.meta || {};
      }
    );
    builder.addCase(
      thunks.getBuildDetailsThunk.fulfilled,
      (state, { payload }) => {
        state.buildPageDetails = payload?.originalData?.data;
      }
    );
  },
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks,
};
