import { Api, ApiResponse, CommonAxiosResponse } from "common";
import {
  CreateAddressPayload,
  GetUserAddressPayload,
  GetUserAddressResponse,
  UpdateAddressPayload,
  GetUserAddressDetailResponse,
  UserAddressIdPayload,
} from "./types";

const createUserAddressApi = (
  payload: CreateAddressPayload
): ApiResponse<CommonAxiosResponse> => {
  return Api.post(`api/ecomm/user/address/create`, payload);
};

const getUserAddressApi = (
  payload: GetUserAddressPayload
): ApiResponse<GetUserAddressResponse> => {
  return Api.get(`api/ecomm/user/address/get`, payload);
};

const getUserAddressDetailsApi = (
  payload: UserAddressIdPayload
): ApiResponse<GetUserAddressDetailResponse> => {
  return Api.get(`api/ecomm/user/address/details/${payload}`);
};

const updateUserAddressApi = (
  payload: UpdateAddressPayload
): ApiResponse<CommonAxiosResponse> => {
  return Api.patch(`api/ecomm/user/address/update/${payload.id}`, payload);
};

const deleteUserAddressApi = (
  payload: UserAddressIdPayload
): ApiResponse<CommonAxiosResponse> => {
  return Api.remove(`api/ecomm/user/address/delete/${payload}`);
};

export const PcwUserAddressApi = {
  createUserAddressApi,
  getUserAddressApi,
  getUserAddressDetailsApi,
  updateUserAddressApi,
  deleteUserAddressApi,
};
