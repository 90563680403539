import { _ } from "common";
import {
  Accessories,
  ACCESSORIES,
  Casing,
  CASING,
  CPU,
  CPU_Cooler,
  CPU_COOLER,
  Fan,
  FAN,
  GPU,
  HDD,
  MEMORY,
  Monitor,
  MONITOR,
  Motherboard,
  MOTHERBOARD,
  PROCESSOR,
  PSU,
  RAM,
  SSD,
  KEYBOARD_MOUSE,
  KeyboardAndMouse,
  KEYBOARD,
  KeyboardItem,
  Mouse,
  MOUSE,
  componentRows,
} from "./constants";
import { ComponentName, LabelValue, PartsCategory, TitleValue } from "./types";

export const mapComponentNameToCategoryName = (
  componentName: ComponentName
): PartsCategory => {
  switch (componentName) {
    case PROCESSOR:
      return CPU;
    case MOTHERBOARD:
      return Motherboard;
    case MEMORY:
      return RAM;
    case CASING:
      return Casing;
    case CPU_COOLER:
      return CPU_Cooler;
    case FAN:
      return Fan;
    case ACCESSORIES:
      return Accessories;
    case MONITOR:
      return Monitor;
    case KEYBOARD_MOUSE:
      return KeyboardAndMouse;
    case KEYBOARD:
      return KeyboardItem;
    case MOUSE:
      return Mouse;
    default:
      return componentName;
  }
};

export const mapCategoryNameToComponentName = (categoryName: PartsCategory) => {
  switch (categoryName) {
    case CPU:
      return PROCESSOR;
    case Motherboard:
      return MOTHERBOARD;
    case RAM:
      return MEMORY;
    case Casing:
      return CASING;
    case CPU_Cooler:
      return CPU_COOLER;
    case Fan:
      return FAN;
    case Monitor:
      return MONITOR;
    case Accessories:
      return ACCESSORIES;
    default:
      return categoryName;
  }
};

const removePartPropertiesImage = (partsProperties: LabelValue[]) => {
  const parts_properties = _.filter(partsProperties, (property) => {
    return (
      property?.value &&
      property?.label !== "image_thumbnail_url" &&
      property?.label !== "image_url" &&
      property?.label !== "Image Thumbnail Url"
    );
  });
  return parts_properties;
};

export const splitPartProperties = (
  partsProperties: LabelValue[] = [],
  customSpecs: TitleValue[] = []
) => {
  const combinedValues = [...partsProperties, ...customSpecs];

  const partsPropertiesAndCustomSpecs = combinedValues.map(
    (item: LabelValue & TitleValue) => ({
      label: item?.label || item?.title,
      value: item.value,
    })
  );
  const parts_properties = removePartPropertiesImage(partsPropertiesAndCustomSpecs);
  const isEmptyPartsProperties = _.isEmpty(parts_properties);

  if (isEmptyPartsProperties) {
    return {
      isEmptyPartsProperties,
      partsPropertiesFirst: [],
      partsPropertiesSecond: [],
    };
  }

  const length = _.size(parts_properties);
  const firstArrayEndIndex = Math.ceil(length * 0.5);
  const secondArrayStartIndex = Math.floor(length * 0.5);
  const partsPropertiesFirst = _.slice(parts_properties, 0, firstArrayEndIndex);
  const partsPropertiesSecond = _.slice(
    parts_properties,
    secondArrayStartIndex,
    length
  );

  return {
    isEmptyPartsProperties,
    partsPropertiesFirst,
    partsPropertiesSecond,
  };
};

export const getComponentNameBgColor = (componentName: PartsCategory) => {
  switch (componentName) {
    case CPU:
      return "#FFECB3";
    case Motherboard:
      return "#62CBFF";
    case RAM:
      return "#B0BEC5";
    case GPU:
      return "lawngreen";
    case SSD:
      return "#C5E1A5";
    case HDD:
      return "#B2EBF2";
    case PSU:
      return "#81D4FA";
    case Casing:
      return "#B39DDB";
    case CPU_Cooler:
      return "#BCAAA4";
    case Fan:
      return "#B2DFDB";
    default:
      return "white";
  }
};

const getComponentNoteFactory = (componentName: ComponentName) => {
  switch (componentName) {
    case PROCESSOR:
      return "Start by choosing your preferred CPU before adding other components.";
    case MOTHERBOARD:
      return "You need to choose a Processor before choosing your Motherboard.";
    case MEMORY:
      return "You need to choose a Motherboard before choosing your Memory modules.";
    default:
      return "";
  }
};

/**
 * Checks componentRows if selected component is multiple
 * eg. ssd, hdd, accessories
 */
export const isComponentMultiple = (categoryName: PartsCategory) => {
  const componentName = mapCategoryNameToComponentName(categoryName);
  const component = componentRows?.find(
    (c) => c?.name === componentName && c?.multiple
  );
  return component?.multiple;
};

export const getTotalViaQtyAndRetailPrice = (
  retailPrice: number = 0,
  qty: number = 0
) => qty * retailPrice;

export const utils = {
  isComponentMultiple,
  splitPartProperties,
  getComponentNameBgColor,
  mapComponentNameToCategoryName,
  mapCategoryNameToComponentName,
  getComponentNoteFactory,
  getTotalViaQtyAndRetailPrice,
};
