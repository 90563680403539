/* cSpell:disable */

export const provinces = [
  { name: "METRO MANILA", region: "NCR", key: "MM" },
  { name: "KALAKHANG MAYNILA", region: "NCR", key: "MM" },
  { name: "MAYNILA", region: "NCR", key: "MM" },
  { name: "MANILA", region: "NCR", key: "MM" },

  { name: "ABRA", region: "CAR", key: "ABR" },
  { name: "APAYAO", region: "CAR", key: "APA" },
  { name: "BENGUET", region: "CAR", key: "BEN" },
  { name: "IFUGAO", region: "CAR", key: "IFU" },
  { name: "KALINGA", region: "CAR", key: "KAL" },
  { name: "MOUNTAIN PROVINCE", region: "CAR", key: "MOU" },

  { name: "ILOCOS NORTE", region: "I", key: "ILN" },
  { name: "ILOCOS SUR", region: "I", key: "ILS" },
  { name: "LA UNION", region: "I", key: "LUN" },
  { name: "PANGASINAN", region: "I", key: "PAN" },

  { name: "BATANES", region: "II", key: "BTN" },
  { name: "CAGAYAN", region: "II", key: "CAG" },
  { name: "ISABELA", region: "II", key: "ISA" },
  { name: "NUEVA VIZCAYA", region: "II", key: "NUV" },
  { name: "QUIRINO", region: "II", key: "QUI" },

  { name: "AURORA", region: "III", key: "AUR" },
  { name: "BATAAN", region: "III", key: "BAN" },
  { name: "BULACAN", region: "III", key: "BUL" },
  { name: "NUEVA ECIJA", region: "III", key: "NUE" },
  { name: "PAMPANGA", region: "III", key: "PAM" },
  { name: "TARLAC", region: "III", key: "TAR" },
  { name: "ZAMBALES", region: "III", key: "ZMB" },

  { name: "BATANGAS", region: "IV-A", key: "BTG" },
  { name: "CAVITE", region: "IV-A", key: "CAV" },
  { name: "LAGUNA", region: "IV-A", key: "LAG" },
  { name: "QUEZON", region: "IV-A", key: "QUE" },
  { name: "RIZAL", region: "IV-A", key: "RIZ" },

  { name: "MARINDUQUE", region: "IV-B", key: "MAD" },
  { name: "OCCIDENTAL MINDORO", region: "IV-B", key: "MDC" },
  { name: "ORIENTAL MINDORO", region: "IV-B", key: "MDR" },
  { name: "PALAWAN", region: "IV-B", key: "PLW" },
  { name: "ROMBLON", region: "IV-B", key: "ROM" },

  { name: "ALBAY", region: "V", key: "ALB" },
  { name: "CAMARINES NORTE", region: "V", key: "CAN" },
  { name: "CAMARINES SUR", region: "V", key: "CAS" },
  { name: "CATANDUANES", region: "V", key: "CAT" },
  { name: "MASBATE", region: "V", key: "MAS" },
  { name: "SORSOGON", region: "V", key: "SOR" },

  { name: "AKLAN", region: "VI", key: "AKL" },
  { name: "ANTIQUE", region: "VI", key: "ANT" },
  { name: "CAPIZ", region: "VI", key: "CAP" },
  { name: "GUIMARAS", region: "VI", key: "GUI" },
  { name: "ILOILO", region: "VI", key: "ILI" },
  { name: "NEGROS OCCIDENTAL", region: "VI", key: "NEC" },

  { name: "BOHOL", region: "VII", key: "BOH" },
  { name: "CEBU", region: "VII", key: "CEB" },
  { name: "NEGROS ORIENTAL", region: "VII", key: "NER" },
  { name: "SIQUIJOR", region: "VII", key: "SIG" },

  { name: "BILIRAN", region: "VIII", key: "BIL" },
  { name: "EASTERN SAMAR", region: "VIII", key: "EAS" },
  { name: "LEYTE", region: "VIII", key: "LEY" },
  { name: "NORTHERN SAMAR", region: "VIII", key: "NSA" },
  { name: "SAMAR", region: "VIII", key: "WSA" },
  { name: "SOUTHERN LEYTE", region: "VIII", key: "SLE" },

  { name: "ZAMBOANGA DEL NORTE", region: "IX", key: "ZAN" },
  { name: "ZAMBOANGA DEL SUR", region: "IX", key: "ZAS" },
  { name: "ZAMBOANGA SIBUGAY", region: "IX", key: "ZSI" },

  { name: "BUKIDNON", region: "X", key: "BUK" },
  { name: "CAMIGUIN", region: "X", key: "CAM" },
  { name: "LANAO DEL NORTE", region: "X", key: "LAN" },
  { name: "MISAMIS OCCIDENTAL", region: "X", key: "MSC" },
  { name: "MISAMIS ORIENTAL", region: "X", key: "MSR" },

  { name: "COMPOSTELA VALLEY", region: "XI", key: "COM" },
  { name: "DAVAO DEL NORTE", region: "XI", key: "DAV" },
  { name: "DAVAO DEL SUR", region: "XI", key: "DAS" },
  { name: "DAVAO OCCIDENTAL", region: "XI", key: "DAC" },
  { name: "DAVAO ORIENTAL", region: "XI", key: "DAO" },

  { name: "COTABATO", region: "XII", key: "NCO" },
  { name: "SARANGANI", region: "XII", key: "SAR" },
  { name: "SOUTH COTABATO", region: "XII", key: "SCO" },
  { name: "SULTAN KUDARAT", region: "XII", key: "SUK" },

  { name: "AGUSAN DEL NORTE", region: "XIII", key: "AGN" },
  { name: "AGUSAN DEL SUR", region: "XIII", key: "AGS" },
  { name: "DINAGAT ISLANDS", region: "XIII", key: "DIN" },
  { name: "SURIGAO DEL NORTE", region: "XIII", key: "SUN" },
  { name: "SURIGAO DEL SUR", region: "XIII", key: "SUR" },

  { name: "BASILAN", region: "ARMM", key: "BAS" },
  { name: "LANAO DEL SUR", region: "ARMM", key: "LAS" },
  { name: "MAGUINDANAO", region: "ARMM", key: "MAG" },
  { name: "SULU", region: "ARMM", key: "SLU" },
  { name: "TAWI-TAWI", region: "ARMM", key: "TAW" },
];
