import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { pcwMiddleware } from "./pcwMiddleware";
import { rootReducer } from "./rootReducer";
import { rootSaga } from "./rootSaga";
import thunk from "redux-thunk";

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: true, serializableCheck: false })
        .concat(sagaMiddleware)
        .concat(thunk),
  });
  sagaMiddleware.run(rootSaga);
  return store;
};

export const store = createStore();
export const persistor = persistStore(store);
