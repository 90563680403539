// Used overall the app but interestPercentage is used for customized percentageInterest from api options
export const promoPriceToSRP = (
  num: number | undefined | null,
  percentageInDecimal = 0.12
) => {
  let retailPrice = num || 0;
  const toAddIfSRP = retailPrice * percentageInDecimal;

  return retailPrice + toAddIfSRP;
};

// Used overall the app but interestPercentage is used for customized percentageInterest from api options
export const promoPriceDiscounted = (
  num: number | undefined | null,
  percentageInDecimal = 0.12
) => {
  const retailPrice = num || 0;
  return retailPrice * percentageInDecimal;
};

export const isValidCurrency = (amount: string) => {
  return true;
};

export const formatCurrency = (
  amount: any,
  // eslint-disable-next-line no-unused-vars
  symbol: string = "₱",
  decimal = 2,
  // extraOptions?: ExtraOptionsType
  extraOptions?: Record<string, unknown>
) => {
  // Sometimes, 'strings' can still pass amount type.
  // and will error TypeError: amount.toFixed is not a function
  // in that case convert amount params type into number
  if (typeof amount !== "number") {
    if (isValidCurrency(amount)) {
      formatCurrency(+amount);
    } else {
      return "";
    }
  }
  if (amount === null || amount === undefined) {
    return "";
  }
  // Create our number formatter.
  const formatter = new Intl.NumberFormat("en-PH", {
    style: "currency",
    currency: "PHP",
    minimumFractionDigits: decimal,
    ...extraOptions,
  });

  let currencyInStr = `${formatter.format(amount)}`;
  if (extraOptions?.removeCurrency) {
    currencyInStr = currencyInStr.substring(1);
  }

  return currencyInStr;
};

export const formatCurrencySRP = (amount: number) => {
  return formatCurrency(promoPriceToSRP(amount));
};

export const toTitleCase = (str: string) => {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const onCopyText = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const currencyToNumber = (str?: string) => {
  return Number(str?.replace(/[^0-9.-]/g, ""));
};

export const productDetailsPageUrl = (slug?: string, product_uuid?: string) => {
  return `/product/${slug}${product_uuid ? `?q=${product_uuid}` : ""}`;
};

export const slugToNameUtil = (slug: string) => {
  const parts = slug.split("-");

  const formattedParts = parts.map((part) => {
    return part.toUpperCase();
  });

  const formattedSlug = formattedParts.join(" ");

  return formattedSlug;
};

export const standardizeMobileNumber = (input: string) => {
  // Remove non-digit characters
  let digits: string | null = input?.replace(/\D/g, "");

  // Check the length and format
  if (digits?.length === 10 && digits?.startsWith("9")) {
    // If it's 10 digits, add '63' at the beginning
    digits = "63" + digits;
  } else if (digits?.length === 11 && digits?.startsWith("0")) {
    // If it's 11 digits and starts with '0', replace '0' with '63'
    digits = "63" + digits?.substring(1);
  } else if (digits?.length !== 12) {
    // If the length is not 10, 11, or 12, return an error message or handle accordingly
    digits = null;
  }

  return digits;
};
