import { DeliveryOptionsEnum } from "common";
import {
  DeliveryOption,
  JntIslandType,
  ShippingFeeComputationOption,
} from "common/types/checkout";
import { CartItem } from "modules/cart/types";
import { _ } from "common";
import { categoriesShippingFee } from "common/constants/categories-shipping-fee";
import { allowedDeliveryOptionsForCOD, deliveryWithoutShippingFee } from "common/constants/maps/delivery";

export const computeShippingFeeTechOut = (km: number = 1, cart: CartItem[]) => {
  // NOTE: Tech Out is PHP100 base fare and PHP15 IN EVERY KM.

  // If is less than ONE make it ONE
  let roundedOffKm = km;
  if (km < 1) {
    roundedOffKm = 1;
  }
  const techOutBaseFare = 100;
  const farePerKm = 17;
  const fareWithKmAndBase = farePerKm * roundedOffKm + techOutBaseFare;
  // const roundedOffFare = Math.round(fareWithKmAndBase);
  return fareWithKmAndBase || 0;
};

export const getJntCartItemSF = (cartItem: CartItem) => {
  const qty = cartItem?.quantity || 1;
  const defaultShippingFee = 250; // If category is not found lets assume it will be 250

  if (cartItem?.category_id) {
    const res = categoriesShippingFee?.find(
      (x) => x?.id === cartItem?.category_id
    );
    if (res) {
      const itemShippingFee = res?.shipping_fee || defaultShippingFee;
      const itemSFwithQty = itemShippingFee * qty;
      return itemSFwithQty;
    }
  }

  // Some endpoints returns category name only. if category_id is undefined lets use the name
  // let categoryId = cartItem?.category_id;
  if (!cartItem?.category_id && cartItem?.category) {
    const categoryNameUpperCase = cartItem?.category?.toUpperCase();
    const res = categoriesShippingFee?.find(
      (categ) => categ?.name?.toUpperCase() === categoryNameUpperCase
    );
    if (res) {
      const itemShippingFee = res?.shipping_fee || defaultShippingFee;
      const itemSFwithQty = itemShippingFee * qty;
      return itemSFwithQty;
    }
  }

  return defaultShippingFee;
};

export const computeShippingFeeJNT = (
  cart: CartItem[],
  island: JntIslandType // TODO: Later on if we have kg of items.
) => {
  // TODO: Compute base on island
  const cartItemsTotalSF = cart?.reduce((acc, item) => {
    const toBeAdded = getJntCartItemSF(item);
    return toBeAdded + acc;
  }, 0);

  const cartTotalAmount = _.reduce(
    cart,
    (acc, cartItem) => {
      const total = acc + cartItem.amount * cartItem.quantity;
      return total;
    },
    0
  );

  const onePercentOfCartTotal = 0.01 * cartTotalAmount;
  const jntGrandTotalSF = cartItemsTotalSF + onePercentOfCartTotal;
  const roundedOffFare = Math.round(jntGrandTotalSF);

  return roundedOffFare || 0;
};

export const shippingFeeCanBeComputed = (
  options: ShippingFeeComputationOption
) => {
  if (!options?.cart || _.isEmpty(options?.cart)) {
    console.error("Cart is empty");
    return false;
  }
  if (!options?.deliveryOption) {
    console.error("deliveryOption is falsy");
    return false;
  }
  if (options?.km === 0) {
    console.error("distance in Km is zero.");
    return false;
  }
  if (
    options?.deliveryOption === DeliveryOptionsEnum.techOut &&
    (options?.km === undefined || options?.km === null)
  ) {
    console.error("techout but km is missing");
    return false;
  }
  // if (
  //   options?.deliveryOption === DeliveryOptionsEnum.jnt &&
  //   !options?.jntIsland
  // ) {
  //   console.error("jnt but jntIsland is missing");
  //   return false;
  // }
  return true;
};

export const computeShippingFee = (options: ShippingFeeComputationOption) => {
  if (!shippingFeeCanBeComputed(options)) {
    // NOTE:Something is wrong in params
    return 0;
  }
  if (options?.deliveryOption === DeliveryOptionsEnum.techOutFree) {
    return 0;
  }
  if (options?.deliveryOption === DeliveryOptionsEnum.techOut) {
    const origTechOutShippingFee = computeShippingFeeTechOut(
      options?.km,
      options?.cart
    );
    const roundedTechOutShippingFee = Math.round(origTechOutShippingFee);
    return roundedTechOutShippingFee;
  }
  if (options?.deliveryOption === DeliveryOptionsEnum.jnt) {
    const origShippingFee = computeShippingFeeJNT(options?.cart, "NCR"); // TODO: Fix jntIslands later on
    const roundedJntShippingFee = Math.round(origShippingFee);
    return roundedJntShippingFee;
  }
  return 0;
};

export const isDeliveryOptionFree = (deliveryMode: DeliveryOption) => {
  return deliveryWithoutShippingFee?.includes(deliveryMode);
};

export const isDeliveryOptionAllowsCOD = (deliveryMode: DeliveryOption) => {
  return allowedDeliveryOptionsForCOD?.includes(deliveryMode);
};
