import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import { KachiPointsResponse } from "./types";
import { KachiPointsApi } from "./api";

export const getKachiPointsThunks = createAsyncThunk<
  CommonAxiosResponse<KachiPointsResponse>,
  undefined
>("kachiPoints/getKachiPointsThunks", async () => {
  const res = await KachiPointsApi.getKachiPoints({});
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getEarnedHistoryThunk = createAsyncThunk<
  CommonAxiosResponse<KachiPointsResponse>,
  undefined
>("kachiPoints/getEarnedHistoryThunk", async () => {
  const res = await KachiPointsApi.getKachiPoints({
    earned_points: true,
  });
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getSpendingHistoryThunk = createAsyncThunk<
  CommonAxiosResponse<KachiPointsResponse>,
  undefined
>("kachiPoints/getSpendingHistoryThunk", async () => {
  const res = await KachiPointsApi.getKachiPoints({
    spending_points: true,
  });
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
