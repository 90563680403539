import { Api, ApiResponse } from "common";
import {
  UserBasic,
  UserSocialDataPayload,
  UserSocialLoginResponse,
  UserSocialResponse,
  userLoginPayload,
} from "./types";
import { stringify } from "querystring";

const userSocialCallback = (
  payload: UserSocialDataPayload
): ApiResponse<UserSocialResponse> => {
  return Api.post(`api/ecomm/user/social/callback`, payload);
};

const userSocialLogin = (
  email: userLoginPayload
): ApiResponse<UserSocialLoginResponse> => {
  return Api.post(`api/ecomm/user/login/social`, email);
};

export const PcwSocialProfileApi = {
  userSocialCallback,
  userSocialLogin,
};
