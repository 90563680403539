import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import { BannersApi } from "./api";
import { GetBannersResponse } from "./types";

export const getBannersThunk = createAsyncThunk<
  CommonAxiosResponse<GetBannersResponse>,
  undefined
>("Banner/getBannersThunk", async () => {
  const res = await BannersApi.getBanner();
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
