import {
  Box,
  Card,
  CardProps,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import BazarButton from "components/BazarButton";
import CloseIcon from "@mui/icons-material/Close";
import BazarTextField from "components/BazarTextField";
import { H3, H6, Small } from "components/Typography";
import { useFormik } from "formik";
import React, { useCallback, useState } from "react";
import * as yup from "yup";
import EyeToggleButton from "./EyeToggleButton";
import SocialButtons from "./SocialButtons";
import LazyImage from "components/LazyImage";
import { FlexBox, FlexRowCenter } from "components/flex-box";
import modules from "modules";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { unwrapResult } from "@reduxjs/toolkit";
import { pcwVerifyLoginPayload } from "utils/pcw-account/login-registration-utils";
import ForgotPassword from "./ForgotPasswordForm";
import { useRouter } from "next/router";
import { useDialogueShow } from "hooks/useDialogue";
import { useCart } from "hooks/use-cart";
import { userProfileClass } from "modules/pcw-profile";
import { navigationRoutes } from "common/constants/navigation-routes";
import { useAppSelector } from "hooks/use-app-selector";

const fbStyle = { background: "#3B5998", color: "white" };
const googleStyle = { background: "#4285F4", color: "white" };

const { actions: PcwLoginActions, selectors: PcwLoginSelectors } =
  modules.pcwUserLogin;
const { actions: PcwRegistrationActions } = modules.pcwRegistration;
const { actions: PcwProfileActions } = modules.pcwUserProfile;
const { utils: trackingUtils, constants: trackingConst } = modules.tracking;
const { selectors: optionsSelectors } = modules.options;

type WrapperProps = { passwordVisibility?: boolean };

export const Wrapper = styled<React.FC<WrapperProps & CardProps>>(
  ({ children, passwordVisibility, ...rest }) => (
    <Card {...rest}>{children}</Card>
  )
)<CardProps>(({ theme, passwordVisibility }) => ({
  width: 500,
  padding: "2rem 3rem",
  [theme.breakpoints.down("sm")]: { width: "100%" },
  ".passwordEye": {
    color: passwordVisibility
      ? theme.palette.grey[600]
      : theme.palette.grey[400],
  },
  ".facebookButton": { marginBottom: 10, ...fbStyle, "&:hover": fbStyle },
  ".googleButton": { ...googleStyle, "&:hover": googleStyle },
  ".agreement": { marginTop: 12, marginBottom: 24 },
}));

type LoginProps = {
  signUpOpen: () => void;
  loginClose?: (param: boolean) => void;
};

const Login: React.FC<LoginProps> = ({}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const socialBtnOption = useAppSelector(
    optionsSelectors?.selectSocialBtnOptions
  );
  const fbLoginStatusMsg = useAppSelector(
    PcwLoginSelectors?.selectFacebookLoginMessage
  );
  const { showSignUpFormOpen, showLoginFormClose } = useDialogueShow();
  const { clearCart } = useCart();

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [loginErr, getLoginErr] = useState<any>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pwordOpen, setPwordOpen] = useState<boolean>(false);

  const toggleForgotPword = () => {
    setPwordOpen(!pwordOpen);
  };

  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisibility((visible) => !visible);
  }, []);

  const handleFormSubmit = async (values: {
    emailOrPhone: string;
    password: string;
  }) => {
    setIsLoading(true);
    const loginPayload = pcwVerifyLoginPayload(values);
    const response = unwrapResult(
      await dispatch(
        PcwLoginActions?.setPcwUserLoginThunk(loginPayload?.emailOrPhone)
      )
    );
    if (response?.success && !response?.originalData?.is_pending) {
      getLoginErr(null);
      setIsLoading(false);
      showLoginFormClose();
      //get profile initial login
      await dispatch(
        PcwProfileActions?.pcwUserProfileThunk({
          class: userProfileClass.all,
        })
      );
      //clean cart
      clearCart();
      //set user login status true
      trackingUtils.setIdentity(
        values?.emailOrPhone,
        response?.originalData?.data
      );

      trackingUtils.trackEvent(trackingConst.loginDialog.event.onSuccessLogin, {
        identity: values?.emailOrPhone,
      });
      router?.push(navigationRoutes.pcwProfile);
    }
    if (response?.message && !response?.success) {
      getLoginErr(response?.message);
      setIsLoading(false);
    }
    if (response?.originalData?.is_pending) {
      dispatch(
        PcwRegistrationActions?.showConfirmationDialogue({
          showConfirmation: true,
          actionCase: loginPayload?.validation,
          loginMobile: values?.emailOrPhone,
        })
      );
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit: handleFormSubmit,
      validationSchema: formSchema,
    });

  const showForgotPassword = async () => {
    await dispatch(PcwLoginActions?.showPasswordDialogue(true));
  };

  const showSignUp = () => {
    showSignUpFormOpen();
  };

  return (
    <Wrapper
      elevation={3}
      passwordVisibility={passwordVisibility}
      sx={{
        width: "440px",
        maxWidth: "400px",
        padding: 2.5,
      }}
    >
      <div
        style={{
          position: "relative",
          float: "right",
          marginTop: "-20px",
          marginRight: "-15px",
        }}
      >
        {" "}
        <IconButton onClick={showLoginFormClose}>
          <CloseIcon
            sx={{ color: "primary" }}
            classes={{ colorPrimary: "primary" }}
          />
        </IconButton>
      </div>
      <FlexBox justifyContent={"center"} alignItems={"center"} mt={3}>
        <LazyImage src="/assets/images/logo.png" width={50} height={50} />
      </FlexBox>

      <form onSubmit={handleSubmit}>
        <H3 textAlign="center" mb={2} fontSize={17}>
          Welcome To PC Worth
          <span
            style={{
              display: "inline-block",
              marginLeft: "5px",
              fontSize: "10px",
              fontWeight: "600",
              color: "grey.800",
            }}
          >
            (Beta)
          </span>
        </H3>

        <BazarTextField
          mb={1.5}
          fullWidth
          name="emailOrPhone"
          size="small"
          variant="outlined"
          onBlur={handleBlur}
          value={values.emailOrPhone}
          onChange={handleChange}
          label="Email or Phone Number"
          placeholder="example@mail.com"
          error={!!touched.emailOrPhone && !!errors.emailOrPhone}
          helperText={touched.emailOrPhone && errors.emailOrPhone}
        />

        <BazarTextField
          mb={2}
          fullWidth
          size="small"
          name="password"
          label="Password"
          autoComplete="on"
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password}
          placeholder="*********"
          type={passwordVisibility ? "text" : "password"}
          error={!!touched.password && !!errors.password}
          helperText={touched.password && errors.password}
          InputProps={{
            endAdornment: (
              <EyeToggleButton
                show={passwordVisibility}
                click={togglePasswordVisibility}
              />
            ),
          }}
        />
        <Small
          mb={1}
          sx={{ textAlign: "center", display: "block", color: "red" }}
        >
          {loginErr ? loginErr : null}
        </Small>

        <BazarButton
          fullWidth
          size="medium"
          type="submit"
          color="secondary"
          variant="contained"
          sx={{ mb: 0.5, height: 44 }}
        >
          {isLoading ? <CircularProgress /> : "Login"}
        </BazarButton>
      </form>
      <Small
        mt={1}
        mb={1}
        sx={{ textAlign: "center", display: "block", color: "red" }}
      >
        {fbLoginStatusMsg}
      </Small>
      {socialBtnOption == 3 ? null : (
        <SocialButtons socialOpt={socialBtnOption} />
      )}

      <FlexRowCenter my="1.25rem">
        <Box>{"Don't have account yet?"}</Box>
        <H6
          ml={1}
          borderBottom="1px solid"
          borderColor="grey.900"
          onClick={showSignUp}
          sx={{ cursor: "pointer" }}
        >
          {"Sign Up"}
        </H6>
      </FlexRowCenter>

      <FlexBox
        justifyContent="center"
        bgcolor="grey.200"
        borderRadius="4px"
        py={2.5}
      >
        Forgot your password?
        <H6
          ml={1}
          borderBottom="1px solid"
          borderColor="grey.900"
          onClick={showForgotPassword}
          sx={{ cursor: "pointer" }}
        >
          Reset It
        </H6>
      </FlexBox>

      <Dialog open={pwordOpen} onClose={toggleForgotPword}>
        <DialogContent sx={{ margin: 0, padding: 0 }}>
          <ForgotPassword />
        </DialogContent>
      </Dialog>
      <div style={{ fontSize: "10px", marginTop: "10px" }}>
        By logging in, I confirm that I am at least eighteen years old, accept
        our{" "}
        <Link href={"/privacy-policy "}>
          <a
            style={{ color: "#2F80ED" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            privacy policy,
          </a>
        </Link>{" "}
        and certify that all the information provided above is accurate to the
        best of my knowledge and belief.
      </div>
    </Wrapper>
  );
};

const initialValues = {
  emailOrPhone: "",
  password: "",
};

const formSchema = yup.object().shape({
  password: yup.string().required("Password is required"),
  emailOrPhone: yup
    .string()
    .required("Field is required")
    .test("emailOrPhoneNumber", "Invalid email or phone number", (value) => {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const phoneRegex = /^\d{11}$/;
      if (!value) {
        return true;
      }
      return emailRegex.test(value) || phoneRegex.test(value);
    }),
});

export default Login;
