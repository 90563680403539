import { RootState, _ } from "common";
import { createSelector } from "reselect";

const selectLocationState = (state: RootState) => state.Location;

const selectRegions = createSelector(
  selectLocationState,
  (state) => state.regions
);

const selectRegionsLoading = createSelector(
  selectLocationState,
  (state) => state.regionsLoading
);

const selectProvinces = createSelector(
  selectLocationState,
  (state) => state.provinces
);

const selectProvincesLoading = createSelector(
  selectLocationState,
  (state) => state.provincesLoading
);

const selectCities = createSelector(
  selectLocationState,
  (state) => state.cities
);

const selectCitiesLoading = createSelector(
  selectLocationState,
  (state) => state.citiesLoading
);

const selectBarangays = createSelector(
  selectLocationState,
  (state) => state.barangays
);

const selectBarangaysLoading = createSelector(
  selectLocationState,
  (state) => state.barangaysLoading
);

const selectRegionsError = createSelector(
  selectLocationState,
  (state) => state.isRegionsError
);

const selectProvincesError = createSelector(
  selectLocationState,
  (state) => state.isProvincesError
);

const selectCitiesError = createSelector(
  selectLocationState,
  (state) => state.isCitiesError
);

const selectBarangaysError = createSelector(
  selectLocationState,
  (state) => state.isBarangaysError
);

export const selectors = {
  selectRegions,
  selectCities,
  selectProvinces,
  selectBarangays,
  selectRegionsLoading,
  selectCitiesLoading,
  selectProvincesLoading,
  selectBarangaysLoading,
  selectRegionsError,
  selectProvincesError,
  selectCitiesError,
  selectBarangaysError,
};
