import modules from "modules";
import { useAppSelector } from "./use-app-selector";
import { useAppDispatch } from "./use-app-dispatch";

const { selectors: UserLoginSelectors, actions: UserLoginActions } =
  modules.pcwUserLogin;
const { selectors: showDialogueSelectors } = modules.pcwRegistration;
export const useDialogueShow = () => {
  const dispatch = useAppDispatch();

  //=======use for registration=========
  const showDialogueEmail = useAppSelector(
    showDialogueSelectors.selectShowEmailDialogue
  );
  const showDialogueMobile = useAppSelector(
    showDialogueSelectors.selectShowMobileDialogue
  );
  //=======

  const showFpwordDialogue = useAppSelector(
    UserLoginSelectors.selectShowForgotPassword
  );

  const showResetPasswordOtp = useAppSelector(
    UserLoginSelectors.selectShowResetPasswordOtp
  );

  const showUpdatePasswordForm = useAppSelector(
    UserLoginSelectors.selectUpdatePasswordForm
  );

  const showLoginForm = useAppSelector(UserLoginSelectors.selectShowLoginForm);

  const showSignUpForm = useAppSelector(
    UserLoginSelectors.selectShowSignUpForm
  );

  const showEmailVerify = useAppSelector(UserLoginSelectors.selectEmailVerify);

  const showMobileVerify = useAppSelector(
    UserLoginSelectors.selectMobileVerify
  );

  //=== All dispatched action here is use to close the dialoguetherefore the default action is false ===
  const showForgotPasswordClose = () => {
    dispatch(UserLoginActions?.showPasswordDialogue(false));
  };

  const showUpdatePasswordFormClose = () => {
    dispatch(UserLoginActions?.showUpdatePasswordForm({ isOpen: false }));
  };

  const showResetPasswordOtpClose = () => {
    dispatch(UserLoginActions?.showPasswordOtp({ isOpen: false }));
  };

  const showLoginFormOpen = () => {
    dispatch(UserLoginActions?.showLoginForm(true));
  };

  const showSignUpFormOpen = () => {
    dispatch(UserLoginActions?.showSignUpForm(true));
  };

  const showLoginFormClose = () => {
    dispatch(UserLoginActions?.showLoginForm(false));
  };

  const showSignUpFormClose = () => {
    dispatch(UserLoginActions?.showSignUpForm(false));
  };

  return {
    showFpwordDialogue,
    showDialogueEmail,
    showDialogueMobile,
    showResetPasswordOtp,
    showUpdatePasswordForm,
    showLoginForm,
    showSignUpForm,
    showEmailVerify,
    showMobileVerify,

    showLoginFormOpen,
    showSignUpFormOpen,
    showForgotPasswordClose,
    showUpdatePasswordFormClose,
    showResetPasswordOtpClose,
    showLoginFormClose,
    showSignUpFormClose,
  };
};
