import {
  Action,
  isFailResponseData,
  isSuccessResponseData,
  Response,
} from "common";
import { call, put, select, StrictEffect } from "redux-saga/effects";
import { CategoryApi } from "./api";
import { selectors as categorySelectors } from "./selectors";
import { actions } from "./slice";
import { CategoriesPayload, CategoriesResponse } from "./types";

export function* categoriesSaga(
  action: Action<CategoriesPayload>
): Generator<StrictEffect, void, Response<CategoriesResponse>> {
  let data = null;
  let problem = null;
  try {
    const response = yield call(CategoryApi.getCategories, action.payload);
    data = response.data;
    problem = response.problem;
  } catch (error) {
    yield put(actions.categoriesReject());
  }

  if (!data) {
    yield put(actions.categoriesReject(problem));
    return;
  }

  if (isFailResponseData(data)) {
    yield put(actions.categoriesReject(data));
    return;
  }

  if (isSuccessResponseData(data)) {
    yield put(actions.categoriesResolve(data));
  }
}

export const sagas = {
  categoriesSaga,
};
