import { ClientTokenResponse } from "./types";

export const isClientTokenData = (
  data: unknown
): data is ClientTokenResponse => {
  const clientTokenData = data as ClientTokenResponse;
  return !!clientTokenData?.access_token;
};

export const utils = {
  isClientTokenData,
};
