import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action } from "common";
import * as thunks from "./thunks";
import { CheckoutCart, UserCartState } from "./types";
import _ from "lodash";

const initialState: UserCartState = {
  userCartItem: [],
  userCartItemWithoutStock: [],
  cartLength: 0,
  isCartItemLoading: false,
  checkOutCartState: [],
  cartLoading: false,
};

const addCheckoutCartItem = (
  state: UserCartState,
  action: Action<CheckoutCart>
) => {
  const clonedCartState = _.cloneDeep(state.checkOutCartState);
  const newItemToBeInsertedInCart = action.payload;
  const indexOfExistingItem = state.checkOutCartState?.findIndex(
    (x) => x?.product_slug === newItemToBeInsertedInCart?.product_slug
  );

  if (indexOfExistingItem > -1) {
    // If item already in cart just add the existing quantity
    const toBeInsertedQty = newItemToBeInsertedInCart?.quantity || 0;
    const currentQty = clonedCartState[indexOfExistingItem]?.quantity || 0;
    const theNewQty = currentQty + toBeInsertedQty;

    if (
      theNewQty > (clonedCartState[indexOfExistingItem]?.stocks_left || 999)
    ) {
      clonedCartState[indexOfExistingItem].quantity =
        clonedCartState[indexOfExistingItem]?.stocks_left;
    } else {
      clonedCartState[indexOfExistingItem].quantity =
        currentQty + toBeInsertedQty;
    }
    state.checkOutCartState = clonedCartState;
    return;
  }

  if (state.checkOutCartState) {
    state.checkOutCartState = state.checkOutCartState.concat(
      newItemToBeInsertedInCart
    );
    return;
  }
  state.checkOutCartState = [newItemToBeInsertedInCart];
};

const checkoutCartMultiple = (
  state: UserCartState,
  action: Action<CheckoutCart[]>
) => {
  if (state.checkOutCartState) {
    state.checkOutCartState = [...state.checkOutCartState, ...action.payload];
  } else {
    state.checkOutCartState = action.payload;
  }
};

const removeCheckoutCartItem = (
  state: UserCartState,
  action: Action<{ product_slug?: string }>
) => {
  const { product_slug } = action.payload;
  const cart = _.filter(state.checkOutCartState, (item, checkIndex) => {
    if (product_slug) {
      return item?.product_slug !== action.payload?.product_slug;
    }
  });
  state.checkOutCartState = cart;
};

const addCartItemQuantity = (
  state: UserCartState,
  action: Action<{ cart_item_id: number; product_slug: string }>
) => {
  const clonedCartState = _.cloneDeep(state.userCartItem);
  const clonedCheckoutCart = _.cloneDeep(state.checkOutCartState);

  const cart = _.map(clonedCartState, (cartItem) => {
    if (cartItem.cart_id === action.payload?.cart_item_id) {
      cartItem.quantity++;
    }
    return cartItem;
  });

  const checkoutCart = _.map(clonedCheckoutCart, (checkoutItem) => {
    if (checkoutItem.product_slug === action.payload?.product_slug) {
      checkoutItem.quantity++;
    }
    return checkoutItem;
  });
  state.userCartItem = cart;
  state.checkOutCartState = checkoutCart;
};

const subtractCartItemQuantity = (
  state: UserCartState,
  action: Action<{ cart_item_id: number; product_slug: string }>
) => {
  const clonedCartState = _.cloneDeep(state.userCartItem);
  const clonedCheckoutCart = _.cloneDeep(state.checkOutCartState);

  const cart = _.map(clonedCartState, (cartItem) => {
    if (cartItem.cart_id === action.payload?.cart_item_id) {
      cartItem.quantity--;
    }
    return cartItem;
  });

  const checkoutCart = _.map(clonedCheckoutCart, (checkoutItem) => {
    if (checkoutItem.product_slug === action.payload?.product_slug) {
      checkoutItem.quantity--;
    }
    return checkoutItem;
  });
  state.userCartItem = cart;
  state.checkOutCartState = checkoutCart;
};

const removeFromCart = (
  state: UserCartState,
  action: Action<{ cart_id: number }>
) => {
  const { cart_id } = action.payload;
  const cart = _.filter(state.userCartItem, (item) => {
    if (cart_id) {
      return item?.cart_id !== action.payload?.cart_id;
    }
  });

  const withoutStockCart = _.filter(state.userCartItemWithoutStock, (item) => {
    if (cart_id) {
      return item?.cart_id !== action.payload?.cart_id;
    }
  });

  state.userCartItem = cart;
  state.userCartItemWithoutStock = withoutStockCart;
  --state.cartLength;
};

const clearCheckoutCart = (state: UserCartState) => {
  state.checkOutCartState = undefined || [];
};

const setCartLoading = (
  state: UserCartState,
  action: Action<{ isLoading: boolean }>
) => {
  state.cartLoading = action.payload.isLoading;
};

const slice = createSlice({
  name: "PcwUserCart",
  initialState,
  reducers: {
    addCartItemQuantity,
    checkoutCartMultiple,
    subtractCartItemQuantity,
    removeFromCart,
    addCheckoutCartItem,
    removeCheckoutCartItem,
    clearCheckoutCart,
    setCartLoading,
  },
  extraReducers(builder) {
    builder.addCase(
      thunks.getUserCartItemThunk.pending,
      (state, { payload }) => {
        state.isCartItemLoading = true;
      }
    );
    builder.addCase(
      thunks.getUserCartItemThunk.rejected,
      (state, { payload }) => {
        state.isCartItemLoading = false;
      }
    );
    builder.addCase(
      thunks.getUserCartItemThunk.fulfilled,
      (state, { payload }) => {
        state.isCartItemLoading = false;
        state.userCartItem = payload?.originalData?.data || [];
        state.checkOutCartState = payload?.originalData?.data || [];
      }
    );
    builder.addCase(
      thunks.getUserCartItemWithoutStockThunk.fulfilled,
      (state, { payload }) => {
        state.isCartItemLoading = false;
        state.userCartItemWithoutStock = payload?.originalData?.data || [];
      }
    );
    builder.addCase(
      thunks.getUserCartLength.fulfilled,
      (state, { payload }) => {
        state.cartLength = payload?.originalData?.data?.cart_items_count;
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
