import { RootState, _ } from "common";
import { createSelector } from "reselect";

const selectKachiPointsState = (state: RootState) => state.KachiPoints;

const selectIsKachiPointsLoading = createSelector(
  selectKachiPointsState,
  (state) => state.isKachiPointsLoading
);

const selectKachiPointsHistory = createSelector(
  selectKachiPointsState,
  (state) => state.userKachiPointsHistory
);

const selectKachiEarnedHistory = createSelector(
  selectKachiPointsState,
  (state) => state.userEarnedHistory
);

const selectKachiSpendingHistory = createSelector(
  selectKachiPointsState,
  (state) => state.userSpendingHistory
);

const selectIsFetchedEarningHistory = createSelector(
  selectKachiPointsState,
  (state) => state.isEarnHistoryFetched
);

const selectIsFetchedSpendingHistory = createSelector(
  selectKachiPointsState,
  (state) => state.isSpendingHistoryFetched
);

const selectTotalKachiPoints = createSelector(
  selectKachiPointsState,
  (state) => state.totalKachiPoints
);

export const selectors = {
  selectTotalKachiPoints,

  selectIsKachiPointsLoading,

  selectIsFetchedEarningHistory,
  selectIsFetchedSpendingHistory,

  selectKachiPointsHistory,
  selectKachiEarnedHistory,
  selectKachiSpendingHistory,
};
