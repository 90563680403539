import { createSlice } from "@reduxjs/toolkit";
import { Action, ErrorResponse, SliceStatusValue } from "common";
import {
  CategoriesPayload,
  CategoriesResponse,
  CategoryItem,
  CategoryState,
  MainCategoryItem,
} from "./types";
import * as thunks from "./thunks";

// const allCategoriesAppend = {
//   id: undefined,
//   name: 'All',
// };

const categoriesRequest = (
  state: CategoryState,
  _action: Action<CategoriesPayload>
) => {
  state.categoriesStatus = SliceStatusValue.loading;
};

const categoriesResolve = (
  state: CategoryState,
  action: Action<CategoriesResponse>
) => {
  // (action.payload?.data || []).unshift(allCategoriesAppend);
  state.categories = action.payload?.data;
  state.categoriesStatus = SliceStatusValue.resolve;
};

const categoriesReject = (
  state: CategoryState,
  _action: Action<ErrorResponse>
) => {
  state.categoriesStatus = SliceStatusValue.reject;
};

const setCategoryItem = (
  state: CategoryState,
  action: Action<CategoryItem>
) => {
  state.categoryItem = action.payload;
};

const setMainCategoryItem = (
  state: CategoryState,
  action: Action<MainCategoryItem>
) => {
  state.mainCategoryItem = action.payload;
};

const slice = createSlice({
  name: "Category",
  initialState: { categories: [], mainCategories: [] } as CategoryState,
  reducers: {
    categoriesRequest,
    categoriesResolve,
    categoriesReject,
    setCategoryItem,
    setMainCategoryItem,
  },
  extraReducers(builder) {
    builder.addCase(
      thunks.getMainCategoriesThunk.fulfilled,
      (state, { payload }) => {
        state.mainCategories = payload?.originalData?.data || [];
      }
    );
  }
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
