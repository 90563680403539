import { createSelector } from "reselect";
import { RootState } from "common";

const selectUserCart = (state: RootState) => state.PcwUserCart;

const selectUserCartItem = createSelector(selectUserCart, (state) => {
  return state.userCartItem;
});

const selectUserCartItemWithoutStock = createSelector(
  selectUserCart,
  (state) => {
    return state.userCartItemWithoutStock;
  }
);

const selectCheckoutCart = createSelector(selectUserCart, (state) => {
  return state.checkOutCartState;
});

const selectUserCartLength = createSelector(
  selectUserCart,
  (state) => state.cartLength
);

const selectUserCartTotalAmount = createSelector(selectUserCart, (state) =>
  state.userCartItem?.reduce(
    (previousAmount, cartItem) => previousAmount + cartItem?.amount,
    0
  )
);

const selectUserCartLoading = createSelector(
  selectUserCart,
  (state) => state.cartLoading
);

export const selectors = {
  selectUserCartItem,
  selectUserCartItemWithoutStock,
  selectUserCartLength,
  selectUserCartTotalAmount,
  selectCheckoutCart,
  selectUserCartLoading,
};
