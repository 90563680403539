import debounce from "lodash/debounce";
import { useEffect, useState } from "react";

const useWindowSizeV2 = () => {
  const [windowSize, setWindowSize] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });

  useEffect(() => {
    const updateWindowSize = debounce(() => {
      const width = window.innerWidth;
      setWindowSize({
        isMobile: width < 768, // Mobile is less than 768px
        isTablet: width >= 768 && width < 1024, // Tablet is between 768px and 1024px
        isDesktop: width >= 1024, // Desktop is 1024px or more
      });
    }, 250);

    updateWindowSize();

    window.addEventListener("resize", updateWindowSize);

    return () => {
      updateWindowSize.cancel();
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return windowSize;
};

export default useWindowSizeV2;
