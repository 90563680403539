import { createSlice } from "@reduxjs/toolkit";
import { Action, ErrorResponse } from "common";
import { SliceStatusValue, _ } from "common";
import {
  BuildsGalleryState,
  GetBuildsGalleryPayload,
  GetBuildsGalleryResponse,
} from "./types";
import * as thunks from "./thunks";

const initialState: BuildsGalleryState = {
  buildsGallery: [],
  buildsGalleryLoading: false,
  buildsGalleryPage: [],
  buildsGalleryPageLoading: false,
  buildsDetails: {},
  buildsGalleryOnProduct: [],
  buildsSearchProductName: "",
};

const buildsGalleryRequest = (
  state: BuildsGalleryState,
  _action: Action<GetBuildsGalleryPayload>
) => {
  state.getBuildsGalleryStatus = SliceStatusValue.loading;
  state.buildsGalleryLoading = true;
};

const buildsGalleryResolve = (
  state: BuildsGalleryState,
  action: Action<GetBuildsGalleryResponse>
) => {
  state.buildsGalleryLoading = false;
  state.buildsGallery = action?.payload?.data || [];
  state.getBuildsGalleryStatus = SliceStatusValue.resolve;
};

const buildsGalleryReject = (
  state: BuildsGalleryState,
  _action: Action<ErrorResponse>
) => {
  state.getBuildsGalleryStatus = SliceStatusValue.reject;
};

const buildsProductSearch = (
  state: BuildsGalleryState,
  action: Action<{ prodName: string }>
) => {
  const { prodName } = action.payload;
  state.buildsSearchProductName = prodName;
};

const slice = createSlice({
  name: "BuildsGallery",
  initialState,
  reducers: {
    buildsGalleryRequest,
    buildsGalleryResolve,
    buildsGalleryReject,
    buildsProductSearch,
  },
  extraReducers(builder) {
    builder.addCase(
      thunks.getBuildsGalleryThunk.pending,
      (state, { payload }) => {
        state.buildsGalleryLoading = true;
      }
    );
    builder.addCase(
      thunks.getBuildsGalleryThunk.rejected,
      (state, { payload }) => {
        state.buildsGalleryLoading = false;
      }
    );
    builder.addCase(
      thunks.getBuildsGalleryThunk.fulfilled,
      (state, { payload }) => {
        state.buildsGalleryLoading = false;
        state.buildsGallery = payload?.originalData?.data || [];
      }
    );
    builder.addCase(
      thunks.getBuildsGalleryPageThunk.pending,
      (state, { payload }) => {
        state.buildsGalleryPageLoading = true;
      }
    );
    builder.addCase(
      thunks.getBuildsGalleryPageThunk.rejected,
      (state, { payload }) => {
        state.buildsGalleryPageLoading = false;
      }
    );
    builder.addCase(
      thunks.getBuildsGalleryPageThunk.fulfilled,
      (state, { payload }) => {
        state.buildsGalleryPageLoading = false;
        state.buildsGalleryPage = payload?.originalData?.data || [];
      }
    );
    builder.addCase(
      thunks.getBuildsDetailsThunk.pending,
      (state, { payload }) => {
        state.buildsGalleryPageLoading = true;
      }
    );
    builder.addCase(
      thunks.getBuildsDetailsThunk.rejected,
      (state, { payload }) => {
        state.buildsGalleryLoading = false;
      }
    );
    builder.addCase(
      thunks.getBuildsDetailsThunk.fulfilled,
      (state, { payload }) => {
        state.buildsDetails = payload?.originalData?.data || {};
      }
    );

    builder.addCase(
      thunks.getBuildsItemOnProductThunk.pending,
      (state, { payload }) => {
        state.buildsGalleryPageLoading = true;
      }
    );
    builder.addCase(
      thunks.getBuildsItemOnProductThunk.rejected,
      (state, { payload }) => {
        state.buildsGalleryLoading = false;
      }
    );
    builder.addCase(
      thunks.getBuildsItemOnProductThunk.fulfilled,
      (state, { payload }) => {
        state.buildsGalleryOnProduct = payload?.originalData?.data || [];
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
