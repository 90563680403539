import { Button, CircularProgress } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import BazarImage from "components/BazarImage";
import { H3, Paragraph, Small } from "components/Typography";
import { FlexBox } from "components/flex-box";
import { useSnackBar } from "hooks";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useAppSelector } from "hooks/use-app-selector";
import modules from "modules";
import { useEffect, useState } from "react";
import { OtpPurpose } from "utils/constants";
const { selectors: UserRegDataSelectors, actions: ValidateOtpActions } =
  modules.pcwRegistration;
type VerificationProps = {};

const VerificationSignUp: React.FC<VerificationProps> = () => {
  const resendTime = 240;
  const snackbar = useSnackBar();
  const dispatch = useAppDispatch();

  const userData = useAppSelector(UserRegDataSelectors?.selectUserRegData);

  const [isLoading, setIsLoading] = useState<boolean>();
  const [userOtpData, getUserData] = useState<any>();
  const [errMsg, setErrMsg] = useState<string>();

  //for timer
  const [time, setTime] = useState(resendTime);
  const [isCounting, setIsCounting] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [disableBtn, setDisableBtn] = useState<boolean>(false);

  const resendEmail = async () => {
    setDisableBtn(true);
    setIsLoading(true);
    setErrMsg("");
    const resendPayload = {
      email: userOtpData?.email,
      purpose: OtpPurpose.confirm_account,
    };
    const response = unwrapResult(
      await dispatch(ValidateOtpActions?.setResendOtpThunk(resendPayload))
    );
    if (response?.success) {
      setIsCounting(true);
      setIsLoading(false);
      snackbar.show({
        severity: "info",
        message: "Successfully resend confirmation Email",
      });
    }
    if (response?.message && !response?.success) {
      setIsLoading(false);
      setErrMsg(response?.message);
      setDisableBtn(false);
    }
  };

  const resendOtpCountdown = () => {
    setIsCounting(true);
    const timerId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(intervalId);
          setIsCounting(false);
          return prevTime;
        }
      });
    }, 1000);
    setIntervalId(timerId);
  };

  useEffect(() => {
    if (userData) {
      getUserData(userData);
    }

    if (isCounting) {
      resendOtpCountdown();
    } else {
      clearInterval(intervalId);
      setTime(resendTime);
      setDisableBtn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCounting, userData]);

  return (
    <div>
      <FlexBox
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={250}
        minWidth={250}
        maxWidth={350}
        flexDirection={"column"}
        p={5}
        textAlign={"center"}
      >
        <H3>Verify Your Account</H3>
        <Paragraph fontSize={"12px"}>
          Please check your inbox and click the link to confirm your email
          address
        </Paragraph>
        <BazarImage
          src={"/assets/images/kachi/kachiVerify.png"}
          width={130}
          height={150}
        />
        <Button
          color="info"
          variant="outlined"
          onClick={resendEmail}
          sx={{ fontSize: "12px", mt: 1.5 }}
          disabled={isCounting || disableBtn}
        >
          {isCounting ? `Resend(${time})` : `Resend Email`}
        </Button>
        <Small
          mb={1}
          sx={{ textAlign: "center", display: "block", color: "red" }}
        >
          {errMsg ? errMsg : null}
        </Small>
      </FlexBox>
    </div>
  );
};

export default VerificationSignUp;
