import {
  Action,
  isFailResponseData,
  isSuccessResponseData,
  Response,
} from "common";
import { call, put, StrictEffect } from "redux-saga/effects";
import { PCBuilderApi } from "./api";
import { actions } from "./slice";
import { PartsCompatiblePayload, PartsCompatibleResponse } from "./types";

export function* partsCompatibleSaga(
  action: Action<PartsCompatiblePayload>
): Generator<StrictEffect, void, Response<PartsCompatibleResponse>> {
  let data = null;
  let problem = null;
  try {
    const response = yield call(
      PCBuilderApi.getPartsCompatible,
      action.payload
    );
    data = response.data;
    problem = response.problem;
  } catch (error) {
    yield put(actions.partsCompatibleReject());
  }

  if (!data) {
    yield put(actions.partsCompatibleReject(problem));
    return;
  }

  if (isFailResponseData(data)) {
    yield put(actions.partsCompatibleReject(data));
    return;
  }

  if (isSuccessResponseData(data)) {
    yield put(actions.partsCompatibleResolve(data));
  }
}

export const sagas = {
  partsCompatibleSaga,
};
