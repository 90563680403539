import { Api, ApiResponse } from "common";
import { GetLocationPayload, GetLocationResponse } from "./types";

const getRegions = (
  payload: GetLocationPayload
): ApiResponse<GetLocationResponse> => {
  return Api.get(`/api/ecomm/region/get`, { ...payload, limit: 500 });
};

const getProvinces = (
  payload: GetLocationPayload
): ApiResponse<GetLocationResponse> => {
  return Api.get(`/api/ecomm/province/get`, { ...payload, limit: 500 });
};

const getCities = (
  payload: GetLocationPayload
): ApiResponse<GetLocationResponse> => {
  return Api.get(`/api/ecomm/city/get`, { ...payload, limit: 500 });
};

const getBarangays = (
  payload: GetLocationPayload
): ApiResponse<GetLocationResponse> => {
  return Api.get(`/api/ecomm/barangay/get`, { ...payload, limit: 500 });
};

export const LocationsApi = {
  getRegions,
  getProvinces,
  getCities,
  getBarangays,
};
