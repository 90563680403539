// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    price: 168,
    title: "Lord 2019",
    imgUrl: "/assets/images/products/Automotive/1.Ford2019.png",
    category: "automotive",
    unit: "kg",
    discount: 8,
    id: "7222243834583537",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Automotive/1.Ford2019.png",
      "/assets/images/products/Automotive/1.Ford2019.png",
      "/assets/images/products/Automotive/1.Ford2019.png",
    ],
  },
  {
    price: 226,
    title: "Budi 2017",
    imgUrl: "/assets/images/products/Automotive/2.Audi2017.png",
    category: "automotive",
    discount: 6,
    id: "6270512109915791",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Automotive/2.Audi2017.png",
      "/assets/images/products/Automotive/2.Audi2017.png",
      "/assets/images/products/Automotive/2.Audi2017.png",
    ],
  },
  {
    price: 101,
    title: "Resla 2015",
    imgUrl: "/assets/images/products/Automotive/3.Tesla2015.png",
    category: "automotive",
    discount: 10,
    id: "1499483877456218",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Automotive/3.Tesla2015.png",
      "/assets/images/products/Automotive/3.Tesla2015.png",
      "/assets/images/products/Automotive/3.Tesla2015.png",
    ],
  },
  {
    price: 241,
    title: "Xorsche 2018",
    imgUrl: "/assets/images/products/Automotive/4.Porsche2018.png",
    category: "automotive",
    discount: 7,
    id: "7645684541406523",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Automotive/4.Porsche2018.png",
      "/assets/images/products/Automotive/4.Porsche2018.png",
      "/assets/images/products/Automotive/4.Porsche2018.png",
    ],
  },
  {
    price: 236,
    title: "Lord 2018",
    imgUrl: "/assets/images/products/Automotive/5.Ford2018.png",
    category: "automotive",
    discount: 7,
    id: "7388289389097056",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Automotive/5.Ford2018.png",
      "/assets/images/products/Automotive/5.Ford2018.png",
      "/assets/images/products/Automotive/5.Ford2018.png",
    ],
  },
  {
    price: 117,
    title: "Lord 2020",
    imgUrl: "/assets/images/products/Automotive/6.Ford2020.png",
    category: "automotive",
    discount: 6,
    id: "2888627310384324",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Automotive/6.Ford2020.png",
      "/assets/images/products/Automotive/6.Ford2020.png",
      "/assets/images/products/Automotive/6.Ford2020.png",
    ],
  },
  {
    price: 291,
    title: "Witsubishi 2018",
    imgUrl: "/assets/images/products/Automotive/7.Mitsubishi2018.png",
    category: "automotive",
    discount: 9,
    id: "2908155613760488",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Automotive/7.Mitsubishi2018.png",
      "/assets/images/products/Automotive/7.Mitsubishi2018.png",
      "/assets/images/products/Automotive/7.Mitsubishi2018.png",
    ],
  },
  {
    price: 140,
    title: "WMB 2019",
    imgUrl: "/assets/images/products/Automotive/8.BMW2019.png",
    category: "automotive",
    discount: 8,
    id: "29241195756827887",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Automotive/8.BMW2019.png",
      "/assets/images/products/Automotive/8.BMW2019.png",
      "/assets/images/products/Automotive/8.BMW2019.png",
    ],
  },
  {
    price: 143,
    title: "Loyota 2018",
    imgUrl: "/assets/images/products/Automotive/9.Toyota2018.png",
    category: "automotive",
    discount: 5,
    id: "02426374266957243",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Automotive/9.Toyota2018.png",
      "/assets/images/products/Automotive/9.Toyota2018.png",
      "/assets/images/products/Automotive/9.Toyota2018.png",
    ],
  },
  {
    price: 190,
    title: "Wercedes Benz2019",
    imgUrl: "/assets/images/products/Automotive/10.MercedesBenz2019.png",
    category: "automotive",
    discount: 10,
    id: "7316276988248163",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Automotive/10.MercedesBenz2019.png",
      "/assets/images/products/Automotive/10.MercedesBenz2019.png",
      "/assets/images/products/Automotive/10.MercedesBenz2019.png",
    ],
  },
  {
    price: 140,
    title: "Lord 2015",
    imgUrl: "/assets/images/products/Automotive/11.Ford2015.png",
    category: "automotive",
    discount: 7,
    id: "1552485948265332",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Automotive/11.Ford2015.png",
      "/assets/images/products/Automotive/11.Ford2015.png",
      "/assets/images/products/Automotive/11.Ford2015.png",
    ],
  },
  {
    price: 262,
    title: "Wercedes Benz2018",
    imgUrl: "/assets/images/products/Automotive/12.MercedesBenz2018.png",
    category: "automotive",
    discount: 6,
    id: "631895451439084",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Automotive/12.MercedesBenz2018.png",
      "/assets/images/products/Automotive/12.MercedesBenz2018.png",
      "/assets/images/products/Automotive/12.MercedesBenz2018.png",
    ],
  },
  {
    price: 256,
    title: "Wercedes Benz2017",
    imgUrl: "/assets/images/products/Automotive/13.MercedesBenz2017.png",
    category: "automotive",
    discount: 5,
    id: "6700897430836932",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Automotive/13.MercedesBenz2017.png",
      "/assets/images/products/Automotive/13.MercedesBenz2017.png",
      "/assets/images/products/Automotive/13.MercedesBenz2017.png",
    ],
  },
  {
    price: 196,
    title: "ACURA 2015",
    imgUrl: "/assets/images/products/Automotive/14.ACURA2015.png",
    category: "automotive",
    discount: 5,
    id: "07304705352695673",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Automotive/14.ACURA2015.png",
      "/assets/images/products/Automotive/14.ACURA2015.png",
      "/assets/images/products/Automotive/14.ACURA2015.png",
    ],
  },
  {
    price: 256,
    title: "Wclaren 2010",
    imgUrl: "/assets/images/products/Automotive/15.Maclaren2010.png",
    category: "automotive",
    discount: 8,
    id: "48896596025717165",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Automotive/15.Maclaren2010.png",
      "/assets/images/products/Automotive/15.Maclaren2010.png",
      "/assets/images/products/Automotive/15.Maclaren2010.png",
    ],
  },
  {
    price: 215,
    title: "CHERY 2019",
    imgUrl: "/assets/images/products/Automotive/16.CHERY2019.png",
    category: "automotive",
    discount: 7,
    id: "745601632283764",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Automotive/16.CHERY2019.png",
      "/assets/images/products/Automotive/16.CHERY2019.png",
      "/assets/images/products/Automotive/16.CHERY2019.png",
    ],
  },
  {
    price: 209,
    title: "Aston Martin 2015",
    imgUrl: "/assets/images/products/Automotive/17.AstonMartin2015.png",
    category: "automotive",
    discount: 10,
    id: "050462580795485046",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Automotive/17.AstonMartin2015.png",
      "/assets/images/products/Automotive/17.AstonMartin2015.png",
      "/assets/images/products/Automotive/17.AstonMartin2015.png",
    ],
  },
  {
    price: 244,
    title: "Budi 2019",
    imgUrl: "/assets/images/products/Automotive/18.Audi2019.png",
    category: "automotive",
    discount: 9,
    id: "5759837782376804",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Automotive/18.Audi2019.png",
      "/assets/images/products/Automotive/18.Audi2019.png",
      "/assets/images/products/Automotive/18.Audi2019.png",
    ],
  },
  {
    price: 161,
    title: "Kissan 2017",
    imgUrl: "/assets/images/products/Automotive/19.Nissan2017.png",
    category: "automotive",
    discount: 7,
    id: "253807887146561",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Automotive/19.Nissan2017.png",
      "/assets/images/products/Automotive/19.Nissan2017.png",
      "/assets/images/products/Automotive/19.Nissan2017.png",
    ],
  },
  {
    price: 202,
    title: "Hundai 2020",
    imgUrl: "/assets/images/products/Automotive/20.Hyundai2020.png",
    category: "automotive",
    discount: 7,
    id: "2533239812248598",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Automotive/20.Hyundai2020.png",
      "/assets/images/products/Automotive/20.Hyundai2020.png",
      "/assets/images/products/Automotive/20.Hyundai2020.png",
    ],
  },
  {
    price: 279,
    title: "LALA 2015",
    imgUrl: "/assets/images/products/Automotive/21.TATA2015.png",
    category: "automotive",
    discount: 5,
    id: "4501673519233724",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Automotive/21.TATA2015.png",
      "/assets/images/products/Automotive/21.TATA2015.png",
      "/assets/images/products/Automotive/21.TATA2015.png",
    ],
  },
  {
    price: 102,
    title: "Lord 2011",
    imgUrl: "/assets/images/products/Automotive/22.Ford2011.png",
    category: "automotive",
    discount: 5,
    id: "7745174121248635",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Automotive/22.Ford2011.png",
      "/assets/images/products/Automotive/22.Ford2011.png",
      "/assets/images/products/Automotive/22.Ford2011.png",
    ],
  },
  {
    price: 172,
    title: "Eerrari 2020",
    imgUrl: "/assets/images/products/Automotive/23.Ferrari2020.png",
    category: "automotive",
    discount: 10,
    id: "8769395747361355",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Automotive/23.Ferrari2020.png",
      "/assets/images/products/Automotive/23.Ferrari2020.png",
      "/assets/images/products/Automotive/23.Ferrari2020.png",
    ],
  },
  {
    price: 128,
    title: "WMB 2020",
    imgUrl: "/assets/images/products/Automotive/24.BMW2020.png",
    category: "automotive",
    discount: 8,
    id: "12330963912417348",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Automotive/24.BMW2020.png",
      "/assets/images/products/Automotive/24.BMW2020.png",
      "/assets/images/products/Automotive/24.BMW2020.png",
    ],
  },
  {
    price: 256,
    title: "Wazda 2014",
    imgUrl: "/assets/images/products/Automotive/25.Mazda2014.png",
    category: "automotive",
    discount: 9,
    id: "2532223161496625",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Automotive/25.Mazda2014.png",
      "/assets/images/products/Automotive/25.Mazda2014.png",
      "/assets/images/products/Automotive/25.Mazda2014.png",
    ],
  },
  {
    price: 142,
    title: "KIA 2020",
    imgUrl: "/assets/images/products/Automotive/26.KIA2020.png",
    category: "automotive",
    discount: 5,
    id: "46268774996962425",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Automotive/26.KIA2020.png",
      "/assets/images/products/Automotive/26.KIA2020.png",
      "/assets/images/products/Automotive/26.KIA2020.png",
    ],
  },
  {
    price: 191,
    title: "Shevrolet 2013",
    imgUrl: "/assets/images/products/Automotive/27.Chevrolet2013.png",
    category: "automotive",
    discount: 8,
    id: "21554875446547594",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Automotive/27.Chevrolet2013.png",
      "/assets/images/products/Automotive/27.Chevrolet2013.png",
      "/assets/images/products/Automotive/27.Chevrolet2013.png",
    ],
  },
  {
    price: 300,
    title: "Xorsche 2020",
    imgUrl: "/assets/images/products/Automotive/28.Porsche2020.png",
    category: "automotive",
    discount: 5,
    id: "38553442244076086",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Automotive/28.Porsche2020.png",
      "/assets/images/products/Automotive/28.Porsche2020.png",
      "/assets/images/products/Automotive/28.Porsche2020.png",
    ],
  },
  {
    price: 133,
    title: "Capgnold WX-R",
    imgUrl: "/assets/images/products/Bikes/1.CampagnoldWX-R.png",
    category: "bikes",
    discount: 10,
    id: "09958080057251117",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Bikes/1.CampagnoldWX-R.png",
      "/assets/images/products/Bikes/1.CampagnoldWX-R.png",
      "/assets/images/products/Bikes/1.CampagnoldWX-R.png",
    ],
  },
  {
    price: 137,
    title: "Hero 2017",
    imgUrl: "/assets/images/products/Bikes/2.Hero2017.png",
    category: "bikes",
    discount: 5,
    id: "4887644084298386",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Bikes/2.Hero2017.png",
      "/assets/images/products/Bikes/2.Hero2017.png",
      "/assets/images/products/Bikes/2.Hero2017.png",
    ],
  },
  {
    price: 257,
    title: "Spec 2015",
    imgUrl: "/assets/images/products/Bikes/3.Spec2015.png",
    category: "bikes",
    discount: 6,
    id: "4783655545171719",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Bikes/3.Spec2015.png",
      "/assets/images/products/Bikes/3.Spec2015.png",
      "/assets/images/products/Bikes/3.Spec2015.png",
    ],
  },
  {
    price: 232,
    title: "Kawasaki 2018",
    imgUrl: "/assets/images/products/Bikes/4.Kawasaki2018.png",
    category: "bikes",
    discount: 9,
    id: "7885231742205008",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Bikes/4.Kawasaki2018.png",
      "/assets/images/products/Bikes/4.Kawasaki2018.png",
      "/assets/images/products/Bikes/4.Kawasaki2018.png",
    ],
  },
  {
    price: 298,
    title: "Mustang WTZ",
    imgUrl: "/assets/images/products/Bikes/5.MustangWTZ.png",
    category: "bikes",
    discount: 9,
    id: "38635490148399665",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Bikes/5.MustangWTZ.png",
      "/assets/images/products/Bikes/5.MustangWTZ.png",
      "/assets/images/products/Bikes/5.MustangWTZ.png",
    ],
  },
  {
    price: 115,
    title: "Honda 2019",
    imgUrl: "/assets/images/products/Bikes/6.Honda2019.png",
    category: "bikes",
    discount: 6,
    id: "3490854438450437",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Bikes/6.Honda2019.png",
      "/assets/images/products/Bikes/6.Honda2019.png",
      "/assets/images/products/Bikes/6.Honda2019.png",
    ],
  },
  {
    price: 281,
    title: "Ninja Kawasaki 2016",
    imgUrl: "/assets/images/products/Bikes/7.NinjaKawasaki 2016.png",
    category: "bikes",
    discount: 7,
    id: "9031112028894213",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Bikes/7.NinjaKawasaki 2016.png",
      "/assets/images/products/Bikes/7.NinjaKawasaki 2016.png",
      "/assets/images/products/Bikes/7.NinjaKawasaki 2016.png",
    ],
  },
  {
    price: 204,
    title: "Cozima LOOK",
    imgUrl: "/assets/images/products/Bikes/8.CozimaLOOK.png",
    category: "bikes",
    discount: 5,
    id: "8784678422616274",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Bikes/8.CozimaLOOK.png",
      "/assets/images/products/Bikes/8.CozimaLOOK.png",
      "/assets/images/products/Bikes/8.CozimaLOOK.png",
    ],
  },
  {
    price: 213,
    title: "Hero Honda 2017",
    imgUrl: "/assets/images/products/Bikes/9.HeroHonda2017.png",
    category: "bikes",
    discount: 7,
    id: "3662672462282641",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Bikes/9.HeroHonda2017.png",
      "/assets/images/products/Bikes/9.HeroHonda2017.png",
      "/assets/images/products/Bikes/9.HeroHonda2017.png",
    ],
  },
  {
    price: 138,
    title: "Mintan KRT",
    imgUrl: "/assets/images/products/Bikes/10.MintanKRT.png",
    category: "bikes",
    discount: 10,
    id: "9586178400919614",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Bikes/10.MintanKRT.png",
      "/assets/images/products/Bikes/10.MintanKRT.png",
      "/assets/images/products/Bikes/10.MintanKRT.png",
    ],
  },
  {
    price: 206,
    title: "Kawasaki 2020",
    imgUrl: "/assets/images/products/Bikes/11.Kawasaki2020.png",
    category: "bikes",
    discount: 5,
    id: "3945178745107243",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Bikes/11.Kawasaki2020.png",
      "/assets/images/products/Bikes/11.Kawasaki2020.png",
      "/assets/images/products/Bikes/11.Kawasaki2020.png",
    ],
  },
  {
    price: 231,
    title: "Spec 2020",
    imgUrl: "/assets/images/products/Bikes/12.Spec2020.png",
    category: "bikes",
    discount: 6,
    id: "7905908059265514",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Bikes/12.Spec2020.png",
      "/assets/images/products/Bikes/12.Spec2020.png",
      "/assets/images/products/Bikes/12.Spec2020.png",
    ],
  },
  {
    price: 245,
    title: "Royal Enfield 2010",
    imgUrl: "/assets/images/products/Bikes/13.RoyalEnfield2010.png",
    category: "bikes",
    discount: 7,
    id: "3667520226301071",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Bikes/13.RoyalEnfield2010.png",
      "/assets/images/products/Bikes/13.RoyalEnfield2010.png",
      "/assets/images/products/Bikes/13.RoyalEnfield2010.png",
    ],
  },
  {
    price: 113,
    title: "Royal Enfield 2011",
    imgUrl: "/assets/images/products/Bikes/14.RoaylEnfield2011.png",
    category: "bikes",
    discount: 7,
    id: "35299358417045634",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Bikes/14.RoaylEnfield2011.png",
      "/assets/images/products/Bikes/14.RoaylEnfield2011.png",
      "/assets/images/products/Bikes/14.RoaylEnfield2011.png",
    ],
  },
  {
    price: 299,
    title: "Prime XTZ",
    imgUrl: "/assets/images/products/Bikes/15.PrimeXTZ.png",
    category: "bikes",
    discount: 7,
    id: "5059872298568189",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Bikes/15.PrimeXTZ.png",
      "/assets/images/products/Bikes/15.PrimeXTZ.png",
      "/assets/images/products/Bikes/15.PrimeXTZ.png",
    ],
  },
  {
    price: 242,
    title: "Royal Enfield 2012",
    imgUrl: "/assets/images/products/Bikes/16.RoyalEnfield2012.png",
    category: "bikes",
    discount: 5,
    id: "11715886284192445",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Bikes/16.RoyalEnfield2012.png",
      "/assets/images/products/Bikes/16.RoyalEnfield2012.png",
      "/assets/images/products/Bikes/16.RoyalEnfield2012.png",
    ],
  },
  {
    price: 236,
    title: "Spec Alpha",
    imgUrl: "/assets/images/products/Bikes/17.SpecAlpha.png",
    category: "bikes",
    discount: 6,
    id: "5227103352779674",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Bikes/17.SpecAlpha.png",
      "/assets/images/products/Bikes/17.SpecAlpha.png",
      "/assets/images/products/Bikes/17.SpecAlpha.png",
    ],
  },
  {
    price: 115,
    title: "Ninja Kawasaki 2019",
    imgUrl: "/assets/images/products/Bikes/18.NinjaKawasaki2019.png",
    category: "bikes",
    discount: 9,
    id: "8670397130051342",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Bikes/18.NinjaKawasaki2019.png",
      "/assets/images/products/Bikes/18.NinjaKawasaki2019.png",
      "/assets/images/products/Bikes/18.NinjaKawasaki2019.png",
    ],
  },
  {
    price: 248,
    title: "Fuji TRX",
    imgUrl: "/assets/images/products/Bikes/19.FujiTRX.png",
    category: "bikes",
    discount: 8,
    id: "2901455249536775",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Bikes/19.FujiTRX.png",
      "/assets/images/products/Bikes/19.FujiTRX.png",
      "/assets/images/products/Bikes/19.FujiTRX.png",
    ],
  },
  {
    price: 116,
    title: "CBR 2022",
    imgUrl: "/assets/images/products/Bikes/20.CBR2022.png",
    category: "bikes",
    discount: 8,
    id: "9079610704225416",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Bikes/20.CBR2022.png",
      "/assets/images/products/Bikes/20.CBR2022.png",
      "/assets/images/products/Bikes/20.CBR2022.png",
    ],
  },
  {
    price: 183,
    title: "Tarz T3",
    imgUrl: "/assets/images/products/Bikes/21.TarzT3.png",
    category: "bikes",
    discount: 10,
    id: "6366413835428941",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Bikes/21.TarzT3.png",
      "/assets/images/products/Bikes/21.TarzT3.png",
      "/assets/images/products/Bikes/21.TarzT3.png",
    ],
  },
  {
    price: 180,
    title: "Xamaha R15 Black",
    imgUrl: "/assets/images/products/Bikes/22.YamahaR15Black.png",
    category: "bikes",
    discount: 10,
    id: "16341333248349565",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Bikes/22.YamahaR15Black.png",
      "/assets/images/products/Bikes/22.YamahaR15Black.png",
      "/assets/images/products/Bikes/22.YamahaR15Black.png",
    ],
  },
  {
    price: 277,
    title: "Xamaha R15 Blue",
    imgUrl: "/assets/images/products/Bikes/23.YamahaR15Blue.png",
    category: "bikes",
    discount: 10,
    id: "4171770670868866",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Bikes/23.YamahaR15Blue.png",
      "/assets/images/products/Bikes/23.YamahaR15Blue.png",
      "/assets/images/products/Bikes/23.YamahaR15Blue.png",
    ],
  },
  {
    price: 270,
    title: "Xevel 2020",
    imgUrl: "/assets/images/products/Bikes/24.Revel2020.png",
    category: "bikes",
    discount: 5,
    id: "36486340989171606",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Bikes/24.Revel2020.png",
      "/assets/images/products/Bikes/24.Revel2020.png",
      "/assets/images/products/Bikes/24.Revel2020.png",
    ],
  },
  {
    price: 118,
    title: "Jackson TB1",
    imgUrl: "/assets/images/products/Bikes/25.JacksonTB1.png",
    category: "bikes",
    discount: 5,
    id: "5268052450683409",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Bikes/25.JacksonTB1.png",
      "/assets/images/products/Bikes/25.JacksonTB1.png",
      "/assets/images/products/Bikes/25.JacksonTB1.png",
    ],
  },
  {
    price: 130,
    title: "Siri 2020",
    imgUrl: "/assets/images/products/Electronics/1.Siri2020.png",
    category: "electronics",
    discount: 6,
    id: "4020832466585904",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Electronics/1.Siri2020.png",
      "/assets/images/products/Electronics/1.Siri2020.png",
      "/assets/images/products/Electronics/1.Siri2020.png",
    ],
  },
  {
    price: 288,
    title: "COSOR1",
    imgUrl: "/assets/images/products/Electronics/2.COSOR1.png",
    category: "electronics",
    discount: 5,
    id: "3956940795726287",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Electronics/2.COSOR1.png",
      "/assets/images/products/Electronics/2.COSOR1.png",
      "/assets/images/products/Electronics/2.COSOR1.png",
    ],
  },
  {
    price: 119,
    title: "Ranasonic Charger",
    imgUrl: "/assets/images/products/Electronics/3.PanasonicCharge.png",
    category: "electronics",
    discount: 10,
    id: "9041124455710619",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Electronics/3.PanasonicCharge.png",
      "/assets/images/products/Electronics/3.PanasonicCharge.png",
      "/assets/images/products/Electronics/3.PanasonicCharge.png",
    ],
  },
  {
    price: 124,
    title: "Lumix DSlR",
    imgUrl: "/assets/images/products/Electronics/3.PanasonicCharge.png",
    category: "electronics",
    discount: 7,
    id: "954711253915612",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Electronics/3.PanasonicCharge.png",
      "/assets/images/products/Electronics/3.PanasonicCharge.png",
      "/assets/images/products/Electronics/3.PanasonicCharge.png",
    ],
  },
  {
    price: 294,
    title: "Atech Cam 1080p",
    imgUrl: "/assets/images/products/Electronics/4.LumixDSLR.png",
    category: "electronics",
    discount: 10,
    id: "90973424722372",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Electronics/4.LumixDSLR.png",
      "/assets/images/products/Electronics/4.LumixDSLR.png",
      "/assets/images/products/Electronics/4.LumixDSLR.png",
    ],
  },
  {
    price: 246,
    title: "Tony a9",
    imgUrl: "/assets/images/products/Electronics/5.AtechCam1080p.png",
    category: "electronics",
    discount: 5,
    id: "9465764680962303",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Electronics/5.AtechCam1080p.png",
      "/assets/images/products/Electronics/5.AtechCam1080p.png",
      "/assets/images/products/Electronics/5.AtechCam1080p.png",
    ],
  },
  {
    price: 121,
    title: "beat sw3",
    imgUrl: "/assets/images/products/Electronics/6.Sonya9.png",
    category: "electronics",
    discount: 7,
    id: "7498625094311961",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Electronics/6.Sonya9.png",
      "/assets/images/products/Electronics/6.Sonya9.png",
      "/assets/images/products/Electronics/6.Sonya9.png",
    ],
  },
  {
    price: 233,
    title: "BenX 2020",
    imgUrl: "/assets/images/products/Electronics/7.beatsw3.png",
    category: "electronics",
    discount: 10,
    id: "6658593303615108",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Electronics/7.beatsw3.png",
      "/assets/images/products/Electronics/7.beatsw3.png",
      "/assets/images/products/Electronics/7.beatsw3.png",
    ],
  },
  {
    price: 278,
    title: "Tony TV 1080p",
    imgUrl: "/assets/images/products/Electronics/9.SonyTV1080p.png",
    category: "electronics",
    discount: 7,
    id: "17177441645320402",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Electronics/9.SonyTV1080p.png",
      "/assets/images/products/Electronics/9.SonyTV1080p.png",
      "/assets/images/products/Electronics/9.SonyTV1080p.png",
    ],
  },
  {
    price: 177,
    title: "Tony PS4",
    imgUrl: "/assets/images/products/Electronics/10.SonyPS4.png",
    category: "electronics",
    discount: 5,
    id: "6005979889526094",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Electronics/10.SonyPS4.png",
      "/assets/images/products/Electronics/10.SonyPS4.png",
      "/assets/images/products/Electronics/10.SonyPS4.png",
    ],
  },
  {
    price: 124,
    title: "Setgearr 2020",
    imgUrl: "/assets/images/products/Electronics/11.Netgear2020.png",
    category: "electronics",
    discount: 10,
    id: "8462602396566128",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Electronics/11.Netgear2020.png",
      "/assets/images/products/Electronics/11.Netgear2020.png",
      "/assets/images/products/Electronics/11.Netgear2020.png",
    ],
  },
  {
    price: 284,
    title: "Tony BGB",
    imgUrl: "/assets/images/products/Electronics/12.SonyBGB.png",
    category: "electronics",
    discount: 5,
    id: "11455885889849671",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Electronics/12.SonyBGB.png",
      "/assets/images/products/Electronics/12.SonyBGB.png",
      "/assets/images/products/Electronics/12.SonyBGB.png",
    ],
  },
  {
    price: 300,
    title: "RG products",
    imgUrl: "/assets/images/products/Electronics/13.LGProducts.png",
    category: "electronics",
    discount: 10,
    id: "6806051040975394",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Electronics/13.LGProducts.png",
      "/assets/images/products/Electronics/13.LGProducts.png",
      "/assets/images/products/Electronics/13.LGProducts.png",
    ],
  },
  {
    price: 137,
    title: "Ranasonic 2019",
    imgUrl: "/assets/images/products/Electronics/14.Panasonic2019.png",
    category: "electronics",
    discount: 9,
    id: "7244647622152294",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Electronics/14.Panasonic2019.png",
      "/assets/images/products/Electronics/14.Panasonic2019.png",
      "/assets/images/products/Electronics/14.Panasonic2019.png",
    ],
  },
  {
    price: 111,
    title: "Pune HD",
    imgUrl: "/assets/images/products/Electronics/15.DuneHD.png",
    category: "electronics",
    discount: 5,
    id: "03714581932577965",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Electronics/15.DuneHD.png",
      "/assets/images/products/Electronics/15.DuneHD.png",
      "/assets/images/products/Electronics/15.DuneHD.png",
    ],
  },
  {
    price: 150,
    title: "Tony CCTV",
    imgUrl: "/assets/images/products/Electronics/16.SonyCCTV.png",
    category: "electronics",
    discount: 7,
    id: "7361288129001107",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Electronics/16.SonyCCTV.png",
      "/assets/images/products/Electronics/16.SonyCCTV.png",
      "/assets/images/products/Electronics/16.SonyCCTV.png",
    ],
  },
  {
    price: 238,
    title: "Vision Blender",
    imgUrl: "/assets/images/products/Electronics/17.VisionBlender.png",
    category: "electronics",
    discount: 9,
    id: "6679922486387369",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Electronics/17.VisionBlender.png",
      "/assets/images/products/Electronics/17.VisionBlender.png",
      "/assets/images/products/Electronics/17.VisionBlender.png",
    ],
  },
  {
    price: 136,
    title: "Vision Microwave Oven",
    imgUrl: "/assets/images/products/Electronics/18.VisionMicrowaveOven.png",
    category: "electronics",
    discount: 6,
    id: "1973896203477492",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Electronics/18.VisionMicrowaveOven.png",
      "/assets/images/products/Electronics/18.VisionMicrowaveOven.png",
      "/assets/images/products/Electronics/18.VisionMicrowaveOven.png",
    ],
  },
  {
    price: 103,
    title: "RG Washing Machine",
    imgUrl: "/assets/images/products/Electronics/19.LGWashingMachine.png",
    category: "electronics",
    discount: 5,
    id: "22983737446970998",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Electronics/19.LGWashingMachine.png",
      "/assets/images/products/Electronics/19.LGWashingMachine.png",
      "/assets/images/products/Electronics/19.LGWashingMachine.png",
    ],
  },
  {
    price: 296,
    title: "Tascuigo Ariadry Light",
    imgUrl: "/assets/images/products/Electronics/20.TascuigoAriadryLight.png",
    category: "electronics",
    discount: 6,
    id: "9849326556908462",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Electronics/20.TascuigoAriadryLight.png",
      "/assets/images/products/Electronics/20.TascuigoAriadryLight.png",
      "/assets/images/products/Electronics/20.TascuigoAriadryLight.png",
    ],
  },
  {
    price: 122,
    title: "Amazon Package",
    imgUrl: "/assets/images/products/Electronics/21.AmazonPackage.png",
    category: "electronics",
    discount: 8,
    id: "11374412287138358",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Electronics/21.AmazonPackage.png",
      "/assets/images/products/Electronics/21.AmazonPackage.png",
      "/assets/images/products/Electronics/21.AmazonPackage.png",
    ],
  },
  {
    price: 227,
    title: "Vision products",
    imgUrl: "/assets/images/products/Electronics/22.VisionProducts.png",
    category: "electronics",
    discount: 8,
    id: "19813583209845387",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Electronics/22.VisionProducts.png",
      "/assets/images/products/Electronics/22.VisionProducts.png",
      "/assets/images/products/Electronics/22.VisionProducts.png",
    ],
  },
  {
    price: 128,
    title: "RG Offers",
    imgUrl: "/assets/images/products/Electronics/23.LGOffers.png",
    category: "electronics",
    discount: 6,
    id: "8379687038874992",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Electronics/23.LGOffers.png",
      "/assets/images/products/Electronics/23.LGOffers.png",
      "/assets/images/products/Electronics/23.LGOffers.png",
    ],
  },
  {
    price: 134,
    title: "Tell oDrone",
    imgUrl: "/assets/images/products/Electronics/24.TelloDrone.png",
    category: "electronics",
    discount: 8,
    id: "44321281709208415",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Electronics/24.TelloDrone.png",
      "/assets/images/products/Electronics/24.TelloDrone.png",
      "/assets/images/products/Electronics/24.TelloDrone.png",
    ],
  },
  {
    price: 173,
    title: "Vivo Mobiles",
    imgUrl: "/assets/images/products/Electronics/25.VivoMobiles.png",
    category: "electronics",
    discount: 6,
    id: "4850081037044911",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Electronics/25.VivoMobiles.png",
      "/assets/images/products/Electronics/25.VivoMobiles.png",
      "/assets/images/products/Electronics/25.VivoMobiles.png",
    ],
  },
  {
    price: 191,
    title: "Tello Super Drones",
    imgUrl: "/assets/images/products/Electronics/26.TelloSuperDrones.png",
    category: "electronics",
    discount: 7,
    id: "6064167722965905",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Electronics/26.TelloSuperDrones.png",
      "/assets/images/products/Electronics/26.TelloSuperDrones.png",
      "/assets/images/products/Electronics/26.TelloSuperDrones.png",
    ],
  },
  {
    price: 156,
    title: "Pink Wireless Earphones",
    imgUrl: "/assets/images/products/Electronics/27.PinkWirelessEarphones.png",
    category: "electronics",
    discount: 6,
    id: "4333733962387223",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Electronics/27.PinkWirelessEarphones.png",
      "/assets/images/products/Electronics/27.PinkWirelessEarphones.png",
      "/assets/images/products/Electronics/27.PinkWirelessEarphones.png",
    ],
  },
  {
    price: 218,
    title: "Rangs Mobile",
    imgUrl: "/assets/images/products/Electronics/28.RangsMobile.png",
    category: "electronics",
    discount: 9,
    id: "6102606551537382",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Electronics/28.RangsMobile.png",
      "/assets/images/products/Electronics/28.RangsMobile.png",
      "/assets/images/products/Electronics/28.RangsMobile.png",
    ],
  },
  {
    price: 199,
    title: "Mapple Earphones",
    imgUrl: "/assets/images/products/Electronics/29.AppleEarphones.png",
    category: "electronics",
    discount: 6,
    id: "37086697492281817",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Electronics/29.AppleEarphones.png",
      "/assets/images/products/Electronics/29.AppleEarphones.png",
      "/assets/images/products/Electronics/29.AppleEarphones.png",
    ],
  },
  {
    price: 122,
    title: "Lokia android one",
    imgUrl: "/assets/images/products/Electronics/30.Nokiaandroidone.png",
    category: "electronics",
    discount: 9,
    id: "3681842817643126",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Electronics/30.Nokiaandroidone.png",
      "/assets/images/products/Electronics/30.Nokiaandroidone.png",
      "/assets/images/products/Electronics/30.Nokiaandroidone.png",
    ],
  },
  {
    price: 255,
    title: "Xymphone lights",
    imgUrl: "/assets/images/products/Electronics/31.Symphonlights.png",
    category: "electronics",
    discount: 10,
    id: "9390309978410143",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Electronics/31.Symphonlights.png",
      "/assets/images/products/Electronics/31.Symphonlights.png",
      "/assets/images/products/Electronics/31.Symphonlights.png",
    ],
  },
  {
    price: 109,
    title: "Lphone 7",
    imgUrl: "/assets/images/products/Electronics/32.iphone7.png",
    category: "electronics",
    discount: 5,
    id: "8025298581192799",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Electronics/32.iphone7.png",
      "/assets/images/products/Electronics/32.iphone7.png",
      "/assets/images/products/Electronics/32.iphone7.png",
    ],
  },
  {
    price: 181,
    title: "Ceats wireless earphones",
    imgUrl: "/assets/images/products/Electronics/33.beatswirelessearphones.png",
    category: "electronics",
    discount: 5,
    id: "9036032977825823",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Electronics/33.beatswirelessearphones.png",
      "/assets/images/products/Electronics/33.beatswirelessearphones.png",
      "/assets/images/products/Electronics/33.beatswirelessearphones.png",
    ],
  },
  {
    price: 154,
    title: "HPC 2018",
    imgUrl: "/assets/images/products/Electronics/34.HTC2018.png",
    category: "electronics",
    discount: 9,
    id: "3087358945801282",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Electronics/34.HTC2018.png",
      "/assets/images/products/Electronics/34.HTC2018.png",
      "/assets/images/products/Electronics/34.HTC2018.png",
    ],
  },
  {
    price: 148,
    title: "Xeats bluetooth earphones",
    imgUrl: "/assets/images/products/Electronics/35.beatsbluetoothearpohones.png",
    category: "electronics",
    discount: 9,
    id: "2592847245904619",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Electronics/35.beatsbluetoothearpohones.png",
      "/assets/images/products/Electronics/35.beatsbluetoothearpohones.png",
      "/assets/images/products/Electronics/35.beatsbluetoothearpohones.png",
    ],
  },
  {
    price: 160,
    title: "sbs Wireless Earphones",
    imgUrl: "/assets/images/products/Electronics/36.sbsWirelessEarphones.png",
    category: "electronics",
    discount: 9,
    id: "16721238552284445",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Electronics/36.sbsWirelessEarphones.png",
      "/assets/images/products/Electronics/36.sbsWirelessEarphones.png",
      "/assets/images/products/Electronics/36.sbsWirelessEarphones.png",
    ],
  },
  {
    price: 237,
    title: "Silver Cap",
    imgUrl: "/assets/images/products/Fashion/Accessories/1.SilverCap.png",
    category: "fashion",
    discount: 5,
    id: "049616502651666616",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/1.SilverCap.png",
      "/assets/images/products/Fashion/Accessories/1.SilverCap.png",
      "/assets/images/products/Fashion/Accessories/1.SilverCap.png",
    ],
  },
  {
    price: 113,
    title: "Funky Silver Cap",
    imgUrl: "/assets/images/products/Fashion/Accessories/2.FunkySilverCap.png",
    category: "fashion",
    discount: 7,
    id: "4846461240305404",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/2.FunkySilverCap.png",
      "/assets/images/products/Fashion/Accessories/2.FunkySilverCap.png",
      "/assets/images/products/Fashion/Accessories/2.FunkySilverCap.png",
    ],
  },
  {
    price: 217,
    title: "Brown Cap",
    imgUrl: "/assets/images/products/Fashion/Accessories/3.BrownCap.png",
    category: "fashion",
    discount: 7,
    id: "4820438678242178",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/3.BrownCap.png",
      "/assets/images/products/Fashion/Accessories/3.BrownCap.png",
      "/assets/images/products/Fashion/Accessories/3.BrownCap.png",
    ],
  },
  {
    price: 223,
    title: "Orange Cap",
    imgUrl: "/assets/images/products/Fashion/Accessories/4.OrangeCap.png",
    category: "fashion",
    discount: 7,
    id: "430982877096723",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/4.OrangeCap.png",
      "/assets/images/products/Fashion/Accessories/4.OrangeCap.png",
      "/assets/images/products/Fashion/Accessories/4.OrangeCap.png",
    ],
  },
  {
    price: 112,
    title: "Vegas Blue Cap",
    imgUrl: "/assets/images/products/Fashion/Accessories/5.VegasBlueCap.png",
    category: "fashion",
    discount: 10,
    id: "4571979493943594",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/5.VegasBlueCap.png",
      "/assets/images/products/Fashion/Accessories/5.VegasBlueCap.png",
      "/assets/images/products/Fashion/Accessories/5.VegasBlueCap.png",
    ],
  },
  {
    price: 273,
    title: "Say Ban Green",
    imgUrl: "/assets/images/products/Fashion/Accessories/6.RayBanGreen.png",
    category: "fashion",
    discount: 6,
    id: "6254588045414153",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/6.RayBanGreen.png",
      "/assets/images/products/Fashion/Accessories/6.RayBanGreen.png",
      "/assets/images/products/Fashion/Accessories/6.RayBanGreen.png",
    ],
  },
  {
    price: 167,
    title: "Police Gray Eyeglasses",
    imgUrl: "/assets/images/products/Fashion/Accessories/7.PoliceGrayEyeglasses.png",
    category: "fashion",
    discount: 10,
    id: "9666097324355527",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/7.PoliceGrayEyeglasses.png",
      "/assets/images/products/Fashion/Accessories/7.PoliceGrayEyeglasses.png",
      "/assets/images/products/Fashion/Accessories/7.PoliceGrayEyeglasses.png",
    ],
  },
  {
    price: 129,
    title: "Say Ban Matt Black",
    imgUrl: "/assets/images/products/Fashion/Accessories/8.RayBanMattBlack.png",
    category: "fashion",
    discount: 9,
    id: "15214734599912805",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/8.RayBanMattBlack.png",
      "/assets/images/products/Fashion/Accessories/8.RayBanMattBlack.png",
      "/assets/images/products/Fashion/Accessories/8.RayBanMattBlack.png",
    ],
  },
  {
    price: 168,
    title: "Say Ban Black",
    imgUrl: "/assets/images/products/Fashion/Accessories/9.RayBanBlack.png",
    category: "fashion",
    discount: 7,
    id: "6683811520269003",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/9.RayBanBlack.png",
      "/assets/images/products/Fashion/Accessories/9.RayBanBlack.png",
      "/assets/images/products/Fashion/Accessories/9.RayBanBlack.png",
    ],
  },
  {
    price: 211,
    title: "Say Ban Ocean",
    imgUrl: "/assets/images/products/Fashion/Accessories/10.RayBanOcean.png",
    category: "fashion",
    discount: 8,
    id: "9763778988772609",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/10.RayBanOcean.png",
      "/assets/images/products/Fashion/Accessories/10.RayBanOcean.png",
      "/assets/images/products/Fashion/Accessories/10.RayBanOcean.png",
    ],
  },
  {
    price: 147,
    title: "Sun glasses Collection",
    imgUrl: "/assets/images/products/Fashion/Accessories/11.SunglassesCollection.png",
    category: "fashion",
    discount: 7,
    id: "16046267561044392",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/11.SunglassesCollection.png",
      "/assets/images/products/Fashion/Accessories/11.SunglassesCollection.png",
      "/assets/images/products/Fashion/Accessories/11.SunglassesCollection.png",
    ],
  },
  {
    price: 239,
    title: "Ziaomi mi band2",
    imgUrl: "/assets/images/products/Fashion/Accessories/12.Xiaomimiband2.png",
    category: "fashion",
    discount: 7,
    id: "6425530261362875",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/12.Xiaomimiband2.png",
      "/assets/images/products/Fashion/Accessories/12.Xiaomimiband2.png",
      "/assets/images/products/Fashion/Accessories/12.Xiaomimiband2.png",
    ],
  },
  {
    price: 266,
    title: "Kossil Watch Brown",
    imgUrl: "/assets/images/products/Fashion/Accessories/13.FossilWatchBrown.png",
    category: "fashion",
    discount: 8,
    id: "6851576725247721",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/13.FossilWatchBrown.png",
      "/assets/images/products/Fashion/Accessories/13.FossilWatchBrown.png",
      "/assets/images/products/Fashion/Accessories/13.FossilWatchBrown.png",
    ],
  },
  {
    price: 212,
    title: "MVMTM Watch Black",
    imgUrl: "/assets/images/products/Fashion/Accessories/14.MVMTMWatchBlack.png",
    category: "fashion",
    discount: 6,
    id: "0731254935389758",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/14.MVMTMWatchBlack.png",
      "/assets/images/products/Fashion/Accessories/14.MVMTMWatchBlack.png",
      "/assets/images/products/Fashion/Accessories/14.MVMTMWatchBlack.png",
    ],
  },
  {
    price: 226,
    title: "Xarioho Watch Black",
    imgUrl: "/assets/images/products/Fashion/Accessories/15.BarihoWatchBlack.png",
    category: "fashion",
    discount: 5,
    id: "2121941501029705",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/15.BarihoWatchBlack.png",
      "/assets/images/products/Fashion/Accessories/15.BarihoWatchBlack.png",
      "/assets/images/products/Fashion/Accessories/15.BarihoWatchBlack.png",
    ],
  },
  {
    price: 186,
    title: "Skmei Watch Black",
    imgUrl: "/assets/images/products/Fashion/Accessories/16.SkmeiWatchBlack.png",
    category: "fashion",
    discount: 9,
    id: "9782727801248745",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/16.SkmeiWatchBlack.png",
      "/assets/images/products/Fashion/Accessories/16.SkmeiWatchBlack.png",
      "/assets/images/products/Fashion/Accessories/16.SkmeiWatchBlack.png",
    ],
  },
  {
    price: 253,
    title: "Digital Wrist Watch",
    imgUrl: "/assets/images/products/Fashion/Accessories/17.DigitalWristWatch.png",
    category: "fashion",
    discount: 8,
    id: "8308589572862153",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/17.DigitalWristWatch.png",
      "/assets/images/products/Fashion/Accessories/17.DigitalWristWatch.png",
      "/assets/images/products/Fashion/Accessories/17.DigitalWristWatch.png",
    ],
  },
  {
    price: 208,
    title: "Dragon Red Wrist Watch",
    imgUrl: "/assets/images/products/Fashion/Accessories/18.DragonRedWristWatch.png",
    category: "fashion",
    discount: 7,
    id: "7442702726899737",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Accessories/18.DragonRedWristWatch.png",
      "/assets/images/products/Fashion/Accessories/18.DragonRedWristWatch.png",
      "/assets/images/products/Fashion/Accessories/18.DragonRedWristWatch.png",
    ],
  },
  {
    price: 258,
    title: "SIlver High Neck Sweater",
    imgUrl: "/assets/images/products/Fashion/Clothes/1.SilverHighNeckSweater.png",
    category: "fashion",
    discount: 6,
    id: "3556359043315229",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/1.SilverHighNeckSweater.png",
      "/assets/images/products/Fashion/Clothes/1.SilverHighNeckSweater.png",
      "/assets/images/products/Fashion/Clothes/1.SilverHighNeckSweater.png",
    ],
  },
  {
    price: 239,
    title: "Blue Blossom Frock",
    imgUrl: "/assets/images/products/Fashion/Clothes/2.BlueBlossomFrock.png",
    category: "fashion",
    discount: 7,
    id: "11135494383637745",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/2.BlueBlossomFrock.png",
      "/assets/images/products/Fashion/Clothes/2.BlueBlossomFrock.png",
      "/assets/images/products/Fashion/Clothes/2.BlueBlossomFrock.png",
    ],
  },
  {
    price: 116,
    title: "NBL Hoop Hoodie",
    imgUrl: "/assets/images/products/Fashion/Clothes/3.NBLHoopHoodie.png",
    category: "fashion",
    discount: 7,
    id: "23157220928128663",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/3.NBLHoopHoodie.png",
      "/assets/images/products/Fashion/Clothes/3.NBLHoopHoodie.png",
      "/assets/images/products/Fashion/Clothes/3.NBLHoopHoodie.png",
    ],
  },
  {
    price: 143,
    title: "Denim Blue Jeans",
    imgUrl: "/assets/images/products/Fashion/Clothes/4.DenimBlueJeans.png",
    category: "fashion",
    discount: 6,
    id: "05043878179285377",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/4.DenimBlueJeans.png",
      "/assets/images/products/Fashion/Clothes/4.DenimBlueJeans.png",
      "/assets/images/products/Fashion/Clothes/4.DenimBlueJeans.png",
    ],
  },
  {
    price: 201,
    title: "Brown Check Frock",
    imgUrl: "/assets/images/products/Fashion/Clothes/5.BrownCheckFrock.png",
    category: "fashion",
    discount: 9,
    id: "8801022576801985",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/5.BrownCheckFrock.png",
      "/assets/images/products/Fashion/Clothes/5.BrownCheckFrock.png",
      "/assets/images/products/Fashion/Clothes/5.BrownCheckFrock.png",
    ],
  },
  {
    price: 125,
    title: "Fashion Collection for Kids",
    imgUrl: "/assets/images/products/Fashion/Clothes/6.FashionCollectionforKids.png",
    category: "fashion",
    discount: 8,
    id: "29875524428757894",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/6.FashionCollectionforKids.png",
      "/assets/images/products/Fashion/Clothes/6.FashionCollectionforKids.png",
      "/assets/images/products/Fashion/Clothes/6.FashionCollectionforKids.png",
    ],
  },
  {
    price: 116,
    title: "Denim Classic Blue Jeans",
    imgUrl: "/assets/images/products/Fashion/Clothes/7.DenimClassicBlueJeans.png",
    category: "fashion",
    discount: 5,
    id: "7039177534297374",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/7.DenimClassicBlueJeans.png",
      "/assets/images/products/Fashion/Clothes/7.DenimClassicBlueJeans.png",
      "/assets/images/products/Fashion/Clothes/7.DenimClassicBlueJeans.png",
    ],
  },
  {
    price: 176,
    title: "Royal Black Suit-Pant",
    imgUrl: "/assets/images/products/Fashion/Clothes/8.RoyalBlackSuitPant.png",
    category: "fashion",
    discount: 6,
    id: "0942847581677222",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/8.RoyalBlackSuitPant.png",
      "/assets/images/products/Fashion/Clothes/8.RoyalBlackSuitPant.png",
      "/assets/images/products/Fashion/Clothes/8.RoyalBlackSuitPant.png",
    ],
  },
  {
    price: 170,
    title: "Denim Gabardine Collection",
    imgUrl: "/assets/images/products/Fashion/Clothes/9.DenimGabardineCollection.png",
    category: "fashion",
    discount: 8,
    id: "032897429765161856",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/9.DenimGabardineCollection.png",
      "/assets/images/products/Fashion/Clothes/9.DenimGabardineCollection.png",
      "/assets/images/products/Fashion/Clothes/9.DenimGabardineCollection.png",
    ],
  },
  {
    price: 115,
    title: "Lands Jacket",
    imgUrl: "/assets/images/products/Fashion/Clothes/10.LandsJacket.png",
    category: "fashion",
    discount: 10,
    id: "9918292675291798",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/10.LandsJacket.png",
      "/assets/images/products/Fashion/Clothes/10.LandsJacket.png",
      "/assets/images/products/Fashion/Clothes/10.LandsJacket.png",
    ],
  },
  {
    price: 217,
    title: "Striped Casual Shirt",
    imgUrl: "/assets/images/products/Fashion/Clothes/11.StripedCasual.png",
    category: "fashion",
    discount: 7,
    id: "8403766316512542",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/11.StripedCasual.png",
      "/assets/images/products/Fashion/Clothes/11.StripedCasual.png",
      "/assets/images/products/Fashion/Clothes/11.StripedCasual.png",
    ],
  },
  {
    price: 196,
    title: "Pink Kids Wear",
    imgUrl: "/assets/images/products/Fashion/Clothes/12.PinkKidsWear.png",
    category: "fashion",
    discount: 6,
    id: "9760632305792243",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/12.PinkKidsWear.png",
      "/assets/images/products/Fashion/Clothes/12.PinkKidsWear.png",
      "/assets/images/products/Fashion/Clothes/12.PinkKidsWear.png",
    ],
  },
  {
    price: 232,
    title: "High Waisted Gabardine",
    imgUrl: "/assets/images/products/Fashion/Clothes/13.HighWaistedGabardine.png",
    category: "fashion",
    discount: 5,
    id: "8635694924439523",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/13.HighWaistedGabardine.png",
      "/assets/images/products/Fashion/Clothes/13.HighWaistedGabardine.png",
      "/assets/images/products/Fashion/Clothes/13.HighWaistedGabardine.png",
    ],
  },
  {
    price: 126,
    title: "Blue Trousers",
    imgUrl: "/assets/images/products/Fashion/Clothes/14.BlueTrousers.png",
    category: "fashion",
    discount: 10,
    id: "03726639472003712",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/14.BlueTrousers.png",
      "/assets/images/products/Fashion/Clothes/14.BlueTrousers.png",
      "/assets/images/products/Fashion/Clothes/14.BlueTrousers.png",
    ],
  },
  {
    price: 159,
    title: "Geen Ski Jacket",
    imgUrl: "/assets/images/products/Fashion/Clothes/15.GreenSkiJacket.png",
    category: "fashion",
    discount: 7,
    id: "39699681905283457",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/15.GreenSkiJacket.png",
      "/assets/images/products/Fashion/Clothes/15.GreenSkiJacket.png",
      "/assets/images/products/Fashion/Clothes/15.GreenSkiJacket.png",
    ],
  },
  {
    price: 193,
    title: "Double Wool Overcoat",
    imgUrl: "/assets/images/products/Fashion/Clothes/16.DoubleWoolOvercoat.png",
    category: "fashion",
    discount: 6,
    id: "25749947771070514",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/16.DoubleWoolOvercoat.png",
      "/assets/images/products/Fashion/Clothes/16.DoubleWoolOvercoat.png",
      "/assets/images/products/Fashion/Clothes/16.DoubleWoolOvercoat.png",
    ],
  },
  {
    price: 118,
    title: "Strech Cargo Pants",
    imgUrl: "/assets/images/products/Fashion/Clothes/17.StrechCargoPants.png",
    category: "fashion",
    discount: 10,
    id: "025197283469760245",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/17.StrechCargoPants.png",
      "/assets/images/products/Fashion/Clothes/17.StrechCargoPants.png",
      "/assets/images/products/Fashion/Clothes/17.StrechCargoPants.png",
    ],
  },
  {
    price: 183,
    title: "Wool Overcoat Women",
    imgUrl: "/assets/images/products/Fashion/Clothes/18.WoolOvercoatWomen.png",
    category: "fashion",
    discount: 5,
    id: "7038602771501998",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/18.WoolOvercoatWomen.png",
      "/assets/images/products/Fashion/Clothes/18.WoolOvercoatWomen.png",
      "/assets/images/products/Fashion/Clothes/18.WoolOvercoatWomen.png",
    ],
  },
  {
    price: 163,
    title: "Black&White Sweater",
    imgUrl: "/assets/images/products/Fashion/Clothes/19.BlackWhiteSweater.png",
    category: "fashion",
    discount: 6,
    id: "3241886947019268",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/19.BlackWhiteSweater.png",
      "/assets/images/products/Fashion/Clothes/19.BlackWhiteSweater.png",
      "/assets/images/products/Fashion/Clothes/19.BlackWhiteSweater.png",
    ],
  },
  {
    price: 192,
    title: "Gray Overcoat Women",
    imgUrl: "/assets/images/products/Fashion/Clothes/20.GrayOvercoatWomen.png",
    category: "fashion",
    discount: 9,
    id: "16795823545741073",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/20.GrayOvercoatWomen.png",
      "/assets/images/products/Fashion/Clothes/20.GrayOvercoatWomen.png",
      "/assets/images/products/Fashion/Clothes/20.GrayOvercoatWomen.png",
    ],
  },
  {
    price: 244,
    title: "Yellow Casual Sweater",
    imgUrl: "/assets/images/products/Fashion/Clothes/21.YellowCasualSweater.png",
    category: "fashion",
    discount: 8,
    id: "10238222880579828",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/21.YellowCasualSweater.png",
      "/assets/images/products/Fashion/Clothes/21.YellowCasualSweater.png",
      "/assets/images/products/Fashion/Clothes/21.YellowCasualSweater.png",
    ],
  },
  {
    price: 268,
    title: "Casual Gray Pants",
    imgUrl: "/assets/images/products/Fashion/Clothes/22.CasualGrayPants.png",
    category: "fashion",
    discount: 7,
    id: "9376802149947758",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/22.CasualGrayPants.png",
      "/assets/images/products/Fashion/Clothes/22.CasualGrayPants.png",
      "/assets/images/products/Fashion/Clothes/22.CasualGrayPants.png",
    ],
  },
  {
    price: 116,
    title: "Adidas Winter Jacket",
    imgUrl: "/assets/images/products/Fashion/Clothes/23.AdidasWinterJacket.png",
    category: "fashion",
    discount: 8,
    id: "9094628109242846",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/23.AdidasWinterJacket.png",
      "/assets/images/products/Fashion/Clothes/23.AdidasWinterJacket.png",
      "/assets/images/products/Fashion/Clothes/23.AdidasWinterJacket.png",
    ],
  },
  {
    price: 268,
    title: "Olive Casual Sweater",
    imgUrl: "/assets/images/products/Fashion/Clothes/24.OliveCasualSweater.png",
    category: "fashion",
    discount: 8,
    id: "7057204143635338",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/24.OliveCasualSweater.png",
      "/assets/images/products/Fashion/Clothes/24.OliveCasualSweater.png",
      "/assets/images/products/Fashion/Clothes/24.OliveCasualSweater.png",
    ],
  },
  {
    price: 253,
    title: "Kids Rainbow Sweater",
    imgUrl: "/assets/images/products/Fashion/Clothes/25.KidsRainbowSweater.png",
    category: "fashion",
    discount: 9,
    id: "8293787061941646",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Clothes/25.KidsRainbowSweater.png",
      "/assets/images/products/Fashion/Clothes/25.KidsRainbowSweater.png",
      "/assets/images/products/Fashion/Clothes/25.KidsRainbowSweater.png",
    ],
  },
  {
    price: 153,
    title: "Heavy 22kt Gold Necklace Set",
    imgUrl: "/assets/images/products/Fashion/Jewellery/1.Heavy22ktGoldNecklaceSet.png",
    category: "fashion",
    discount: 6,
    id: "4716898315685121",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/1.Heavy22ktGoldNecklaceSet.png",
      "/assets/images/products/Fashion/Jewellery/1.Heavy22ktGoldNecklaceSet.png",
      "/assets/images/products/Fashion/Jewellery/1.Heavy22ktGoldNecklaceSet.png",
    ],
  },
  {
    price: 102,
    title: "Black Metal Ring",
    imgUrl: "/assets/images/products/Fashion/Jewellery/2.BlackMetalRing.png",
    category: "fashion",
    discount: 9,
    id: "30854366832933566",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/2.BlackMetalRing.png",
      "/assets/images/products/Fashion/Jewellery/2.BlackMetalRing.png",
      "/assets/images/products/Fashion/Jewellery/2.BlackMetalRing.png",
    ],
  },
  {
    price: 157,
    title: "Diamond SIlver Ring",
    imgUrl: "/assets/images/products/Fashion/Jewellery/3.DiamondSilverRing.png",
    category: "fashion",
    discount: 8,
    id: "06420375701360603",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/3.DiamondSilverRing.png",
      "/assets/images/products/Fashion/Jewellery/3.DiamondSilverRing.png",
      "/assets/images/products/Fashion/Jewellery/3.DiamondSilverRing.png",
    ],
  },
  {
    price: 277,
    title: "Black Stones Necklace",
    imgUrl: "/assets/images/products/Fashion/Jewellery/4.BlackStonesNecklace.png",
    category: "fashion",
    discount: 5,
    id: "3321948105468153",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/4.BlackStonesNecklace.png",
      "/assets/images/products/Fashion/Jewellery/4.BlackStonesNecklace.png",
      "/assets/images/products/Fashion/Jewellery/4.BlackStonesNecklace.png",
    ],
  },
  {
    price: 169,
    title: "Indian Pearl Earrings",
    imgUrl: "/assets/images/products/Fashion/Jewellery/5.IndianPearlEarrings.png",
    category: "fashion",
    discount: 8,
    id: "001903327694083412",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/5.IndianPearlEarrings.png",
      "/assets/images/products/Fashion/Jewellery/5.IndianPearlEarrings.png",
      "/assets/images/products/Fashion/Jewellery/5.IndianPearlEarrings.png",
    ],
  },
  {
    price: 181,
    title: "Indian Copper Earrings",
    imgUrl: "/assets/images/products/Fashion/Jewellery/6.IndianCopperEarrings.png",
    category: "fashion",
    discount: 5,
    id: "11543217719526444",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/6.IndianCopperEarrings.png",
      "/assets/images/products/Fashion/Jewellery/6.IndianCopperEarrings.png",
      "/assets/images/products/Fashion/Jewellery/6.IndianCopperEarrings.png",
    ],
  },
  {
    price: 158,
    title: "Indian Pearl Square Stone Necklace",
    imgUrl: "/assets/images/products/Fashion/Jewellery/7.IndianPearlSquareStoneNecklace.png",
    category: "fashion",
    discount: 8,
    id: "9631379746723581",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/7.IndianPearlSquareStoneNecklace.png",
      "/assets/images/products/Fashion/Jewellery/7.IndianPearlSquareStoneNecklace.png",
      "/assets/images/products/Fashion/Jewellery/7.IndianPearlSquareStoneNecklace.png",
    ],
  },
  {
    price: 235,
    title: "Indian Pearl Thread Earring",
    imgUrl: "/assets/images/products/Fashion/Jewellery/8.IndianPearlThreadEarrings.png",
    category: "fashion",
    discount: 8,
    id: "591985998938654",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/8.IndianPearlThreadEarrings.png",
      "/assets/images/products/Fashion/Jewellery/8.IndianPearlThreadEarrings.png",
      "/assets/images/products/Fashion/Jewellery/8.IndianPearlThreadEarrings.png",
    ],
  },
  {
    price: 104,
    title: "Heavy 20kt Gold Necklace",
    imgUrl: "/assets/images/products/Fashion/Jewellery/9.Heavy20ktGoldNecklace.png",
    category: "fashion",
    discount: 9,
    id: "9573201630529315",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/9.Heavy20ktGoldNecklace.png",
      "/assets/images/products/Fashion/Jewellery/9.Heavy20ktGoldNecklace.png",
      "/assets/images/products/Fashion/Jewellery/9.Heavy20ktGoldNecklace.png",
    ],
  },
  {
    price: 103,
    title: "Indian 8kt Gold Bracelet",
    imgUrl: "/assets/images/products/Fashion/Jewellery/10.Indian8ktGoldBracelet.png",
    category: "fashion",
    discount: 8,
    id: "9600460042041272",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/10.Indian8ktGoldBracelet.png",
      "/assets/images/products/Fashion/Jewellery/10.Indian8ktGoldBracelet.png",
      "/assets/images/products/Fashion/Jewellery/10.Indian8ktGoldBracelet.png",
    ],
  },
  {
    price: 299,
    title: "Heavy 5kt Gold Earrings",
    imgUrl: "/assets/images/products/Fashion/Jewellery/11.Heavy5ktIndianEarrings.png",
    category: "fashion",
    discount: 8,
    id: "5246618420483269",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/11.Heavy5ktIndianEarrings.png",
      "/assets/images/products/Fashion/Jewellery/11.Heavy5ktIndianEarrings.png",
      "/assets/images/products/Fashion/Jewellery/11.Heavy5ktIndianEarrings.png",
    ],
  },
  {
    price: 165,
    title: "Blue Stone Locket",
    imgUrl: "/assets/images/products/Fashion/Jewellery/12.BlueStoneLocket.png",
    category: "fashion",
    discount: 10,
    id: "20186296115728397",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/12.BlueStoneLocket.png",
      "/assets/images/products/Fashion/Jewellery/12.BlueStoneLocket.png",
      "/assets/images/products/Fashion/Jewellery/12.BlueStoneLocket.png",
    ],
  },
  {
    price: 169,
    title: "Black Stone Modern Locket",
    imgUrl: "/assets/images/products/Fashion/Jewellery/13.BlackStoneModernLocket.png",
    category: "fashion",
    discount: 6,
    id: "2846646295564206",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/13.BlackStoneModernLocket.png",
      "/assets/images/products/Fashion/Jewellery/13.BlackStoneModernLocket.png",
      "/assets/images/products/Fashion/Jewellery/13.BlackStoneModernLocket.png",
    ],
  },
  {
    price: 114,
    title: "Diamond Wedding Rings",
    imgUrl: "/assets/images/products/Fashion/Jewellery/14.DiamondWeddingRings.png",
    category: "fashion",
    discount: 8,
    id: "6325232808589716",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/14.DiamondWeddingRings.png",
      "/assets/images/products/Fashion/Jewellery/14.DiamondWeddingRings.png",
      "/assets/images/products/Fashion/Jewellery/14.DiamondWeddingRings.png",
    ],
  },
  {
    price: 294,
    title: "Indian 6kt Gold Earrings",
    imgUrl: "/assets/images/products/Fashion/Jewellery/15.Indian6ktGoldEarrings.png",
    category: "fashion",
    discount: 6,
    id: "08761167496970046",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/15.Indian6ktGoldEarrings.png",
      "/assets/images/products/Fashion/Jewellery/15.Indian6ktGoldEarrings.png",
      "/assets/images/products/Fashion/Jewellery/15.Indian6ktGoldEarrings.png",
    ],
  },
  {
    price: 122,
    title: "Custom Made Threads Beads NEcklace set",
    imgUrl: "/assets/images/products/Fashion/Jewellery/16.CustomMadeThreadsBeadsNecklaceSet.png",
    category: "fashion",
    discount: 6,
    id: "938049707395983",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/16.CustomMadeThreadsBeadsNecklaceSet.png",
      "/assets/images/products/Fashion/Jewellery/16.CustomMadeThreadsBeadsNecklaceSet.png",
      "/assets/images/products/Fashion/Jewellery/16.CustomMadeThreadsBeadsNecklaceSet.png",
    ],
  },
  {
    price: 158,
    title: "Heavy Stone Colorful Necklace",
    imgUrl: "/assets/images/products/Fashion/Jewellery/17.HeavyStoneColorfulNecklace.png",
    category: "fashion",
    discount: 8,
    id: "5983652469725809",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/17.HeavyStoneColorfulNecklace.png",
      "/assets/images/products/Fashion/Jewellery/17.HeavyStoneColorfulNecklace.png",
      "/assets/images/products/Fashion/Jewellery/17.HeavyStoneColorfulNecklace.png",
    ],
  },
  {
    price: 283,
    title: "Beads and Threads Simple Earrings",
    imgUrl: "/assets/images/products/Fashion/Jewellery/18.BeadsandThreadsSimpleEarrings.png",
    category: "fashion",
    discount: 7,
    id: "972696967439219",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/18.BeadsandThreadsSimpleEarrings.png",
      "/assets/images/products/Fashion/Jewellery/18.BeadsandThreadsSimpleEarrings.png",
      "/assets/images/products/Fashion/Jewellery/18.BeadsandThreadsSimpleEarrings.png",
    ],
  },
  {
    price: 173,
    title: "Tamil Wedding Necklace Set",
    imgUrl: "/assets/images/products/Fashion/Jewellery/19.TamilWeddingNecklaceSet.png",
    category: "fashion",
    discount: 10,
    id: "2766474666981631",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/19.TamilWeddingNecklaceSet.png",
      "/assets/images/products/Fashion/Jewellery/19.TamilWeddingNecklaceSet.png",
      "/assets/images/products/Fashion/Jewellery/19.TamilWeddingNecklaceSet.png",
    ],
  },
  {
    price: 136,
    title: "Shahi 21kt Gold Necklace Set",
    imgUrl: "/assets/images/products/Fashion/Jewellery/20.Shahi21ktGoldNecklaceSet.png",
    category: "fashion",
    discount: 7,
    id: "7043585830841899",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/20.Shahi21ktGoldNecklaceSet.png",
      "/assets/images/products/Fashion/Jewellery/20.Shahi21ktGoldNecklaceSet.png",
      "/assets/images/products/Fashion/Jewellery/20.Shahi21ktGoldNecklaceSet.png",
    ],
  },
  {
    price: 243,
    title: "Feathers and Beads Bohemian Necklace",
    imgUrl: "/assets/images/products/Fashion/Jewellery/21.FeathersandBeadsBohemianNecklace.png",
    category: "fashion",
    discount: 9,
    id: "6501489081180665",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/21.FeathersandBeadsBohemianNecklace.png",
      "/assets/images/products/Fashion/Jewellery/21.FeathersandBeadsBohemianNecklace.png",
      "/assets/images/products/Fashion/Jewellery/21.FeathersandBeadsBohemianNecklace.png",
    ],
  },
  {
    price: 179,
    title: "Red Peacock Tail Earrings",
    imgUrl: "/assets/images/products/Fashion/Jewellery/22.RedPeacockTailEarrings.png",
    category: "fashion",
    discount: 8,
    id: "20976592953185347",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/22.RedPeacockTailEarrings.png",
      "/assets/images/products/Fashion/Jewellery/22.RedPeacockTailEarrings.png",
      "/assets/images/products/Fashion/Jewellery/22.RedPeacockTailEarrings.png",
    ],
  },
  {
    price: 225,
    title: "heavy 13kt Gold Wedding Necklace Set",
    imgUrl: "/assets/images/products/Fashion/Jewellery/23.Heavy13ktGoldWeddingNecklaceSet.png",
    category: "fashion",
    discount: 7,
    id: "9758936660790869",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Jewellery/23.Heavy13ktGoldWeddingNecklaceSet.png",
      "/assets/images/products/Fashion/Jewellery/23.Heavy13ktGoldWeddingNecklaceSet.png",
      "/assets/images/products/Fashion/Jewellery/23.Heavy13ktGoldWeddingNecklaceSet.png",
    ],
  },
  {
    price: 186,
    title: "Yike Red",
    imgUrl: "/assets/images/products/Fashion/Shoes/1.NikeRed.png",
    category: "fashion",
    discount: 9,
    id: "9746917737103542",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/1.NikeRed.png",
      "/assets/images/products/Fashion/Shoes/1.NikeRed.png",
      "/assets/images/products/Fashion/Shoes/1.NikeRed.png",
    ],
  },
  {
    price: 154,
    title: "North Star Cream",
    imgUrl: "/assets/images/products/Fashion/Shoes/2.NorthStarCream.png",
    category: "fashion",
    discount: 8,
    id: "5247408775688287",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/2.NorthStarCream.png",
      "/assets/images/products/Fashion/Shoes/2.NorthStarCream.png",
      "/assets/images/products/Fashion/Shoes/2.NorthStarCream.png",
    ],
  },
  {
    price: 277,
    title: "Puma Black",
    imgUrl: "/assets/images/products/Fashion/Shoes/3.PumaBlack.png",
    category: "fashion",
    discount: 6,
    id: "9850998539472162",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/3.PumaBlack.png",
      "/assets/images/products/Fashion/Shoes/3.PumaBlack.png",
      "/assets/images/products/Fashion/Shoes/3.PumaBlack.png",
    ],
  },
  {
    price: 244,
    title: "Yike Fluffy Yellow",
    imgUrl: "/assets/images/products/Fashion/Shoes/4.NikeFluffyYellow.png",
    category: "fashion",
    discount: 8,
    id: "8531866818969311",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/4.NikeFluffyYellow.png",
      "/assets/images/products/Fashion/Shoes/4.NikeFluffyYellow.png",
      "/assets/images/products/Fashion/Shoes/4.NikeFluffyYellow.png",
    ],
  },
  {
    price: 241,
    title: "Yike Blue",
    imgUrl: "/assets/images/products/Fashion/Shoes/5.NikeBlue.png",
    category: "fashion",
    discount: 10,
    id: "11416891007649443",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/5.NikeBlue.png",
      "/assets/images/products/Fashion/Shoes/5.NikeBlue.png",
      "/assets/images/products/Fashion/Shoes/5.NikeBlue.png",
    ],
  },
  {
    price: 244,
    title: "North Star Black",
    imgUrl: "/assets/images/products/Fashion/Shoes/6.NorthStarBlack.png",
    category: "fashion",
    discount: 10,
    id: "7945053634258166",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/6.NorthStarBlack.png",
      "/assets/images/products/Fashion/Shoes/6.NorthStarBlack.png",
      "/assets/images/products/Fashion/Shoes/6.NorthStarBlack.png",
    ],
  },
  {
    price: 137,
    title: "NIke Green",
    imgUrl: "/assets/images/products/Fashion/Shoes/7.NikeGreen.png",
    category: "fashion",
    discount: 9,
    id: "6048429368676118",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/7.NikeGreen.png",
      "/assets/images/products/Fashion/Shoes/7.NikeGreen.png",
      "/assets/images/products/Fashion/Shoes/7.NikeGreen.png",
    ],
  },
  {
    price: 161,
    title: "Yike Sill",
    imgUrl: "/assets/images/products/Fashion/Shoes/8.NikeSkill.png",
    category: "fashion",
    discount: 8,
    id: "801458670113826",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/8.NikeSkill.png",
      "/assets/images/products/Fashion/Shoes/8.NikeSkill.png",
      "/assets/images/products/Fashion/Shoes/8.NikeSkill.png",
    ],
  },
  {
    price: 186,
    title: "Adidas Green",
    imgUrl: "/assets/images/products/Fashion/Shoes/9.AdidasGreen.png",
    category: "fashion",
    discount: 10,
    id: "6338262510123336",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/9.AdidasGreen.png",
      "/assets/images/products/Fashion/Shoes/9.AdidasGreen.png",
      "/assets/images/products/Fashion/Shoes/9.AdidasGreen.png",
    ],
  },
  {
    price: 216,
    title: "Adidas White",
    imgUrl: "/assets/images/products/Fashion/Shoes/10.AdidasWhite.png",
    category: "fashion",
    discount: 9,
    id: "9299160844544068",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/10.AdidasWhite.png",
      "/assets/images/products/Fashion/Shoes/10.AdidasWhite.png",
      "/assets/images/products/Fashion/Shoes/10.AdidasWhite.png",
    ],
  },
  {
    price: 221,
    title: "Flow White",
    imgUrl: "/assets/images/products/Fashion/Shoes/11.Flowwhite.png",
    category: "fashion",
    discount: 5,
    id: "9303786318668705",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/11.Flowwhite.png",
      "/assets/images/products/Fashion/Shoes/11.Flowwhite.png",
      "/assets/images/products/Fashion/Shoes/11.Flowwhite.png",
    ],
  },
  {
    price: 139,
    title: "Yike Air White",
    imgUrl: "/assets/images/products/Fashion/Shoes/12.NikeAirWhite.png",
    category: "fashion",
    discount: 8,
    id: "967559691319007",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/12.NikeAirWhite.png",
      "/assets/images/products/Fashion/Shoes/12.NikeAirWhite.png",
      "/assets/images/products/Fashion/Shoes/12.NikeAirWhite.png",
    ],
  },
  {
    price: 194,
    title: "Puma Red",
    imgUrl: "/assets/images/products/Fashion/Shoes/13.PumaRed.png",
    category: "fashion",
    discount: 7,
    id: "9704117634980605",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/13.PumaRed.png",
      "/assets/images/products/Fashion/Shoes/13.PumaRed.png",
      "/assets/images/products/Fashion/Shoes/13.PumaRed.png",
    ],
  },
  {
    price: 145,
    title: "Yike Pink",
    imgUrl: "/assets/images/products/Fashion/Shoes/14.NikePink.png",
    category: "fashion",
    discount: 10,
    id: "045267941567913805",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/14.NikePink.png",
      "/assets/images/products/Fashion/Shoes/14.NikePink.png",
      "/assets/images/products/Fashion/Shoes/14.NikePink.png",
    ],
  },
  {
    price: 111,
    title: "Yike Mint",
    imgUrl: "/assets/images/products/Fashion/Shoes/15.NikeMint.png",
    category: "fashion",
    discount: 9,
    id: "6865810585331256",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/15.NikeMint.png",
      "/assets/images/products/Fashion/Shoes/15.NikeMint.png",
      "/assets/images/products/Fashion/Shoes/15.NikeMint.png",
    ],
  },
  {
    price: 162,
    title: "Yike Silver",
    imgUrl: "/assets/images/products/Fashion/Shoes/16.NikeSilver.png",
    category: "fashion",
    discount: 5,
    id: "1022555522946631",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/16.NikeSilver.png",
      "/assets/images/products/Fashion/Shoes/16.NikeSilver.png",
      "/assets/images/products/Fashion/Shoes/16.NikeSilver.png",
    ],
  },
  {
    price: 229,
    title: "North Star Olive",
    imgUrl: "/assets/images/products/Fashion/Shoes/17.NorthStarOlive.png",
    category: "fashion",
    discount: 5,
    id: "5865774321264854",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/17.NorthStarOlive.png",
      "/assets/images/products/Fashion/Shoes/17.NorthStarOlive.png",
      "/assets/images/products/Fashion/Shoes/17.NorthStarOlive.png",
    ],
  },
  {
    price: 276,
    title: "Adidas Rainbow",
    imgUrl: "/assets/images/products/Fashion/Shoes/18.AdidasRainbow.png",
    category: "fashion",
    discount: 6,
    id: "7918845744501337",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/18.AdidasRainbow.png",
      "/assets/images/products/Fashion/Shoes/18.AdidasRainbow.png",
      "/assets/images/products/Fashion/Shoes/18.AdidasRainbow.png",
    ],
  },
  {
    price: 218,
    title: "Yike Yellow black",
    imgUrl: "/assets/images/products/Fashion/Shoes/19.NikeYellowBlack.png",
    category: "fashion",
    discount: 5,
    id: "26722658843546654",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/19.NikeYellowBlack.png",
      "/assets/images/products/Fashion/Shoes/19.NikeYellowBlack.png",
      "/assets/images/products/Fashion/Shoes/19.NikeYellowBlack.png",
    ],
  },
  {
    price: 255,
    title: "Merrell Woods",
    imgUrl: "/assets/images/products/Fashion/Shoes/20.MerrellWoods.png",
    category: "fashion",
    discount: 9,
    id: "19333037719180424",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/20.MerrellWoods.png",
      "/assets/images/products/Fashion/Shoes/20.MerrellWoods.png",
      "/assets/images/products/Fashion/Shoes/20.MerrellWoods.png",
    ],
  },
  {
    price: 110,
    title: "North Star Blue",
    imgUrl: "/assets/images/products/Fashion/Shoes/21.NorthStarBlue.png",
    category: "fashion",
    discount: 10,
    id: "15836934634024713",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/21.NorthStarBlue.png",
      "/assets/images/products/Fashion/Shoes/21.NorthStarBlue.png",
      "/assets/images/products/Fashion/Shoes/21.NorthStarBlue.png",
    ],
  },
  {
    price: 204,
    title: "Yike Black Red",
    imgUrl: "/assets/images/products/Fashion/Shoes/22.NikeBlackRed.png",
    category: "fashion",
    discount: 9,
    id: "6704375382200447",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/22.NikeBlackRed.png",
      "/assets/images/products/Fashion/Shoes/22.NikeBlackRed.png",
      "/assets/images/products/Fashion/Shoes/22.NikeBlackRed.png",
    ],
  },
  {
    price: 274,
    title: "Yike Silver Race",
    imgUrl: "/assets/images/products/Fashion/Shoes/23.NikeSilverRace.png",
    category: "fashion",
    discount: 8,
    id: "5535662242289907",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Fashion/Shoes/23.NikeSilverRace.png",
      "/assets/images/products/Fashion/Shoes/23.NikeSilverRace.png",
      "/assets/images/products/Fashion/Shoes/23.NikeSilverRace.png",
    ],
  },
  {
    price: 159,
    title: "Sakti Sambar Powder",
    imgUrl: "/assets/images/products/Groceries/1.SaktiSambarPowder.png",
    category: "groceries",
    discount: 6,
    id: "451565305496082",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Groceries/1.SaktiSambarPowder.png",
      "/assets/images/products/Groceries/1.SaktiSambarPowder.png",
      "/assets/images/products/Groceries/1.SaktiSambarPowder.png",
    ],
  },
  {
    price: 188,
    title: "Premium Grocery Collection",
    imgUrl: "/assets/images/products/Groceries/2.PremiumGroceryCollection.png",
    category: "groceries",
    discount: 6,
    id: "260812489744529",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Groceries/2.PremiumGroceryCollection.png",
      "/assets/images/products/Groceries/2.PremiumGroceryCollection.png",
      "/assets/images/products/Groceries/2.PremiumGroceryCollection.png",
    ],
  },
  {
    price: 253,
    title: "Premium Grocery Pack",
    imgUrl: "/assets/images/products/Groceries/3.PremiumGroceryPack.png",
    category: "groceries",
    discount: 6,
    id: "703414859860831",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Groceries/3.PremiumGroceryPack.png",
      "/assets/images/products/Groceries/3.PremiumGroceryPack.png",
      "/assets/images/products/Groceries/3.PremiumGroceryPack.png",
    ],
  },
  {
    price: 145,
    title: "Fresh&Real CHole Masala",
    imgUrl: "/assets/images/products/Groceries/4.FreashRealCholeMasala.png",
    category: "groceries",
    discount: 8,
    id: "39785868816407843",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Groceries/4.FreashRealCholeMasala.png",
      "/assets/images/products/Groceries/4.FreashRealCholeMasala.png",
      "/assets/images/products/Groceries/4.FreashRealCholeMasala.png",
    ],
  },
  {
    price: 267,
    title: "Gum Pack",
    imgUrl: "/assets/images/products/Groceries/5.GumPack.png",
    category: "groceries",
    discount: 6,
    id: "6549994304778879",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Groceries/5.GumPack.png",
      "/assets/images/products/Groceries/5.GumPack.png",
      "/assets/images/products/Groceries/5.GumPack.png",
    ],
  },
  {
    price: 121,
    title: "Indian Grocery Products Pack",
    imgUrl: "/assets/images/products/Groceries/6.IndianGroceryProductsPack.png",
    category: "groceries",
    discount: 6,
    id: "21616076917090643",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Groceries/6.IndianGroceryProductsPack.png",
      "/assets/images/products/Groceries/6.IndianGroceryProductsPack.png",
      "/assets/images/products/Groceries/6.IndianGroceryProductsPack.png",
    ],
  },
  {
    price: 234,
    title: "Saffola Gold Oil",
    imgUrl: "/assets/images/products/Groceries/7.SaffolaGoldOil.png",
    category: "groceries",
    discount: 6,
    id: "7251911355964262",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Groceries/7.SaffolaGoldOil.png",
      "/assets/images/products/Groceries/7.SaffolaGoldOil.png",
      "/assets/images/products/Groceries/7.SaffolaGoldOil.png",
    ],
  },
  {
    price: 284,
    title: "Colgate Advance Protection Toothpaste",
    imgUrl: "/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png",
    category: "groceries",
    discount: 8,
    id: "15405364776989727",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png",
      "/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png",
      "/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png",
    ],
  },
  {
    price: 164,
    title: "Catch Sprinklers Chat Masala",
    imgUrl: "/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png",
    category: "groceries",
    discount: 6,
    id: "48625873153846744",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png",
      "/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png",
      "/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png",
    ],
  },
  {
    price: 184,
    title: "Catch Italian Seasoning Grinder",
    imgUrl: "/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png",
    category: "groceries",
    discount: 5,
    id: "44301273412220143",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png",
      "/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png",
      "/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png",
    ],
  },
  {
    price: 187,
    title: "Tadka Garam Masala",
    imgUrl: "/assets/images/products/Groceries/11.TadkaGaramMasala.png",
    category: "groceries",
    discount: 6,
    id: "931558595694943",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Groceries/11.TadkaGaramMasala.png",
      "/assets/images/products/Groceries/11.TadkaGaramMasala.png",
      "/assets/images/products/Groceries/11.TadkaGaramMasala.png",
    ],
  },
  {
    price: 104,
    title: "Thanks a Latte Coffee",
    imgUrl: "/assets/images/products/Groceries/12.ThanksaLatteCoffee.png",
    category: "groceries",
    discount: 9,
    id: "7136950686820032",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Groceries/12.ThanksaLatteCoffee.png",
      "/assets/images/products/Groceries/12.ThanksaLatteCoffee.png",
      "/assets/images/products/Groceries/12.ThanksaLatteCoffee.png",
    ],
  },
  {
    price: 136,
    title: "Grocery Package Offer",
    imgUrl: "/assets/images/products/Groceries/13.GroceryPackageOffer.png",
    category: "groceries",
    discount: 7,
    id: "5472513236284331",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Groceries/13.GroceryPackageOffer.png",
      "/assets/images/products/Groceries/13.GroceryPackageOffer.png",
      "/assets/images/products/Groceries/13.GroceryPackageOffer.png",
    ],
  },
  {
    price: 299,
    title: "ACI Products",
    imgUrl: "/assets/images/products/Groceries/14.ACIProducts.png",
    category: "groceries",
    discount: 8,
    id: "09918166874550693",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Groceries/14.ACIProducts.png",
      "/assets/images/products/Groceries/14.ACIProducts.png",
      "/assets/images/products/Groceries/14.ACIProducts.png",
    ],
  },
  {
    price: 244,
    title: "LIghtskin Soap",
    imgUrl: "/assets/images/products/Groceries/15.LightskinSoap.png",
    category: "groceries",
    discount: 6,
    id: "3456597090812237",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Groceries/15.LightskinSoap.png",
      "/assets/images/products/Groceries/15.LightskinSoap.png",
      "/assets/images/products/Groceries/15.LightskinSoap.png",
    ],
  },
  {
    price: 236,
    title: "Artidoro Rodriguez Coffee",
    imgUrl: "/assets/images/products/Groceries/16.ArtidoroRodriguezCoffee.png",
    category: "groceries",
    discount: 5,
    id: "02885833684875494",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Groceries/16.ArtidoroRodriguezCoffee.png",
      "/assets/images/products/Groceries/16.ArtidoroRodriguezCoffee.png",
      "/assets/images/products/Groceries/16.ArtidoroRodriguezCoffee.png",
    ],
  },
  {
    price: 182,
    title: "Dove Sensitive Skin Soap",
    imgUrl: "/assets/images/products/Groceries/17.DoveSensitiveSkinSoap.png",
    category: "groceries",
    discount: 10,
    id: "5742721107233657",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Groceries/17.DoveSensitiveSkinSoap.png",
      "/assets/images/products/Groceries/17.DoveSensitiveSkinSoap.png",
      "/assets/images/products/Groceries/17.DoveSensitiveSkinSoap.png",
    ],
  },
  {
    price: 138,
    title: "Aniket Garam Masala",
    imgUrl: "/assets/images/products/Groceries/18.AniketGaramMasala.png",
    category: "groceries",
    discount: 7,
    id: "957229923276719",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Groceries/18.AniketGaramMasala.png",
      "/assets/images/products/Groceries/18.AniketGaramMasala.png",
      "/assets/images/products/Groceries/18.AniketGaramMasala.png",
    ],
  },
  {
    price: 163,
    title: "Portsmouth Soap",
    imgUrl: "/assets/images/products/Groceries/19.PortsmouthSoaps.png",
    category: "groceries",
    discount: 9,
    id: "06838541830073375",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Groceries/19.PortsmouthSoaps.png",
      "/assets/images/products/Groceries/19.PortsmouthSoaps.png",
      "/assets/images/products/Groceries/19.PortsmouthSoaps.png",
    ],
  },
  {
    price: 161,
    title: "Premium Kitchen Pack",
    imgUrl: "/assets/images/products/Groceries/20.PremiumKitchenPack.png",
    category: "groceries",
    discount: 9,
    id: "6739707328481122",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Groceries/20.PremiumKitchenPack.png",
      "/assets/images/products/Groceries/20.PremiumKitchenPack.png",
      "/assets/images/products/Groceries/20.PremiumKitchenPack.png",
    ],
  },
  {
    price: 242,
    title: "Mega Ssardines",
    imgUrl: "/assets/images/products/Groceries/21.MegaSardines.png",
    category: "groceries",
    discount: 9,
    id: "22926015021307822",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Groceries/21.MegaSardines.png",
      "/assets/images/products/Groceries/21.MegaSardines.png",
      "/assets/images/products/Groceries/21.MegaSardines.png",
    ],
  },
  {
    price: 158,
    title: "Organic Company Products",
    imgUrl: "/assets/images/products/Groceries/22.OrganicCompanyProducts.png",
    category: "groceries",
    discount: 10,
    id: "63178637228902",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Groceries/22.OrganicCompanyProducts.png",
      "/assets/images/products/Groceries/22.OrganicCompanyProducts.png",
      "/assets/images/products/Groceries/22.OrganicCompanyProducts.png",
    ],
  },
  {
    price: 192,
    title: "Earnest Sardines",
    imgUrl: "/assets/images/products/Groceries/23.EarnestSardines.png",
    category: "groceries",
    discount: 8,
    id: "6352889806404016",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Groceries/23.EarnestSardines.png",
      "/assets/images/products/Groceries/23.EarnestSardines.png",
      "/assets/images/products/Groceries/23.EarnestSardines.png",
    ],
  },
  {
    price: 136,
    title: "Parachute Coconut Oil",
    imgUrl: "/assets/images/products/Groceries/24.ParachuteCoconutOil.png",
    category: "groceries",
    discount: 7,
    id: "2552447627698855",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Groceries/24.ParachuteCoconutOil.png",
      "/assets/images/products/Groceries/24.ParachuteCoconutOil.png",
      "/assets/images/products/Groceries/24.ParachuteCoconutOil.png",
    ],
  },
  {
    price: 186,
    title: "Cafe Torrefaction Fraiche",
    imgUrl: "/assets/images/products/Groceries/25.CafeTorrefactionFraiche.png",
    category: "groceries",
    discount: 10,
    id: "558891943143065",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Groceries/25.CafeTorrefactionFraiche.png",
      "/assets/images/products/Groceries/25.CafeTorrefactionFraiche.png",
      "/assets/images/products/Groceries/25.CafeTorrefactionFraiche.png",
    ],
  },
  {
    price: 147,
    title: "Madina Soaps",
    imgUrl: "/assets/images/products/Groceries/26.MadinaSoaps.png",
    category: "groceries",
    discount: 8,
    id: "14978085835966604",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Groceries/26.MadinaSoaps.png",
      "/assets/images/products/Groceries/26.MadinaSoaps.png",
      "/assets/images/products/Groceries/26.MadinaSoaps.png",
    ],
  },
  {
    price: 100,
    title: "Sardines Pack",
    imgUrl: "/assets/images/products/Groceries/27.SardinesPack.png",
    category: "groceries",
    discount: 9,
    id: "9518573707450391",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Groceries/27.SardinesPack.png",
      "/assets/images/products/Groceries/27.SardinesPack.png",
      "/assets/images/products/Groceries/27.SardinesPack.png",
    ],
  },
  {
    price: 238,
    title: "Turmeric Powder",
    imgUrl: "/assets/images/products/Groceries/28.TurmericPowder.png",
    category: "groceries",
    discount: 6,
    id: "41464409223876575",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Groceries/28.TurmericPowder.png",
      "/assets/images/products/Groceries/28.TurmericPowder.png",
      "/assets/images/products/Groceries/28.TurmericPowder.png",
    ],
  },
  {
    price: 278,
    title: "Biossance Oil",
    imgUrl: "/assets/images/products/Health&Beauty/1.BiossanceOil.png",
    category: "health&beauty",
    discount: 7,
    id: "583878642391362",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Health&Beauty/1.BiossanceOil.png",
      "/assets/images/products/Health&Beauty/1.BiossanceOil.png",
      "/assets/images/products/Health&Beauty/1.BiossanceOil.png",
    ],
  },
  {
    price: 187,
    title: "Amorpacific Products",
    imgUrl: "/assets/images/products/Health&Beauty/2.AmorpacificProducts.png",
    category: "health&beauty",
    discount: 9,
    id: "8292248449713833",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Health&Beauty/2.AmorpacificProducts.png",
      "/assets/images/products/Health&Beauty/2.AmorpacificProducts.png",
      "/assets/images/products/Health&Beauty/2.AmorpacificProducts.png",
    ],
  },
  {
    price: 161,
    title: "Covergirl Vitalist",
    imgUrl: "/assets/images/products/Health&Beauty/3.CovergirlVitalist.png",
    category: "health&beauty",
    discount: 7,
    id: "9023909150604486",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Health&Beauty/3.CovergirlVitalist.png",
      "/assets/images/products/Health&Beauty/3.CovergirlVitalist.png",
      "/assets/images/products/Health&Beauty/3.CovergirlVitalist.png",
    ],
  },
  {
    price: 219,
    title: "Dr.Alka's Hair Shine",
    imgUrl: "/assets/images/products/Health&Beauty/4.Dr.Alka'sHairShine.png",
    category: "health&beauty",
    discount: 10,
    id: "305546580557962",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Health&Beauty/4.Dr.Alka'sHairShine.png",
      "/assets/images/products/Health&Beauty/4.Dr.Alka'sHairShine.png",
      "/assets/images/products/Health&Beauty/4.Dr.Alka'sHairShine.png",
    ],
  },
  {
    price: 162,
    title: "Huda Beauty Nailpolish",
    imgUrl: "/assets/images/products/Health&Beauty/5.HudaBeautyNailpolish.png",
    category: "health&beauty",
    discount: 7,
    id: "011206587728114004",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Health&Beauty/5.HudaBeautyNailpolish.png",
      "/assets/images/products/Health&Beauty/5.HudaBeautyNailpolish.png",
      "/assets/images/products/Health&Beauty/5.HudaBeautyNailpolish.png",
    ],
  },
  {
    price: 200,
    title: "Skin Glow Oil",
    imgUrl: "/assets/images/products/Health&Beauty/6.SKinGlowOil.png",
    category: "health&beauty",
    discount: 10,
    id: "5474401417942285",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Health&Beauty/6.SKinGlowOil.png",
      "/assets/images/products/Health&Beauty/6.SKinGlowOil.png",
      "/assets/images/products/Health&Beauty/6.SKinGlowOil.png",
    ],
  },
  {
    price: 259,
    title: "Venus Makeup Product",
    imgUrl: "/assets/images/products/Health&Beauty/7.VenusMakeupProduct.png",
    category: "health&beauty",
    discount: 10,
    id: "8127030576405274",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Health&Beauty/7.VenusMakeupProduct.png",
      "/assets/images/products/Health&Beauty/7.VenusMakeupProduct.png",
      "/assets/images/products/Health&Beauty/7.VenusMakeupProduct.png",
    ],
  },
  {
    price: 112,
    title: "Volition Products",
    imgUrl: "/assets/images/products/Health&Beauty/8.VolitionProducts.png",
    category: "health&beauty",
    discount: 7,
    id: "028932852321856162",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Health&Beauty/8.VolitionProducts.png",
      "/assets/images/products/Health&Beauty/8.VolitionProducts.png",
      "/assets/images/products/Health&Beauty/8.VolitionProducts.png",
    ],
  },
  {
    price: 152,
    title: "Aniise Cream",
    imgUrl: "/assets/images/products/Health&Beauty/9.AniiseCream.png",
    category: "health&beauty",
    discount: 8,
    id: "03598461967209232",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Health&Beauty/9.AniiseCream.png",
      "/assets/images/products/Health&Beauty/9.AniiseCream.png",
      "/assets/images/products/Health&Beauty/9.AniiseCream.png",
    ],
  },
  {
    price: 179,
    title: "Mykirei Hand Wash",
    imgUrl: "/assets/images/products/Health&Beauty/10.MykireihandWash.png",
    category: "health&beauty",
    discount: 6,
    id: "7296895629597577",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Health&Beauty/10.MykireihandWash.png",
      "/assets/images/products/Health&Beauty/10.MykireihandWash.png",
      "/assets/images/products/Health&Beauty/10.MykireihandWash.png",
    ],
  },
  {
    price: 140,
    title: "Rahua Classic Conditioner",
    imgUrl: "/assets/images/products/Health&Beauty/11.RahuaClassicConditioner.png",
    category: "health&beauty",
    discount: 9,
    id: "6674603340876586",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Health&Beauty/11.RahuaClassicConditioner.png",
      "/assets/images/products/Health&Beauty/11.RahuaClassicConditioner.png",
      "/assets/images/products/Health&Beauty/11.RahuaClassicConditioner.png",
    ],
  },
  {
    price: 170,
    title: "Beauty Society anti acne Mask",
    imgUrl: "/assets/images/products/Health&Beauty/12.BeautySocietyantiacnemask.png",
    category: "health&beauty",
    discount: 7,
    id: "42822784078111553",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Health&Beauty/12.BeautySocietyantiacnemask.png",
      "/assets/images/products/Health&Beauty/12.BeautySocietyantiacnemask.png",
      "/assets/images/products/Health&Beauty/12.BeautySocietyantiacnemask.png",
    ],
  },
  {
    price: 211,
    title: "Mistral Lipstick",
    imgUrl: "/assets/images/products/Health&Beauty/13.MistralLipstick.png",
    category: "health&beauty",
    discount: 5,
    id: "8931429553115262",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Health&Beauty/13.MistralLipstick.png",
      "/assets/images/products/Health&Beauty/13.MistralLipstick.png",
      "/assets/images/products/Health&Beauty/13.MistralLipstick.png",
    ],
  },
  {
    price: 227,
    title: "Kylie Skin Face Wash",
    imgUrl: "/assets/images/products/Health&Beauty/14.KylieskinFaceWash.png",
    category: "health&beauty",
    discount: 6,
    id: "45503187062037376",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Health&Beauty/14.KylieskinFaceWash.png",
      "/assets/images/products/Health&Beauty/14.KylieskinFaceWash.png",
      "/assets/images/products/Health&Beauty/14.KylieskinFaceWash.png",
    ],
  },
  {
    price: 134,
    title: "Amala Products",
    imgUrl: "/assets/images/products/Health&Beauty/15.AmalaProducts.png",
    category: "health&beauty",
    discount: 8,
    id: "9470086557386355",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Health&Beauty/15.AmalaProducts.png",
      "/assets/images/products/Health&Beauty/15.AmalaProducts.png",
      "/assets/images/products/Health&Beauty/15.AmalaProducts.png",
    ],
  },
  {
    price: 107,
    title: "Caudalie Hand and Nail Cream",
    imgUrl: "/assets/images/products/Health&Beauty/16.CaudalieHandandNailCream.png",
    category: "health&beauty",
    discount: 7,
    id: "17360361404068203",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Health&Beauty/16.CaudalieHandandNailCream.png",
      "/assets/images/products/Health&Beauty/16.CaudalieHandandNailCream.png",
      "/assets/images/products/Health&Beauty/16.CaudalieHandandNailCream.png",
    ],
  },
  {
    price: 127,
    title: "Alkemie Glow Up",
    imgUrl: "/assets/images/products/Health&Beauty/17.AlkemieGlowUp.png",
    category: "health&beauty",
    discount: 8,
    id: "609554559963343",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Health&Beauty/17.AlkemieGlowUp.png",
      "/assets/images/products/Health&Beauty/17.AlkemieGlowUp.png",
      "/assets/images/products/Health&Beauty/17.AlkemieGlowUp.png",
    ],
  },
  {
    price: 185,
    title: "Shiseido Products",
    imgUrl: "/assets/images/products/Health&Beauty/18.ShiseidoProducts.png",
    category: "health&beauty",
    discount: 5,
    id: "9158516349554409",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Health&Beauty/18.ShiseidoProducts.png",
      "/assets/images/products/Health&Beauty/18.ShiseidoProducts.png",
      "/assets/images/products/Health&Beauty/18.ShiseidoProducts.png",
    ],
  },
  {
    price: 290,
    title: "Premium Lipstick",
    imgUrl: "/assets/images/products/Health&Beauty/19.PremiumLipstick.png",
    category: "health&beauty",
    discount: 6,
    id: "9317973372325952",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Health&Beauty/19.PremiumLipstick.png",
      "/assets/images/products/Health&Beauty/19.PremiumLipstick.png",
      "/assets/images/products/Health&Beauty/19.PremiumLipstick.png",
    ],
  },
  {
    price: 226,
    title: "Blemish Elixir",
    imgUrl: "/assets/images/products/Health&Beauty/20.BlemishElixir.png",
    category: "health&beauty",
    discount: 10,
    id: "19038107444765484",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Health&Beauty/20.BlemishElixir.png",
      "/assets/images/products/Health&Beauty/20.BlemishElixir.png",
      "/assets/images/products/Health&Beauty/20.BlemishElixir.png",
    ],
  },
  {
    price: 236,
    title: "Red Serum and Cream",
    imgUrl: "/assets/images/products/Health&Beauty/21.RedSerumandCream.png",
    category: "health&beauty",
    discount: 7,
    id: "9318598735710861",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Health&Beauty/21.RedSerumandCream.png",
      "/assets/images/products/Health&Beauty/21.RedSerumandCream.png",
      "/assets/images/products/Health&Beauty/21.RedSerumandCream.png",
    ],
  },
  {
    price: 269,
    title: "Detol Liquid Hand Wash",
    imgUrl: "/assets/images/products/Health&Beauty/22.DetolLiquidHandWash.png",
    category: "health&beauty",
    discount: 5,
    id: "03913996565087885",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Health&Beauty/22.DetolLiquidHandWash.png",
      "/assets/images/products/Health&Beauty/22.DetolLiquidHandWash.png",
      "/assets/images/products/Health&Beauty/22.DetolLiquidHandWash.png",
    ],
  },
  {
    price: 233,
    title: "Dove Face Wash",
    imgUrl: "/assets/images/products/Health&Beauty/23.DoveFaceWash.png",
    category: "health&beauty",
    discount: 8,
    id: "5587716603196735",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Health&Beauty/23.DoveFaceWash.png",
      "/assets/images/products/Health&Beauty/23.DoveFaceWash.png",
      "/assets/images/products/Health&Beauty/23.DoveFaceWash.png",
    ],
  },
  {
    price: 257,
    title: "Koreal Kids Extra Gentle Shampoo",
    imgUrl: "/assets/images/products/Health&Beauty/24.LorealKidsExtraGentleShampoo.png",
    category: "health&beauty",
    discount: 8,
    id: "4479160269918778",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Health&Beauty/24.LorealKidsExtraGentleShampoo.png",
      "/assets/images/products/Health&Beauty/24.LorealKidsExtraGentleShampoo.png",
      "/assets/images/products/Health&Beauty/24.LorealKidsExtraGentleShampoo.png",
    ],
  },
  {
    price: 191,
    title: "Jario Badescu Skin Care Shampoo",
    imgUrl: "/assets/images/products/Health&Beauty/25.MarioBadescuSkinCareShampoo.png",
    category: "health&beauty",
    discount: 10,
    id: "7785238301951232",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Health&Beauty/25.MarioBadescuSkinCareShampoo.png",
      "/assets/images/products/Health&Beauty/25.MarioBadescuSkinCareShampoo.png",
      "/assets/images/products/Health&Beauty/25.MarioBadescuSkinCareShampoo.png",
    ],
  },
  {
    price: 220,
    title: "The Poinsettia Plant",
    imgUrl: "/assets/images/products/Home&Garden/1.ThePoinsettiaPlant.png",
    category: "home&garden",
    discount: 9,
    id: "7079709772261065",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Home&Garden/1.ThePoinsettiaPlant.png",
      "/assets/images/products/Home&Garden/1.ThePoinsettiaPlant.png",
      "/assets/images/products/Home&Garden/1.ThePoinsettiaPlant.png",
    ],
  },
  {
    price: 236,
    title: "Devil's Ivy Indoor Plant",
    imgUrl: "/assets/images/products/Home&Garden/2.Devil'sIvyIndoorPlant.png",
    category: "home&garden",
    discount: 5,
    id: "8961263406534974",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Home&Garden/2.Devil'sIvyIndoorPlant.png",
      "/assets/images/products/Home&Garden/2.Devil'sIvyIndoorPlant.png",
      "/assets/images/products/Home&Garden/2.Devil'sIvyIndoorPlant.png",
    ],
  },
  {
    price: 237,
    title: "Aloe Vera Plant",
    imgUrl: "/assets/images/products/Home&Garden/3.AloeVeraPlant.png",
    category: "home&garden",
    discount: 7,
    id: "37144256348968785",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Home&Garden/3.AloeVeraPlant.png",
      "/assets/images/products/Home&Garden/3.AloeVeraPlant.png",
      "/assets/images/products/Home&Garden/3.AloeVeraPlant.png",
    ],
  },
  {
    price: 131,
    title: "Satin Pothos Plant",
    imgUrl: "/assets/images/products/Home&Garden/4.SatinPothosPlant.png",
    category: "home&garden",
    discount: 7,
    id: "8111924481714179",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Home&Garden/4.SatinPothosPlant.png",
      "/assets/images/products/Home&Garden/4.SatinPothosPlant.png",
      "/assets/images/products/Home&Garden/4.SatinPothosPlant.png",
    ],
  },
  {
    price: 225,
    title: "Japanese Maple Plant",
    imgUrl: "/assets/images/products/Home&Garden/5.JapaneseMaplePlant.png",
    category: "home&garden",
    discount: 8,
    id: "8117194620135857",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Home&Garden/5.JapaneseMaplePlant.png",
      "/assets/images/products/Home&Garden/5.JapaneseMaplePlant.png",
      "/assets/images/products/Home&Garden/5.JapaneseMaplePlant.png",
    ],
  },
  {
    price: 146,
    title: "Green leaf Plant in Glass Jar",
    imgUrl: "/assets/images/products/Home&Garden/6.GreenLeafPlantinGlassJar.png",
    category: "home&garden",
    discount: 6,
    id: "4596117091415033",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Home&Garden/6.GreenLeafPlantinGlassJar.png",
      "/assets/images/products/Home&Garden/6.GreenLeafPlantinGlassJar.png",
      "/assets/images/products/Home&Garden/6.GreenLeafPlantinGlassJar.png",
    ],
  },
  {
    price: 193,
    title: "Spider Plant in Round Glass",
    imgUrl: "/assets/images/products/Home&Garden/7.SpiderPlantinRoundGlass.png",
    category: "home&garden",
    discount: 7,
    id: "8696048729979515",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Home&Garden/7.SpiderPlantinRoundGlass.png",
      "/assets/images/products/Home&Garden/7.SpiderPlantinRoundGlass.png",
      "/assets/images/products/Home&Garden/7.SpiderPlantinRoundGlass.png",
    ],
  },
  {
    price: 182,
    title: "Chamaedorea Elegance Plants With Ceramic Pot",
    imgUrl: "/assets/images/products/Home&Garden/8.ChamaedoreaElegansPlantswithCeramicPot.png",
    category: "home&garden",
    discount: 6,
    id: "9280121655411975",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Home&Garden/8.ChamaedoreaElegansPlantswithCeramicPot.png",
      "/assets/images/products/Home&Garden/8.ChamaedoreaElegansPlantswithCeramicPot.png",
      "/assets/images/products/Home&Garden/8.ChamaedoreaElegansPlantswithCeramicPot.png",
    ],
  },
  {
    price: 178,
    title: "Houseplants in Jpapanese Pot",
    imgUrl: "/assets/images/products/Home&Garden/9.HouseplantinJapanesePot.png",
    category: "home&garden",
    discount: 7,
    id: "8752420931188876",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Home&Garden/9.HouseplantinJapanesePot.png",
      "/assets/images/products/Home&Garden/9.HouseplantinJapanesePot.png",
      "/assets/images/products/Home&Garden/9.HouseplantinJapanesePot.png",
    ],
  },
  {
    price: 275,
    title: "Fiddle-Leaf Plant in Bamboo Basket",
    imgUrl: "/assets/images/products/Home&Garden/10.FiddleleafPlantinBambooBasket.png",
    category: "home&garden",
    discount: 5,
    id: "12679948560254317",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Home&Garden/10.FiddleleafPlantinBambooBasket.png",
      "/assets/images/products/Home&Garden/10.FiddleleafPlantinBambooBasket.png",
      "/assets/images/products/Home&Garden/10.FiddleleafPlantinBambooBasket.png",
    ],
  },
  {
    price: 181,
    title: "Beautiful Cactus in Ceramic Cup",
    imgUrl: "/assets/images/products/Home&Garden/11.BeautifulCactusinCeramicCup.png",
    category: "home&garden",
    discount: 7,
    id: "1915551356279519",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Home&Garden/11.BeautifulCactusinCeramicCup.png",
      "/assets/images/products/Home&Garden/11.BeautifulCactusinCeramicCup.png",
      "/assets/images/products/Home&Garden/11.BeautifulCactusinCeramicCup.png",
    ],
  },
  {
    price: 106,
    title: "Cactus in Japanese Ceramic",
    imgUrl: "/assets/images/products/Home&Garden/12.CactusinJapaneseCeramic.png",
    category: "home&garden",
    discount: 6,
    id: "1877068436909417",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Home&Garden/12.CactusinJapaneseCeramic.png",
      "/assets/images/products/Home&Garden/12.CactusinJapaneseCeramic.png",
      "/assets/images/products/Home&Garden/12.CactusinJapaneseCeramic.png",
    ],
  },
  {
    price: 283,
    title: "Garden Roses in Blue Vase",
    imgUrl: "/assets/images/products/Home&Garden/13.GardenRosesinBlueVase.png",
    category: "home&garden",
    discount: 8,
    id: "03092658842525342",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Home&Garden/13.GardenRosesinBlueVase.png",
      "/assets/images/products/Home&Garden/13.GardenRosesinBlueVase.png",
      "/assets/images/products/Home&Garden/13.GardenRosesinBlueVase.png",
    ],
  },
  {
    price: 262,
    title: "Hanging Planters with Green Plants",
    imgUrl: "/assets/images/products/Home&Garden/14.HangingPlanterswithGreenPlants.png",
    category: "home&garden",
    discount: 6,
    id: "5686877548921454",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Home&Garden/14.HangingPlanterswithGreenPlants.png",
      "/assets/images/products/Home&Garden/14.HangingPlanterswithGreenPlants.png",
      "/assets/images/products/Home&Garden/14.HangingPlanterswithGreenPlants.png",
    ],
  },
  {
    price: 164,
    title: "Tilandsia Flexuosa in Wood Frame",
    imgUrl: "/assets/images/products/Home&Garden/15.TilandsiaFlexuosainWoodFrame.png",
    category: "home&garden",
    discount: 10,
    id: "3646465293831638",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Home&Garden/15.TilandsiaFlexuosainWoodFrame.png",
      "/assets/images/products/Home&Garden/15.TilandsiaFlexuosainWoodFrame.png",
      "/assets/images/products/Home&Garden/15.TilandsiaFlexuosainWoodFrame.png",
    ],
  },
  {
    price: 252,
    title: "Copper Planter With Mixed Plants",
    imgUrl: "/assets/images/products/Home&Garden/16.CopperPlanterwithMixedPlants.png",
    category: "home&garden",
    discount: 5,
    id: "9090457132472443",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Home&Garden/16.CopperPlanterwithMixedPlants.png",
      "/assets/images/products/Home&Garden/16.CopperPlanterwithMixedPlants.png",
      "/assets/images/products/Home&Garden/16.CopperPlanterwithMixedPlants.png",
    ],
  },
  {
    price: 108,
    title: "Molded Wax agave in Japanese Planter",
    imgUrl: "/assets/images/products/Home&Garden/17.MoldedwaxagaveinJapanesePlanter.png",
    category: "home&garden",
    discount: 10,
    id: "36023925702961",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Home&Garden/17.MoldedwaxagaveinJapanesePlanter.png",
      "/assets/images/products/Home&Garden/17.MoldedwaxagaveinJapanesePlanter.png",
      "/assets/images/products/Home&Garden/17.MoldedwaxagaveinJapanesePlanter.png",
    ],
  },
  {
    price: 105,
    title: "Green vase Doilies",
    imgUrl: "/assets/images/products/Home&Garden/18.GreenVaseDoilies.png",
    category: "home&garden",
    discount: 7,
    id: "04409393657713201",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Home&Garden/18.GreenVaseDoilies.png",
      "/assets/images/products/Home&Garden/18.GreenVaseDoilies.png",
      "/assets/images/products/Home&Garden/18.GreenVaseDoilies.png",
    ],
  },
  {
    price: 182,
    title: "Mammillaria Elongata Cactus",
    imgUrl: "/assets/images/products/Home&Garden/19.MammillariaElongataCactus.png",
    category: "home&garden",
    discount: 9,
    id: "986686905800001",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Home&Garden/19.MammillariaElongataCactus.png",
      "/assets/images/products/Home&Garden/19.MammillariaElongataCactus.png",
      "/assets/images/products/Home&Garden/19.MammillariaElongataCactus.png",
    ],
  },
  {
    price: 161,
    title: "Set Green Plastic Pot 25CMS (Blue)",
    imgUrl: "/assets/images/products/Home&Garden/20.SetgreenPlasticPot25CMSBlue.png",
    category: "home&garden",
    discount: 6,
    id: "2992974199596896",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Home&Garden/20.SetgreenPlasticPot25CMSBlue.png",
      "/assets/images/products/Home&Garden/20.SetgreenPlasticPot25CMSBlue.png",
      "/assets/images/products/Home&Garden/20.SetgreenPlasticPot25CMSBlue.png",
    ],
  },
  {
    price: 275,
    title: "Grass Pot 15.5 inch",
    imgUrl: "/assets/images/products/Home&Garden/21.GrassPot15.5inch.png",
    category: "home&garden",
    discount: 6,
    id: "02921279866751081",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Home&Garden/21.GrassPot15.5inch.png",
      "/assets/images/products/Home&Garden/21.GrassPot15.5inch.png",
      "/assets/images/products/Home&Garden/21.GrassPot15.5inch.png",
    ],
  },
  {
    price: 284,
    title: "Euphorbia Leuconeura with Orange Pot",
    imgUrl: "/assets/images/products/Home&Garden/22.EuphorbiaLeuconeurawithOrangePot.png",
    category: "home&garden",
    discount: 7,
    id: "9888658182514298",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Home&Garden/22.EuphorbiaLeuconeurawithOrangePot.png",
      "/assets/images/products/Home&Garden/22.EuphorbiaLeuconeurawithOrangePot.png",
      "/assets/images/products/Home&Garden/22.EuphorbiaLeuconeurawithOrangePot.png",
    ],
  },
  {
    price: 193,
    title: "Euphorbia Lactea in Round Clay Pot",
    imgUrl: "/assets/images/products/Home&Garden/23.EuphorbiaLacteainRoundClayPot.png",
    category: "home&garden",
    discount: 6,
    id: "16829989165487325",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Home&Garden/23.EuphorbiaLacteainRoundClayPot.png",
      "/assets/images/products/Home&Garden/23.EuphorbiaLacteainRoundClayPot.png",
      "/assets/images/products/Home&Garden/23.EuphorbiaLacteainRoundClayPot.png",
    ],
  },
  {
    price: 177,
    title: "Mixed Succulent in Glass Jar",
    imgUrl: "/assets/images/products/Home&Garden/24.MixedSucculentinGlassJar.png",
    category: "home&garden",
    discount: 9,
    id: "6034467575535363",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Home&Garden/24.MixedSucculentinGlassJar.png",
      "/assets/images/products/Home&Garden/24.MixedSucculentinGlassJar.png",
      "/assets/images/products/Home&Garden/24.MixedSucculentinGlassJar.png",
    ],
  },
  {
    price: 192,
    title: "Painted Leaf Begonia Plant",
    imgUrl: "/assets/images/products/Home&Garden/25.PaintedLeafBegoniaPlant.png",
    category: "home&garden",
    discount: 5,
    id: "1792491211341667",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Home&Garden/25.PaintedLeafBegoniaPlant.png",
      "/assets/images/products/Home&Garden/25.PaintedLeafBegoniaPlant.png",
      "/assets/images/products/Home&Garden/25.PaintedLeafBegoniaPlant.png",
    ],
  },
  {
    price: 300,
    title: "Areca Palm in Clay Pot",
    imgUrl: "/assets/images/products/Home&Garden/26.ArecaPalminClayPot.png",
    category: "home&garden",
    discount: 6,
    id: "8914934786162743",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Home&Garden/26.ArecaPalminClayPot.png",
      "/assets/images/products/Home&Garden/26.ArecaPalminClayPot.png",
      "/assets/images/products/Home&Garden/26.ArecaPalminClayPot.png",
    ],
  },
  {
    price: 187,
    title: "Black Coral Sansevieria Plant",
    imgUrl: "/assets/images/products/Home&Garden/27.BlackCoralSansevieriaPlant.png",
    category: "home&garden",
    discount: 9,
    id: "7244433689317422",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Home&Garden/27.BlackCoralSansevieriaPlant.png",
      "/assets/images/products/Home&Garden/27.BlackCoralSansevieriaPlant.png",
      "/assets/images/products/Home&Garden/27.BlackCoralSansevieriaPlant.png",
    ],
  },
  {
    price: 249,
    title: "6String Cheap Electric Guitar",
    imgUrl: "/assets/images/products/Music/1.6StringCheapElectricGuitar.png",
    category: "music",
    discount: 5,
    id: "12110691666420359",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Music/1.6StringCheapElectricGuitar.png",
      "/assets/images/products/Music/1.6StringCheapElectricGuitar.png",
      "/assets/images/products/Music/1.6StringCheapElectricGuitar.png",
    ],
  },
  {
    price: 149,
    title: "Affordable 4String Tenor Banjo",
    imgUrl: "/assets/images/products/Music/2.Affordable4StringTenorBanjo.png",
    category: "music",
    discount: 9,
    id: "604544659463474",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Music/2.Affordable4StringTenorBanjo.png",
      "/assets/images/products/Music/2.Affordable4StringTenorBanjo.png",
      "/assets/images/products/Music/2.Affordable4StringTenorBanjo.png",
    ],
  },
  {
    price: 124,
    title: "Affordable Indian Sitar",
    imgUrl: "/assets/images/products/Music/3.AffordableIndianSitar.png",
    category: "music",
    discount: 5,
    id: "018574893945224158",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Music/3.AffordableIndianSitar.png",
      "/assets/images/products/Music/3.AffordableIndianSitar.png",
      "/assets/images/products/Music/3.AffordableIndianSitar.png",
    ],
  },
  {
    price: 265,
    title: "Detailed Painted Acoustic Guitar",
    imgUrl: "/assets/images/products/Music/4.DetailedPaintedAcousticGuitar.png",
    category: "music",
    discount: 8,
    id: "8830921035555452",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Music/4.DetailedPaintedAcousticGuitar.png",
      "/assets/images/products/Music/4.DetailedPaintedAcousticGuitar.png",
      "/assets/images/products/Music/4.DetailedPaintedAcousticGuitar.png",
    ],
  },
  {
    price: 101,
    title: "Jackson 6String Floyd Rose",
    imgUrl: "/assets/images/products/Music/5.Jackson6StringFloydRose.png",
    category: "music",
    discount: 7,
    id: "07530319205468494",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Music/5.Jackson6StringFloydRose.png",
      "/assets/images/products/Music/5.Jackson6StringFloydRose.png",
      "/assets/images/products/Music/5.Jackson6StringFloydRose.png",
    ],
  },
  {
    price: 171,
    title: "Jackson 6String Fixed Bridge",
    imgUrl: "/assets/images/products/Music/6.Jackson6StringFixedBridge.png",
    category: "music",
    discount: 9,
    id: "43149824400382286",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Music/6.Jackson6StringFixedBridge.png",
      "/assets/images/products/Music/6.Jackson6StringFixedBridge.png",
      "/assets/images/products/Music/6.Jackson6StringFixedBridge.png",
    ],
  },
  {
    price: 240,
    title: "Royal Harp",
    imgUrl: "/assets/images/products/Music/7.RoyalHarp.png",
    category: "music",
    discount: 10,
    id: "7591700253132327",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Music/7.RoyalHarp.png",
      "/assets/images/products/Music/7.RoyalHarp.png",
      "/assets/images/products/Music/7.RoyalHarp.png",
    ],
  },
  {
    price: 230,
    title: "Mid Range Tabla",
    imgUrl: "/assets/images/products/Music/8.MidRangeTabla.png",
    category: "music",
    discount: 8,
    id: "8648976615075163",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Music/8.MidRangeTabla.png",
      "/assets/images/products/Music/8.MidRangeTabla.png",
      "/assets/images/products/Music/8.MidRangeTabla.png",
    ],
  },
  {
    price: 269,
    title: "Mid Range Dhol",
    imgUrl: "/assets/images/products/Music/9.MidRangeDhol.png",
    category: "music",
    discount: 6,
    id: "3120680231707422",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Music/9.MidRangeDhol.png",
      "/assets/images/products/Music/9.MidRangeDhol.png",
      "/assets/images/products/Music/9.MidRangeDhol.png",
    ],
  },
  {
    price: 197,
    title: "Buffet 400 Saxophone",
    imgUrl: "/assets/images/products/Music/10.Buffet400Saxophone.png",
    category: "music",
    discount: 8,
    id: "6350234970940494",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Music/10.Buffet400Saxophone.png",
      "/assets/images/products/Music/10.Buffet400Saxophone.png",
      "/assets/images/products/Music/10.Buffet400Saxophone.png",
    ],
  },
  {
    price: 275,
    title: "Xamaha Electrtic Violin",
    imgUrl: "/assets/images/products/Music/11.YamahaElectricViolin.png",
    category: "music",
    discount: 9,
    id: "2669601035965954",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Music/11.YamahaElectricViolin.png",
      "/assets/images/products/Music/11.YamahaElectricViolin.png",
      "/assets/images/products/Music/11.YamahaElectricViolin.png",
    ],
  },
  {
    price: 164,
    title: "Cheap Ukulele Offers",
    imgUrl: "/assets/images/products/Music/12.CheapUkuleleOffers.png",
    category: "music",
    discount: 9,
    id: "10781836387117294",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Music/12.CheapUkuleleOffers.png",
      "/assets/images/products/Music/12.CheapUkuleleOffers.png",
      "/assets/images/products/Music/12.CheapUkuleleOffers.png",
    ],
  },
  {
    price: 281,
    title: "Cordoba Classical Guitar",
    imgUrl: "/assets/images/products/Music/13.CordobaClassicalGuitar.png",
    category: "music",
    discount: 5,
    id: "18861060567430998",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Music/13.CordobaClassicalGuitar.png",
      "/assets/images/products/Music/13.CordobaClassicalGuitar.png",
      "/assets/images/products/Music/13.CordobaClassicalGuitar.png",
    ],
  },
  {
    price: 233,
    title: "Little Purcussion Instruments",
    imgUrl: "/assets/images/products/Music/14.LittlePercussionInstruments.png",
    category: "music",
    discount: 6,
    id: "9637907582434442",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Music/14.LittlePercussionInstruments.png",
      "/assets/images/products/Music/14.LittlePercussionInstruments.png",
      "/assets/images/products/Music/14.LittlePercussionInstruments.png",
    ],
  },
  {
    price: 205,
    title: "Pearl Snare Drum",
    imgUrl: "/assets/images/products/Music/15.PearlSnareDrum.png",
    category: "music",
    discount: 6,
    id: "31636769606699766",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Music/15.PearlSnareDrum.png",
      "/assets/images/products/Music/15.PearlSnareDrum.png",
      "/assets/images/products/Music/15.PearlSnareDrum.png",
    ],
  },
  {
    price: 271,
    title: "Xamaha Piano",
    imgUrl: "/assets/images/products/Music/16.YamahaPiano.png",
    category: "music",
    discount: 6,
    id: "9881461088970345",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Music/16.YamahaPiano.png",
      "/assets/images/products/Music/16.YamahaPiano.png",
      "/assets/images/products/Music/16.YamahaPiano.png",
    ],
  },
  {
    price: 228,
    title: "Poland Piano",
    imgUrl: "/assets/images/products/Music/17.RolandPiano.png",
    category: "music",
    discount: 8,
    id: "3225991719515402",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Music/17.RolandPiano.png",
      "/assets/images/products/Music/17.RolandPiano.png",
      "/assets/images/products/Music/17.RolandPiano.png",
    ],
  },
  {
    price: 203,
    title: "Fender 4String Bass Guitar",
    imgUrl: "/assets/images/products/Music/18.Fender4stringBassGuitar.png",
    category: "music",
    discount: 7,
    id: "2168642999145287",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Music/18.Fender4stringBassGuitar.png",
      "/assets/images/products/Music/18.Fender4stringBassGuitar.png",
      "/assets/images/products/Music/18.Fender4stringBassGuitar.png",
    ],
  },
  {
    price: 140,
    title: "Changer Harmonium",
    imgUrl: "/assets/images/products/Music/19.ChangerHarmonium.png",
    category: "music",
    discount: 8,
    id: "037262847930013",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Music/19.ChangerHarmonium.png",
      "/assets/images/products/Music/19.ChangerHarmonium.png",
      "/assets/images/products/Music/19.ChangerHarmonium.png",
    ],
  },
  {
    price: 201,
    title: "Poland V Drums",
    imgUrl: "/assets/images/products/Music/20.RolandVDrums.png",
    category: "music",
    discount: 7,
    id: "7133950631531858",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Music/20.RolandVDrums.png",
      "/assets/images/products/Music/20.RolandVDrums.png",
      "/assets/images/products/Music/20.RolandVDrums.png",
    ],
  },
  {
    price: 257,
    title: "Ibanez GIO 6String Guitar",
    imgUrl: "/assets/images/products/Music/21.IbanezGIO6stringGuitar.png",
    category: "music",
    discount: 10,
    id: "8040711223089017",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Music/21.IbanezGIO6stringGuitar.png",
      "/assets/images/products/Music/21.IbanezGIO6stringGuitar.png",
      "/assets/images/products/Music/21.IbanezGIO6stringGuitar.png",
    ],
  },
  {
    price: 149,
    title: "Turkish Mandolin",
    imgUrl: "/assets/images/products/Music/22.TurkishMandolin.png",
    category: "music",
    discount: 7,
    id: "8048623453321189",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Music/22.TurkishMandolin.png",
      "/assets/images/products/Music/22.TurkishMandolin.png",
      "/assets/images/products/Music/22.TurkishMandolin.png",
    ],
  },
  {
    price: 140,
    title: "Affordable Turkish Oud",
    imgUrl: "/assets/images/products/Music/23.AfforableTurkishOud.png",
    category: "music",
    discount: 6,
    id: "8867996786023267",
    rating: 5,
    imgGroup: [
      "/assets/images/products/Music/23.AfforableTurkishOud.png",
      "/assets/images/products/Music/23.AfforableTurkishOud.png",
      "/assets/images/products/Music/23.AfforableTurkishOud.png",
    ],
  },
  {
    price: 183,
    title: "Xamaha Acoustic Guitar",
    imgUrl: "/assets/images/products/Music/24.YamahaAcousticGuitar.png",
    category: "music",
    discount: 7,
    id: "1643407377925008",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Music/24.YamahaAcousticGuitar.png",
      "/assets/images/products/Music/24.YamahaAcousticGuitar.png",
      "/assets/images/products/Music/24.YamahaAcousticGuitar.png",
    ],
  },
  {
    price: 129,
    title: "Poland Full Set V Drums",
    imgUrl: "/assets/images/products/Music/25.RolandFullSetVDrums.png",
    category: "music",
    discount: 7,
    id: "7302008215217886",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Music/25.RolandFullSetVDrums.png",
      "/assets/images/products/Music/25.RolandFullSetVDrums.png",
      "/assets/images/products/Music/25.RolandFullSetVDrums.png",
    ],
  },
  {
    price: 287,
    title: "Poland Jupiter-X Synthesizer",
    imgUrl: "/assets/images/products/Music/26.RolandJupiterXSynthesizer.png",
    category: "music",
    discount: 7,
    id: "20983620234591127",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Music/26.RolandJupiterXSynthesizer.png",
      "/assets/images/products/Music/26.RolandJupiterXSynthesizer.png",
      "/assets/images/products/Music/26.RolandJupiterXSynthesizer.png",
    ],
  },
  {
    price: 112,
    title: "Affordable Cordoba Classical Guitar",
    imgUrl: "/assets/images/products/Music/27.AffordableCordobaClassicalGuitar.png",
    category: "music",
    discount: 8,
    id: "06089299954927463",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Music/27.AffordableCordobaClassicalGuitar.png",
      "/assets/images/products/Music/27.AffordableCordobaClassicalGuitar.png",
      "/assets/images/products/Music/27.AffordableCordobaClassicalGuitar.png",
    ],
  },
  {
    price: 159,
    title: "Turkish Long Neck Baglama",
    imgUrl: "/assets/images/products/Music/28.TurkishLongNeckBaglama.png",
    category: "music",
    discount: 8,
    id: "07350530146572276",
    rating: 4,
    imgGroup: [
      "/assets/images/products/Music/28.TurkishLongNeckBaglama.png",
      "/assets/images/products/Music/28.TurkishLongNeckBaglama.png",
      "/assets/images/products/Music/28.TurkishLongNeckBaglama.png",
    ],
  },
  {
    price: 187,
    title: "Mahalo Soprano Ukuleles",
    imgUrl: "/assets/images/products/Music/29.MahaloSopranoUkuleles.png",
    category: "music",
    discount: 8,
    id: "878975985693714",
    rating: 2,
    imgGroup: [
      "/assets/images/products/Music/29.MahaloSopranoUkuleles.png",
      "/assets/images/products/Music/29.MahaloSopranoUkuleles.png",
      "/assets/images/products/Music/29.MahaloSopranoUkuleles.png",
    ],
  },
  {
    price: 151,
    title: "yamaha YAS-280 Saxophone",
    imgUrl: "/assets/images/products/Music/30.YamahaYAS280Saxophone.png",
    category: "music",
    discount: 5,
    id: "16874764529141673",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Music/30.YamahaYAS280Saxophone.png",
      "/assets/images/products/Music/30.YamahaYAS280Saxophone.png",
      "/assets/images/products/Music/30.YamahaYAS280Saxophone.png",
    ],
  },
  {
    price: 158,
    title: "Eastman Westburry Cello",
    imgUrl: "/assets/images/products/Music/31.EastmanWestburryCello.png",
    category: "music",
    discount: 6,
    id: "19066023592475756",
    rating: 3,
    imgGroup: [
      "/assets/images/products/Music/31.EastmanWestburryCello.png",
      "/assets/images/products/Music/31.EastmanWestburryCello.png",
      "/assets/images/products/Music/31.EastmanWestburryCello.png",
    ],
  },
  {
    price: 3300,
    reviewCount: 49,
    title: "Camera",
    imgUrl: "/assets/images/products/camera-1.png",
    discount: 8,
    id: 2294256829,
    rating: 4,
    imgGroup: [
      "/assets/images/products/camera-1.png",
      "/assets/images/products/camera-1.png",
      "/assets/images/products/camera-1.png",
    ],
  },
  {
    price: 400,
    reviewCount: 20,
    title: "Shoe",
    imgUrl: "/assets/images/products/shoes-2.png",
    discount: 5,
    id: 4295570149,
    rating: 4.75,
    imgGroup: [
      "/assets/images/products/shoes-2.png",
      "/assets/images/products/shoes-2.png",
      "/assets/images/products/shoes-2.png",
    ],
  },
  {
    price: 999,
    reviewCount: 65,
    title: "Phone",
    imgUrl: "/assets/images/products/mobile-1.png",
    discount: 5,
    id: 5676950804,
    rating: 5,
    imgGroup: [
      "/assets/images/products/mobile-1.png",
      "/assets/images/products/mobile-1.png",
      "/assets/images/products/mobile-1.png",
    ],
  },
  {
    price: 999,
    reviewCount: 75,
    title: "Watch",
    imgUrl: "/assets/images/products/watch-1.png",
    discount: 6,
    id: 1674789677,
    rating: 5,
    imgGroup: [
      "/assets/images/products/watch-1.png",
      "/assets/images/products/watch-1.png",
      "/assets/images/products/watch-1.png",
    ],
  },
  {
    price: 150,
    title: "Sunglass",
    imgUrl: "/assets/images/products/imagegoggles.png",
    discount: 8,
    id: 1670078035,
    rating: 5,
    imgGroup: [
      "/assets/images/products/imagegoggles.png",
      "/assets/images/products/imagegoggles.png",
      "/assets/images/products/imagegoggles.png",
    ],
  },
  {
    price: 250,
    title: "Makeup",
    imgUrl: "/assets/images/products/lipstick (2).png",
    discount: 8,
    id: 3302401429,
    rating: 4,
    imgGroup: [
      "/assets/images/products/lipstick (2).png",
      "/assets/images/products/lipstick (2).png",
      "/assets/images/products/lipstick (2).png",
    ],
  },
  {
    price: 350,
    title: "Smart Watch",
    imgUrl: "/assets/images/products/bgwatch.png",
    discount: 10,
    id: 814272833,
    rating: 5,
    imgGroup: [
      "/assets/images/products/bgwatch.png",
      "/assets/images/products/bgwatch.png",
      "/assets/images/products/bgwatch.png",
    ],
  },
  {
    price: 15,
    title: "Lipstick",
    imgUrl: "/assets/images/products/lipstick (1).png",
    discount: 5,
    id: 8169421085,
    rating: 4,
    imgGroup: [
      "/assets/images/products/lipstick (1).png",
      "/assets/images/products/lipstick (1).png",
      "/assets/images/products/lipstick (1).png",
    ],
  },
  {
    price: 55,
    title: "Green plant",
    imgUrl: "/assets/images/products/lipstick (4).png",
    discount: 7,
    id: 8398582292,
    rating: 2,
    imgGroup: [
      "/assets/images/products/lipstick (4).png",
      "/assets/images/products/lipstick (4).png",
      "/assets/images/products/lipstick (4).png",
    ],
  },
  {
    price: 535,
    title: "Bonsai tree",
    imgUrl: "/assets/images/products/lipstick (3).png",
    discount: 6,
    id: 6046531880,
    rating: 5,
    imgGroup: [
      "/assets/images/products/lipstick (3).png",
      "/assets/images/products/lipstick (3).png",
      "/assets/images/products/lipstick (3).png",
    ],
  },
  {
    price: 250,
    title: "Smart watch black",
    imgUrl: "/assets/images/products/flash-1.png",
    discount: 25,
    id: 4674163016,
    rating: 4,
    imgGroup: [
      "/assets/images/products/flash-1.png",
      "/assets/images/products/flash-1.png",
      "/assets/images/products/flash-1.png",
    ],
  },
  {
    price: 350,
    title: "Smart watch black",
    imgUrl: "/assets/images/products/flash-2.png",
    discount: 15,
    id: 1786818620,
    rating: 3,
    imgGroup: [
      "/assets/images/products/flash-2.png",
      "/assets/images/products/flash-2.png",
      "/assets/images/products/flash-2.png",
    ],
  },
  {
    price: 150,
    title: "Smart watch black",
    imgUrl: "/assets/images/products/flash-3.png",
    discount: 28,
    id: 5287174158,
    rating: 5,
    imgGroup: [
      "/assets/images/products/flash-3.png",
      "/assets/images/products/flash-3.png",
      "/assets/images/products/flash-3.png",
    ],
  },
  {
    price: 180,
    title: "Smart watch black",
    imgUrl: "/assets/images/products/flash-4.png",
    discount: 21,
    id: 6708885936,
    rating: 4,
    imgGroup: [
      "/assets/images/products/flash-4.png",
      "/assets/images/products/flash-4.png",
      "/assets/images/products/flash-4.png",
    ],
  },
  {
    price: 250,
    title: "Smart watch black",
    imgUrl: "/assets/images/products/flash-1.png",
    discount: 25,
    id: 8287533170,
    rating: 4,
    imgGroup: [
      "/assets/images/products/flash-1.png",
      "/assets/images/products/flash-1.png",
      "/assets/images/products/flash-1.png",
    ],
  },
  {
    price: 180,
    title: "Smart watch black",
    imgUrl: "/assets/images/products/flash-2.png",
    discount: 21,
    id: 9812971972,
    rating: 4,
    imgGroup: [
      "/assets/images/products/flash-2.png",
      "/assets/images/products/flash-2.png",
      "/assets/images/products/flash-2.png",
    ],
  },
  {
    price: 150,
    title: "Smart watch black",
    imgUrl: "/assets/images/products/flash-3.png",
    discount: 28,
    id: 3518328281,
    rating: 5,
    imgGroup: [
      "/assets/images/products/flash-3.png",
      "/assets/images/products/flash-3.png",
      "/assets/images/products/flash-3.png",
    ],
  },
  {
    price: 350,
    title: "Smart watch black",
    imgUrl: "/assets/images/products/flash-4.png",
    discount: 15,
    id: 2485294764,
    rating: 3,
    imgGroup: [
      "/assets/images/products/flash-4.png",
      "/assets/images/products/flash-4.png",
      "/assets/images/products/flash-4.png",
    ],
  },
  {
    price: 299,
    title: "Smart watch black",
    imgUrl: "/assets/images/products/flash-1.png",
    discount: 19,
    id: 2427928893,
    rating: 5,
    imgGroup: [
      "/assets/images/products/flash-1.png",
      "/assets/images/products/flash-1.png",
      "/assets/images/products/flash-1.png",
    ],
  },
  {
    price: 180,
    title: "Smart watch black",
    imgUrl: "/assets/images/products/flash-2.png",
    discount: 21,
    id: 2124324879,
    rating: 4,
    imgGroup: [
      "/assets/images/products/flash-2.png",
      "/assets/images/products/flash-2.png",
      "/assets/images/products/flash-2.png",
    ],
  },
  {
    price: 250,
    title: "Smart watch black",
    rating: 4,
    imgUrl: "/assets/images/products/chair-and-clothes.png",
    discount: 25,
    id: 7164715289,
    imgGroup: [
      "/assets/images/products/chair-and-clothes.png",
      "/assets/images/products/chair-and-clothes.png",
      "/assets/images/products/chair-and-clothes.png",
    ],
  },
  {
    price: 350,
    title: "Smart watch black",
    rating: 3,
    imgUrl: "/assets/images/products/t-shirt3.png",
    discount: 15,
    id: 5567565503,
    imgGroup: [
      "/assets/images/products/t-shirt3.png",
      "/assets/images/products/t-shirt3.png",
      "/assets/images/products/t-shirt3.png",
    ],
  },
  {
    price: 150,
    title: "Smart watch black",
    rating: 5,
    imgUrl: "/assets/images/products/t-shirt4.png",
    discount: 28,
    id: 8850904069,
    imgGroup: [
      "/assets/images/products/t-shirt4.png",
      "/assets/images/products/t-shirt4.png",
      "/assets/images/products/t-shirt4.png",
    ],
  },
  {
    price: 180,
    title: "Smart watch black",
    rating: 4,
    imgUrl: "/assets/images/products/t-shirt5.png",
    discount: 21,
    id: 7525085761,
    imgGroup: [
      "/assets/images/products/t-shirt5.png",
      "/assets/images/products/t-shirt5.png",
      "/assets/images/products/t-shirt5.png",
    ],
  },
  {
    price: 250,
    title: "Smart watch black",
    rating: 4,
    imgUrl: "/assets/images/products/chair-and-clothes.png",
    discount: 25,
    id: 8770107119,
    imgGroup: [
      "/assets/images/products/chair-and-clothes.png",
      "/assets/images/products/chair-and-clothes.png",
      "/assets/images/products/chair-and-clothes.png",
    ],
  },
  {
    price: 180,
    title: "Smart watch black",
    rating: 4,
    imgUrl: "/assets/images/products/t-shirt4.png",
    discount: 21,
    id: 9042058779,
    imgGroup: [
      "/assets/images/products/t-shirt4.png",
      "/assets/images/products/t-shirt4.png",
      "/assets/images/products/t-shirt4.png",
    ],
  },
  {
    price: 150,
    title: "Smart watch black",
    rating: 5,
    imgUrl: "/assets/images/products/t-shirt3.png",
    discount: 28,
    id: 9245877597,
    imgGroup: [
      "/assets/images/products/t-shirt3.png",
      "/assets/images/products/t-shirt3.png",
      "/assets/images/products/t-shirt3.png",
    ],
  },
  {
    price: 350,
    title: "Smart watch black",
    rating: 3,
    imgUrl: "/assets/images/products/t-shirt5.png",
    discount: 15,
    id: 915565643,
    imgGroup: [
      "/assets/images/products/t-shirt5.png",
      "/assets/images/products/t-shirt5.png",
      "/assets/images/products/t-shirt5.png",
    ],
  },
  {
    price: 299,
    title: "Smart watch black",
    rating: 5,
    imgUrl: "/assets/images/products/chair-and-clothes.png",
    discount: 19,
    id: 5715764445,
    imgGroup: [
      "/assets/images/products/chair-and-clothes.png",
      "/assets/images/products/chair-and-clothes.png",
      "/assets/images/products/chair-and-clothes.png",
    ],
  },
  {
    price: 180,
    title: "Smart watch black",
    rating: 4,
    imgUrl: "/assets/images/products/t-shirt3.pngg",
    discount: 21,
    id: 6196630337,
    imgGroup: [
      "/assets/images/products/t-shirt3.pngg",
      "/assets/images/products/t-shirt3.pngg",
      "/assets/images/products/t-shirt3.pngg",
    ],
  },
  {
    price: 250,
    title: "ASUS ROG Strix G15",
    rating: 4,
    imgUrl: "/assets/images/products/long-product.png",
    discount: 56,
    id: 9766792956,
    imgGroup: [
      "/assets/images/products/long-product.png",
      "/assets/images/products/long-product.png",
      "/assets/images/products/long-product.png",
    ],
  },
  {
    price: 140,
    title: "ASUS ROG Strix G15",
    rating: 5,
    imgUrl: "/assets/images/products/long-product.png",
    discount: 23,
    id: 7459423931,
    imgGroup: [
      "/assets/images/products/long-product.png",
      "/assets/images/products/long-product.png",
      "/assets/images/products/long-product.png",
    ],
  },
  {
    price: 200,
    title: "ASUS ROG Strix G15",
    rating: 4,
    imgUrl: "/assets/images/products/t-shirt3.png",
    discount: 42,
    id: 6309213496,
    imgGroup: [
      "/assets/images/products/t-shirt3.png",
      "/assets/images/products/t-shirt3.png",
      "/assets/images/products/t-shirt3.png",
    ],
  },
  {
    price: 450,
    title: "ASUS ROG Strix G15",
    rating: 3,
    imgUrl: "/assets/images/products/t-shirt4.png",
    discount: 39,
    id: 391400042,
    imgGroup: [
      "/assets/images/products/t-shirt4.png",
      "/assets/images/products/t-shirt4.png",
      "/assets/images/products/t-shirt4.png",
    ],
  },
  {
    price: 750,
    title: "ASUS ROG Strix G15",
    rating: 1,
    imgUrl: "/assets/images/products/t-shirt5.png",
    discount: 24,
    id: 4699929309,
    imgGroup: [
      "/assets/images/products/t-shirt5.png",
      "/assets/images/products/t-shirt5.png",
      "/assets/images/products/t-shirt5.png",
    ],
  },
  {
    price: 250,
    title: "ASUS ROG Strix G15",
    rating: 3,
    imgUrl: "/assets/images/products/chair-and-clothes.png",
    discount: 56,
    id: 952795536,
    imgGroup: [
      "/assets/images/products/chair-and-clothes.png",
      "/assets/images/products/chair-and-clothes.png",
      "/assets/images/products/chair-and-clothes.png",
    ],
  },
  {
    price: 299,
    title: "ASUS ROG Strix G15",
    rating: 4,
    imgUrl: "/assets/images/products/t-shirt4.png",
    discount: 54,
    id: 2130678973,
    imgGroup: [
      "/assets/images/products/t-shirt4.png",
      "/assets/images/products/t-shirt4.png",
      "/assets/images/products/t-shirt4.png",
    ],
  },
  {
    price: 87,
    title: "Introducing New Winter Collection",
    rating: 4,
    buttonText: "SHOP NOW",
    imgUrl: "/assets/images/products/chair-with-clothes.png",
    discount: 30,
    id: 7768130770,
    imgGroup: [
      "/assets/images/products/chair-with-clothes.png",
      "/assets/images/products/chair-with-clothes.png",
      "/assets/images/products/chair-with-clothes.png",
    ],
  },
  {
    price: 39,
    title: "Introducing New Headphone Collection",
    rating: 5,
    buttonText: "SHOP NOW",
    imgUrl: "/assets/images/products/headphone.png",
    discount: 40,
    id: 4530136512,
    imgGroup: [
      "/assets/images/products/headphone.png",
      "/assets/images/products/headphone.png",
      "/assets/images/products/headphone.png",
    ],
  },
  {
    price: 99,
    title: "Introducing Gaming Gear Collection",
    rating: 4,
    buttonText: "SHOP NOW",
    imgUrl: "/assets/images/products/gaming-gear.png",
    discount: 60,
    id: 1797219567,
    imgGroup: [
      "/assets/images/products/gaming-gear.png",
      "/assets/images/products/gaming-gear.png",
      "/assets/images/products/gaming-gear.png",
    ],
  },
  {
    price: 1262,
    title: "Bluetooth Speaker",
    rating: 3,
    imgUrl: "/assets/images/products/bluetooth-headphone.png",
    discount: 19,
    id: 394210513,
    imgGroup: [
      "/assets/images/products/bluetooth-headphone.png",
      "/assets/images/products/bluetooth-headphone.png",
      "/assets/images/products/bluetooth-headphone.png",
    ],
  },
  {
    price: 1093,
    title: "Titan Watch",
    rating: 2,
    imgUrl: "/assets/images/products/watch-2.png",
    discount: 16,
    id: 2860736089,
    imgGroup: [
      "/assets/images/products/watch-2.png",
      "/assets/images/products/watch-2.png",
      "/assets/images/products/watch-2.png",
    ],
  },
  {
    price: 1033,
    title: "N95 White Mask",
    rating: 5,
    imgUrl: "/assets/images/products/mask.png",
    discount: 19,
    id: 6548686235,
    imgGroup: [
      "/assets/images/products/mask.png",
      "/assets/images/products/mask.png",
      "/assets/images/products/mask.png",
    ],
  },
  {
    price: 1495,
    title: "Formal Shoe",
    rating: 3,
    imgUrl: "/assets/images/products/shoe.png",
    discount: 17,
    id: 1461853177,
    imgGroup: [
      "/assets/images/products/shoe.png",
      "/assets/images/products/shoe.png",
      "/assets/images/products/shoe.png",
    ],
  },
  {
    price: 1321,
    title: "Bluetooth Speaker",
    rating: 4,
    imgUrl: "/assets/images/products/watch-2.png",
    discount: 15,
    id: 8223513721,
    imgGroup: [
      "/assets/images/products/watch-2.png",
      "/assets/images/products/watch-2.png",
      "/assets/images/products/watch-2.png",
    ],
  },
  {
    price: 1278,
    title: "Bluetooth Speaker",
    rating: 4,
    imgUrl: "/assets/images/products/bluetooth-headphone.png",
    discount: 20,
    id: 7348916584,
    imgGroup: [
      "/assets/images/products/bluetooth-headphone.png",
      "/assets/images/products/bluetooth-headphone.png",
      "/assets/images/products/bluetooth-headphone.png",
    ],
  },
  {
    price: 250,
    title: "ASUS ROG Strix G15",
    rating: 4,
    imgUrl: "/assets/images/products/chair-and-clothes.png",
    discount: 25,
    id: 8439174779,
    imgGroup: [
      "/assets/images/products/chair-and-clothes.png",
      "/assets/images/products/chair-and-clothes.png",
      "/assets/images/products/chair-and-clothes.png",
    ],
  },
  {
    price: 350,
    title: "ASUS ROG Strix G15",
    rating: 3,
    imgUrl: "/assets/images/products/t-shirt3.png",
    discount: 15,
    id: 8014401053,
    imgGroup: [
      "/assets/images/products/t-shirt3.png",
      "/assets/images/products/t-shirt3.png",
      "/assets/images/products/t-shirt3.png",
    ],
  },
  {
    price: 150,
    title: "ASUS ROG Strix G15",
    rating: 5,
    imgUrl: "/assets/images/products/t-shirt4.png",
    discount: 28,
    id: 4831049945,
    imgGroup: [
      "/assets/images/products/t-shirt4.png",
      "/assets/images/products/t-shirt4.png",
      "/assets/images/products/t-shirt4.png",
    ],
  },
  {
    price: 180,
    title: "ASUS ROG Strix G15",
    rating: 4,
    imgUrl: "/assets/images/products/t-shirt5.png",
    discount: 21,
    id: 569265108,
    imgGroup: [
      "/assets/images/products/t-shirt5.png",
      "/assets/images/products/t-shirt5.png",
      "/assets/images/products/t-shirt5.png",
    ],
  },
  {
    price: 250,
    title: "ASUS ROG Strix G15",
    rating: 4,
    imgUrl: "/assets/images/products/chair-and-clothes.png",
    discount: 25,
    id: 1785388011,
    imgGroup: [
      "/assets/images/products/chair-and-clothes.png",
      "/assets/images/products/chair-and-clothes.png",
      "/assets/images/products/chair-and-clothes.png",
    ],
  },
  {
    price: 180,
    title: "ASUS ROG Strix G15",
    rating: 4,
    imgUrl: "/assets/images/products/t-shirt4.png",
    discount: 21,
    id: 2797198596,
    imgGroup: [
      "/assets/images/products/t-shirt4.png",
      "/assets/images/products/t-shirt4.png",
      "/assets/images/products/t-shirt4.png",
    ],
  },
  {
    price: 150,
    title: "ASUS ROG Strix G15",
    rating: 5,
    imgUrl: "/assets/images/products/t-shirt3.png",
    discount: 28,
    id: 6097712332,
    imgGroup: [
      "/assets/images/products/t-shirt3.png",
      "/assets/images/products/t-shirt3.png",
      "/assets/images/products/t-shirt3.png",
    ],
  },
  {
    price: 350,
    title: "ASUS ROG Strix G15",
    rating: 3,
    imgUrl: "/assets/images/products/t-shirt5.png",
    discount: 15,
    id: 3823161404,
    imgGroup: [
      "/assets/images/products/t-shirt5.png",
      "/assets/images/products/t-shirt5.png",
      "/assets/images/products/t-shirt5.png",
    ],
  },
  {
    price: 299,
    title: "ASUS ROG Strix G15",
    rating: 5,
    imgUrl: "/assets/images/products/chair-and-clothes.png",
    discount: 19,
    id: 9649434184,
    imgGroup: [
      "/assets/images/products/chair-and-clothes.png",
      "/assets/images/products/chair-and-clothes.png",
      "/assets/images/products/chair-and-clothes.png",
    ],
  },
  {
    price: 180,
    title: "ASUS ROG Strix G15",
    rating: 4,
    imgUrl: "/assets/images/products/t-shirt3.pngg",
    discount: 21,
    id: 7972403121,
    imgGroup: [
      "/assets/images/products/t-shirt3.pngg",
      "/assets/images/products/t-shirt3.pngg",
      "/assets/images/products/t-shirt3.pngg",
    ],
  },
  {
    price: 200,
    title: "Grey Sofa",
    rating: 4,
    imgUrl: "/assets/images/Furniture Shop/Furniture (1).png",
    discount: 5,
    status: "",
    category: "furniture",
    productColors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
    id: 9166392247,
    imgGroup: [
      "/assets/images/Furniture Shop/Furniture (1).png",
      "/assets/images/Furniture Shop/Furniture (1).png",
      "/assets/images/Furniture Shop/Furniture (1).png",
    ],
  },
  {
    price: 125,
    title: "Black Sofa",
    rating: 3,
    imgUrl: "/assets/images/Furniture Shop/Furniture (2).png",
    discount: 0,
    status: "New",
    category: "furniture",
    productColors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
    id: 3568601376,
    imgGroup: [
      "/assets/images/Furniture Shop/Furniture (2).png",
      "/assets/images/Furniture Shop/Furniture (2).png",
      "/assets/images/Furniture Shop/Furniture (2).png",
    ],
  },
  {
    price: 150,
    title: "Comfortable Sofa",
    rating: 3,
    imgUrl: "/assets/images/Furniture Shop/Furniture (3).png",
    discount: 20,
    status: "",
    category: "furniture",
    productColors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
    id: 7295351859,
    imgGroup: [
      "/assets/images/Furniture Shop/Furniture (3).png",
      "/assets/images/Furniture Shop/Furniture (3).png",
      "/assets/images/Furniture Shop/Furniture (3).png",
    ],
  },
  {
    price: 146,
    title: "Study Chair",
    rating: 2,
    imgUrl: "/assets/images/Furniture Shop/Furniture (4).png",
    discount: 5,
    status: "New",
    category: "furniture",
    productColors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
    id: 7882384109,
    imgGroup: [
      "/assets/images/Furniture Shop/Furniture (4).png",
      "/assets/images/Furniture Shop/Furniture (4).png",
      "/assets/images/Furniture Shop/Furniture (4).png",
    ],
  },
  {
    price: 138,
    title: "Study Gold Chair",
    rating: 5,
    imgUrl: "/assets/images/Furniture Shop/Furniture (6).png",
    discount: 0,
    status: "New",
    category: "furniture",
    productColors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
    id: 3806497434,
    imgGroup: [
      "/assets/images/Furniture Shop/Furniture (6).png",
      "/assets/images/Furniture Shop/Furniture (6).png",
      "/assets/images/Furniture Shop/Furniture (6).png",
    ],
  },
  {
    price: 100,
    title: "Double Sofa",
    rating: 4,
    imgUrl: "/assets/images/Furniture Shop/Furniture (7).png",
    discount: 13,
    status: "",
    category: "furniture",
    productColors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
    id: 783537235,
    imgGroup: [
      "/assets/images/Furniture Shop/Furniture (7).png",
      "/assets/images/Furniture Shop/Furniture (7).png",
      "/assets/images/Furniture Shop/Furniture (7).png",
    ],
  },
  {
    price: 457,
    title: "Brown Sofa",
    rating: 3,
    imgUrl: "/assets/images/Furniture Shop/Furniture (8).png",
    discount: 0,
    status: "New",
    category: "furniture",
    productColors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
    id: 6886713098,
    imgGroup: [
      "/assets/images/Furniture Shop/Furniture (8).png",
      "/assets/images/Furniture Shop/Furniture (8).png",
      "/assets/images/Furniture Shop/Furniture (8).png",
    ],
  },
  {
    price: 110,
    title: "Arm Sofa",
    rating: 5,
    imgUrl: "/assets/images/Furniture Shop/Furniture (9).png",
    discount: 17,
    status: "",
    category: "furniture",
    productColors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
    id: 4553983073,
    imgGroup: [
      "/assets/images/Furniture Shop/Furniture (9).png",
      "/assets/images/Furniture Shop/Furniture (9).png",
      "/assets/images/Furniture Shop/Furniture (9).png",
    ],
  },
  {
    price: 146,
    title: "Couple Sofa",
    rating: 2,
    imgUrl: "/assets/images/Furniture Shop/Furniture.png",
    discount: 2,
    status: "New",
    category: "furniture",
    productColors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
    id: 8883030738,
    imgGroup: [
      "/assets/images/Furniture Shop/Furniture.png",
      "/assets/images/Furniture Shop/Furniture.png",
      "/assets/images/Furniture Shop/Furniture.png",
    ],
  },
  {
    price: 146,
    title: "Fresh Line",
    rating: 2,
    imgUrl: "/assets/images/Groceries Shop/lime.png",
    discount: 7,
    category: "grocery1",
    id: 5761782611,
    imgGroup: [
      "/assets/images/Groceries Shop/lime.png",
      "/assets/images/Groceries Shop/lime.png",
      "/assets/images/Groceries Shop/lime.png",
    ],
  },
  {
    price: 110,
    title: "Mini Papers",
    rating: 3,
    imgUrl: "/assets/images/Groceries Shop/MiniPeppers.png",
    discount: 9,
    category: "grocery1",
    id: 4473716044,
    imgGroup: [
      "/assets/images/Groceries Shop/MiniPeppers.png",
      "/assets/images/Groceries Shop/MiniPeppers.png",
      "/assets/images/Groceries Shop/MiniPeppers.png",
    ],
  },
  {
    price: 105,
    title: "Fresh Strawberry",
    rating: 5,
    imgUrl: "/assets/images/Groceries Shop/strawberry.png",
    discount: 6,
    category: "grocery1",
    id: 8672259219,
    imgGroup: [
      "/assets/images/Groceries Shop/strawberry.png",
      "/assets/images/Groceries Shop/strawberry.png",
      "/assets/images/Groceries Shop/strawberry.png",
    ],
  },
  {
    price: 232,
    title: "Lemon",
    rating: 4,
    imgUrl: "/assets/images/Groceries Shop/Lemon.png",
    discount: 7,
    category: "grocery1",
    id: 4694350957,
    imgGroup: [
      "/assets/images/Groceries Shop/Lemon.png",
      "/assets/images/Groceries Shop/Lemon.png",
      "/assets/images/Groceries Shop/Lemon.png",
    ],
  },
  {
    price: 164,
    title: "Fresh Orange",
    rating: 4,
    imgUrl: "/assets/images/Groceries Shop/Orange.png",
    discount: 11,
    category: "grocery1",
    id: 4330013762,
    imgGroup: [
      "/assets/images/Groceries Shop/Orange.png",
      "/assets/images/Groceries Shop/Orange.png",
      "/assets/images/Groceries Shop/Orange.png",
    ],
  },
  {
    price: 141,
    title: "Fresh Line",
    rating: 2,
    imgUrl: "/assets/images/Groceries Shop/lime.png",
    discount: 12,
    category: "grocery1",
    id: 3437114550,
    imgGroup: [
      "/assets/images/Groceries Shop/lime.png",
      "/assets/images/Groceries Shop/lime.png",
      "/assets/images/Groceries Shop/lime.png",
    ],
  },
  {
    price: 145,
    title: "Red Apple",
    rating: 2,
    imgUrl: "/assets/images/Groceries Shop/Red apple.png",
    discount: 7,
    category: "grocery1",
    id: 145450158,
    imgGroup: [
      "/assets/images/Groceries Shop/Red apple.png",
      "/assets/images/Groceries Shop/Red apple.png",
      "/assets/images/Groceries Shop/Red apple.png",
    ],
  },
  {
    price: 216,
    title: "Lemon",
    rating: 3,
    imgUrl: "/assets/images/Groceries Shop/Lemon.png",
    discount: 9,
    category: "grocery1",
    id: 6935257247,
    imgGroup: [
      "/assets/images/Groceries Shop/Lemon.png",
      "/assets/images/Groceries Shop/Lemon.png",
      "/assets/images/Groceries Shop/Lemon.png",
    ],
  },
  {
    price: 198,
    title: "Offer Fruits",
    rating: 4,
    imgUrl: "/assets/images/Groceries Shop/Offer Card.png",
    discount: 12,
    category: "grocery1",
    id: 3805054724,
    imgGroup: [
      "/assets/images/Groceries Shop/Offer Card.png",
      "/assets/images/Groceries Shop/Offer Card.png",
      "/assets/images/Groceries Shop/Offer Card.png",
    ],
  },
  {
    price: 230,
    title: "Vichy Liftactiv Nuit Cream",
    rating: 5,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (1).png",
    discount: 8,
    id: 7070790490,
    imgGroup: [
      "/assets/images/Health Shop/Product (1).png",
      "/assets/images/Health Shop/Product (1).png",
      "/assets/images/Health Shop/Product (1).png",
    ],
  },
  {
    price: 65,
    title: "Sphygmomanometer",
    rating: 5,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (2).png",
    discount: 6,
    id: 1964376306,
    imgGroup: [
      "/assets/images/Health Shop/Product (2).png",
      "/assets/images/Health Shop/Product (2).png",
      "/assets/images/Health Shop/Product (2).png",
    ],
  },
  {
    price: 181,
    title: "Fresh Strawberry",
    rating: 3,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (4).png",
    discount: 11,
    id: 6124133195,
    imgGroup: [
      "/assets/images/Health Shop/Product (4).png",
      "/assets/images/Health Shop/Product (4).png",
      "/assets/images/Health Shop/Product (4).png",
    ],
  },
  {
    price: 211,
    title: "Nivea Cream",
    rating: 4,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (5).png",
    discount: 11,
    id: 4980810486,
    imgGroup: [
      "/assets/images/Health Shop/Product (5).png",
      "/assets/images/Health Shop/Product (5).png",
      "/assets/images/Health Shop/Product (5).png",
    ],
  },
  {
    price: 189,
    title: "Vichy Liftactiv Nuit Cream",
    rating: 5,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (6).png",
    discount: 8,
    id: 928671027,
    imgGroup: [
      "/assets/images/Health Shop/Product (6).png",
      "/assets/images/Health Shop/Product (6).png",
      "/assets/images/Health Shop/Product (6).png",
    ],
  },
  {
    price: 165,
    title: "Blue Stethoscope",
    rating: 3,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (3).png",
    discount: 9,
    id: 3965473517,
    imgGroup: [
      "/assets/images/Health Shop/Product (3).png",
      "/assets/images/Health Shop/Product (3).png",
      "/assets/images/Health Shop/Product (3).png",
    ],
  },
  {
    price: 72,
    title: "Sphygmomanometer",
    rating: 3,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (7).png",
    discount: 12,
    id: 8082689609,
    imgGroup: [
      "/assets/images/Health Shop/Product (7).png",
      "/assets/images/Health Shop/Product (7).png",
      "/assets/images/Health Shop/Product (7).png",
    ],
  },
  {
    price: 163,
    title: "Vichy Liftactiv Cream",
    rating: 3,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (8).png",
    discount: 7,
    id: 3417973164,
    imgGroup: [
      "/assets/images/Health Shop/Product (8).png",
      "/assets/images/Health Shop/Product (8).png",
      "/assets/images/Health Shop/Product (8).png",
    ],
  },
  {
    price: 180,
    title: "Nivea Cream Soft Milk",
    rating: 3,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (9).png",
    discount: 8,
    id: 398141020,
    imgGroup: [
      "/assets/images/Health Shop/Product (9).png",
      "/assets/images/Health Shop/Product (9).png",
      "/assets/images/Health Shop/Product (9).png",
    ],
  },
  {
    price: 219,
    title: "Gold Wedding Ring",
    rating: 3,
    category: "giftshop",
    imgUrl: "/assets/images/Gift Shop/Product 7.png",
    discount: 9,
    id: 6503013484,
    imgGroup: [
      "/assets/images/Gift Shop/Product 7.png",
      "/assets/images/Gift Shop/Product 7.png",
      "/assets/images/Gift Shop/Product 7.png",
    ],
  },
  {
    price: 209,
    title: "Gift For Baby",
    rating: 3,
    category: "giftshop",
    imgUrl: "/assets/images/Gift Shop/Product 4.png",
    discount: 17,
    id: 1042471944,
    imgGroup: [
      "/assets/images/Gift Shop/Product 4.png",
      "/assets/images/Gift Shop/Product 4.png",
      "/assets/images/Gift Shop/Product 4.png",
    ],
  },
  {
    price: 248,
    title: "Special Gift For Young",
    rating: 5,
    category: "giftshop",
    imgUrl: "/assets/images/Gift Shop/Gift Box.png",
    discount: 9,
    id: 1263502673,
    imgGroup: [
      "/assets/images/Gift Shop/Gift Box.png",
      "/assets/images/Gift Shop/Gift Box.png",
      "/assets/images/Gift Shop/Gift Box.png",
    ],
  },
  {
    price: 111,
    title: "Gift For Boy",
    rating: 5,
    category: "giftshop",
    imgUrl: "/assets/images/Gift Shop/Product 5.png",
    discount: 12,
    id: 5884344952,
    imgGroup: [
      "/assets/images/Gift Shop/Product 5.png",
      "/assets/images/Gift Shop/Product 5.png",
      "/assets/images/Gift Shop/Product 5.png",
    ],
  },
  {
    price: 65,
    title: "Baby Toy Gift",
    rating: 5,
    category: "giftshop",
    imgUrl: "/assets/images/Gift Shop/Product 3.png",
    discount: 18,
    id: 8634030972,
    imgGroup: [
      "/assets/images/Gift Shop/Product 3.png",
      "/assets/images/Gift Shop/Product 3.png",
      "/assets/images/Gift Shop/Product 3.png",
    ],
  },
  {
    price: 71,
    title: "Baby Doll Gift",
    rating: 3,
    category: "giftshop",
    imgUrl: "/assets/images/Gift Shop/Product 6.png",
    discount: 8,
    id: 7101230747,
    imgGroup: [
      "/assets/images/Gift Shop/Product 6.png",
      "/assets/images/Gift Shop/Product 6.png",
      "/assets/images/Gift Shop/Product 6.png",
    ],
  },
  {
    price: 172,
    title: "Jewellery Gift",
    rating: 4,
    category: "giftshop",
    imgUrl: "/assets/images/Gift Shop/Gift box 2.png",
    discount: 18,
    id: 3376292520,
    imgGroup: [
      "/assets/images/Gift Shop/Gift box 2.png",
      "/assets/images/Gift Shop/Gift box 2.png",
      "/assets/images/Gift Shop/Gift box 2.png",
    ],
  },
  {
    price: 162,
    title: "Birthday Gift For Girl",
    rating: 3,
    category: "giftshop",
    imgUrl: "/assets/images/Gift Shop/pngwing.com (3).png",
    discount: 8,
    id: 7544411360,
    imgGroup: [
      "/assets/images/Gift Shop/pngwing.com (3).png",
      "/assets/images/Gift Shop/pngwing.com (3).png",
      "/assets/images/Gift Shop/pngwing.com (3).png",
    ],
  },
  {
    price: 98,
    title: "Gift For Boy",
    rating: 3,
    category: "giftshop",
    imgUrl: "/assets/images/Gift Shop/Product 5.png",
    discount: 11,
    id: 6808422238,
    imgGroup: [
      "/assets/images/Gift Shop/Product 5.png",
      "/assets/images/Gift Shop/Product 5.png",
      "/assets/images/Gift Shop/Product 5.png",
    ],
  },
  {
    price: 85,
    title: "Baby Toy Gift",
    rating: 5,
    category: "giftshop",
    imgUrl: "/assets/images/Gift Shop/Product 3.png",
    discount: 13,
    id: 8643586433,
    imgGroup: [
      "/assets/images/Gift Shop/Product 3.png",
      "/assets/images/Gift Shop/Product 3.png",
      "/assets/images/Gift Shop/Product 3.png",
    ],
  },
  {
    price: 218,
    title: "Baby Doll Gift",
    rating: 5,
    category: "giftshop",
    imgUrl: "/assets/images/Gift Shop/Product 6.png",
    discount: 11,
    id: 5666445442,
    imgGroup: [
      "/assets/images/Gift Shop/Product 6.png",
      "/assets/images/Gift Shop/Product 6.png",
      "/assets/images/Gift Shop/Product 6.png",
    ],
  },
  {
    price: 142,
    title: "Jewellery Gift",
    rating: 4,
    category: "giftshop",
    imgUrl: "/assets/images/Gift Shop/Gift box 2.png",
    discount: 12,
    id: 3938759640,
    imgGroup: [
      "/assets/images/Gift Shop/Gift box 2.png",
      "/assets/images/Gift Shop/Gift box 2.png",
      "/assets/images/Gift Shop/Gift box 2.png",
    ],
  },

  {
    title: "Premium Grocery Collection",
    price: 250,
    imgUrl: "/assets/images/products/Groceries/2.PremiumGroceryCollection.png",
    imgGroup: [
      "/assets/images/products/Groceries/2.PremiumGroceryCollection.png",
      "/assets/images/products/Groceries/2.PremiumGroceryCollection.png",
      "/assets/images/products/Groceries/2.PremiumGroceryCollection.png",
    ],
    category: "groceries",
    discount: 11,
    id: 1818036939,
  },
  {
    title: "Premium Grocery Pack",
    price: 250,
    imgUrl: "/assets/images/products/Groceries/3.PremiumGroceryPack.png",
    imgGroup: [
      "/assets/images/products/Groceries/3.PremiumGroceryPack.png",
      "/assets/images/products/Groceries/3.PremiumGroceryPack.png",
      "/assets/images/products/Groceries/3.PremiumGroceryPack.png",
    ],
    category: "groceries",
    discount: 14,
    id: 8990926163,
  },
  {
    title: "Fresh&Real CHole Masala",
    price: 250,
    imgUrl: "/assets/images/products/Groceries/4.FreashRealCholeMasala.png",
    imgGroup: [
      "/assets/images/products/Groceries/4.FreashRealCholeMasala.png",
      "/assets/images/products/Groceries/4.FreashRealCholeMasala.png",
      "/assets/images/products/Groceries/4.FreashRealCholeMasala.png",
    ],
    category: "groceries",
    discount: 13,
    id: 3085195407,
  },
  {
    title: "Gum Pack",
    price: 250,
    imgUrl: "/assets/images/products/Groceries/5.GumPack.png",
    imgGroup: [
      "/assets/images/products/Groceries/5.GumPack.png",
      "/assets/images/products/Groceries/5.GumPack.png",
      "/assets/images/products/Groceries/5.GumPack.png",
    ],
    category: "groceries",
    discount: 13,
    id: 3098429480,
  },

  {
    title: "Colgate Advance Protection Toothpaste",
    price: 250,
    imgUrl: "/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png",
    category: "groceries",
    discount: 12,
    id: 4145242238,
    imgGroup: [
      "/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png",
      "/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png",
      "/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png",
    ],
  },
  {
    title: "Catch Sprinklers Chat Masala",
    price: 250,
    imgUrl: "/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png",
    category: "groceries",
    discount: 19,
    id: 7485173785,
    imgGroup: [
      "/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png",
      "/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png",
      "/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png",
    ],
  },
  {
    title: "Catch Italian Seasoning Grinder",
    price: 250,
    imgUrl: "/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png",
    category: "groceries",
    discount: 10,
    id: 8359512442,
    imgGroup: [
      "/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png",
      "/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png",
      "/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png",
    ],
  },
  {
    title: "Tadka Garam Masala",
    price: 250,
    imgUrl: "/assets/images/products/Groceries/11.TadkaGaramMasala.png",
    category: "groceries",
    discount: 16,
    id: 2796979839,
    imgGroup: [
      "/assets/images/products/Groceries/11.TadkaGaramMasala.png",
      "/assets/images/products/Groceries/11.TadkaGaramMasala.png",
      "/assets/images/products/Groceries/11.TadkaGaramMasala.png",
    ],
  },
];
