import { RootState, _ } from "common";
import { createSelector } from "reselect";

const selectPriceListState = (state: RootState) => state.PriceList;

const selectPriceListEUData = createSelector(
  selectPriceListState,
  (state) => state.EUpriceList.data
);

const selectPriceListEU = createSelector(
  selectPriceListState,
  (state) => state.EUpriceList
);

const selectPaginationMeta = createSelector(
  selectPriceListState,
  (state) => state.EUpriceList.meta
);

const selectSortDir = createSelector(
  selectPriceListState,
  (state) => state.payload.sort
);

const selectPayload = createSelector(
  selectPriceListState,
  (state) => state.payload
);

const selectPriceListLoading = createSelector(
  selectPriceListState,
  (state) => state.priceListLoading
);

export const selectors = {
  selectPriceListEU,
  selectPriceListLoading,
  selectPayload,
  selectPaginationMeta,
  selectPriceListEUData,
  selectSortDir,
};
