import { currentTimeMilliseconds, RootState, _ } from "common";
import { createSelector } from "reselect";

const selectOAuth = (state: RootState) => state.OAuth;

const selectClientToken = createSelector(selectOAuth, (state) => {
  const clientToken = state.access_token || null;
  const expiresIn = state.expires_in || null;

  const validClientToken =
    currentTimeMilliseconds() >= expiresIn ? null : clientToken;
  return validClientToken;
});

export const selectors = {
  selectOAuth,
  selectClientToken
};
