import { Api, ApiResponse } from "common";
import { CompanyInfoResponse } from "./types";
// import { CategoriesPayload, CategoriesResponse } from "./types";

// const getCategories = (
//   params?: CategoriesPayload
// ): ApiResponse<CategoriesResponse> => {
//   return Api.get("api/ecomm/category/get", params);
// };

// export const CategoryApi = {
//   getCategories,
// };

const getCompanyInfo = (
): ApiResponse<CompanyInfoResponse> => {
    return Api.get(`api/company/information/get`);
};

export const CompanyInfoApi = {
    getCompanyInfo
};
