export const packagePayments = [
  {
    no: 1,
    date: "20-04-2022",
    seller: "Ethan Booth",
    package: "Premium",
    amount: 200,
    payment: "Stripe",
  },
  {
    no: 2,
    date: "19-04-2022",
    seller: "Morgan Benson",
    package: "Gold",
    amount: 115,
    payment: "PayPal",
  },
  {
    no: 3,
    date: "17-04-2022",
    seller: "Hayden Arnold",
    package: "Silver",
    amount: 105,
    payment: "Payoneer",
  },
  {
    no: 4,
    date: "14-04-2022",
    seller: "Connor Birch",
    package: "Premium",
    amount: 215,
    payment: "Stripe",
  },
  {
    no: 5,
    date: "08-04-2022",
    seller: "Jude Sullivan",
    package: "Premium",
    amount: 200,
    payment: "PayPal",
  },
  {
    no: 6,
    date: "01-04-2022",
    seller: "Alex Sykes",
    package: "Silver",
    amount: 100,
    payment: "Visa Card",
  },
  {
    no: 7,
    date: "26-03-2022",
    seller: "Alex Sykes",
    package: "Silver",
    amount: 100,
    payment: "Visa Card",
  },
  {
    no: 8,
    date: "16-03-2022",
    seller: "Alex Sykes",
    package: "Silver",
    amount: 100,
    payment: "Visa Card",
  },
  {
    no: 9,
    date: "12-03-2022",
    seller: "Alex Sykes",
    package: "Silver",
    amount: 100,
    payment: "Visa Card",
  },
];
