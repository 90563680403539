import cloneDeep from "lodash/cloneDeep";

import { CartItem } from "modules/cart/types";
import { CategoriesIdsEnum } from "common/enums/categories";

export const getAllCartItemInQtyOne = (cart: CartItem[]) => {
  const clonedCart = cloneDeep(cart);
  let newCart: CartItem[] = [];

  clonedCart?.forEach((x) => {
    if (x?.quantity > 1) {
      for (let i = 0; i < x?.quantity; i++) {
        newCart.push({ ...x, quantity: 1 });
      }
    } else {
      newCart.push(x);
    }
  });
  return newCart;
};

export const isCPUAllowedInCart = (cartItem: CartItem, cart: CartItem[]) => {
  if (
    cartItem?.category_id === CategoriesIdsEnum.CPU &&
    cartItem?.with_bundle
  ) {
    const clonedCart = cloneDeep(cart || []);
    let motherboardsInCart = clonedCart?.filter(
      (x) => x?.category_id === CategoriesIdsEnum.Motherboard
    );

    const compatibleMobos = cartItem?.compatible_mobos || [];
    let hasCompatibleMobo;

    motherboardsInCart?.every(function (element, index) {
      if (hasCompatibleMobo) {
        return false;
      } else {
        hasCompatibleMobo = compatibleMobos?.find(
          (i) => i?.slug === element?.product_slug
        );
        return true;
      }
    });

    if (!hasCompatibleMobo) {
      return false;
    }
  }
  return true;
};

/**
 *
 * @param cpuCartItem - the CPU item
 * @param mobos - the motherboards selection
 * @returns the first motherboard name compatible with CPU
 */
export const getCompatibleMoboViaCPU = (
  cpuCartItem: CartItem,
  mobosForChecker: CartItem[]
) => {
  if (
    cpuCartItem?.category_id === CategoriesIdsEnum.CPU ||
    cpuCartItem?.category === "CPU"
  ) {
    const compatibleMobos = cpuCartItem?.compatible_mobos || [];
    let compatibleMoboSlug: string;

    mobosForChecker?.every((moboCheck) => {
      const isExistInCompatibleMobos = compatibleMobos?.find(
        (x) => x?.slug === moboCheck?.product_slug
      );
      if (isExistInCompatibleMobos) {
        compatibleMoboSlug = isExistInCompatibleMobos?.slug;
        return false;
      }
      return true;
    });
    return compatibleMoboSlug;
  }
};

/**
 * We want to arrange products to have unique product_slug only.
 * If duplicate product_slug exist, add quantity
 * @param cart
 */
export const cartItemsToUniqSlugs = (cartArg: CartItem[]) => {
  const cart = cloneDeep(cartArg);
  let finalCartItems: CartItem[] = [];

  cart?.forEach((item) => {
    const itemExistIndex = finalCartItems?.findIndex(
      (x) => x?.product_slug === item?.product_slug
    );
    if (itemExistIndex > -1) {
      // Add qty
      finalCartItems[itemExistIndex].quantity =
        (finalCartItems[itemExistIndex]?.quantity || 1) + (item?.quantity || 0);
    } else {
      // insert new
      finalCartItems?.push(item);
    }
  });

  // quantity of each item should not exceed stocks_left property..
  finalCartItems?.map((item) => {
    const stocksLeft = item?.stocks_left || 100;
    const quantity = item?.quantity || 1;

    if (quantity > stocksLeft) {
      item.quantity = stocksLeft;
    }
    return item;
  });

  return finalCartItems || [];
};
