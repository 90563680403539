import { useCallback, useMemo, useState } from "react";

import modules from "modules";
import { useAppSelector } from "./use-app-selector";
import { useAppDispatch } from "./use-app-dispatch";
import { useRouter } from "next/router";
import { ProductsPayload } from "modules/products/types";
import { categoryNameToId } from "utils/categoryConverterUtils";
import { ProdSortDirPayloadEnum } from "common";
import { fbqTrack } from "utils/FacebookMetaPixel";
import { MainCategoryDetails } from "modules/category/types";
import { number } from "yup";

const { selectors: productsSelectors, actions: productsActions } =
  modules.products;

const { selectors: categorySelectors } = modules.category;

type SearchV2Params = MainCategoryDetails & { page?: number };

export const useSearchPage = () => {
  const router = useRouter();
  const keyword: string = (router?.query?.id as string) || ("" as string);
  const queries = router?.query as ProductsPayload | undefined;

  const mainCategoryItem = useAppSelector(
    categorySelectors.selectMainCategoryItem
  );

  const [selectedSearchPageCategory, setSearchSelectedPageCategory] =
    useState(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const categoryName = useMemo(
    () => (mainCategoryItem ? mainCategoryItem.name : ""),
    [mainCategoryItem]
  );
  const dispatch = useAppDispatch();

  /** ========== SELECTORS / VARIABLES / CONSTANTS ========== */
  const searchPageItems = useAppSelector(
    productsSelectors.selectSearchProductsItems
  );
  const isSearchPageLoading = useAppSelector(
    productsSelectors.selectSearchProductsLoading
  );
  const searchPageResponse = useAppSelector(
    productsSelectors.selectSearchProductsResponse
  );

  const productSearchFacet = useAppSelector(
    productsSelectors.selectProductSearchingFacet
  );

  const subCategoryId = useAppSelector(productsSelectors.selectSubCategoryId);

  const subCategoryState = useAppSelector(
    productsSelectors.selectSubCategoryState
  );

  const responseMeta = useMemo(() => {
    return searchPageResponse?.meta;
  }, [searchPageResponse]);

  const subCategoryStateKeyword = useMemo(() => {
    return subCategoryState.find((sub_categ) => {
      return sub_categ?.category_id === subCategoryId;
    });
  }, [subCategoryId, subCategoryState]);

  const handleSelectSearchPageCategory = (categories) => {
    const selectedCategory = categories?.find(
      (category) =>
        category?.id ===
        categoryNameToId(queries?.category?.toString(), categories)
    );

    setSearchSelectedPageCategory(
      selectedCategory?.name ? selectedCategory?.name : categoryName
    );
  };

  /** ========== FUNCTIONS ========== */
  const searchPageRequest = useCallback(
    (categoryID?: number) => {
      fbqTrack("Search", {
        search_string: keyword,
        content_category: "Product Search Page",
      });

      const newSort =
        queries?.sort_direction === ProdSortDirPayloadEnum.Descending
          ? "desc"
          : "asc";

      const payload = {
        ...queries,
        keyword,
        category: categoryID,
        sort_direction: newSort,
        limit: 48,
      };
      delete payload.id;
      dispatch(productsActions.getSearchPageProductsThunk(payload));
    },
    [dispatch, keyword, queries]
  );

  const searchPageRequestV2 = useCallback(
    (searchParams?: SearchV2Params, categories?: any) => {
      fbqTrack("Search", {
        search_string: keyword,
        content_category: "Product Search Page",
      });

      const newSort =
        queries?.sort_direction === ProdSortDirPayloadEnum.Descending
          ? "desc"
          : "asc";

      const categoryID = categoryNameToId(
        queries?.category?.toString(),
        categories
      );

      const payload = {
        ...queries,
        sub_category_ids: [],
        category: subCategoryId || categoryID,
        keyword,
        sort_direction: newSort,
        limit: 48,
      };

      if (!queries?.category) {
        delete payload?.category;
      }

      delete payload.id;
      dispatch(productsActions.getSearchPageProductsThunk(payload));
    },
    [dispatch, keyword, queries, subCategoryId]
  );

  const subCategoryPerKeyword = useCallback(
    (params: {
      categ_id?: number;
      sub_keyword?: string;
      price_range?: string;
      brands?: string;
    }) => {
      const { categ_id, sub_keyword, price_range, brands } = params;

      const currentMainCategoryState = subCategoryState.find(
        (mainCategory) => mainCategory?.category_id === params?.categ_id // Fixed condition
      );

      if (currentMainCategoryState) {
        const newSubCategoryKeywordState = subCategoryState.map((sub_categ) => {
          if (sub_categ?.category_id === categ_id) {
            return {
              category_id: sub_categ?.category_id,
              category_keyword: sub_keyword || sub_categ?.category_keyword,
              price_range: price_range || sub_categ?.price_range,
              brands_selected:
                brands === null ? null : brands || sub_categ?.brands_selected, //brands selected can be activated and deactivated
            };
          }
          return sub_categ;
        });

        dispatch(
          productsActions?.changeMainCategoryState(newSubCategoryKeywordState)
        );
      } else {
        dispatch(
          productsActions?.changeMainCategoryState([
            ...subCategoryState,
            {
              category_id: categ_id,
              category_keyword: sub_keyword || "",
              price_range,
              brands_selected: brands,
            },
          ])
        );
      }
    },
    [dispatch, subCategoryState]
  );

  const subCategoryClearKeywordState = useCallback(
    (clearAll?: boolean) => {
      const transformSubCategState = subCategoryState?.map((item) => ({
        ...item,
        category_keyword: "",
      }));

      if (clearAll) {
        dispatch(productsActions?.changeMainCategoryState([]));
        return;
      }

      dispatch(
        productsActions?.changeMainCategoryState(transformSubCategState)
      );
    },
    [dispatch, subCategoryState]
  );

  return {
    isSearchPageLoading,
    searchPageItems,
    searchPageResponse,
    responseMeta,
    keyword,
    queries,
    selectedSearchPageCategory,
    categoryName,
    productSearchFacet,
    subCategoryId,
    subCategoryState,
    subCategoryStateKeyword,
    handleSelectSearchPageCategory,
    searchPageRequest,
    searchPageRequestV2,
    subCategoryPerKeyword,
    subCategoryClearKeywordState,
  };
};
