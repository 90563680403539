import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import { PriceListApi } from "./api";
import {
  GetPaymongoMerchantInterestResponse,
  GetSocialLoginBtnOptionResponse,
} from "./types";

export const getPaymongoInterestThunk = createAsyncThunk<
  CommonAxiosResponse<GetPaymongoMerchantInterestResponse>
>("EUpricelist/getPriceListThunk", async () => {
  const res = await PriceListApi.getPaymongoInterest();
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getSocialBtnThunk = createAsyncThunk<
  CommonAxiosResponse<GetSocialLoginBtnOptionResponse>
>("EUpricelist/getSocialBtnThunk", async () => {
  const res = await PriceListApi.getSocialLoginOptions();
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
