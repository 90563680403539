import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import { PcPackageApi } from "./api";
import {
  PcPackageDetailPayLoad,
  PcPackageDetailResponse,
  PcPackagePayload,
  PcPackageListResponse,
  PcPackageOnProductResponse,
} from "./types";

export const getPcPackageListThunk = createAsyncThunk<
  CommonAxiosResponse<PcPackageListResponse>,
  PcPackagePayload
>("PcPackage/getPcPackageThunk", async (data) => {
  const res = await PcPackageApi.getPcPackage(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getPcPackageDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<PcPackageDetailResponse>,
  PcPackageDetailPayLoad
>("PcPackageDetail/getPcPackageDetailsThunk", async (data) => {
  const res = await PcPackageApi.getPcPackageDetails(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getPcPackagesOnProductThunk = createAsyncThunk<
  CommonAxiosResponse<PcPackageOnProductResponse>,
  string
>("PcPackageDetail/getPcPackagesOnProductThunk", async (uuid) => {
  const res = await PcPackageApi.getPcPackageOnProduct(uuid);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
