import { Box, Button, Divider } from "@mui/material";
import BazarButton from "components/BazarButton";
import Image from "components/BazarImage";
import { FlexBox, FlexRowCenter } from "components/flex-box";
import { H6 } from "components/Typography";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useSession, signIn, signOut } from "next-auth/react";
import modules from "modules";
import Link from "next/link";
import React, { FC, Fragment, useState } from "react";
import { useRouter } from "next/router";
import { useIsMobile } from "hooks";
import { navigationRoutes } from "common/constants/navigation-routes";

type SocialButtonsProps = { socialOpt?: number };

const SocialButtons: FC<SocialButtonsProps> = ({
  socialOpt,
}: SocialButtonsProps) => {
  const router = useRouter();
  const { data: session, status } = useSession();
  const isMobile = useIsMobile();

  const handleFacebookLogin = (e) => {
    e.preventDefault();
    signIn("facebook", {
      callbackUrl: navigationRoutes.pcwProfile,
      prompt: "login",
    });
  };

  const handleGoogleLogin = async (e) => {
    e.preventDefault();
    signIn("google", {
      callbackUrl: navigationRoutes.pcwProfile,
      prompt: "login",
    });
  };

  return (
    <Fragment>
      {socialOpt === 1 ? null : (
        <BazarButton
          className="facebookButton"
          size="medium"
          fullWidth
          sx={{ height: 44 }}
          onClick={handleFacebookLogin}
        >
          <Image
            src="/assets/images/icons/facebook-filled-white.svg"
            alt="facebook"
          />
          <Box fontSize="12px" ml={1}>
            Continue with Facebook
          </Box>
        </BazarButton>
      )}

      {socialOpt === 2 ? null : (
        <BazarButton
          className="googleButton"
          size="medium"
          fullWidth
          sx={{ height: 44 }}
          onClick={handleGoogleLogin}
        >
          <Image src="/assets/images/icons/google-1.svg" alt="facebook" />
          <Box fontSize="12px" ml={1}>
            Continue with Google
          </Box>
        </BazarButton>
      )}
    </Fragment>
  );
};

export default SocialButtons;
