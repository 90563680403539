export const name = "PcwUserProfile";
export const userProfileClass = {
  all: "all",
  basic: "basic",
  email: "email",
  status: "status",
  kachi: "kachi_points",
};

export const constants = {
  name,
};
