export const name = "OAuth";
export const GRANT_TYPE_CLIENT = "client_credentials";
export const SCOPE = "*";
export const CLIENT_ID = process.env.NEXT_PUBLIC_PCWORTH_CLIENT_ID;
export const CLIENT_SECRET = process.env.NEXT_PUBLIC_PCWORTH_CLIENT_SECRET;

export const constants = {
  name,
  GRANT_TYPE_CLIENT,
  SCOPE,
  CLIENT_ID,
  CLIENT_SECRET,
};
