import {Location } from "modules/location/types";

export const mockRegions: Location[] = [
  {
    id: 15,
    name: "AUTONOMOUS REGION IN MUSLIM MINDANAO (ARMM)1",
    href: "https://ph-locations-api.buonzz.com/v1/regions/15",
  },
  {
    id: 15,
    name: "AUTONOMOUS REGION IN MUSLIM MINDANAO (ARMM)",
    href: "https://ph-locations-api.buonzz.com/v1/regions/15",
  },
  {
    id: 15,
    name: "AUTONOMOUS REGION IN MUSLIM MINDANAO (ARMM)",
    href: "https://ph-locations-api.buonzz.com/v1/regions/15",
  },
  {
    id: 15,
    name: "AUTONOMOUS REGION IN MUSLIM MINDANAO (ARMM)",
    href: "https://ph-locations-api.buonzz.com/v1/regions/15",
  },
  {
    id: 14,
    name: "CORDILLERA ADMINISTRATIVE REGION (CAR)",
    href: "https://ph-locations-api.buonzz.com/v1/regions/14",
  },
  {
    id: 14,
    name: "CORDILLERA ADMINISTRATIVE REGION (CAR)",
    href: "https://ph-locations-api.buonzz.com/v1/regions/14",
  },
  {
    id: 14,
    name: "CORDILLERA ADMINISTRATIVE REGION (CAR)",
    href: "https://ph-locations-api.buonzz.com/v1/regions/14",
  },
  {
    id: 13,
    name: "NATIONAL CAPITAL REGION (NCR)",
    href: "https://ph-locations-api.buonzz.com/v1/regions/13",
  },
  {
    id: 1,
    name: "REGION I (ILOCOS REGION)",
    href: "https://ph-locations-api.buonzz.com/v1/regions/01",
  },
  {
    id: 2,
    name: "REGION II (CAGAYAN VALLEY)E",
    href: "https://ph-locations-api.buonzz.com/v1/regions/02",
  },
];

export const mockProvinces: Location[] = [
  {
    id: 1339,
    name: "CITY OF MANILA",
    region_code: 13,
    href: "https://ph-locations-api.buonzz.com/v1/provinces/1339",
  },
  {
    id: 1339,
    name: "NCR, CITY OF MANILA, FIRST DISTRICT",
    region_code: 13,
    href: "https://ph-locations-api.buonzz.com/v1/provinces/1339",
  },
  {
    id: 1376,
    name: "NCR, FOURTH DISTRICT",
    region_code: 13,
    href: "https://ph-locations-api.buonzz.com/v1/provinces/1376",
  },
  {
    id: 1374,
    name: "NCR, SECOND DISTRICT",
    region_code: 13,
    href: "https://ph-locations-api.buonzz.com/v1/provinces/1374",
  },
  {
    id: 1375,
    name: "NCR, THIRD DISTRICT",
    region_code: 13,
    href: "https://ph-locations-api.buonzz.com/v1/provinces/1375",
  },
];

export const mockCities: Location[] = [
  {
    id: 133913,
    name: "PORT AREA",
    region_code: 13,
    province_code: 1339,
    href: "https://ph-locations-api.buonzz.com/v1/cities/133913",
  },
  {
    id: 133903,
    name: "QUIAPO",
    region_code: 13,
    province_code: 1339,
    href: "https://ph-locations-api.buonzz.com/v1/cities/133903",
  },
  {
    id: 133906,
    name: "SAMPALOC",
    region_code: 13,
    province_code: 1339,
    href: "https://ph-locations-api.buonzz.com/v1/cities/133906",
  },
  {
    id: 133907,
    name: "SAN MIGUEL",
    region_code: 13,
    province_code: 1339,
    href: "https://ph-locations-api.buonzz.com/v1/cities/133907",
  },
];

export const mockBarangays: Location[] = [
  {
    id: 133906004,
    name: "Barangay 398",
    region_code: 13,
    province_code: 1339,
    city_code: 133906,
    href: "https://ph-locations-api.buonzz.com/v1/barangays/133906004",
  },
  {
    id: 133906005,
    name: "Barangay 399",
    region_code: 13,
    province_code: 1339,
    city_code: 133906,
    href: "https://ph-locations-api.buonzz.com/v1/barangays/133906005",
  },
  {
    id: 133906006,
    name: "Barangay 400",
    region_code: 13,
    province_code: 1339,
    city_code: 133906,
    href: "https://ph-locations-api.buonzz.com/v1/barangays/133906006",
  },
  {
    id: 133906007,
    name: "Barangay 401",
    region_code: 13,
    province_code: 1339,
    city_code: 133906,
    href: "https://ph-locations-api.buonzz.com/v1/barangays/133906007",
  },
  {
    id: 133906008,
    name: "Barangay 402",
    region_code: 13,
    province_code: 1339,
    city_code: 133906,
    href: "https://ph-locations-api.buonzz.com/v1/barangays/133906008",
  },
  {
    id: 133906009,
    name: "Barangay 403",
    region_code: 13,
    province_code: 1339,
    city_code: 133906,
    href: "https://ph-locations-api.buonzz.com/v1/barangays/133906009",
  },
  {
    id: 133906010,
    name: "Barangay 404",
    region_code: 13,
    province_code: 1339,
    city_code: 133906,
    href: "https://ph-locations-api.buonzz.com/v1/barangays/133906010",
  },
  {
    id: 133906011,
    name: "Barangay 405",
    region_code: 13,
    province_code: 1339,
    city_code: 133906,
    href: "https://ph-locations-api.buonzz.com/v1/barangays/133906011",
  },
];