import modules from "modules";
import { all, takeLatest } from "redux-saga/effects";

const { sagas: cartSagas, actions: cartActions } = modules.cart;
const { sagas: pcBuilderSagas, actions: pcBuilderActions } = modules.pcBuilder;
const { sagas: categorySagas, actions: categoryActions } = modules.category;
const { sagas: buildsGallerySagas, actions: buildsGalleryActions } =
  modules.buildsGallery;
const { sagas: productDetailsSagas, actions: productDetailsActions } =
  modules.productDetails;

export function* rootSaga(): Generator {
  yield all([
    /* builds Gallery */
    yield takeLatest(
      buildsGalleryActions.buildsGalleryRequest.type,
      buildsGallerySagas.getBuildsGallerySaga
    ),
    /* cart */
    yield takeLatest(
      cartActions.cartAvailabilityRequest.type,
      cartSagas.cartAvailabilitySaga
    ),
    yield takeLatest(
      cartActions.productsAvailabilityRequest.type,
      cartSagas.productsAvailabilitySaga
    ),
    yield takeLatest(
      cartActions.createOrderRequest.type,
      cartSagas.createOrderSaga
    ),

    /* category */
    yield takeLatest(
      categoryActions.categoriesRequest.type,
      categorySagas.categoriesSaga
    ),

    /* pcbuilder */
    yield takeLatest(
      pcBuilderActions.partsCompatibleRequest.type,
      pcBuilderSagas.partsCompatibleSaga
    ),

    /* product details */
    yield takeLatest(
      productDetailsActions.productDetailsRequest.type,
      productDetailsSagas.productDetailsSaga
    ),

    /* product details images*/
    yield takeLatest(
      productDetailsActions.productImagesRequest.type,
      productDetailsSagas.productImagesSaga
    ),
  ]);
}
