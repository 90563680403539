import { Dialog, DialogContent } from "@mui/material";
import ForgotPassword from "./ForgotPasswordForm";
import VerificationSignUp from "./SignUpVerification";
import OtpVerification from "./OtpVerification";
import modules from "modules";
import { useDialogueShow } from "hooks/useDialogue";
import ResetPasswordOtp from "./ResetPasswordOtp";
import UpdatePasswordForm from "./UpdatePasswordForm";

type DialogueSessionsProps = {};
const DialogueSessions: React.FC<DialogueSessionsProps> = () => {
  const {
    showFpwordDialogue,
    showDialogueEmail,
    showDialogueMobile,
    showResetPasswordOtp,
    showUpdatePasswordForm,
    showEmailVerify,
    showMobileVerify,
    showForgotPasswordClose,
  } = useDialogueShow();

  return (
    <div>
      <Dialog open={showDialogueEmail || showEmailVerify} onClose={() => {}}>
        <DialogContent sx={{ margin: 0, padding: 0 }}>
          <VerificationSignUp />
        </DialogContent>
      </Dialog>

      <Dialog open={showDialogueMobile || showMobileVerify} onClose={() => {}}>
        <DialogContent sx={{ margin: 0, padding: 0 }}>
          {/* for mobile only :( */}
          <OtpVerification />
        </DialogContent>
      </Dialog>

      <Dialog
        open={showFpwordDialogue}
        onClose={() => showForgotPasswordClose()}
      >
        <DialogContent sx={{ margin: 0, padding: 0 }}>
          <ForgotPassword />
        </DialogContent>
      </Dialog>

      <Dialog open={showResetPasswordOtp} onClose={() => {}}>
        <DialogContent sx={{ margin: 0, padding: 0 }}>
          <ResetPasswordOtp />
        </DialogContent>
      </Dialog>

      <Dialog open={showUpdatePasswordForm} onClose={() => {}}>
        <DialogContent sx={{ margin: 0, padding: 0 }}>
          <UpdatePasswordForm />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DialogueSessions;
