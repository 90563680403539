import { Api, ApiResponse } from "common";
import { CodeUrlResponse, UrlCodeParamPayload } from "./types";

// import { GetFlashDealsPayload, GetFlashDealsResponse } from "./types";

const getUrlCode = (
  payload: UrlCodeParamPayload
): ApiResponse<CodeUrlResponse> => {
  return Api.get(`api/ecomm/code_url/get/${payload}`);
};

export const PcwUrlEvent = {
  getUrlCode,
};
