import { _ } from "./lodash";

export const addItemInArrayUnique = <T>(arr: T[], val: T) => {
  return _.union(arr, [val]);
};

export const addNonPrimItemInArrayUnique = <T>(
  arr: T[],
  val: T,
  key: string
) => {
  const existingIndex = arr?.findIndex((x) => val[key] === x[key]);
  if (existingIndex > -1) {
    arr[existingIndex] = val;
    return arr;
  }
  return _.union(arr, [val]);
};

export const addArrayInArrayUnique = <T>(arr: T[], val: T[]) => {
  return _.union(arr, val);
};

export const removeItemInArrayUnique = <T>(arr: T[], val: T) => {
  return _.filter(arr, (x) => x !== val);
};

export const chunkArrayInGroups = (arr: any[], size: number) => {
  const clonedArr = _.cloneDeep(arr);
  var result = [];
  var j = 0;

  for (var i = 0; i < Math.ceil(clonedArr?.length / size); i++) {
    result[i] = clonedArr?.slice(j, j + size);
    j = j + size;
  }
  return result;
};

export const convertArrayWithArrays = <T>(arr: T[] = []): T[][] => {
  const result: T[][] = [];
  const numGroups = Math.ceil(arr?.length / 2);

  for (let i = 0; i < numGroups; i++) {
    const group: T[] = [];
    if (i * 2 < arr?.length) {
      group.push(arr[i * 2]);
    }
    if (i * 2 + 1 < arr?.length) {
      group.push(arr[i * 2 + 1]);
    }
    result.push(group);
  }

  // Remove any empty subarrays at the end
  while (result[result?.length - 1]?.length === 0) {
    result.pop();
  }

  return result || [];
};
