import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as thunks from "./thunks";
import { BlogDetails, BlogsPayload, BlogsResponse, FilterData } from "./types";
import { SliceStatusValue } from "common";
import { initialPayload } from "./constants";

type State = {
  blogs?: BlogsResponse;
  blogDetails?: BlogDetails;
  filters?: FilterData[];
  blogStatus?: string;
  isBlogsLoading?: boolean;
  categoryFilters?: string[];
  dateFilters?: string[];
  contentFilters?: string[];
  payload: BlogsPayload;
};

const initialState: State = {
  blogs: {},
  blogDetails: {},
  filters: [],
  blogStatus: SliceStatusValue.idle,
  categoryFilters: ["all"],
  dateFilters: ["all"],
  contentFilters: ["all"],
  isBlogsLoading: false,
  payload: initialPayload,
};

const incrementPayloadPage = (state: State) => {
  state.payload.page += 1;
};

const setPayload = (state: State, { payload }: PayloadAction<BlogsPayload>) => {
  state.payload = { ...state.payload, ...initialPayload, ...payload }; // reset limit and page
};

const deleteKeyFromPayload = (
  state: State,
  { payload }: PayloadAction<string>
) => {
  const newPayload = state.payload;
  delete newPayload[payload];
  state.payload = { ...newPayload, ...initialPayload };
};

const resetAllFilters = (state: State) => {
  state.categoryFilters = ["all"];
  state.dateFilters = ["all"];
  state.contentFilters = ["all"];
  state.payload = initialPayload;
};

const setContentFilters = (
  state: State,
  { payload }: PayloadAction<string[]>
) => {
  state.contentFilters = payload;
};

const setDateFilters = (state: State, { payload }: PayloadAction<string[]>) => {
  state.dateFilters = payload;
};

const setCategoryFilters = (
  state: State,
  { payload }: PayloadAction<string[]>
) => {
  state.categoryFilters = payload;
};

const slice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setContentFilters,
    setDateFilters,
    setCategoryFilters,
    resetAllFilters,
    incrementPayloadPage,
    setPayload,
    deleteKeyFromPayload,
  },
  extraReducers(builder) {
    builder.addCase(thunks.getBlogsThunk.rejected, (state) => {
      state.isBlogsLoading = false;
      state.blogStatus = SliceStatusValue.reject;
    });
    builder.addCase(thunks.getBlogsThunk.pending, (state) => {
      state.isBlogsLoading = true;
      state.blogStatus = SliceStatusValue.loading;
    });
    builder.addCase(thunks.getBlogsThunk.fulfilled, (state, { payload }) => {
      state.blogs = payload?.originalData || {};

      state.payload.page += 1;
      state.isBlogsLoading = false;
      state.blogStatus = SliceStatusValue.resolve;
    });
    builder.addCase(thunks.getBlogsNextPageThunk.rejected, (state) => {
      state.isBlogsLoading = false;
      state.blogStatus = SliceStatusValue.reject;
    });
    builder.addCase(thunks.getBlogsNextPageThunk.pending, (state) => {
      state.isBlogsLoading = true;
      state.blogStatus = SliceStatusValue.loading;
    });
    builder.addCase(
      thunks.getBlogsNextPageThunk.fulfilled,
      (state, { payload }) => {
        state.payload.page += 1;
        state.isBlogsLoading = false;
        state.blogStatus = SliceStatusValue.resolve;

        if (
          state.blogs?.data?.length > 0 &&
          state.payload?.page > 1 &&
          payload?.originalData?.data?.length > 0
        ) {
          state.blogs.data.push(...payload?.originalData?.data);
        }
      }
    );
    builder.addCase(
      thunks.getBlogFiltersThunk.fulfilled,
      (state, { payload }) => {
        let updatedFilters = state.filters;
        let newData = payload?.originalData?.data;
        if (newData.label === "year_published") {
          newData.options = newData.options.map((i) => String(i));
        }
        updatedFilters.push(newData);
        state.filters = updatedFilters;
      }
    );
    builder.addCase(
      thunks.getRelatedBlogsThunk.fulfilled,
      (state, { payload }) => {
        if (payload.success) {
          state.blogs = payload?.originalData;
        }
      }
    );
    builder.addCase(
      thunks.getBlogDetailsThunk.fulfilled,
      (state, { payload }) => {
        if (payload.success) {
          state.blogDetails = payload.originalData.data;
        }
      }
    );
  },
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks,
};
