import { Button, CircularProgress, TextField } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import BazarButton from "components/BazarButton";
import { H3, Paragraph, Small } from "components/Typography";
import { FlexBox } from "components/flex-box";
import { useSnackBar } from "hooks";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useAppSelector } from "hooks/use-app-selector";
import { useDialogueShow } from "hooks/useDialogue";
import modules from "modules";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  resendPayloadOtp,
  validatePayloadOtp,
} from "utils/pcw-account/login-registration-utils";

type OtpVerificationProps = {};

const { actions: ValidateOtpActions } = modules.pcwRegistration;

const { selectors: UserLoginSelectors, actions: UserLoginActions } =
  modules.pcwUserLogin;

const ResetPasswordOtp: React.FC<OtpVerificationProps> = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { showResetPasswordOtpClose } = useDialogueShow();

  const userData = useAppSelector(UserLoginSelectors?.selectUserMobileOrEmail);
  const resendTime = 240;
  const snackbar = useSnackBar();

  const [userOtpData, getUserData] = useState<string>();
  const [getOTP, setOTP] = useState("");
  const [time, setTime] = useState(resendTime);
  const [isCounting, setIsCounting] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [errMsg, setErrMsg] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const ValidateOtp = async () => {
    setIsLoading(true);
    const validatePayload = validatePayloadOtp(getOTP, userOtpData);
    const response = unwrapResult(
      await dispatch(ValidateOtpActions?.setValidateOtpThunk(validatePayload))
    );
    if (response?.success) {
      setErrMsg("");
      dispatch(
        UserLoginActions?.showUpdatePasswordForm({
          isOpen: true,
          emailOrMobile: userOtpData,
          otp: getOTP,
        })
      );
      showResetPasswordOtpClose();
      setIsLoading(false);
    }
    if (response?.message && !response?.success) {
      setErrMsg(response?.errors?.otp || response?.message);
      setIsLoading(false);
    }
  };

  const resendOtp = async () => {
    setIsLoading(true);
    const validatePayload = resendPayloadOtp(userOtpData);
    const response = unwrapResult(
      await dispatch(ValidateOtpActions?.setResendOtpThunk(validatePayload))
    );
    if (response?.success) {
      setIsLoading(false);
      setIsCounting(true);
      setErrMsg("");
      snackbar.show({
        severity: "info",
        message: "Successfully resend OTP",
      });
    }
    if (response?.message && !response?.success) {
      setErrMsg(response?.errors?.otp || response?.message);
      setIsLoading(false);
    }
  };

  const handleChangeInput = (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue.slice(0, 4);
    inputValue = inputValue.slice(0, 4);
    setOTP(inputValue);
  };

  const resendOtpCountdown = () => {
    setIsCounting(true);
    const timerId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(intervalId);
          setIsCounting(false);
          return prevTime;
        }
      });
    }, 1000);
    setIntervalId(timerId);
  };

  useEffect(() => {
    if (userData) {
      getUserData(userData);
    }

    if (isCounting) {
      resendOtpCountdown();
    } else {
      clearInterval(intervalId);
      setTime(resendTime);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, isCounting]);

  return (
    <FlexBox
      justifyContent={"center"}
      alignItems={"center"}
      minWidth={250}
      flexDirection={"column"}
      p={5}
      textAlign={"center"}
    >
      <H3>Enter OTP</H3>
      <Paragraph fontSize={"12px"}>
        {`Please Enter The 4 Digit Code Sent to ${userOtpData}`}
      </Paragraph>
      <TextField
        fullWidth
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            ValidateOtp();
          }
        }}
        label="Enter OTP"
        sx={{
          margin: 1,
          "& input": {
            textAlign: "center",
            padding: 2,
            fontSize: "16px",
            letterSpacing: 5,
            fontWeight: 900,
          },
        }}
        onChange={(e) => {
          handleChangeInput(e);
        }}
      />
      <Small
        mb={1}
        sx={{ textAlign: "center", display: "block", color: "red" }}
      >
        {errMsg ? errMsg : null}
      </Small>
      <BazarButton
        variant="contained"
        sx={{ mt: 2 }}
        color="secondary"
        fullWidth
        onClick={ValidateOtp}
        onKeyDown={ValidateOtp}
      >
        {isLoading ? <CircularProgress /> : "Verify"}
      </BazarButton>
      <Small sx={{ textDecoration: "underline", color: "#2F80ED" }} mt={1}>
        {isCounting ? (
          <Button color="info" variant="outlined" sx={{ fontSize: "12px" }}>
            {`Resend(${time})`}
          </Button>
        ) : (
          <Button
            color="info"
            variant="outlined"
            onClick={resendOtp}
            sx={{ fontSize: "12px" }}
          >
            {`Resend`}
          </Button>
        )}
      </Small>
    </FlexBox>
  );
};

export default ResetPasswordOtp;
