import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action } from "common";
import * as thunks from "./thunks";
import { PcwOrderHistoryState } from "./types";

const initialState: PcwOrderHistoryState = {
  orderDetailState: {},
  orderListState: [],
  isLoading: false,
  orderTotal: 0,
};

const slice = createSlice({
  name: "PcwOrderHistory",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(thunks.getOrderListThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunks.getOrderListThunk.rejected, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      thunks.getOrderListThunk.fulfilled,
      (state, { payload }) => {
        state.orderListState = payload?.originalData?.data || [];
        state.orderTotal = payload?.originalData?.meta?.total || 0;
      }
    );

    builder.addCase(thunks.getOrderDetailThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunks.getOrderDetailThunk.rejected, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      thunks.getOrderDetailThunk.fulfilled,
      (state, { payload }) => {
        state.orderDetailState = payload?.originalData?.data || {};
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
