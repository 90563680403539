import { Api, ApiResponse } from "common";
import {
  GetProductDetailsResponse,
  GetProductDetailsPayload,
  GetProductImagesPayload,
  GetProductImagesResponse,
} from "./types";
import { GetAvailabilityBranchesOfProductResponse } from "modules/products/types";

const getProductDetailsInitial = (
  payload: GetProductDetailsPayload
): ApiResponse<GetProductDetailsResponse> => {
  return Api.get(
    `api/ecomm/products/init/details/${payload.product_slug || ""}${
      payload?.product_uuid ? `?q=${payload?.product_uuid}` : ""
    }`
  );
};

const getProductDetails = (
  payload: GetProductDetailsPayload
): ApiResponse<GetProductDetailsResponse> => {
  return Api.get(
    `api/ecomm/product/details/${payload.product_slug}${
      payload?.product_uuid ? `?q=${payload?.product_uuid}` : ""
    }`
  );
};

const getProductImages = (
  payload: GetProductImagesPayload
): ApiResponse<GetProductImagesResponse> => {
  return Api.get(`api/ecomm/product/images/get/${payload?.product_slug}`);
};

const getAvailabilityBranchesOfProductApi = (
  uuid: string
): ApiResponse<GetAvailabilityBranchesOfProductResponse> => {
  return Api.get(`api/ecomm/products/available_branches/get/${uuid}`);
};

export const ProductDetailsApi = {
  getProductDetails,
  getProductDetailsInitial,
  getProductImages,
  getAvailabilityBranchesOfProductApi,
};
