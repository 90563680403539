export const name = "Pricelist";

export const initialPayload = {
  category: "0",
  keyword: null,
  sort: "asc",
  limit: 48,
  page: 1,
};

export const constants = {
  name,
  initialPayload,
};
