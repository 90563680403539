export const healthBeautyNavigation = [
  {
    icon: "Home",
    title: "Home",
    href: "/product/search/Dariry & Eggs",
  },
  {
    icon: "Popular",
    title: "Popular Products",
    href: "/product/search/Breakfast",
  },
  {
    icon: "Trending",
    title: "Trending Products",
    href: "/product/search/Frozen",
  },
  {
    icon: "Products",
    title: "All Products",
    href: "/product/search/vegetables",
  },
  {
    icon: "HealthBeauty",
    title: "Health and beauty",
    href: "/product/search/Fruits & Vegetables",
    child: [
      {
        title: "Beauty Items",
        href: "/product/search/Onion",
      },
      {
        title: "Medicine Items",
        href: "/product/search/Potato",
      },
      {
        title: "Makeup Items",
        href: "/product/search/Vegetable Pack",
      },
    ],
  },
  {
    icon: "Mascara",
    title: "Mascara",
    href: "/product/search/Dariry & Eggs",
    child: [
      {
        title: "Beauty Items",
        href: "/product/search/Onion",
      },
      {
        title: "Medicine Items",
        href: "/product/search/Potato",
      },
      {
        title: "Makeup Items",
        href: "/product/search/Vegetable Pack",
      },
    ],
  },
  {
    icon: "Powder",
    title: "Powder",
    href: "/product/search/Breakfast",
    child: [
      {
        title: "Beauty Items",
        href: "/product/search/Onion",
      },
      {
        title: "Medicine Items",
        href: "/product/search/Potato",
      },
      {
        title: "Makeup Items",
        href: "/product/search/Vegetable Pack",
      },
    ],
  },
  {
    icon: "Lotion",
    title: "Lotion",
    href: "/product/search/Frozen",
    child: [
      {
        title: "Beauty Items",
        href: "/product/search/Onion",
      },
      {
        title: "Medicine Items",
        href: "/product/search/Potato",
      },
      {
        title: "Makeup Items",
        href: "/product/search/Vegetable Pack",
      },
    ],
  },
  {
    icon: "HealthBeauty",
    title: "Makeup",
    href: "/product/search/Organic",
    child: [
      {
        title: "Beauty Items",
        href: "/product/search/Onion",
      },
      {
        title: "Medicine Items",
        href: "/product/search/Potato",
      },
      {
        title: "Makeup Items",
        href: "/product/search/Vegetable Pack",
      },
    ],
  },
  {
    icon: "Lipstick",
    title: "Lipstick",
    href: "/product/search/Canned Food",
    child: [
      {
        title: "Beauty Items",
        href: "/product/search/Onion",
      },
      {
        title: "Medicine Items",
        href: "/product/search/Potato",
      },
      {
        title: "Makeup Items",
        href: "/product/search/Vegetable Pack",
      },
    ],
  },
  {
    icon: "FaceMask",
    title: "Face Mask",
    href: "/product/search/Coffee & Snacks",
    child: [
      {
        title: "Beauty Items",
        href: "/product/search/Onion",
      },
      {
        title: "Medicine Items",
        href: "/product/search/Potato",
      },
      {
        title: "Makeup Items",
        href: "/product/search/Vegetable Pack",
      },
    ],
  },
  {
    icon: "Thermometer",
    title: "Digital Thermometer",
    href: "/product/search/Sauces & Jems",
  },
  {
    icon: "OxygenPump",
    title: "Oxygen Pump",
    href: "/product/search/Breakfast",
  },
  {
    icon: "LenseCase",
    title: "Lense Case Kit",
    href: "/product/search/Frozen",
  },
  {
    icon: "AutoMotive",
    title: "Automotive",
    href: "/product/search/Organic",
  },
];

export const topNewProducts = [
  {
    price: 189,
    title: "Vichy Liftactiv Nuit Cream",
    rating: 5,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (6).png",
    discount: 8,
    id: 928671027,
  },
  {
    price: 165,
    title: "Blue Stethoscope",
    rating: 3,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (3).png",
    discount: 9,
    id: 3965473517,
  },
  {
    price: 72,
    title: "Sphygmomanometer",
    rating: 3,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (7).png",
    discount: 12,
    id: 8082689609,
  },
  {
    price: 163,
    title: "Vichy Liftactiv Cream",
    rating: 3,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (8).png",
    discount: 7,
    id: 3417973164,
  },
  {
    price: 180,
    title: "Nivea Cream Soft Milk",
    rating: 3,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (9).png",
    discount: 8,
    id: 398141020,
  },
];

export const healthBeautyProducts = [
  {
    price: 230,
    title: "Vichy Liftactiv Nuit Cream",
    rating: 5,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (1).png",
    discount: 8,
    id: 7070790490,
  },
  {
    price: 65,
    title: "Sphygmomanometer",
    rating: 5,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (2).png",
    discount: 6,
    id: 1964376306,
  },
  {
    price: 181,
    title: "Fresh Strawberry",
    rating: 3,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (4).png",
    discount: 11,
    id: 6124133195,
  },
  {
    price: 211,
    title: "Nivea Cream",
    rating: 4,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (5).png",
    discount: 11,
    id: 4980810486,
  },
  {
    price: 189,
    title: "Vichy Liftactiv Nuit Cream",
    rating: 5,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (6).png",
    discount: 8,
    id: 928671027,
  },
  {
    price: 165,
    title: "Blue Stethoscope",
    rating: 3,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (3).png",
    discount: 9,
    id: 3965473517,
  },
  {
    price: 72,
    title: "Sphygmomanometer",
    rating: 3,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (7).png",
    discount: 12,
    id: 8082689609,
  },
  {
    price: 163,
    title: "Vichy Liftactiv Cream",
    rating: 3,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (8).png",
    discount: 7,
    id: 3417973164,
  },
  {
    price: 180,
    title: "Nivea Cream Soft Milk",
    rating: 3,
    category: "healthbeauty",
    imgUrl: "/assets/images/Health Shop/Product (9).png",
    discount: 8,
    id: 398141020,
  },
];

export const healthBeautyServices = [
  {
    icon: "Truck",
    title: "Fast Delivery",
    subtitle1: "Start from $10",
    subtitle2: "centric approach",
  },
  {
    icon: "PickUpBox",
    title: "Pick Up",
    subtitle1: "Secure system",
    subtitle2: "centric approach",
  },
  {
    icon: "OnlineService",
    title: "Online Support",
    subtitle1: "24/7 daily",
    subtitle2: "centric approach",
  },
];
