import AOS from "aos";
import "aos/dist/aos.css";
import { persistor, store } from "app/createStore";
import RTL from "components/RTL";
import GlobalSnackBar from "components/snackbar/GlobalSnackBar";
import { AppProvider } from "contexts/AppContext";
import SettingsProvider from "contexts/SettingContext";
import { NextPage } from "next";
import { AppProps } from "next/app";
import { DefaultSeo, DefaultSeoProps } from "next-seo";
import Router from "next/router";
import nProgress from "nprogress";
import "nprogress/nprogress.css";
import { Fragment, ReactElement, ReactNode, useEffect, useMemo } from "react";
import "react-quill/dist/quill.snow.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "simplebar/dist/simplebar.min.css";
import MuiTheme from "theme/MuiTheme";
import "../src/fake-db";
import { Analytics } from "@vercel/analytics/react";
import { SessionProvider } from "next-auth/react";
import GlobalLogin from "components/user-login/GlobalLogin";
import DialogueSessions from "pages-sections/sessions/DialogueSessions";
import modules from "modules";
import NextSEOConfig from "../next-seo.config";
import FacebookMetaPixel from "utils/FacebookMetaPixel";
import PageTracker from "components/tracking/PageTracker";
import { GlobalAPICalls } from "./api/auth/GlobalAPICalls";
import HotJarScript from "utils/HotJarScript";
import GoogleTags from "utils/GoogleTags";

const { utils: trackingUtils } = modules.tracking;

declare global {
  interface Window {
    fbq: any;
    dataLayer?: any;
  }
}

type MyAppProps = AppProps & {
  Component: NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
  };
  pageProps:
    | {
        session: any;
        seoProps?: DefaultSeoProps;
      }
    | any;
};

//Binding events.
Router.events.on("routeChangeStart", () => nProgress.start());
Router.events.on("routeChangeComplete", () => nProgress.done());
Router.events.on("routeChangeError", () => nProgress.done());
// small change
nProgress.configure({ showSpinner: false });

const App = ({
  Component,
  pageProps: { session, ...pageProps },
}: MyAppProps) => {
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    AOS.init();
    AOS.refresh();

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => trackingUtils.initialize(), []);

  const finalSeoProps = useMemo(() => {
    const config = { ...NextSEOConfig, ...pageProps?.seoProps };
    return config;
  }, [pageProps?.seoProps]);

  return (
    <Fragment>
      <meta key={"_app.tsx_fix_on_searchbar"} charSet="utf-8" />
      <meta
        name="viewport"
        key={"_app.tsx_fix_on_searchbar"}
        content="initial-scale=1.0, width=device-width"
      />
      <FacebookMetaPixel />
      <GoogleTags />
      <HotJarScript />
      <DefaultSeo key="NextSeoMainHead" {...finalSeoProps} />
      <SessionProvider
        session={session}
        refetchOnWindowFocus={false}
        refetchInterval={0}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <SettingsProvider>
              <AppProvider>
                <MuiTheme>
                  <RTL>{getLayout(<Component {...pageProps} />)}</RTL>
                  <GlobalAPICalls />
                  <GlobalSnackBar />
                  <GlobalLogin />
                  <DialogueSessions />
                  <PageTracker />
                </MuiTheme>
              </AppProvider>
            </SettingsProvider>
          </PersistGate>
        </Provider>
      </SessionProvider>

      <Analytics />
    </Fragment>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// App.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps };
// };

export default App;
