import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import {
  GetOrderDetailsPayload,
  MyPurchasedResponse,
  OrderDetailResponse,
  OrderListPayload,
  OrderListResponse,
} from "./types";
import { PcwOrderApi } from "./api";

export const getOrderListThunk = createAsyncThunk<
  CommonAxiosResponse<OrderListResponse>,
  OrderListPayload
>("PcwOrderList/getOrderListThunk", async (data) => {
  const res = await PcwOrderApi.getOrderHistoryList(data);
  const toThunk = await thunkResponseHandler(res);
  return toThunk;
});

export const getOrderDetailThunk = createAsyncThunk<
  CommonAxiosResponse<OrderDetailResponse>,
  GetOrderDetailsPayload
>("PcwOrderList/getOrderDetailThunk", async (data) => {
  const res = await PcwOrderApi.getOrderHistoryDetails(data);
  const toThunk = await thunkResponseHandler(res);
  return toThunk;
});

export const getMyPurchasedThunk = createAsyncThunk<
  CommonAxiosResponse<MyPurchasedResponse>,
  undefined
>("PcwOrderList/getMyPurchasedThunk", async () => {
  const res = await PcwOrderApi.getPruchasedProducts();
  const toThunk = await thunkResponseHandler(res);
  return toThunk;
});
