import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action } from "common";
import * as thunks from "./thunks";
import { PcwUserAddressState } from "./types";

const initialState: PcwUserAddressState = {
  userAddressListState: [],
  isLoading: false,
  userAddressDetailState: {},
};

const slice = createSlice({
  name: "PcwUserAddress",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(thunks.getUserAddressThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunks.getUserAddressThunk.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      thunks.getUserAddressThunk.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.userAddressListState = payload?.originalData?.data || [];
      }
    );

    builder.addCase(thunks.getUserAddressDetailsThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunks.getUserAddressDetailsThunk.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      thunks.getUserAddressDetailsThunk.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.userAddressDetailState = payload?.originalData?.data || {};
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
