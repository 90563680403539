import { navigationRoutes } from "../common/constants/navigation-routes";

export const deviceSize = {
  xs: 425,
  sm: 768,
  md: 1024,
  lg: 1440,
};

export const layoutConstant = {
  topbarHeight: 40,
  topbarDeliveryHeight: 24,
  grocerySidenavWidth: 280,
  containerWidth: 1200,
  mobileNavHeight: 64,
  headerHeight: 80,
  mobileHeaderHeight: 64,
};

export const ItemIcon = {
  GPU: "/assets/images/computer-parts/gpu.png",
  CPU: "/assets/images/computer-parts/cpu.png",
  Motherboard: "/assets/images/computer-parts/motherboard.png",
  RAM: "/assets/images/computer-parts/ram.png",
  Casing: "/assets/images/computer-parts/casing.png",
};

export const WorthItBuilderData = [
  {
    title: "Do it Yourself Builder",
    image: "/assets/images/banners/diy-builder.png",
    caption: "This feature is for knowledgeable and experienced pc enthusiast",
    link: navigationRoutes.diyBuilder,
  },
  {
    title: "AI Builder",
    image: "/assets/images/banners/ai-builder.png",
    caption: "It is for anyone who’s learning and unexperienced in PC Building",
    link: navigationRoutes.aiBuilder,
  },
  {
    title: "PC Package",
    image: "/assets/images/banners/pc-package.png",
    caption: "This feature is for anyone who’s looking for pre-build PC",
    link: navigationRoutes.pcPackage,
  },
  {
    title: "Build Gallery",
    image: "/assets/images/banners/pc-gallery.png",
    caption: "This feature is for those who is looking for PC Build inspirations",
    link: navigationRoutes.buildGallery,
  },
];

export const OtpPurpose = {
  reset_password: "reset_password",
  confirm_account: "confirm_account",
};
