import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import { WorthBuilderApi } from "./api";
import { AiBuildDataDetailsResponse, AiBuildPayload, AiBuildResponse } from "./types";

export const getAiBuilderThunk = createAsyncThunk<
    CommonAxiosResponse<AiBuildResponse>, AiBuildPayload
>("AIbuilder/getAiBuilderThunk", async (data) => {
    const res = await WorthBuilderApi.getAiPcBuilds(data);
    const toThunk = thunkResponseHandler(res);
    return toThunk;
});

export const getAiBuilderDetailsThunk = createAsyncThunk<
    CommonAxiosResponse<AiBuildDataDetailsResponse>, AiBuildPayload
>("AIbuilder/getAiBuilderDetailsThunk", async (data) => {
    const res = await WorthBuilderApi.getAiPcBuildsDetails({ id: data?.id });
    const toThunk = thunkResponseHandler(res);
    return toThunk;
});