import { Api, ApiResponse } from "common";
import {
  PcPackageDetailPayLoad,
  PcPackageDetailResponse,
  PcPackagePayload,
  PcPackageListResponse,
  PcPackageOnProductResponse,
} from "./types";
// import { GetFlashDealsPayload, GetFlashDealsResponse } from "./types";

const getPcPackage = (
  payload: PcPackagePayload
): ApiResponse<PcPackageListResponse> => {
  return Api.get(`api/ecomm/pc_package/list/get`, payload);
};

const getPcPackageDetails = (
  payload: PcPackageDetailPayLoad
): ApiResponse<PcPackageDetailResponse> => {
  return Api.get(`api/ecomm/pc_package/details/get/${payload.uuid}`);
};

const getPcPackageOnProduct = (
  uuid: string
): ApiResponse<PcPackageOnProductResponse> => {
  return Api.get(`api/ecomm/products/pc_packages/get/${uuid}`);
};

export const PcPackageApi = {
  getPcPackage,
  getPcPackageDetails,
  getPcPackageOnProduct,
};
