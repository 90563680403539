import SHA256 from "crypto-js/sha256";

import { store } from "app/createStore";
import { dateBirthPixel, drjInfo, standardizeMobileNumber } from "common";
import { CustomerInfoPixel } from "./types";
import {
  addressesThatUseAlabangPixel,
  addressesThatUseEarnshawPixel,
  addressesThatUseQCPixel,
  metaPixelAlabang,
  metaPixelEarnshaw,
  metaPixelQC,
} from "./constants";

const customerInfoTransformer = (customer: CustomerInfoPixel) => {
  const clonedCustomerInfo = { ...customer };

  Object.keys(clonedCustomerInfo).forEach((key) => {
    if (clonedCustomerInfo[key]) {
      clonedCustomerInfo[key] = clonedCustomerInfo[key].trim();
    } else {
      delete clonedCustomerInfo[key];
    }
  });

  if (clonedCustomerInfo?.em) {
    clonedCustomerInfo.em = SHA256(
      clonedCustomerInfo.em?.toLowerCase()
    ).toString();
  }

  if (clonedCustomerInfo?.ph) {
    clonedCustomerInfo.ph = SHA256(
      standardizeMobileNumber(clonedCustomerInfo.ph)
    ).toString();
  }

  if (clonedCustomerInfo?.fn) {
    clonedCustomerInfo.fn = SHA256(
      clonedCustomerInfo.fn?.toLowerCase()
    ).toString();
  }

  if (clonedCustomerInfo?.ln) {
    clonedCustomerInfo.ln = SHA256(
      clonedCustomerInfo.ln?.toLowerCase()
    ).toString();
  }

  if (clonedCustomerInfo?.db) {
    clonedCustomerInfo.db = SHA256(
      dateBirthPixel(clonedCustomerInfo.db)
    ).toString();
  }

  return clonedCustomerInfo;
};

export const customerInfoPixel = () => {
  const storeState = store?.getState();
  const userProfileLoginState = storeState?.PcwUserLogin?.userProfile;
  const userProfileState = storeState?.PcwUserProfile?.userProfileData;

  const customerInfo = {
    em: userProfileLoginState?.email || userProfileState?.email, // Needs hashing, trim, lowercase
    ph: userProfileLoginState?.mobile_no || userProfileState?.mobile_no, // Needs hashing, no symbols, leading zero, letters, Needs country code see src\redux\slices\sms\utils.ts drj admin
    fn: userProfileLoginState?.first_name || userProfileState?.first_name, // Needs hashing, trim, lower case, if special character in UTF-8 format.
    ln: userProfileLoginState?.last_name || userProfileState?.last_name, // Needs hashing, trim, lower case, if special character in UTF-8 format.
    db: userProfileState?.birthdate_raw, // Needs hashing, trim, YYYYMMDD
    // ge: f or m.. Needs hashing, trim. We will know this later?
    // ct: Needs hashing, trim. We will know this later?
    // st: Needs hashing, trim. We will know this later?
    // zp: Needs hashing, trim, no dash We will know this later?
    country: "ph", // Needs hashing, trim, uppercase
    page_id: drjInfo.facebookPageId,
  };
  return customerInfoTransformer(customerInfo);
};

export const getPixelIdViaAddress = (address: string) => {
  if (!address) {
    return "";
  }

  const checkAddress = (str: string, arr: string[]) =>
    arr.some((item) => str.toLowerCase().includes(item.toLowerCase()));

  if (checkAddress(address, addressesThatUseAlabangPixel)) {
    return metaPixelAlabang;
  }
  if (checkAddress(address, addressesThatUseQCPixel)) {
    return metaPixelQC;
  }
  if (checkAddress(address, addressesThatUseEarnshawPixel)) {
    return metaPixelEarnshaw; // TODO: Change to Manila pixel lang later on
  }
  return metaPixelEarnshaw;
};
