import { createSlice } from "@reduxjs/toolkit";
import {
  Action,
  addItemInArrayUnique,
  ErrorResponse,
  SliceStatusValue,
} from "common";
import {
  GetProductDetailsResponse,
  ProductDetailsState,
  GetProductDetailsPayload,
  GetProductImagesPayload,
  GetProductImagesResponse,
} from "./types";
import * as thunks from "./thunks";

const initialState: ProductDetailsState = {
  productDetailsArr: [],
  productImages: [],
};

/** Product Details Related Reducers **/
// ======================================================================== //
const productDetailsReducerClear = (state: ProductDetailsState) => {
  state.productDetails = undefined;
  state.productImages = [];
};

const productDetailsRequest = (
  state: ProductDetailsState,
  _action: Action<GetProductDetailsPayload>
) => {
  state.productDetailsStatus = SliceStatusValue.loading;
  state.productDetails = undefined;
};

const productDetailsResolve = (
  state: ProductDetailsState,
  action: Action<GetProductDetailsResponse>
) => {
  state.productDetailsStatus = SliceStatusValue.resolve;
  const { data } = action.payload;
  if (data?.product_slug) {
    state.productDetailsArr = addItemInArrayUnique(
      state.productDetailsArr,
      data
    );
  }
  state.productDetails = data;
};

const productDetailsReject = (
  state: ProductDetailsState,
  _action: Action<ErrorResponse>
) => {
  state.productDetailsStatus = SliceStatusValue.reject;
};

/** Product Images Related Reducers**/
// ======================================================================== //
const productImagesRequest = (
  state: ProductDetailsState,
  _action: Action<GetProductImagesPayload>
) => {
  state.productImagesStatus = SliceStatusValue.loading;
  state.productImages = [];
};

const productImagesResolve = (
  state: ProductDetailsState,
  action: Action<GetProductImagesResponse>
) => {
  const { data } = action.payload;
  state.productImagesStatus = SliceStatusValue.resolve;
  state.productImages = data?.images || [];
};

const productImagesReject = (
  state: ProductDetailsState,
  _action: Action<ErrorResponse>
) => {
  state.productImagesStatus = SliceStatusValue.reject;
};

const slice = createSlice({
  name: "ProductDetails",
  initialState: initialState,
  reducers: {
    productDetailsReducerClear,

    productDetailsRequest,
    productDetailsResolve,
    productDetailsReject,

    productImagesRequest,
    productImagesResolve,
    productImagesReject,
  },
});

//TODO: revert if cause bugs
// export const { actions, reducer } = slice;
export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
