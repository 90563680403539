import React, { useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { useDialogueShow } from "hooks/useDialogue";
import Login from "pages-sections/sessions/Login";
import Signup from "pages-sections/sessions/Signup";
import { useState } from "react";
import { useRouter } from "next/router";

type GlobalLoginProps = {};

const GlobalLogin = ({}) => {
  const {
    showLoginForm,
    showSignUpForm,
    showLoginFormClose,
    showSignUpFormClose,
    showSignUpFormOpen,
  } = useDialogueShow();

  const router = useRouter();

  const [signUpOpen, setSignUpOpen] = useState<boolean>(false);

  const toggleSignUp = () => {
    //if this dialogue was called by other component use this function to close this dialogue
    showSignUpFormClose();
  };

  const openSignUpDialogue = () => {
    setSignUpOpen(!signUpOpen);
  };

  const closeLoginForm = () => {
    showLoginFormClose();
  };

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (router?.query?.signup === "1") {
      showSignUpFormOpen();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.isReady]);

  return (
    <div>
      <Dialog open={showSignUpForm} onClose={toggleSignUp}>
        <DialogContent sx={{ margin: 0, padding: 0, minHeight: "70vh" }}>
          <Signup signUpClose={toggleSignUp} />
        </DialogContent>
      </Dialog>
      <Dialog open={showLoginForm} onClose={closeLoginForm}>
        <DialogContent sx={{ margin: 0, padding: 0, minHeight: "70vh" }}>
          <Login signUpOpen={openSignUpDialogue} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default GlobalLogin;
