import { Api, ApiResponse } from "common";
import {
  CommonResponse,
  ConfirmAccountPayload,
  EmailVerifyPayload,
  OtpPayload,
  ResendOtpPayload,
  UserRegistrationPayload,
  UserRegistrationResponse,
} from "./types";

const getUserRegistration = (
  payload: UserRegistrationPayload
): ApiResponse<UserRegistrationResponse> => {
  return Api.post(`api/ecomm/user/register`, payload);
};

const getValidateOtp = (payload: OtpPayload): ApiResponse<CommonResponse> => {
  return Api.post(`/api/ecomm/otp/validate`, payload);
};

const patchAccountConfirmOtp = (
  payload: ConfirmAccountPayload
): ApiResponse<CommonResponse> => {
  return Api.patch(`api/ecomm/user/account/confirm/otp`, payload);
};

const patchAccountConfirmEmail = (
  payload: EmailVerifyPayload
): ApiResponse<CommonResponse> => {
  return Api.patch(`api/ecomm/user/account/confirm/email`, payload);
};

const setResendOtp = (
  payload: ResendOtpPayload
): ApiResponse<CommonResponse> => {
  return Api.post(`/api/ecomm/resend/otp`, payload);
};

export const PcwLoginApi = {
  getUserRegistration,
  getValidateOtp,
  patchAccountConfirmOtp,
  setResendOtp,
  patchAccountConfirmEmail,
};
