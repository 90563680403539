export const categories = [
  {
    id: 27,
    name: "Accessories",
  },
  {
    id: 37,
    name: "AVR",
  },
  {
    id: 23,
    name: "Cable Wire",
  },
  {
    id: 20,
    name: "Casing",
  },
  {
    id: 16,
    name: "CPU",
  },
  {
    id: 26,
    name: "CPU Cooler",
  },
  {
    id: 47,
    name: "DP CABLE",
  },
  {
    id: 7,
    name: "Enclosure",
  },
  {
    id: 19,
    name: "Fan",
  },
  {
    id: 29,
    name: "Fan Hub",
  },
  {
    id: 41,
    name: "FLASH DRIVE",
  },
  {
    id: 36,
    name: "Gaming Chair",
  },
  {
    id: 42,
    name: "GLASS PANEL",
  },
  {
    id: 21,
    name: "GPU",
  },
  {
    id: 15,
    name: "HDD",
  },
  {
    id: 12,
    name: "HDMI Cable",
  },
  {
    id: 28,
    name: "Headset",
  },
  {
    id: 3,
    name: "Keyboard",
  },
  {
    id: 4,
    name: "Keyboard and Mouse",
  },
  {
    id: 39,
    name: "Laptop",
  },
  {
    id: 24,
    name: "LED Strips",
  },
  {
    id: 32,
    name: "Marketing Materials",
  },
  {
    id: 40,
    name: "MICROPHONE",
  },
  {
    id: 46,
    name: "MOBILE WIFI",
  },
  {
    id: 25,
    name: "Monitor",
  },
  {
    id: 17,
    name: "Motherboard",
  },
  {
    id: 2,
    name: "Mouse",
  },
  {
    id: 5,
    name: "Mouse Pad",
  },
  {
    id: 45,
    name: "NETWORK CONNECTOR",
  },
  {
    id: 38,
    name: "OPERATING SYSTEM",
  },
  {
    id: 10,
    name: "Patch Cord / LAN Cable",
  },
  {
    id: 18,
    name: "PSU",
  },
  {
    id: 13,
    name: "RAM",
  },
  {
    id: 44,
    name: "ROUTER",
  },
  {
    id: 8,
    name: "SATA Cable",
  },
  {
    id: 35,
    name: "SERVICE",
  },
  {
    id: 30,
    name: "Splitter",
  },
  {
    id: 14,
    name: "SSD",
  },
  {
    id: 43,
    name: "SWITCH",
  },
  {
    id: 9,
    name: "Thermal Paste",
  },
  {
    id: 11,
    name: "UPS",
  },
  {
    id: 6,
    name: "VGA Cable",
  },
  {
    id: 1,
    name: "WiFi Dongle",
  },
];

//This will be used to get the default main category in homepage and search
export const mainCategoriesSlugs = [
  "laptops",
  "pc-parts",
  "peripherals",
  "accessories-and-others",
];
