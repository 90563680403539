import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import { PcBundleApi } from "./api";
import {
  PCBundleDetailsResponse,
  PCBundleInitialResponse,
  PCBundleListResponse,
  PcBundleManufacturerResponse,
  PCBundlePayload,
  PCBundleSpecificationsResponse,
  PCBundleVideosResponse,
} from "./types";

export const getPcBundleListThunk = createAsyncThunk<
  CommonAxiosResponse<PCBundleListResponse>,
  string
>("pc-bundle/getPcBundleListThunk", async (data) => {
  const res = await PcBundleApi.getPCBundleList(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getPCBundleInitialThunk = createAsyncThunk<
  CommonAxiosResponse<PCBundleInitialResponse>,
  PCBundlePayload
>("pc-bundle/getPCBundleInitialThunk", async (data) => {
  const res = await PcBundleApi.getPCBundleInitial(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getPCBundleDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<PCBundleDetailsResponse>,
  PCBundlePayload
>("pc-bundle/getPCBundleDetailsThunk", async (data) => {
  const res = await PcBundleApi.getPCBundleDetails(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getPCBundleVideosThunk = createAsyncThunk<
  CommonAxiosResponse<PCBundleVideosResponse>,
  PCBundlePayload
>("pc-bundle/getPCBundleVideosThunk", async (data) => {
  const res = await PcBundleApi.getPCBundleVideos(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getPCBundleSpecificationsThunk = createAsyncThunk<
  CommonAxiosResponse<PCBundleSpecificationsResponse>,
  PCBundlePayload
>("pc-bundle/getPCBundleSpecificationsThunk", async (data) => {
  const res = await PcBundleApi.getPCBundleSpecifications(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getPCBundleManufacturersThunk = createAsyncThunk<
  CommonAxiosResponse<PcBundleManufacturerResponse>,
  undefined
>("pc-bundle/getPCBundleManufacturersThuunk", async () => {
  const res = await PcBundleApi.getPCBundleManufacturer();
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
