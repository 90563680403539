import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "./thunks";
import { KachiPointState } from "./types";

const initialState: KachiPointState = {
  totalKachiPoints: 0,
  isKachiPointsLoading: false,
  isEarnHistoryFetched: false,
  isSpendingHistoryFetched: false,
  userKachiPointsHistory: [],
  userEarnedHistory: [],
  userSpendingHistory: [],
};

const slice = createSlice({
  name: "kachiPoints",
  initialState,
  //TODO: if needed add reducers here
  reducers: {},
  extraReducers(builder) {
    builder.addCase(thunks.getKachiPointsThunks.pending, (state, {}) => {
      state.isKachiPointsLoading = true;
    });
    builder.addCase(thunks.getKachiPointsThunks.rejected, (state, {}) => {
      state.isKachiPointsLoading = false;
    });
    builder.addCase(
      thunks.getKachiPointsThunks.fulfilled,
      (state, { payload }) => {
        state.isKachiPointsLoading = false;
        state.userKachiPointsHistory = payload?.originalData?.data || [];
        state.totalKachiPoints = payload?.originalData?.total_kachi_points || 0;
      }
    );

    //====earned history
    builder.addCase(thunks.getEarnedHistoryThunk.pending, (state, {}) => {
      state.isKachiPointsLoading = true;
    });
    builder.addCase(thunks.getEarnedHistoryThunk.rejected, (state, {}) => {
      state.isKachiPointsLoading = false;
      state.isEarnHistoryFetched = false;
    });
    builder.addCase(
      thunks.getEarnedHistoryThunk.fulfilled,
      (state, { payload }) => {
        state.isKachiPointsLoading = false;
        state.userEarnedHistory = payload?.originalData?.data || [];
        state.isEarnHistoryFetched = true;
      }
    );

    //====spending history
    builder.addCase(thunks.getSpendingHistoryThunk.pending, (state, {}) => {
      state.isKachiPointsLoading = true;
    });
    builder.addCase(thunks.getSpendingHistoryThunk.rejected, (state, {}) => {
      state.isKachiPointsLoading = false;
      state.isSpendingHistoryFetched = false;
    });
    builder.addCase(
      thunks.getSpendingHistoryThunk.fulfilled,
      (state, { payload }) => {
        state.isKachiPointsLoading = false;
        state.userSpendingHistory = payload?.originalData?.data || [];
        state.isSpendingHistoryFetched = true;
      }
    );
  },
});

export const reducer = slice.reducer;
export const action = { ...slice.actions, ...thunks };
