import {
  Action,
  Response,
  isFailResponseData,
  isSuccessResponseData,
} from "common";
import { call, put, StrictEffect } from "redux-saga/effects";
import { BuildsGalleryApi } from "./api";
import { actions } from "./slice";
import { GetBuildsGalleryPayload, GetBuildsGalleryResponse } from "./types";

export function* getBuildsGallerySaga(
  action: Action<GetBuildsGalleryPayload>
): Generator<StrictEffect, void, Response<GetBuildsGalleryResponse>> {
  let data = null;
  let problem = null;
  try {
    const response = yield call(
      BuildsGalleryApi.getBuildsGallery,
      action.payload
    );
    data = response.data;
    problem = response.problem;
  } catch (error) {
    yield put(actions.buildsGalleryReject());
  }

  if (!data) {
    yield put(actions.buildsGalleryReject(problem));
    return;
  }

  if (isFailResponseData(data)) {
    yield put(actions.buildsGalleryReject(data));
    return;
  }

  if (isSuccessResponseData(data)) {
    yield put(actions.buildsGalleryResolve(data));
  }
}

export const sagas = {
  getBuildsGallerySaga,
};
