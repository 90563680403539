export const socialMediaLinks = {
  tiktok: "https://www.tiktok.com/@pcworth",
  
  // instagram
  instagram: "https://www.instagram.com/pc_worth",
  instagramAndroid: "instagram://user?username=pc_worth",
  instagramIos: "instagram://user?username=pc_worth",

  // Facebook
  facebook: "https://www.facebook.com/PC.WORTH",
  facebookFullUrl: "https://www.facebook.com/PC.WORTH",
  facebookUrlAndroid: "fb://page/100508121746833",
  facebookUrlIOS: "fb://profile/100508121746833",
  messenger: "https://m.me/100508121746833",
};
