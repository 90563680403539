import { DeliveryOptionsEnum } from "common/enums";
import { pcwEvent } from "../pcw-event";

export const deliveryWithoutShippingFee = [
  DeliveryOptionsEnum.techOutFree,
  DeliveryOptionsEnum.pickup,
];

export const allowedDeliveryOptionsForCOD = [
  DeliveryOptionsEnum.techOut,
  DeliveryOptionsEnum.techOutFree,
  DeliveryOptionsEnum.pickup,
];

export const deliveryOptions = [
  {
    label: "Free Shipping (New)",
    value: DeliveryOptionsEnum.techOutFree,
    img: "/assets/images/logos/free_shipping.png",
    prefHeight: 40,
    locationListNote:
      "Tech Out - Free Shipping is only available on NCR / Metro Manila",
    notes: [
      "Minimum of PHP 10,000 worth of Purchase",
      "Within Metro Manila only",
      "1-3 day(s) lead time",
    ],
    warning:
      "Free shipping is only available for Metro Manila with a lead time of 1-3 days and minimum of PHP 10,000 worth of purchase.",
  },
  {
    label: "Tech Out Express (Same Day)",
    value: DeliveryOptionsEnum.techOut,
    img: "/assets/images/kachi/kachi-motor.png",
    prefHeight: 60,
    locationListNote:
      "Tech Out Express delivery is only available on Metro Manila and nearby areas of Rizal, Cavite and Bulacan.",
    notes: [
      "Delivery is today (cut off time is 3:00PM)",
      "For Metro Manila and nearby areas of Rizal, Cavite and Bulacan",
      "Orders past 3:00 PM will be delivered tomorrow",
      "Our consigned motorcycle riders will take care of your order",
    ],
    warning:
      "Same day delivery is applicable only for Metro Manila and nearby areas of Rizal, Cavite and Bulacan with 3PM cut off time",
  },
  {
    label: "J&T Express",
    value: DeliveryOptionsEnum.jnt,
    img: "/assets/images/logos/j&t.png",
    prefHeight: 20,
    notes: [
      "Shipping To Luzon	1-5 days",
      "Shipping To Visayas	3-7 days",
      "Shipping To Mindanao	3-7 days",
      "Shipping To Island	5-6 days",
    ],
  },
  {
    label: "In-Store Pick Up",
    value: DeliveryOptionsEnum.pickup,
    img: "/assets/images/logos/pcworth_50x50.png",
    prefHeight: 40,
    notes: [
      "Pick Up at PC Worth Sampaloc Store.",
      "Strictly NO RESERVATION for unpaid item(s)",
    ],
  },
];

//For now we use splice just to remove the store pick up
if (pcwEvent.isEventOnGoing) {
  const indexOfPickUp = deliveryOptions.findIndex(
    (x) => x.label === "In-Store Pick Up"
  );
  deliveryOptions.splice(indexOfPickUp, 1);
}
