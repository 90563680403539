import { RootState, _, sevenDaysAgo } from "common";
import { createSelector } from "reselect";

const selectRecentlyViewedState = (state: RootState) =>
  state.RecentlyViewedItems;

const selectRecentlyViewedItems = createSelector(
  selectRecentlyViewedState,
  (state) =>
    state.recentlyViewedItems.filter((i) => i.lastViewed > sevenDaysAgo)
);

export const selectors = {
  selectRecentlyViewedItems,
};
