import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PcPackageState } from "./types";
import * as thunks from "./thunks";

const initialState: PcPackageState = {
  pcPackageList: [],
  pcPackageListLoading: false,

  pcPackageDetailState: {}, // Rename pcPackageDetailState
  pcPackageDetailLoading: false,
  pcPackagesOnProduct: [],
  pcPackagesOnProductLoading: false,
};

const slice = createSlice({
  name: "PcPackage",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      thunks.getPcPackageListThunk.pending,
      (state, { payload }) => {
        state.pcPackageListLoading = true;
      }
    );
    builder.addCase(
      thunks.getPcPackageListThunk.rejected,
      (state, { payload }) => {
        state.pcPackageListLoading = false;
      }
    );
    builder.addCase(
      thunks.getPcPackageListThunk.fulfilled,
      (state, { payload }) => {
        state.pcPackageListLoading = false;
        state.pcPackageList = payload?.originalData?.data || [];
      }
    );
    // for pc package details
    builder.addCase(
      thunks.getPcPackageDetailsThunk.pending,
      (state, { payload }) => {
        state.pcPackageDetailLoading = true;
      }
    );
    builder.addCase(
      thunks.getPcPackageDetailsThunk.rejected,
      (state, { payload }) => {
        state.pcPackageDetailLoading = false;
      }
    );
    builder.addCase(
      thunks.getPcPackageDetailsThunk.fulfilled,
      (state, { payload }) => {
        state.pcPackageDetailLoading = false;
        state.pcPackageDetailState = payload?.originalData?.data || {};
      }
    );
    //for pc packages on product carousel
    builder.addCase(
      thunks.getPcPackagesOnProductThunk.pending,
      (state, { payload }) => {
        state.pcPackagesOnProductLoading = true;
      }
    );
    builder.addCase(
      thunks.getPcPackagesOnProductThunk.rejected,
      (state, { payload }) => {
        state.pcPackagesOnProductLoading = false;
      }
    );
    builder.addCase(
      thunks.getPcPackagesOnProductThunk.fulfilled,
      (state, { payload }) => {
        state.pcPackagesOnProductLoading = false;
        state.pcPackagesOnProduct = payload?.originalData?.data || [];
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
