import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, RootState, thunkResponseHandler } from "common";
import { BlogsApi } from "./api";
import {
  BlogDetailsResponse,
  BlogFiltersPayload,
  BlogFiltersResponse,
  BlogsPayload,
  BlogsResponse,
} from "./types";

const getBlogsNextPageController = new AbortController();

export const getBlogsThunk = createAsyncThunk<
  CommonAxiosResponse<BlogsResponse>,
  undefined,
  { state: RootState }
>("blogs/getBlogsThunk", async (_, api) => {
  getBlogsNextPageController.abort();
  let request = api.getState().Blogs.payload;
  const res = await BlogsApi.getAllBlogs(request);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getBlogFiltersThunk = createAsyncThunk<
  CommonAxiosResponse<BlogFiltersResponse>,
  BlogFiltersPayload,
  { state: RootState }
>("blogs/getBlogFiltersThunk", async (data) => {
  const res = await BlogsApi.getBlogFilters(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
export const getRelatedBlogsThunk = createAsyncThunk<
  CommonAxiosResponse<BlogsResponse>,
  BlogsPayload,
  { state: RootState }
>("blogs/getRelatedBlogsThunk", async (data) => {
  const res = await BlogsApi.getAllBlogs(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getBlogDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<BlogDetailsResponse>,
  string,
  { state: RootState }
>("blogs/getBlogDetailsThunk", async (data) => {
  const res = await BlogsApi.getBlogDetails(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getBlogsNextPageThunk = createAsyncThunk<
  CommonAxiosResponse<BlogsResponse>,
  undefined,
  { state: RootState }
>("blogs/getBlogsNextPageThunk", async (_, api) => {
  getBlogsNextPageController.abort();
  let request = api.getState().Blogs.payload;
  const res = await BlogsApi.getAllBlogs(request);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
