import { createSlice } from "@reduxjs/toolkit";
import { Action, ErrorResponse } from "common";
import { SliceStatusValue, _ } from "common";
import {
  BannersState,
} from "./types";
import * as thunks from "./thunks";

const initialState: BannersState = {
  banners: [],
  bannersLoading: false,
};

const slice = createSlice({
  name: "Banner",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      thunks.getBannersThunk.pending,
      (state, { payload }) => {
        state.bannersLoading = true;
      }
    );
    builder.addCase(
      thunks.getBannersThunk.rejected,
      (state, { payload }) => {
        state.bannersLoading = false;
      }
    );
    builder.addCase(
      thunks.getBannersThunk.fulfilled,
      (state, { payload }) => {
        state.bannersLoading = false;
        state.banners = payload?.originalData?.data || [];
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
