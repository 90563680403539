import { Api, ApiResponse } from "common";
import { CategoriesPayload, CategoriesResponse, MainCategoriesPayload, MainCategoriesResponse, MainCategoryDetailsPayload } from "./types";

const getCategories = (
  params?: CategoriesPayload
): ApiResponse<CategoriesResponse> => {
  return Api.get("api/ecomm/category/get", params);
};

const getMainCategories = (
  params?: MainCategoriesPayload
): ApiResponse<MainCategoriesResponse> => {
  return Api.get("api/ecomm/category/main/get", params)
}

const getMainCategoryDetails = (
  params?: MainCategoryDetailsPayload
): ApiResponse<MainCategoriesResponse> => {
  return Api.get(`api/ecomm/category/main/details/get/${params.slug}`)
}

export const CategoryApi = {
  getCategories,
  getMainCategories,
  getMainCategoryDetails
};
