import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppSnackBarOption, AppState } from "./types";
import * as thunks from "./thunks";
import { drjInfo } from "common/constants";

const initialSnackBarOption: AppSnackBarOption = {
  message: "",
  severity: "info",
  open: false,
};

const initialState: AppState = {
  snackBarOptions: {},
  companyInfoState: drjInfo,
};

const showSnackBar = (
  state: AppState,
  { payload }: PayloadAction<AppSnackBarOption>
) => {
  state.snackBarOptions = {
    message: payload?.message,
    severity: payload?.severity,
    open: true,
  };
};

const hideSnackBar = (state: AppState) => {
  state.snackBarOptions = initialSnackBarOption;
};

const slice = createSlice({
  name: "App",
  initialState,
  reducers: {
    showSnackBar,
    hideSnackBar,
  },
  // TODO: fix this later
  extraReducers(builder) {
    builder.addCase(
      thunks.getCompanyInfoThunk.pending,
      (state, { payload }) => {
        // state.CompanyInfoState = true;
      }
    );
    builder.addCase(
      thunks.getCompanyInfoThunk.rejected,
      (state, { payload }) => {
        // state.CompanyInfoState = true;
      }
    );
    builder.addCase(
      thunks.getCompanyInfoThunk.fulfilled,
      (state, { payload }) => {
        state.companyInfoState = payload?.originalData?.data || {};
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
