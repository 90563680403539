import { Api, ApiResponse, SuccessResponse } from "common";
import {
  AddCartItemPayload,
  CartItemResponse,
  DeleteCartItemPayload,
  GetCartItemPayload,
  UpdateCartPayload,
} from "./types";

const addCartItem = (
  data: AddCartItemPayload
): ApiResponse<SuccessResponse> => {
  return Api.post("api/ecomm/user/cart/item/add", data);
};

const getCartItem = async (
  data: GetCartItemPayload
): ApiResponse<CartItemResponse> => {
  const apiResp = Api.get("api/ecomm/user/cart/get", data)
    .then((resp: any) => {
      if (Array.isArray(resp?.data?.data)) {
        if (resp?.data?.data?.length > 0) {
          resp?.data?.data?.forEach((cartItem: any) => {
            cartItem.amount = cartItem.retail_price;
            
            if (cartItem?.stocks > 0) {
              cartItem.with_stock = true;
            } else {
              cartItem.with_stock = false;
            }
            delete cartItem.discounted_price;
            delete cartItem.retail_price;
          });
        }
      }

      return resp;
    })
    .catch((err) => {
      return err;
    });
  return apiResp;
};

const updateCartItem = (
  data: UpdateCartPayload
): ApiResponse<SuccessResponse> => {
  return Api.patch(`api/ecomm/user/cart/item/update/${data.cart_item_id}`, {
    quantity: data.quantity,
  });
};

const deleteCartItem = (
  cart_item_id: DeleteCartItemPayload
): ApiResponse<SuccessResponse> => {
  return Api.remove(`api/ecomm/user/cart/item/delete`, cart_item_id);
};

export const UserCartApi = {
  addCartItem,
  getCartItem,
  updateCartItem,
  deleteCartItem,
};
