import { colors as MUIColors } from "@mui/material";

export const colors = {
  primary: "#0C304A",
  warning: "orange",
  error: "#f44336",
  success: "green",
  skyBlue: "#87ceeb",
  normal: "#263238",
  black: "#000000",
  link: MUIColors.blue[700],
  ...MUIColors,
  secondary: "#CF151A",
  backgroundGrey: "#292929",
  white: "#FAFAFA",
  text: "#000000",
};
