import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import { LocationsApi } from "./api";
import { GetLocationPayload, GetLocationResponse } from "./types";

export const getLocationRegions = createAsyncThunk<
  CommonAxiosResponse<GetLocationResponse>,
  GetLocationPayload
>("Locations/getLocationRegions", async (data) => {
  const res = await LocationsApi.getRegions(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getLocationProvinces = createAsyncThunk<
  CommonAxiosResponse<GetLocationResponse>,
  GetLocationPayload
>("Locations/getLocationProvinces", async (data) => {
  const res = await LocationsApi.getProvinces(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getLocationCities = createAsyncThunk<
  CommonAxiosResponse<GetLocationResponse>,
  GetLocationPayload
>("Locations/getLocationCities", async (data) => {
  const res = await LocationsApi.getCities(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getLocationBarangays = createAsyncThunk<
  CommonAxiosResponse<GetLocationResponse>,
  GetLocationPayload
>("Locations/getLocationBarangays", async (data) => {
  const res = await LocationsApi.getBarangays(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});
