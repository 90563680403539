import {
  Action,
  isFailResponseData,
  isSuccessResponseData,
  Response,
} from "common";
import { call, put, select, StrictEffect } from "redux-saga/effects";
import { selectors as categorySelectors } from "../category";
import { ProductsApi } from "./api";
import { actions } from "./slice";
import { CategoryItem } from "modules/category/types";
import { ProductsPayload, ProductsResponse } from "./types";

export function* productsSaga(
  action: Action<ProductsPayload>
): Generator<StrictEffect, void, Response<ProductsResponse> & CategoryItem> {
  let data = null;
  let problem = null;
  try {
    const categoryItem: CategoryItem = yield select(
      categorySelectors.selectCategoryItem
    );
    const payload = {
      ...action.payload,
      category: action.payload?.category || categoryItem?.id,
      limit: 70, // TODO: Later on apply proper pagination
    };
    const response = yield call(ProductsApi.getProducts, payload);
    data = response.data;
    problem = response.problem;
  } catch (error) {
    // yield put(actions.productsReject());
  }

  if (!data) {
    // yield put(actions.productsReject(problem));
    return;
  }

  if (isFailResponseData(data)) {
    // yield put(actions.productsReject(data));
    return;
  }

  if (isSuccessResponseData(data)) {
    // yield put(actions.productsResolve(data));
  }
}

export const sagas = {
  productsSaga,
};
