export const name = "Blogs";

export const blogFilters = {
  category: "blog_category",
  contentType: "content_type",
  date: "year_published",
};

export const initialPayload = { limit: 9, page: 1 };

export const constants = {
  name,
  blogFilters,
  initialPayload,
};
