import { createSlice } from "@reduxjs/toolkit";
import { PcwProfileState } from "./types";
import * as thunks from "./thunks";
import { Action } from "common";

const initialState: PcwProfileState = {
  userProfileData: {},
  isLoading: false,
};

const clearUserProfile = (state: PcwProfileState) => {
  state.userProfileData = {} || null;
};

const slice = createSlice({
  name: "PcwProfile",
  initialState,
  reducers: { clearUserProfile },
  extraReducers(builder) {
    builder.addCase(thunks.pcwUserProfileThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunks.pcwUserProfileThunk.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      thunks.pcwUserProfileThunk.fulfilled,
      (state, { payload }) => {
        state.userProfileData = payload?.originalData?.data || {};
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
