export const fontSize = 14;
export const fontFamily = [
  "Open Sans",
  "Roboto",
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Oxygen",
  "Ubuntu",
  "Cantarell",
  "Fira Sans",
  "Droid Sans",
  "Helvetica Neue",
  "sans-serif",
].join(",");

export const typography = {
  fontSize,
  fontFamily,
  htmlFontSize: 16,
  body1: { fontSize },
  body2: { fontSize },
};
