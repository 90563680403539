import { RootState, _ } from "common";
import { createSelector } from "reselect";

const selectAppState = (state: RootState) => state.App;

const selectSnackBarOptions = createSelector(
  selectAppState,
  (state) => state.snackBarOptions
);

const selectCompanyInfoData = createSelector(selectAppState, (state) => state.companyInfoState);

export const selectors = {
  selectSnackBarOptions,
  selectCompanyInfoData,
};
