import modules from "modules";
import { useAppSelector } from "./use-app-selector";
import { useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import { useSnackBar } from "./use-snackbar";

const { selectors: PcwProfileSelectors, actions: PcwProfileActions } =
  modules.pcwUserProfile;

export const useLoginStatus = () => {
  const router = useRouter();
  const snackbar = useSnackBar();

  const profileData = useAppSelector(
    PcwProfileSelectors?.selectUserProfileData
  );

  const isUserLogin = useMemo(() => {
    if (Object.keys(profileData).length !== 0) {
      // TODO: WE SHOULD BE LOOKING AT TOKEN NOT AT PROFILE DATA
      return true;
    }
    return false;
  }, [profileData]);

  //TODO: We should remove this later (Also on pages that has this logic) on once, Token checker is working properly.
  const checkUserAccessibility = useCallback(() => {
    // if (Object.keys(profileData).length === 0) {
    //   snackbar.show({
    //     severity: "warning",
    //     message: "Something went wrong, please try to login your account again",
    //   });
    // }
    return false;
  }, [profileData, snackbar]);

  return {
    profileData,

    isUserLogin,
    checkUserAccessibility,
  };
};
