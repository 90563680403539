import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action, SliceStatusValue } from "common";
import {
  MainCategoryData,
  ProductsPayload,
  ProductsResponse,
  ProductsState,
} from "./types";
import * as thunks from "./thunks";

const initialGetHomeProductsRequest: ProductsPayload = {
  page: 1,
  keyword: "",
  limit: 20,
};

const changeKeyword = (
  state: ProductsState,
  { payload }: PayloadAction<string>
) => {
  state.keyword = payload;
};

const changeMainCategoryId = (
  state: ProductsState,
  { payload }: PayloadAction<number | null>
) => {
  state.sub_main_category_id = payload;
};

const changeMainCategoryState = (
  state: ProductsState,
  { payload }: PayloadAction<MainCategoryData[] | null>
) => {
  state.sub_category_state = payload;
};

const productsSearchBarResolve = (
  state: ProductsState,
  action: Action<ProductsResponse>
) => {
  const { data } = action.payload;
  state.productsSearchBarLoading = false;
  state.productsSearchBarItems = data || [];
};

const slice = createSlice({
  name: "Products",
  initialState: {
    homeProducts: [],
    productsHomePageLoading: false,
    getHomeProductsRequest: initialGetHomeProductsRequest,
    productsSearchBarItems: [],
    searchFacet: [],
    productsSearchBarLoading: false,
    bestSellingProductsLoading: false,
    bestSellingProducts: [],
    keyword: "",
    isAlternativeProductsLoading: false,
    alternativeProductsData: [],
    main_category_id: null,
    sub_category_state: [],
  } as ProductsState,
  reducers: {
    productsSearchBarResolve,
    changeKeyword,
    changeMainCategoryId,
    changeMainCategoryState,
  },
  extraReducers(builder) {
    builder.addCase(
      thunks.getHomePageProductsThunk.pending,
      (state, { payload }) => {
        state.homeProducts = [];
        state.productsHomePageLoading = true;
        state.productsStatus = SliceStatusValue.loading;
        state.getHomeProductsRequest = initialGetHomeProductsRequest;
      }
    );
    builder.addCase(
      thunks.getHomePageProductsThunk.rejected,
      (state, { payload }) => {
        state.productsHomePageLoading = false;
        state.productsStatus = SliceStatusValue.reject;
      }
    );
    builder.addCase(
      thunks.getHomePageProductsThunk.fulfilled,
      (state, { payload }) => {
        state.productsHomePageLoading = false;
        state.productsStatus = SliceStatusValue.resolve;
        state.productsHomePaginationMeta = payload?.originalData?.meta;
        state.homeProducts = payload?.originalData?.data;

        state.getHomeProductsRequest.page =
          state.getHomeProductsRequest.page + 1;
      }
    );

    builder.addCase(
      thunks.getHomePageNextPageProductsThunk.pending,
      (state, { payload }) => {
        state.productsHomePageNextPageLoading = true;
        state.productsStatus = SliceStatusValue.loading;
      }
    );
    builder.addCase(
      thunks.getHomePageNextPageProductsThunk.rejected,
      (state, { payload }) => {
        state.productsHomePageNextPageLoading = false;
        state.productsStatus = SliceStatusValue.reject;
      }
    );
    builder.addCase(
      thunks.getHomePageNextPageProductsThunk.fulfilled,
      (state, { payload }) => {
        state.productsHomePageNextPageLoading = false;
        state.productsStatus = SliceStatusValue.resolve;
        state.productsHomePaginationMeta = payload?.originalData?.meta;

        if (
          state.homeProducts?.length > 0 &&
          state.getHomeProductsRequest?.page > 1 &&
          payload?.originalData?.data?.length > 0
        ) {
          state.getHomeProductsRequest.page =
            state.getHomeProductsRequest.page + 1;
          state.homeProducts.push(...payload?.originalData?.data);
        }
      }
    );

    builder.addCase(
      thunks.getSearchBarProductsThunk.pending,
      (state, { payload }) => {
        state.productsSearchBarLoading = true;
      }
    );
    builder.addCase(
      thunks.getSearchBarProductsThunk.rejected,
      (state, { payload }) => {
        state.productsSearchBarLoading = false;
      }
    );
    builder.addCase(
      thunks.getSearchBarProductsThunk.fulfilled,
      (state, { payload }) => {
        state.productsSearchBarLoading = false;
        state.productsSearchBarItems = payload?.originalData?.data || [];
      }
    );

    builder.addCase(
      thunks.getLatestProductsThunk.pending,
      (state, { payload }) => {
        state.latestProductsLoading = true;
      }
    );
    builder.addCase(
      thunks.getLatestProductsThunk.rejected,
      (state, { payload }) => {
        state.latestProductsLoading = false;
      }
    );
    builder.addCase(
      thunks.getLatestProductsThunk.fulfilled,
      (state, { payload }) => {
        state.latestProductsLoading = false;
        state.latestProductsItems = payload?.originalData?.data || [];
      }
    );

    builder.addCase(
      thunks.getSearchPageProductsThunk.pending,
      (state, { payload }) => {
        state.searchProductsItems = [];
        state.searchProductsLoading = true;
        state.searchProductsResponse = undefined;
      }
    );
    builder.addCase(
      thunks.getSearchPageProductsThunk.rejected,
      (state, { payload }) => {
        state.searchProductsLoading = false;
      }
    );
    builder.addCase(
      thunks.getSearchPageProductsThunk.fulfilled,
      (state, { payload }) => {
        state.searchProductsLoading = false;
        state.searchProductsResponse = payload?.originalData;
        state.searchProductsItems = payload?.originalData?.data || [];

        state.searchFacet = payload?.originalData?.facets || [];
      }
    );
    builder.addCase(
      thunks.getBestSellingProductsThunk.pending,
      (state, { payload }) => {
        state.bestSellingProductsLoading = true;
      }
    );
    builder.addCase(
      thunks.getBestSellingProductsThunk.rejected,
      (state, { payload }) => {
        state.bestSellingProductsLoading = false;
      }
    );
    builder.addCase(
      thunks.getBestSellingProductsThunk.fulfilled,
      (state, { payload }) => {
        state.bestSellingProductsLoading = false;
        state.bestSellingProducts = payload?.originalData?.data || [];
      }
    );
    builder.addCase(
      thunks.getAlternativeProductsThunk.pending,
      (state, { payload }) => {
        state.isAlternativeProductsLoading = true;
      }
    );
    builder.addCase(
      thunks.getAlternativeProductsThunk.rejected,
      (state, { payload }) => {
        state.isAlternativeProductsLoading = false;
      }
    );
    builder.addCase(
      thunks.getAlternativeProductsThunk.fulfilled,
      (state, { payload }) => {
        state.isAlternativeProductsLoading = false;
        state.alternativeProductsData = payload?.originalData?.data || [];
      }
    );
  },
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
