import {
  create,
  ApiResponse as ApiSauceResponse,
  ApiErrorResponse as ApiSauceErrResponse,
} from "apisauce";
import { store } from "app/createStore";
import { AxiosRequestConfig } from "axios";
import modules from "modules";
import { BASE_URL, DEFAULT_HEADERS, localize } from "../constants";
import { ApiResponse, CommonAxiosResponse } from "../types";
import { currentTimeMilliseconds } from "./date-time";
import axios from "axios";

import { actions as pcwProfileActions } from "modules/pcw-profile/slice";

let isRefreshing = false;
let refreshSubscribers = [];

const api = create({ baseURL: BASE_URL });

const getTokenFromStore = () => {
  const clientToken = store?.getState().OAuth?.access_token;
  const expiresIn = store?.getState().OAuth?.expires_in || 0;
  let validClientToken =
    currentTimeMilliseconds() >= expiresIn ? null : clientToken;
  return validClientToken;
};

api.axiosInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${getTokenFromStore()}`,
      ...DEFAULT_HEADERS,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;
    
    if (status === 401) {
      if (!isRefreshing) {
        const userProfileData = store.getState().PcwUserProfile.userProfileData;
        const isLoggedIn = Object.keys(userProfileData).length !== 0;
        if (isLoggedIn) {
          store.dispatch(pcwProfileActions.clearUserProfile());
          window.location.href = "/";
        }

        isRefreshing = true;
        modules.oauth.services.oAuthGetNewToken().then((resp) => {
          isRefreshing = false;
          const newToken = resp?.access_token;
          onRefreshed(newToken);
        });
      }

      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh((token) => {
          // replace the expired token and retry
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    } else {
      return Promise.reject(error);
    }
  }
);

const subscribeTokenRefresh = (cb: (token?: string) => void) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (token: string) => {
  refreshSubscribers.map((cb) => cb(token));
};

export const thunkResponseHandler = async (
  response: ApiSauceResponse<any, any>
) => {
  let handledResponse: CommonAxiosResponse<any> = {
    success: false,
    message: "",
    errors: null,
    originalData: null,
  };

  if (!response.ok && response.problem) {
    handledResponse.success = false;
    handledResponse.originalData = null;

    if (response.problem === "NETWORK_ERROR") {
      handledResponse.message = localize.ERR_API_NETWORK;
    }
    if (response.problem === "SERVER_ERROR") {
      handledResponse.message = localize.ERR_API_SERVER;
    }
    if (response.problem === "TIMEOUT_ERROR") {
      handledResponse.message = localize.ERR_API_TIMEOUT;
    }
    if (response.problem === "CONNECTION_ERROR") {
      handledResponse.message = localize.ERR_API_SERVER_NA;
    }
    if (response.problem === "CLIENT_ERROR") {
      handledResponse.message =
        response.data.message || response.data.error || "Unknown error.";
    }
    return handledResponse;
  }

  if (response.ok && !response?.data?.success) {
    handledResponse = {
      success: false,
      errors: response?.data?.errors || null,
      message: response?.data?.message ? response?.data?.message : "",
      originalData: response?.data ? response.data : null,
    };
    return handledResponse;
  }

  handledResponse = {
    success: true,
    message: response?.data?.message ? response?.data?.message : "",
    originalData: response?.data ? response.data : null,
  };

  return handledResponse;
};

const get = async <T>(
  url: string,
  params?: Record<string, unknown>,
  config?: AxiosRequestConfig
): ApiResponse<T> => {
  return api.get(url, params, config);
};

const post = async <T>(
  url: string,
  data?: Record<string, unknown>,
  config?: AxiosRequestConfig
): ApiResponse<T> => {
  return api.post(url, data, config);
};

const patch = async <T>(
  url: string,
  data?: Record<string, unknown>,
  config?: AxiosRequestConfig
): ApiResponse<T> => {
  return api.patch(url, data, config);
};

const remove = async <T>(
  url: string,
  params?: Record<string, unknown>,
  config?: AxiosRequestConfig
): ApiResponse<T> => {
  return api.delete(url, params, config);
};

export const Api = {
  get,
  post,
  patch,
  remove,
};
