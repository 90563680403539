import { CategoriesResponse, MainCategoriesPayload, MainCategoriesResponse, MainCategoryDetailsPayload } from "./types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonAxiosResponse, thunkResponseHandler } from "common";
import { CategoryApi } from "./api";

export const getMainCategoriesThunk = createAsyncThunk<
  CommonAxiosResponse<MainCategoriesResponse>,
  MainCategoriesPayload
>("categories/getMainCategoriesThunk", async (data) => {
  const res = await CategoryApi.getMainCategories(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getMainCategoryDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<MainCategoriesResponse>,
  MainCategoryDetailsPayload
>("categories/getMainCategoryDetailsThunk", async (data) => {
  const res = await CategoryApi.getMainCategoryDetails(data);
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});

export const getAllCategoriesThunk = createAsyncThunk<
  CommonAxiosResponse<CategoriesResponse>
>("categories/getAllCategoriesThunk", async () => {
  const res = await CategoryApi.getCategories();
  const toThunk = thunkResponseHandler(res);
  return toThunk;
});