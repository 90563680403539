export enum ProductDetailsStatusEnum {
  available = 1,
  outOfStock = 2,
}

export enum ProductSortDirectionEnum {
  Ascending = "Lowest to Highest",
  Descending = "Highest to Lowest",
}

export enum ProdSortDirPayloadEnum {
  Ascending = "lowest-to-highest",
  Descending = "highest-to-lowest",
}
