import { createSelector } from "reselect";
import { RootState } from "common";
import _ from "lodash";

const selectOrderHistory = (state: RootState) => state.PcwOrderHistory;

const selectOrderList = createSelector(
  selectOrderHistory,
  (state) => state.orderListState
);

const selectOrderListTotal = createSelector(selectOrderHistory, (state) => {
  return state.orderTotal;
});

const selectOrderDetail = createSelector(
  selectOrderHistory,
  (state) => state.orderDetailState
);

export const selectors = {
  selectOrderList,
  selectOrderDetail,
  selectOrderListTotal,
};
