export const localize = {
  // Error in API service //
  ERR_API_NETWORK: "Cannot connect to the internet.",
  ERR_API_SERVER: "Server error. Please try again later.",
  ERR_API_TIMEOUT: "Server didn't respond in time.",
  ERR_API_SERVER_NA: "Server not available.",
  ERR_API_UNKNOWN: "Unknown error :(",

  // Info Messages.
  ITEMS_ADDED_CART: `Item(s) Added to Cart`,
};
