import { RootState, _ } from "common";
import { createSelector } from "reselect";

const selectBuildsGalleryState = (state: RootState) => state.BuildsGallery;

const selectBuildsGallery = createSelector(
  selectBuildsGalleryState,
  (state) => state.buildsGallery
);

const selectBuildsGalleryLoading = createSelector(
  selectBuildsGalleryState,
  (state) => state.buildsGalleryLoading
);

const selectBuildsGalleryPage = createSelector(
  selectBuildsGalleryState,
  (state) => state.buildsGalleryPage
);

const selectBuildsGalleryPageLoading = createSelector(
  selectBuildsGalleryState,
  (state) => state.buildsGalleryPageLoading
);

const selectBuildsItemsOnProduct = createSelector(
  selectBuildsGalleryState,
  (state) => state.buildsGalleryOnProduct
);

const selectBuildsProductSearchName = createSelector(
  selectBuildsGalleryState,
  (state) => state.buildsSearchProductName
);

export const selectors = {
  selectBuildsGallery,
  selectBuildsGalleryLoading,
  selectBuildsGalleryPage,
  selectBuildsGalleryPageLoading,
  selectBuildsItemsOnProduct,
  selectBuildsProductSearchName,
};
