export const frequentlyBoughtData = [
  {
    title: "Premium Grocery Collection",
    price: 250,
    imgUrl: "/assets/images/products/Groceries/2.PremiumGroceryCollection.png",
    imgGroup: [
      "/assets/images/products/Groceries/2.PremiumGroceryCollection.png",
      "/assets/images/products/Groceries/2.PremiumGroceryCollection.png",
      "/assets/images/products/Groceries/2.PremiumGroceryCollection.png",
    ],
    category: "groceries",
    discount: 11,
    id: 1818036939,
  },
  {
    title: "Premium Grocery Pack",
    price: 250,
    imgUrl: "/assets/images/products/Groceries/3.PremiumGroceryPack.png",
    imgGroup: [
      "/assets/images/products/Groceries/3.PremiumGroceryPack.png",
      "/assets/images/products/Groceries/3.PremiumGroceryPack.png",
      "/assets/images/products/Groceries/3.PremiumGroceryPack.png",
    ],
    category: "groceries",
    discount: 14,
    id: 8990926163,
  },
  {
    title: "Fresh&Real CHole Masala",
    price: 250,
    imgUrl: "/assets/images/products/Groceries/4.FreashRealCholeMasala.png",
    imgGroup: [
      "/assets/images/products/Groceries/4.FreashRealCholeMasala.png",
      "/assets/images/products/Groceries/4.FreashRealCholeMasala.png",
      "/assets/images/products/Groceries/4.FreashRealCholeMasala.png",
    ],
    category: "groceries",
    discount: 13,
    id: 3085195407,
  },
  {
    title: "Gum Pack",
    price: 250,
    imgUrl: "/assets/images/products/Groceries/5.GumPack.png",
    imgGroup: [
      "/assets/images/products/Groceries/5.GumPack.png",
      "/assets/images/products/Groceries/5.GumPack.png",
      "/assets/images/products/Groceries/5.GumPack.png",
    ],
    category: "groceries",
    discount: 13,
    id: 3098429480,
  },
];

export const relatedProducts = [
  {
    title: "Colgate Advance Protection Toothpaste",
    price: 250,
    imgUrl:
      "/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png",
    category: "groceries",
    discount: 12,
    id: 4145242238,
    imgGroup: [
      "/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png",
      "/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png",
      "/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png",
    ],
  },
  {
    title: "Catch Sprinklers Chat Masala",
    price: 250,
    imgUrl: "/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png",
    category: "groceries",
    discount: 19,
    id: 7485173785,
    imgGroup: [
      "/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png",
      "/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png",
      "/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png",
    ],
  },
  {
    title: "Catch Italian Seasoning Grinder",
    price: 250,
    imgUrl:
      "/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png",
    category: "groceries",
    discount: 10,
    id: 8359512442,
    imgGroup: [
      "/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png",
      "/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png",
      "/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png",
    ],
  },
  {
    title: "Tadka Garam Masala",
    price: 250,
    imgUrl: "/assets/images/products/Groceries/11.TadkaGaramMasala.png",
    category: "groceries",
    discount: 16,
    id: 2796979839,
    imgGroup: [
      "/assets/images/products/Groceries/11.TadkaGaramMasala.png",
      "/assets/images/products/Groceries/11.TadkaGaramMasala.png",
      "/assets/images/products/Groceries/11.TadkaGaramMasala.png",
    ],
  },
];
