import { Api, ApiResponse } from "common";
import { GetFlashDealsPayload, GetFlashDealsResponse } from "./types";

const getFlashDeals = (
  payload: GetFlashDealsPayload
): ApiResponse<GetFlashDealsResponse> => {
  return Api.get(`api/ecomm/flash_deal/get`, payload);
};

export const FlashDealsApi = {
  getFlashDeals,
};
