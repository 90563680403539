import { call, put, select, StrictEffect } from "redux-saga/effects";
import { isUnauthenticatedData, Response } from "../../common";
import { OAuthApi } from "./api";
import { selectors } from "./selectors";
import { actions } from "./slice";
import { isClientTokenData } from "./utils";
import { ClientTokenResponse } from "./types";

export function* clientTokenSaga(): Generator<
  StrictEffect,
  void,
  Response<ClientTokenResponse> & string
> {
  const token = yield select(selectors.selectClientToken);
  if (token) {
    return;
  }

  let data = null;
  let problem = null;
  try {
    const response = yield call(OAuthApi.getClientToken);
    data = response.data;
    problem = response.problem;
  } catch (error) {
    yield put(actions.clientTokenReject());
  }

  if (!data) {
    yield put(actions.clientTokenReject(problem));
    return;
  }

  if (isUnauthenticatedData(data)) {
    yield put(actions.clearClientToken(data));
    return;
  }

  if (isClientTokenData(data)) {
    yield put(actions.clientTokenResolve(data));
  }
}

export const sagas = {
  clientTokenSaga,
};
