import { CircularProgress, TextField } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import BazarButton from "components/BazarButton";
import { H2, Paragraph, Small } from "components/Typography";
import { FlexBox } from "components/flex-box";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useDialogueShow } from "hooks/useDialogue";
import modules from "modules";
import { useRouter } from "next/router";
import { useState } from "react";

type ForgotPasswordProps = {};

const { actions: PcwLoginActions } = modules.pcwUserLogin;

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { showForgotPasswordClose, showLoginFormClose } = useDialogueShow();

  const [payloadField, getPayloadField] = useState();
  const [errMsg, getErrMsg] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const handleChangeInput = (event) => {
    let inputValue = event.target.value;
    getPayloadField(inputValue);
  };

  const sendEmailOtp = async () => {
    setIsLoading(true);
    const regex = /^\d+$/;
    const isMobile = regex.test(payloadField);
    const response = unwrapResult(
      await dispatch(
        PcwLoginActions?.setPcwUserResetPasswordThunk(
          isMobile ? { mobile_no: payloadField } : { email: payloadField }
        )
      )
    );
    if (response?.success) {
      setIsLoading(false);
      dispatch(
        PcwLoginActions?.showPasswordOtp({
          isOpen: true,
          emailOrMobile: payloadField,
        })
      );
      showForgotPasswordClose();
    }
    if (response?.message && !response?.success) {
      getErrMsg(
        response?.errors?.email ||
          response?.errors?.mobile_no ||
          response?.message
      );
      setIsLoading(false);
    }
  };

  const navigateSignUp = () => {
    dispatch(PcwLoginActions?.showSignUpForm(true));
    showForgotPasswordClose();
    showLoginFormClose();
  };

  return (
    <FlexBox
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      p={4}
      maxWidth={350}
      textAlign={"center"}
    >
      <H2>Forgot Password?</H2>
      <FlexBox
        maxWidth={300}
        minHeight={50}
        margin={"auto"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Paragraph fontSize={"12px"} textAlign={"center"} mb={1}>
          {`Provide your account email or mobile no. for which you want to reset your password`}
        </Paragraph>
      </FlexBox>
      <TextField
        fullWidth
        onKeyDown={(event) => event.key === "Enter" && sendEmailOtp()}
        label="Enter Email or Mobile"
        sx={{
          marginTop: 2,
          "& input": {
            fontSize: "14px",
            padding: 1,
          },
        }}
        onChange={(e) => {
          handleChangeInput(e);
        }}
      />
      <Small
        mb={1}
        maxHeight={50}
        sx={{ textAlign: "center", display: "block", color: "red" }}
      >
        {errMsg ? errMsg : null}
      </Small>
      <BazarButton
        variant="contained"
        sx={{ mt: 2 }}
        color="secondary"
        fullWidth
        onClick={sendEmailOtp}
      >
        {isLoading ? <CircularProgress /> : "Reset Password"}
      </BazarButton>
      <Small mt={2} onClick={navigateSignUp}>
        {"Don't have an account? "}
        <a style={{ borderBottom: "1px solid", cursor: "pointer" }}>Sign up</a>
      </Small>
    </FlexBox>
  );
};

export default ForgotPassword;
