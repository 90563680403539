import { colors } from "common/constants/colors";
import { LocationTabValue } from "./types";

const getTabAllyProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
    sx: {
      border: "solid",
      borderWidth: 0,
      fontSize: 12,
      fontWeight: 500,
      borderBottomWidth: 1.5,
      borderBottomColor: colors.grey[400],
      "&.Mui-selected": {
        color: colors.primary,
        fontWeight: 700,
      },
    },
  };
};

const locationTabValToString = (newLocationTabVal: LocationTabValue) => {
  const regionName = newLocationTabVal?.region?.name || "";
  const provinceName = newLocationTabVal?.province?.name || "";
  const cityName = newLocationTabVal?.city?.name || "";
  const brgyName = newLocationTabVal?.barangay?.name || "";

  const namesArr = [regionName, provinceName, cityName, brgyName];
  const namesStr = namesArr?.join(", ");
  const namesStrCaps = namesStr?.toUpperCase();
  return namesStrCaps;
};

export const utils = {
  getTabAllyProps,
  locationTabValToString,
};
