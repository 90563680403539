import { RootState, _ } from "common";
import { createSelector } from "reselect";

const selectOptionsState = (state: RootState) => state.Options;

const selectPaymongoMerchantInterest = createSelector(
  selectOptionsState,
  (state) => state.paymongoMerchantInterest
);

const selectPaymongoMerchantInterestLoading = createSelector(
  selectOptionsState,
  (state) => state.paymongoMerchantInterestLoading
);

const selectSocialBtnOptions = createSelector(
  selectOptionsState,
  (state) => state.isSocialBtnLogin
);

export const selectors = {
  selectPaymongoMerchantInterest,
  selectPaymongoMerchantInterestLoading,
  selectSocialBtnOptions,
};
